import type { PermissionTab } from "@/components/PermissionTabs"
import PermissionTabs from "@/components/PermissionTabs"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import Permission from "@/models/Permission"
import { css } from "vite-css-in-js"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import ReviewRequests from "./management"
import MyHolidays from "./my-requests/MyHolidays"

const pageStyles = css`
	margin: auto;
	width: 100%;
	max-width: 72rem;
`

const HolidayPage: ReactiveComponent = () => {
	const loggedInUser = useLoggedInUser()

	const tabs: PermissionTab[] = [
		{
			id: "my-holidays",
			name: "My Holidays",
			renderContent: () => <MyHolidays user={loggedInUser} />,
		},
		{
			id: "manage-holidays",
			name: "Manage Holidays",
			permission: (user) => user.isHolidayApprover,
			renderContent: ReviewRequests,
		},
		{
			id: "planner",
			name: "Planner",
			permission: Permission.HolidayPlanner,
			url: "/holidays/planner",
			linkType: "router",
		},
	]

	return () => (
		<BasicPage title="Holidays">
			<div class={pageStyles}>
				<PermissionTabs tabs={tabs} type="query" hideWithSingle />
			</div>
		</BasicPage>
	)
}

export default defineComponent(HolidayPage)
