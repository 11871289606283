import StandardForm from "@/components/StandardForm"
import usePopups from "@/data/popupsStore"
import { shallowRef } from "vue"
import { Alert, ConfirmResult, defineComponent, Required, requiredProp } from "vue-utils"

interface Props {
	cancel(): void
	confirm(reason: string): void
}

type Result =
	| {
			type: ConfirmResult.Confirm
			reason: string
	  }
	| {
			type: ConfirmResult.Cancel
	  }

const ConfirmCancelRequestPopup = defineComponent<Props>({
	props: {
		cancel: requiredProp(Function),
		confirm: requiredProp(Function),
	},
	setup(props) {
		const reason = shallowRef("")

		return () => (
			<StandardForm
				showDivider={false}
				submit={{
					onClick: () => props.confirm(reason.value),
					text: "Confirm",
				}}
				cancel={props.cancel}
			>
				<Alert title="Confirm Cancel Request">
					<p>Are you sure you wish to cancel this holiday request?</p>
					<p>
						All time sheets which have been generated will be automatically deleted, and an email will be sent to the
						user with the reason provided below.
					</p>

					<label>
						<Required label="Enter reason for cancellation:" />
						<textarea v-model={reason.value} rows={3} required />
					</label>
				</Alert>
			</StandardForm>
		)
	},
})

export function showConfirmCancelRequestPopup(): Promise<Result> {
	const popups = usePopups()

	return new Promise((resolve) => {
		popups.openPopup({
			content: (props) => (
				<ConfirmCancelRequestPopup
					{...props}
					cancel={() => {
						props.close()
						resolve({ type: ConfirmResult.Cancel })
					}}
					confirm={(reason) => {
						props.close()
						resolve({ type: ConfirmResult.Confirm, reason })
					}}
				/>
			),
			closable: false,
			width: "35rem",
		})
	})
}

export default ConfirmCancelRequestPopup
