import type { SortDirection } from "@/models"
import type { Id, IPagedResult, TimeSheetEntry } from "@/models"
import type { LocalDate } from "@js-joda/core"

export interface TimeSheetExportResult extends IPagedResult {
	entries: TimeSheetEntry[]
}

export interface TimeSheetExportOptions {
	filter: TimeSheetExportFilter
	page: number
}

export interface TimeSheetExportFilter {
	from: LocalDate | null
	to: LocalDate | null
	user: string
	siteIds: Id[]
	activityIds: Id[]
	notes: boolean
	approvedUser: string

	orderBy: TimeSheetOrderBy
	orderDirection: SortDirection
}

export enum TimeSheetOrderBy {
	Date = "date",
	User = "user",
	Site = "site",
	Activity = "activity",
}
