import { useLoggedInUser } from "@/data/loggedInUserStore"
import type { TimeSheetEntry } from "@/models"
import Permission from "@/models/Permission"
import { getUsedTime } from "@/services/timesheetsService"
import { Duration, Instant } from "@js-joda/core"
import { usePollRef } from "vue-utils"

export function useTimeSheetPermissions() {
	const user = useLoggedInUser()
	const currentTime = $(usePollRef(Duration.ofMinutes(1).toMillis(), () => Instant.now()))

	function canAccess(timeSheet: TimeSheetEntry) {
		return user.isAssignedToSite(timeSheet.siteId) || user.hasPermission(Permission.ManagedUserTimeSheets)
	}

	function canEdit(timeSheet: TimeSheetEntry) {
		return canAccess(timeSheet) && timeSheet.approvedById === null && timeSheet.approvalDate === null
	}

	function canAdjust(timeSheet: TimeSheetEntry) {
		return canEdit(timeSheet) && user.hasPermission(Permission.AdjustTimeSheets)
	}

	function canApprove(timeSheet: TimeSheetEntry) {
		return canEdit(timeSheet) && user.hasPermission(Permission.ApproveTimeSheets)
	}

	function canUnApprove(timeSheet: TimeSheetEntry) {
		if (
			timeSheet.approvalDate === null ||
			!canAccess(timeSheet) ||
			!user.hasPermission(Permission.UnApproveTimeSheets)
		) {
			return false
		}
		if (user.hasPermission(Permission.BypassTimeSheetTimeRestrictions) || timeSheet.approvalDate === null) {
			return true
		}
		const time = getUsedTime(timeSheet.clockOut) ?? getUsedTime(timeSheet.clockIn)
		if (!time) {
			return true
		}
		const cutOff = time.plusSeconds(Duration.ofDays(14).seconds())
		return currentTime.compareTo(cutOff) <= 0
	}

	function canDelete(timeSheet: TimeSheetEntry) {
		if (!canEdit(timeSheet) || !user.hasPermission(Permission.DeleteTimeSheets)) {
			return false
		}
		if (user.hasPermission(Permission.BypassTimeSheetTimeRestrictions)) {
			return true
		}
		const time = getUsedTime(timeSheet.clockOut) ?? getUsedTime(timeSheet.clockIn)
		if (!time) {
			return true
		}
		const cutOff = time.plusSeconds(Duration.ofDays(14).seconds())
		return currentTime.compareTo(cutOff) <= 0
	}

	return {
		canAccess,
		canEdit,
		canAdjust,
		canApprove,
		canDelete,
		canUnApprove,
	}
}
