import BootstrapButton from "@/components/BootstrapButton"
import usePopups from "@/data/popupsStore"
import useSystemConfigStore, { useSystemConfig } from "@/data/systemConfigStore"
import { useLoading } from "@/utils/composition/useLoading"
import { DateTimeFormatter, ZoneId } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { Alert, ShadowContainer, defineComponent, type ReactiveComponent } from "vue-utils"

const gridStyles = css`
	display: inline-grid;
	grid-template-columns: max-content max-content;
	gap: 0.5rem;

	& > *:nth-child(odd) {
		font-weight: 600;
	}
`

const timeFormat = DateTimeFormatter.ofPattern("yyyy/MM/dd HH:mm 'UTC'")

const About: ReactiveComponent = () => {
	const { runAction } = useLoading()
	const popups = usePopups()

	const systemStore = useSystemConfigStore()
	const systemConfig = useSystemConfig()

	const buildDate = $computed(() => systemConfig.build.date.atZone(ZoneId.UTC).format(timeFormat))

	const checkForUpdate = async () => {
		const hasUpdate = await systemStore.checkForUpdate()
		if (hasUpdate) {
			void popups.showAlertPopup(() => (
				<Alert title="Update Available">
					An update to TimeLord is available. Please update the system as soon as possible
				</Alert>
			))
		} else {
			void popups.showAlertPopup(() => (
				<Alert title="No Update Available">You are running the latest version of the system</Alert>
			))
		}
	}

	return () => (
		<ShadowContainer>
			<h3>Time Lord Version Information</h3>
			<hr />
			<div class={gridStyles}>
				<span>Revision: </span>
				<span>{systemConfig.build.version.substring(0, 10)}</span>

				<span>Build Date: </span>
				<span>{buildDate}</span>
			</div>
			<br />
			<br />
			<div>
				<BootstrapButton color="primary" onClick={() => void runAction(checkForUpdate())}>
					Check for Update
				</BootstrapButton>
			</div>
		</ShadowContainer>
	)
}

export default defineComponent(About)
