import type { Nullable } from "@/models"
import {
	convert,
	DateTimeFormatter,
	Duration,
	Instant,
	LocalDate,
	LocalTime,
	ZonedDateTime,
	ZoneId,
} from "@js-joda/core"

export const parseDuration = (str: string) => Duration.parse(str)

export const formatDuration = (duration: Nullable<Duration>, defaultValue = "") => {
	if (!duration) {
		return defaultValue
	}
	const times = {
		h: duration.toHours(),
		m: duration.toMinutes() % 60,
		s: duration.seconds() % 60,
	}

	const strs: string[] = Object.entries(times)
		.filter(([, time]) => time > 0)
		.map(([unit, time]) => `${time}${unit}`)
	if (strs.length === 0) {
		return "0"
	}
	return strs.join(" ")
}

export function sumDuration(...durations: Duration[]) {
	return durations.reduce((d1, d2) => d1.plus(d2), Duration.ZERO)
}

export const parseTime = (str: string) => {
	try {
		return LocalTime.parse(str)
	} catch {
		//do nothing
	}
	return ZonedDateTime.parse(str).withZoneSameInstant(ZoneId.UTC).toLocalTime()
}

export const formatTime = (time: LocalTime) => time.format(DateTimeFormatter.ofPattern("HH:mm"))

export const formatTimeInUserTimeZone = (time: Nullable<Instant>): string => {
	if (!time) {
		return ""
	}
	return convert(time).toDate().toLocaleTimeString(undefined, {
		hour12: false,
		hour: "2-digit",
		minute: "2-digit",
	})
}

export const formatDateInUserTimeZone = (time: Instant): string => convert(time).toDate().toLocaleDateString()

export const formatDate = (date: LocalDate) =>
	convert(date).toDate().toLocaleDateString(undefined, {
		weekday: "short",
		day: "2-digit",
		month: "2-digit",
	})

export const formatYearDate = (time: Instant | null) => {
	if (!time) {
		return "-"
	}
	const localDate = ZonedDateTime.ofInstant(time, ZoneId.systemDefault()).toLocalDate()
	return convert(localDate).toDate().toLocaleDateString(undefined, {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
	})
}

export const useLocalDateInput = (action: (date: LocalDate | null) => void) => (event: Event) => {
	const text = (event.target as HTMLInputElement).value.trim()
	if (text.length === 0) {
		action(null)
	}
	try {
		action(LocalDate.parse(text))
	} catch {
		action(null)
	}
}

export const useLocalTimeInput = (action: (date: LocalTime | null) => void) => (event: Event) => {
	const text = (event.target as HTMLInputElement).value.trim()
	if (text.length === 0) {
		action(null)
	}
	try {
		action(LocalTime.parse(text, DateTimeFormatter.ofPattern("HH:mm")))
	} catch {
		action(null)
	}
}

export function minDate(...dates: LocalDate[]): LocalDate | undefined {
	if (dates.length === 0) {
		return undefined
	}
	let min = dates[0]
	for (let i = 1; i < dates.length; i++) {
		if (dates[i].compareTo(min) < 0) {
			min = dates[i]
		}
	}
	return min
}

export function maxDate(...dates: LocalDate[]): LocalDate | undefined {
	if (dates.length === 0) {
		return undefined
	}
	let max = dates[0]
	for (let i = 1; i < dates.length; i++) {
		if (dates[i].compareTo(max) > 0) {
			max = dates[i]
		}
	}
	return max
}
