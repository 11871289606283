import { useActivities } from "@/data/coreDataStore"
import { PlannerHolidayType, compareActivities, type Activity, type Id } from "@/models"
import { css } from "vite-css-in-js"
import { shallowRef, watch } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import PlannerCell, { type PlannerCellSlot } from "./PlannerCell"
import useHolidaysPlannerStore from "./store"

const keyStyles = css`
	display: grid;
	grid-template-columns: max-content auto;
	align-items: center;
	gap: 0.5rem;
	margin: 0;

	dd {
		margin: 0;
	}
	dt:nth-child(4n + 5),
	dd:nth-child(4n + 6) {
		margin-top: 1rem;
	}

	dt {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	svg {
		font-size: 1.5rem;
	}

	td {
		width: 2rem;
		height: 1.85rem;
		font-size: 0.6rem;
	}
`

const PlannerKey: ReactiveComponent = () => {
	const activities = useActivities()
	const store = useHolidaysPlannerStore()

	//Store as a ref so that when reloading data the key remains until the data has loaded in again
	const usedActivities = shallowRef<Activity[]>([])

	function updateActivities() {
		if (store.data.type !== "done") {
			return
		}
		const activityIds = new Set<Id>()
		for (const entry of store.data.result) {
			for (const holiday of entry.holidays) {
				if (holiday.activityId !== null) {
					activityIds.add(holiday.activityId)
				}
			}
		}
		usedActivities.value = (
			Array.from(activityIds.values())
				.map((id) => activities.get(id))
				.filter((a) => !!a) as Activity[]
		).sort(compareActivities)
	}

	watch(() => store.data.type, updateActivities)
	updateActivities()

	function renderDetails(slot: Omit<PlannerCellSlot, "isPaid">) {
		return (
			<>
				<dt>
					<PlannerCell morning={{ ...slot, isPaid: true }} afternoon={{ ...slot, isPaid: true }} />
				</dt>
				<dd>{slot.name} (Paid)</dd>

				<dt>
					<PlannerCell morning={{ ...slot, isPaid: false }} afternoon={{ ...slot, isPaid: false }} />
				</dt>
				<dd>{slot.name} (Unpaid)</dd>
			</>
		)
	}

	return () => (
		<div>
			<h4 style={{ marginBottom: 0, marginTop: "2rem" }}>Key</h4>
			<hr />
			<dl class={keyStyles}>
				{renderDetails({
					type: PlannerHolidayType.Pending,
					name: "Pending Holiday",
					activityId: null,
				})}

				{usedActivities.value.map((activity) =>
					renderDetails({
						type: PlannerHolidayType.Approved,
						activityId: activity.id,
						name: activity.name,
					})
				)}
			</dl>
		</div>
	)
}

export default defineComponent(PlannerKey)
