import type { Id, Nullable, SwitchedSiteRecord, TimeSheetEntry, UserMetadata } from "@/models"
import { RequestMethod, httpJsonRequestJsonResponse } from "@/services/httpService"
import { mapApiEntry } from "@/services/timesheetsService"

export interface CurrentlyClockedInInformation {
	user: UserMetadata
	activeTimeSheet: Nullable<TimeSheetEntry>
	lastActivityId: Nullable<Id>
}

export const getClockedInByPin = async (pin: string): Promise<CurrentlyClockedInInformation | null> => {
	const result = await httpJsonRequestJsonResponse<CurrentlyClockedInInformation | null>(
		`/api/kiosk-mode/clocked-in-by-pin`,
		pin,
		RequestMethod.POST
	)
	if (!result) {
		return null
	}

	return {
		user: result.user,
		lastActivityId: result.lastActivityId,
		activeTimeSheet: result.activeTimeSheet ? mapApiEntry(result.activeTimeSheet) : null,
	}
}

export const clockIn = async (userId: Id, siteId: Id, activityId: Id): Promise<TimeSheetEntry> => {
	const entry = await httpJsonRequestJsonResponse<TimeSheetEntry>(
		"/api/kiosk-mode/clock-in",
		{
			userId,
			siteId,
			activityId,
		},
		RequestMethod.POST
	)
	return mapApiEntry(entry)
}

export const clockOut = async (userId: Id): Promise<TimeSheetEntry> => {
	const entry = await httpJsonRequestJsonResponse<TimeSheetEntry>(
		"/api/kiosk-mode/clock-out",
		userId,
		RequestMethod.POST
	)
	return mapApiEntry(entry)
}

export const switchSiteOrActivity = async (
	userId: Id,
	newSiteId: Id,
	newActivityId: Id
): Promise<SwitchedSiteRecord> => {
	const response = await httpJsonRequestJsonResponse<SwitchedSiteRecord>(
		"/api/kiosk-mode/switch-site",
		{
			userId,
			newSiteId,
			newActivityId,
		},
		RequestMethod.POST
	)

	return {
		closedTimeSheet: mapApiEntry(response.closedTimeSheet),
		newTimeSheet: mapApiEntry(response.newTimeSheet),
	}
}

export const updateTimeSheetNotes = async (timeSheetId: Id, notes: string): Promise<TimeSheetEntry> => {
	const entry = await httpJsonRequestJsonResponse<TimeSheetEntry>(
		"/api/kiosk-mode/set-notes",
		{
			entryId: timeSheetId,
			notes,
		},
		RequestMethod.POST
	)
	return mapApiEntry(entry)
}
