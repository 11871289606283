import type { Id, PlannerHolidayDay, PlannerStaffEntry, User, UserMetadata } from "@/models"
import getOrPut from "@/utils/getOrPut"
import type { LocalDate } from "@js-joda/core"

/**
 * Allows efficient lookup of holidays by date. Useful given the sheer size of the holiday grid
 */
export default class HolidayCache {
	//User -> Date (epoch day) -> List of holiday days
	private userHolidayMap = new Map<Id, Map<number, PlannerHolidayDay[]>>()

	static from(entries: PlannerStaffEntry[]): HolidayCache {
		const cache = new HolidayCache()
		for (const entry of entries) {
			cache.addStaffHolidays(entry.user, ...entry.holidays)
		}
		return cache
	}

	addStaffHolidays(user: Id | UserMetadata, ...holidays: PlannerHolidayDay[]): void {
		const userId = typeof user === "number" ? user : user.id
		const holidayMap = getOrPut(this.userHolidayMap, userId, () => new Map<number, PlannerHolidayDay[]>())

		for (const holiday of holidays) {
			const holidaysOnDate = getOrPut(holidayMap, holiday.date.toEpochDay(), () => [])
			holidaysOnDate.push(holiday)
		}
	}

	getHolidays(user: Id | User, date: LocalDate): PlannerHolidayDay[] {
		const userId = typeof user === "number" ? user : user.id
		const userHolidays = this.userHolidayMap.get(userId)?.get(date.toEpochDay())
		return userHolidays ?? []
	}

	clear(): void {
		this.userHolidayMap.clear()
	}
}
