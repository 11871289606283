import type { HolidayDayType } from "@/models/HolidayDay"
import type { UserMetadata } from "@/models/Users"
import type { LocalDate } from "@js-joda/core"
import type { Id, Nullable } from "."
import type { HolidayAllowance } from "./HolidayAllowance"

export interface PlannerStaffEntry {
	user: UserMetadata
	totalAllowance: HolidayAllowance
	/** Used allowance, including any pending requests */
	usedAllowance: HolidayAllowance
	remainingAllowance: HolidayAllowance
	holidays: PlannerHolidayDay[]
}

export interface PlannerHolidayDay {
	name: Nullable<string>
	date: LocalDate
	dayType: HolidayDayType
	type: PlannerHolidayType
	activityId: Nullable<Id>
	isPaid: boolean
}

export enum PlannerHolidayType {
	Company = "company",
	Pending = "pending",
	Approved = "approved",
}
