import type { YearHolidayAllowance } from "@/models"
import { defineComponent, optionalProp, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"
import type { InlineEditFormProps } from "../InlineEditList"
import AllowanceInput from "./AllowanceInput"

interface Props extends InlineEditFormProps<YearHolidayAllowance, number> {
	defaultAllowance: number
}

const HolidayYearForm: ReactiveComponent<Props> = (props) => {
	const { defaultAllowance, year, allowance, remainingOptions: years, mode, setData, readonly = false } = $(props)

	return () => (
		<>
			<select
				value={year}
				onInput={useOnInput((value) => setData("year", Number.parseInt(value)))}
				disabled={readonly || mode === "edit"}
				style={{ minWidth: "6rem" }}
			>
				{(mode === "edit" ? [year] : years).map((year) => (
					<option key={year} value={year}>
						{year}
					</option>
				))}
			</select>
			<AllowanceInput
				allowance={allowance}
				defaultDays={defaultAllowance}
				setAllowance={(allowance) => setData("allowance", allowance)}
				readonly={readonly || mode === "edit"}
			/>
		</>
	)
}

export default defineComponent(HolidayYearForm, {
	defaultAllowance: requiredProp(Number),
	allowance: requiredProp([String, Number]),
	year: requiredProp(Number),
	mode: requiredProp(String),
	remainingOptions: requiredProp(Array),
	setData: requiredProp(Function),
	readonly: optionalProp(Boolean),
})
