import usePopups from "@/data/popupsStore"
import { useRouter } from "vue-router"
import { Alert, defineComponent, type ReactiveComponent } from "vue-utils"
import BasicScreen from "../shared/clock/screens/BasicScreen"
import ExitButton from "./components/ExitButton"
import PinEnter from "./components/PinEnter"
import { Screen } from "./Screen"
import { useKioskModeStore } from "./store"

const ExitKioskMode: ReactiveComponent = () => {
	const store = useKioskModeStore()
	const router = useRouter()
	const popups = usePopups()

	const tryExitKioskMode = async (pin: string) => {
		if (store.kioskPin !== null && !store.kioskModePinMatches(pin)) {
			void popups.showAlertPopup(() => <Alert title="Wrong PIN">Wrong PIN entered to exit kiosk mode.</Alert>)
			return
		}
		store.logout()
		await store.disableKioskMode()
		await router.replace("/kiosk-mode-settings")
	}

	return () => (
		<BasicScreen title="Enter Kiosk Mode PIN">
			<PinEnter submit={(pin) => void tryExitKioskMode(pin)} />
			<ExitButton onClick={() => store.switchScreen(Screen.Pin)}>Return to PIN entry</ExitButton>
		</BasicScreen>
	)
}

export default defineComponent(ExitKioskMode)
