import DescriptionList from "@/components/DescriptionList"
import UserNameDisplay from "@/components/UserNameDisplay"
import type { HolidayAllowance, HolidayRequest } from "@/models"
import { formatDateInUserTimeZone } from "@/utils/timeUtils"
import { defineComponent, type ReactiveComponent, requiredProp } from "vue-utils"
import { formatHolidayDate } from "../utils"
import { formatAllowance } from "../../service"

interface Props {
	request: HolidayRequest
	totalAllowance: HolidayAllowance
	allowanceToUse: number
	allowanceCurrentlyRemaining: HolidayAllowance
	allowanceRemainingAfter: HolidayAllowance
}

const RequestDescriptor: ReactiveComponent<Props> = (props) => {
	return () => (
		<DescriptionList style={{ marginTop: 0 }}>
			<dt>Created By</dt>
			<dd>{props.request.user ? <UserNameDisplay user={props.request.user} /> : "Unknown"}</dd>

			<dt>Date Created</dt>
			<dd>{formatDateInUserTimeZone(props.request.dateCreated)}</dd>

			<dt>Start Date</dt>
			<dd>{formatHolidayDate(props.request.dateRange.start)}</dd>

			<dt>End Date</dt>
			<dd>{formatHolidayDate(props.request.dateRange.end)}</dd>

			<dt>Total Allowance</dt>
			<dd>{formatAllowance(props.totalAllowance)}</dd>

			<dt>Allowance Currently Remaining</dt>
			<dd>{formatAllowance(props.allowanceCurrentlyRemaining)}</dd>

			<dt>Allowance To Use</dt>
			<dd>{formatAllowance(props.allowanceToUse)}</dd>

			<dt>Allowance Remaining After</dt>
			<dd>{formatAllowance(props.allowanceRemainingAfter)}</dd>

			<dt>Additional Info</dt>
			<dd>
				<i>{props.request.userDescription.length > 0 ? props.request.userDescription : "-"}</i>
			</dd>
		</DescriptionList>
	)
}

export default defineComponent(RequestDescriptor, {
	request: requiredProp(Object),
	totalAllowance: requiredProp(String, Number),
	allowanceToUse: requiredProp(Number),
	allowanceCurrentlyRemaining: requiredProp(String, Number),
	allowanceRemainingAfter: requiredProp(String, Number),
})
