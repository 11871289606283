import AuthorizePermission from "@/authentication/AuthorizePermission"
import Permission from "@/models/Permission"
import { defineComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import TimeSheetManagement from "./TimeSheetManagement"

export default defineComponent({
	name: "TimeSheetManagement",
	setup: () => () => (
		<BasicPage title="Time Sheet Management">
			<AuthorizePermission permissions={[Permission.ViewTimeSheets]}>
				<TimeSheetManagement />
			</AuthorizePermission>
		</BasicPage>
	),
})
