import { getHolidayRequestStatus, HolidayRequestStatus, type HolidayRequest } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import { defineComponent, getUnique, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	requests: HolidayRequest[]

	filter: HolidayFilter
	updateFilter<K extends keyof HolidayFilter, V extends HolidayFilter[K]>(key: K, value: V): void
}

export interface HolidayFilter {
	year: undefined | number
	status: undefined | HolidayRequestStatus
}

const HolidayFilter: ReactiveComponent<Props> = (props) => {
	const allYears = $computed(() =>
		getUnique(props.requests.map((req) => req.dateRange.start.date.year())).sort((a, b) => b - a)
	)
	const allStatuses = $computed(() =>
		Object.values(HolidayRequestStatus).filter((k) => props.requests.some((r) => getHolidayRequestStatus(r) === k))
	)

	function handleStatusSelect(event: Event) {
		const selected = (event.target as HTMLSelectElement).value
		props.updateFilter("status", selected === "all" ? undefined : (selected as HolidayRequestStatus))
	}

	function handleYearSelect(event: Event) {
		const selected = (event.target as HTMLSelectElement).value
		props.updateFilter("year", selected === "all" ? undefined : Number.parseInt(selected))
	}

	return () => (
		<div class="flex items-center spacing-4">
			<label>
				Status
				<select value={props.filter.status ?? "all"} onInput={handleStatusSelect}>
					<option value="all">All</option>
					{allStatuses.map((status) => (
						<option key={status} value={status ?? "pending"}>
							{formatEnum(status)}
						</option>
					))}
				</select>
			</label>
			<label>
				Year
				<select value={props.filter.year ?? "all"} onInput={handleYearSelect}>
					<option value="all">All Time</option>
					{allYears.map((year) => (
						<option key={year} value={year}>
							{year}
						</option>
					))}
				</select>
			</label>
		</div>
	)
}

export function filterHolidays(requests: HolidayRequest[], filter: HolidayFilter): HolidayRequest[] {
	return requests.filter((request) => {
		if (filter.status !== undefined && getHolidayRequestStatus(request) !== filter.status) {
			return false
		}
		if (filter.year !== undefined && request.dateRange.start.date.year() !== filter.year) {
			return false
		}
		return true
	})
}

export default defineComponent(HolidayFilter, {
	requests: requiredProp(Array),
	filter: requiredProp(Object),
	updateFilter: requiredProp(Function),
})
