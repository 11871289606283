import type { Activity, HolidaySettings, Id, Site, Trade, UserType } from "@/models"
import { listActivities } from "@/services/activitiesService"
import { getHolidaySettings } from "@/services/holidaySettingsService"
import { listRuleSets } from "@/services/ruleSetsService"
import { listSites } from "@/services/sitesService"
import { listTrades } from "@/services/tradesService"
import { listUserTypes } from "@/services/userTypesService"
import { arrayToMap } from "@/utils/arrayToMap"
import { proxyProp } from "@/utils/proxyProp"
import { defineStore } from "pinia"
import { computed, type ComputedRef, isRef, type Ref } from "vue"
import { defineGlobals, ensureLoadingHasResult, mergePromises, piniaLoadableState } from "vue-utils"

export interface CoreData {
	activities: Map<Id, Activity>
	sites: Map<Id, Site>
	userTypes: Map<Id, UserType>
	trades: Map<Id, Trade>
	holidaySettings: HolidaySettings
}

async function queryData(): Promise<CoreData> {
	const { activities, sites, userTypes, trades, holidaySettings } = await mergePromises({
		activities: listActivities(),
		sites: listSites(),
		userTypes: listUserTypes(),
		trades: listTrades(),
		holidaySettings: getHolidaySettings(),
	})

	return {
		activities: arrayToMap(activities),
		sites: arrayToMap(sites),
		userTypes: arrayToMap(userTypes),
		trades: arrayToMap(trades),
		holidaySettings,
	}
}

const useCoreDataStore = defineStore("coreData", {
	state: () => ({
		...piniaLoadableState(queryData),
		ruleSets: piniaLoadableState(listRuleSets),
	}),
	actions: {
		setValue<K extends keyof CoreData>(key: K, value: CoreData[K]) {
			const state = ensureLoadingHasResult(this)
			state[key] = value
		},
		getValue<K extends keyof CoreData>(key: K): CoreData[K] {
			const state = ensureLoadingHasResult(this)
			return state[key] as CoreData[K]
		},
	},
})

export function getCoreDataValue<K extends keyof CoreData>(item: K) {
	const state = ensureLoadingHasResult(useCoreDataStore())
	return state[item]
}

export function useCoreDataValue<K extends keyof CoreData>(item: K): CoreData[K] {
	const store = useCoreDataStore()
	return proxyProp(() => ensureLoadingHasResult(store)[item])
}

interface UseById<T> {
	(id: Id | null | undefined): ComputedRef<T | null>
	(idRef: Ref<Id | null | undefined>): ComputedRef<T | null>
	(getId: () => Id | null | undefined): ComputedRef<T | null>
}

const createUseById =
	<T>(getListRef: () => Map<Id, T>): UseById<T> =>
	(data) => {
		const listRef = getListRef()
		const getId = () => {
			if (typeof data === "number" || data === null || data === undefined) return data
			if (isRef(data)) return data.value
			return data()
		}

		return computed<T | null>(() => {
			const id = getId()
			if (typeof id !== "number") {
				return null
			}
			return listRef.get(id) ?? null
		})
	}

export const useSites = () => useCoreDataValue("sites")
export const useActivities = () => useCoreDataValue("activities")
export const useTrades = () => useCoreDataValue("trades")
export const useUserTypes = () => useCoreDataValue("userTypes")

export const useSite = createUseById(useSites)
export const useActivity = createUseById(useActivities)
export const useTrade = createUseById(useTrades)
export const useUserType = createUseById(useUserTypes)

defineGlobals({
	useCoreDataStore,
})

export default useCoreDataStore
