import type { AdjustedTime, Id, TimeSheetEntry, UserMetadata } from "@/models"
import { arrayToMap } from "@/utils/arrayToMap"
import findById from "@/utils/findById"
import { Duration, Instant, LocalDate, LocalDateTime, LocalTime, ZoneId, ZonedDateTime } from "@js-joda/core"
import { defineStore } from "pinia"
import type { SelectedSite } from "./components/SiteSelector"
import {
	OverrideType,
	approveTimeSheet,
	createTimeSheet,
	deleteTimeSheet,
	queryRelevantUsers,
	queryTimeSheetsForUser,
	setTimeSheetActivity,
	setTimeSheetBreakOverride,
	setTimeSheetOverride,
	setTimeSheetSite,
	unApproveTimeSheet,
	updateTimeSheetManagerNotes,
} from "./service"

interface State {
	weekTimeSheets: TimeSheetEntry[]
	listedUsers: Map<Id, UserMetadata>
	week: LocalDate
	filterUnapproved: boolean
	siteId: SelectedSite
	selectedUser: UserMetadata | null
}

const useTimeSheetManagementStore = defineStore("timesheet-management", {
	state: (): State => ({
		weekTimeSheets: [],
		listedUsers: new Map(),
		week: LocalDate.now().getStartOfWeek(),
		filterUnapproved: false,
		siteId: "all",
		selectedUser: null,
	}),
	getters: {
		startOfWeek(): ZonedDateTime {
			return ZonedDateTime.of(LocalDateTime.of(this.week, LocalTime.MIDNIGHT), ZoneId.SYSTEM)
		},
		endOfWeek(): ZonedDateTime {
			return this.startOfWeek.plusWeeks(1)
		},
		dateRange(): { from: Instant; to: Instant } {
			const start = ZonedDateTime.of(LocalDateTime.of(this.week, LocalTime.MIDNIGHT), ZoneId.SYSTEM)
			return {
				from: start.toInstant(),
				to: start.plusWeeks(1).toInstant(),
			}
		},
	},
	actions: {
		async queryRelevantUserList() {
			const siteIds = this.siteId === "all" ? [] : [this.siteId]
			const users = await queryRelevantUsers({
				siteIds,
				...this.dateRange,
				showApproved: !this.filterUnapproved,
			})
			this.listedUsers = arrayToMap(users)
		},
		async requestTimeSheets() {
			if (this.selectedUser == null) {
				this.weekTimeSheets = []
			} else {
				const timesheets = await queryTimeSheetsForUser({
					userId: this.selectedUser.id,
					...this.dateRange,
				})
				this.weekTimeSheets = timesheets
				this.sortTimeSheets()
			}
		},
		sortTimeSheets() {
			this.weekTimeSheets.sort((s1, s2) => {
				const getSortUsed = (time: AdjustedTime) => time.override ?? time.entered ?? time.autoAdjusted

				const t1 = getSortUsed(s1.clockIn) ?? getSortUsed(s2.clockOut)
				const t2 = getSortUsed(s2.clockIn) ?? getSortUsed(s2.clockOut)

				if (t1 === t2) return 0
				if (!t1) return -1
				if (!t2) return 1
				return t1.compareTo(t2)
			})
		},
		getTimeSheetByIdOrThrow(timeSheetId: Id): TimeSheetEntry {
			const existing = findById(this.weekTimeSheets, timeSheetId)
			if (!existing) {
				throw new Error(`Unknown time sheet with id ${timeSheetId}`)
			}
			return existing
		},
		replaceTimeSheets(timeSheet: TimeSheetEntry) {
			const existing = this.getTimeSheetByIdOrThrow(timeSheet.id)
			Object.assign(existing, timeSheet)
		},
		async createNewTimeSheet(timeSheet: TimeSheetEntry): Promise<void> {
			const result = await createTimeSheet(timeSheet)
			this.$patch((state) => {
				state.weekTimeSheets.push(result)
			})
			this.sortTimeSheets()
		},
		async setSite(timeSheetId: Id, siteId: Id): Promise<void> {
			const newTimeSheet = await setTimeSheetSite(timeSheetId, siteId)
			this.replaceTimeSheets(newTimeSheet)
		},
		async setActivity(timeSheetId: Id, activityId: Id): Promise<void> {
			const newTimeSheet = await setTimeSheetActivity(timeSheetId, activityId)
			this.replaceTimeSheets(newTimeSheet)
		},
		async addOverride(timeSheetId: Id, type: OverrideType, time: Instant): Promise<void> {
			const timeSheet = await setTimeSheetOverride({
				timeSheetId,
				type,
				time,
			})
			this.replaceTimeSheets(timeSheet)
		},
		async addBreakOverride(timeSheetId: Id, duration: Duration): Promise<void> {
			const timeSheet = await setTimeSheetBreakOverride({
				timeSheetId,
				duration,
			})
			this.replaceTimeSheets(timeSheet)
		},
		async updateManagerNotes(timeSheetId: Id, notes: string) {
			const newTimeSheet = await updateTimeSheetManagerNotes(timeSheetId, notes)
			this.replaceTimeSheets(newTimeSheet)
		},
		async approveTimeSheet(timeSheetId: Id) {
			const newTimeSheet = await approveTimeSheet(timeSheetId)
			this.replaceTimeSheets(newTimeSheet)
		},
		async unApproveTimeSheet(timeSheetId: Id) {
			await unApproveTimeSheet(timeSheetId)
			const existing = this.getTimeSheetByIdOrThrow(timeSheetId)
			existing.approvedBy = null
			existing.approvalDate = null
			existing.approvedById = null
		},
		async deleteTimeSheet(timeSheetId: Id) {
			await deleteTimeSheet(timeSheetId)
			this.weekTimeSheets = this.weekTimeSheets.filter((ts) => ts.id !== timeSheetId)
		},
	},
})

export default useTimeSheetManagementStore
