import {
	convert,
	DateTimeFormatter,
	DayOfWeek,
	Duration,
	LocalDate,
	LocalTime,
	Month,
	ZonedDateTime,
} from "@js-joda/core"

declare module "@js-joda/core" {
	export interface ZonedDateTime {
		getStartOfWeek(): ZonedDateTime
		getEndOfWeek(): ZonedDateTime
	}

	export interface LocalTime {
		toHTMLInput(): string
	}

	export interface LocalDate {
		getStartOfWeek(): LocalDate
		getEndOfWeek(endDay: DayOfWeek): LocalDate
		toHTMLInput(): string
	}

	export interface Duration {
		toHTMLInput(): string
	}

	export interface Month {
		displayName(): string
	}
}

ZonedDateTime.prototype.getStartOfWeek = function () {
	return this.toLocalDate().getStartOfWeek().atStartOfDay().atZone(this.zone())
}
ZonedDateTime.prototype.getEndOfWeek = function () {
	return this.toLocalDate().getEndOfWeek(DayOfWeek.SUNDAY).atStartOfDay().atZone(this.zone())
}

LocalTime.prototype.toHTMLInput = function () {
	return this.format(DateTimeFormatter.ofPattern("HH:mm"))
}
LocalTime.prototype.toJSON = function () {
	return this.format(DateTimeFormatter.ofPattern("HH:mm:ss"))
}

LocalDate.prototype.getStartOfWeek = function () {
	return this.plusDays(-this.dayOfWeek().value() + 1)
}
LocalDate.prototype.getEndOfWeek = function (endDay: DayOfWeek) {
	return this.plusDays(endDay.value() - this.dayOfWeek().value())
}
LocalDate.prototype.toHTMLInput = function () {
	return this.toJSON()
}

Duration.prototype.toJSON = function () {
	return `PT${this.toHours() % 24}H${this.toMinutes() % 60}M`
}
Duration.prototype.toHTMLInput = function () {
	if (this.isNegative()) {
		//Treat negative times as positive to prevent exception when initialising localtime
		return this.negated().toHTMLInput()
	}
	return LocalTime.of(this.toHours(), this.toMinutes() % 60, this.seconds() % 60).toHTMLInput()
}

DayOfWeek.prototype.equals = function (other) {
	return other instanceof DayOfWeek && other.ordinal() === this.ordinal()
}

Month.prototype.equals = function (other) {
	return other instanceof Month && other.ordinal() === this.ordinal()
}

Month.prototype.displayName = function () {
	return convert(LocalDate.of(LocalDate.now().year(), this, 1))
		.toDate()
		.toLocaleDateString(undefined, {
			month: "long",
		})
}
