import type { Id, IIdentifier, INamed, Nullable, UserRole } from "."
import { compareRoles } from "./Users"

export interface Activity extends IIdentifier, INamed {
	sortOrder: number
	addsToPaidHours: boolean
	canHaveOverride: boolean
	autoPopulateTimes: boolean
	notes: Nullable<string>
	highlightColor: Nullable<string>
	canClockInWith: boolean

	minimumModifyRole: Nullable<UserRole>
	minimumViewRole: Nullable<UserRole>

	isWorkingActivity: boolean
}

export interface ActivityOverride {
	activityId: Id
	addsToPaidHours: boolean
}

export function compareActivities(a1: Activity, a2: Activity) {
	let order = a1.sortOrder - a2.sortOrder
	if (order !== 0) return order
	order = a1.name.localeCompare(a2.name)
	if (order !== 0) return order
	return a1.id - a2.id
}

export function canViewActivityWithRole(activity: Activity, role: UserRole): boolean {
	if (!activity.minimumViewRole) {
		return true
	}
	return compareRoles(role, activity.minimumViewRole) >= 0
}

export function canModifyActivityWithRole(activity: Activity, role: UserRole): boolean {
	if (!activity.minimumModifyRole) {
		return true
	}
	return compareRoles(role, activity.minimumModifyRole) >= 0
}
