import { LocalTime } from "@js-joda/core"

import type { Nullable } from "@/models"
import { formatDuration } from "@/utils/timeUtils"
import { Duration } from "@js-joda/core"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import DisplayEdit from "./DisplayEdit"
import LocalTimeEdit from "./LocalTimeEdit"

interface Props {
	value: Nullable<Duration>
	save(time: Duration): Promise<void>
	readonly?: boolean
}

const EditDuration: ReactiveComponent<Props> = (props) => {
	return () => (
		<DisplayEdit
			displayText={props.value ? formatDuration(props.value) : ""}
			renderContent={({ hide }) => (
				<LocalTimeEdit
					value={props.value ? LocalTime.of(props.value.toHours() % 24, props.value.toMinutes() % 60) : null}
					save={(time) => props.save(Duration.ofSeconds(time.toSecondOfDay()))}
					cancel={hide}
				/>
			)}
			readonly={props.readonly}
		/>
	)
}

export default defineComponent(EditDuration, {
	value: requiredProp(null, Duration),
	save: requiredProp(Function),
	readonly: optionalProp(Boolean),
})
