import type { HTMLAttributes } from "vue"

export interface OnTabEvent {
	blurEvent: FocusEvent
	direction: "back" | "forward"
}
export type OnTabbed = (event: OnTabEvent) => void

export function useOnTabbed(onTabbed: OnTabbed): Partial<HTMLAttributes> {
	let tabPressed = false
	let shiftDown = false

	return {
		onKeyup(e) {
			if (e.key === "Tab") {
				tabPressed = false
				shiftDown = false
			}
		},
		onKeydown(e) {
			if (e.key === "Tab") {
				tabPressed = true
				shiftDown = e.shiftKey
			}
		},
		onBlur(e) {
			if (tabPressed) {
				onTabbed({
					blurEvent: e,
					direction: shiftDown ? "back" : "forward",
				})
			}
			tabPressed = false
			shiftDown = false
		},
		onFocus() {
			tabPressed = false
			shiftDown = false
		},
	}
}
