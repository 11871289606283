import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { YearMonth } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import PlainIconButton from "../PlainIconButton"
import { formatMonth } from "./formatting"

interface Props {
	date: YearMonth
	setDate(date: YearMonth): void
	earliest?: YearMonth
	latest?: YearMonth
}

const navStyles = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f0f0f0;
	padding: 0.5rem 1rem;

	span {
		font-size: 1.125rem;
		font-weight: 500;
	}
`

const CalendarNavigation: ReactiveComponent<Props> = (props) => {
	function nextMonthClick() {
		props.setDate(props.date.plusMonths(1))
	}

	function previousMonthClick() {
		props.setDate(props.date.plusMonths(-1))
	}

	const showPrevious = computed(() => !props.earliest || props.earliest.compareTo(YearMonth.from(props.date)) < 0)
	const showNext = computed(() => !props.latest || props.latest.compareTo(YearMonth.from(props.date)) > 0)

	return () => (
		<div class={navStyles}>
			<PlainIconButton onClick={previousMonthClick} icon={faChevronLeft} disabled={!showPrevious.value} />
			<span>
				{formatMonth(props.date.month())} {props.date.year()}
			</span>
			<PlainIconButton onClick={nextMonthClick} icon={faChevronRight} disabled={!showNext.value} />
		</div>
	)
}

export default defineComponent(CalendarNavigation, {
	date: requiredProp(YearMonth),
	setDate: requiredProp(Function),
	earliest: optionalProp(YearMonth),
	latest: optionalProp(YearMonth),
})
