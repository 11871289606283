import { useActivities } from "@/data/coreDataStore"
import type { Activity, ActivityOverride, Id } from "@/models"
import { createEditOperations } from "@/utils/editOperations"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import createInlineEditList from "../InlineEditList"
import OverrideForm from "./OverrideForm"

interface Props {
	userId: Id
	activityOverrides: ActivityOverride[]
	setActivityOverrides: (overrides: ActivityOverride[]) => void
	readonly: boolean
}

const ActivityOverrideList = createInlineEditList<ActivityOverride, Activity>()

const ActivityOverrides: ReactiveComponent<Props> = (props) => {
	const activities = useActivities()

	function hasOverride(activity: Activity) {
		return props.activityOverrides.some((override) => override.activityId === activity.id)
	}
	const unusedActivities = $computed(() =>
		Array.from(activities.values()).filter((activity) => activity.canHaveOverride && !hasOverride(activity))
	)

	return () => (
		<ActivityOverrideList
			entities={props.activityOverrides}
			remainingOptions={unusedActivities}
			getKey={(override) => override.activityId}
			columnHeaders={["Activity", "Is Paid?"]}
			formGrid="auto auto"
			Form={OverrideForm}
			createData={(activity) => ({
				activityId: activity.id,
				addsToPaidHours: true,
			})}
			{...createEditOperations(
				() => props.activityOverrides,
				props.setActivityOverrides,
				(ao) => ao.activityId
			)}
			addText="Add Override"
			noneText="No activity overrides have been specified for this user"
			readonly={props.readonly}
		/>
	)
}

export default defineComponent(ActivityOverrides, {
	userId: requiredProp(Number),
	activityOverrides: requiredProp(Array),
	setActivityOverrides: requiredProp(Function),
	readonly: requiredProp(Boolean),
})
