import AuthorizePermission from "@/authentication/AuthorizePermission"
import Permission from "@/models/Permission"
import { defineComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import KioskMode from "./KioskMode"
import "./styles.scss"

export default defineComponent({
	name: "KioskModePage",
	setup: () => () => (
		<BasicPage title="TimeLord" noPadding noNav>
			<AuthorizePermission permissions={[Permission.KioskMode]}>
				<KioskMode />
			</AuthorizePermission>
		</BasicPage>
	),
})
