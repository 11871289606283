import usePopups from "@/data/popupsStore"
import type { HolidayRequest, HolidayRequestValidation } from "@/models"
import { Alert } from "vue-utils"
import { validateHolidayRequest } from "../../service"
import HolidayWarnings from "./HolidayWarnings"

export const validateUserHolidayRequest = async (request: HolidayRequest) => {
	const result = await validateHolidayRequest(request)
	if (typeof result.priorAllowance === "number" && result.priorAllowance <= 0) {
		await showNoAllowanceError()
		return false
	}

	if (result.errors.length > 0) {
		await showErrors(result.errors)
		return false
	}

	if (result.warnings.length > 0) {
		return await showWarnings(result)
	}

	return await showRegularConfirmation(result)
}

const showErrors = async (errors: string[]) => {
	const popups = usePopups()
	await popups.showAlertPopup(() => (
		<Alert title="Error with request">
			The following {errors.length === 1 ? "error has" : "errors have"} been raised for your request:
			<HolidayWarnings messages={errors} type="error" />
			Please adjust your request and try again.
		</Alert>
	))
}

const showNoAllowanceError = async () => {
	const popups = usePopups()
	await popups.showAlertPopup(() => (
		<Alert title="No Allowance Remaining">
			You do not have any holiday allowance remaining and cannot create requests.
		</Alert>
	))
}

const showWarnings = async (result: HolidayRequestValidation): Promise<boolean> => {
	const popups = usePopups()
	const warnings = result.warnings

	return await popups.confirm(() => (
		<Alert title="Confirm Creation of Request">
			The following {warnings.length === 1 ? "warning has" : "warnings have"} been reported for your request:
			<HolidayWarnings messages={result.warnings} type="warning" />
			Are you sure you still wish to submit the request? {createAllowanceMessage(result)}
		</Alert>
	))
}

const showRegularConfirmation = async (result: HolidayRequestValidation): Promise<boolean> => {
	const popups = usePopups()
	return await popups.confirm(() => (
		<Alert title="Confirm Creation of Request">
			<strong>Please confirm the details provided in your request are accurate.</strong>
			<p>{createAllowanceMessage(result)}</p>
		</Alert>
	))
}

const createAllowanceMessage = (result: HolidayRequestValidation) => {
	if (result.priorAllowance === "unlimited" || result.newAllowance === "unlimited") {
		return ""
	}
	if (result.newAllowance === 0) {
		return "You will be using all of your remaining allowance."
	}

	const difference = result.priorAllowance - result.newAllowance
	return `You will be using ${difference} / ${result.priorAllowance} day(s) of remaining allowance.`
}
