import { useActivities } from "@/data/coreDataStore"
import { PlannerHolidayType, type Id, type Nullable } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import tinycolor from "tinycolor2"
import { css } from "vite-css-in-js"
import type { CSSProperties } from "vue"
import { defineComponent, optionalProp, type ReactiveComponent } from "vue-utils"

export interface PlannerCellSlot {
	name: Nullable<string>
	type: PlannerHolidayType
	activityId: Nullable<Id>
	isPaid: boolean
}

interface Props {
	morning?: PlannerCellSlot
	afternoon?: PlannerCellSlot
}

const cellStyles = css`
	padding: 0 !important;
	position: relative;

	& > div {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			font-size: 1.5rem;
		}
	}
	&:not(&[data-type="full"]) {
		& > div:nth-child(1) {
			right: 50%;
		}
		& > div:nth-child(2) {
			left: 50%;
		}
	}
`

const typeColors: Record<PlannerHolidayType, string> = {
	[PlannerHolidayType.Approved]: "#32b932",
	[PlannerHolidayType.Pending]: "#b6b6b6",
	[PlannerHolidayType.Company]: "#328ada",
}

const PlannerCell: ReactiveComponent<Props> = (props) => {
	const activities = useActivities()

	function slotsMatch(...styles: PlannerCellSlot[]) {
		if (styles.length < 2) {
			return true
		}
		const first = styles[0]
		return styles.every((s) => s.type === first.type && s.isPaid === first.isPaid && s.activityId === first.activityId)
	}

	function getColor(slot: PlannerCellSlot) {
		return slot.activityId !== null
			? activities.get(slot.activityId)?.highlightColor ?? typeColors[slot.type]
			: typeColors[slot.type]
	}

	function getName(slot: PlannerCellSlot) {
		if (slot.name) {
			return slot.name
		}

		if (slot.activityId !== null) {
			const activity = activities.get(slot.activityId)
			if (activity) {
				return activity.name
			}
		}

		return formatEnum(slot.type)
	}

	function getCellStyling(slot: PlannerCellSlot): Partial<CSSProperties> {
		const color = getColor(slot)
		if (slot.isPaid) {
			return { backgroundColor: color }
		}

		const height = 2.375
		const angle = -58.2
		const width = Math.cos((angle / 180) * Math.PI) * height
		const extraColor = tinycolor(color).darken(15).toHexString()

		return {
			background: `repeating-linear-gradient(${-58.2}deg, ${color}, ${color} ${width / 2}em, ${extraColor} ${
				width / 2
			}em, ${extraColor} ${width}em)`,
		}
	}

	function renderSlot(slot?: PlannerCellSlot) {
		if (!slot) {
			return <div title="" />
		}
		const title = `${getName(slot)} (${slot.isPaid ? "Paid" : "Unpaid"})`
		return <div style={getCellStyling(slot)} title={title} />
	}

	return () => {
		if (!props.morning && !props.afternoon) {
			return <td class={cellStyles} />
		}
		if (!!props.morning && !!props.afternoon && slotsMatch(props.morning, props.afternoon)) {
			return (
				<td data-type="full" class={cellStyles}>
					{renderSlot(props.morning)}
				</td>
			)
		}
		return (
			<td class={cellStyles}>
				{renderSlot(props.morning)}
				{renderSlot(props.afternoon)}
			</td>
		)
	}
}

export default defineComponent(PlannerCell, {
	morning: optionalProp(Object),
	afternoon: optionalProp(Object),
})
