import L from "leaflet"

export default class SiteMarker {
	private readonly locationMarker: L.Marker
	private readonly radiusMarker: L.Circle
	private readonly hue: number

	constructor(options: {
		latitude: number
		longitude: number
		radius: number
		tooltipHtml?: string
		hue?: number
		radiusOptions?: Omit<L.CircleMarkerOptions, "radius">
	}) {
		const { latitude, longitude, radius, tooltipHtml, radiusOptions = {}, hue = 0 } = options
		this.locationMarker = L.marker([latitude, longitude])
		this.radiusMarker = L.circle([latitude, longitude], {
			...radiusOptions,
			radius,
		})
		if (tooltipHtml) {
			this.locationMarker.bindTooltip(
				L.tooltip({
					permanent: true,
					content: tooltipHtml,
				})
			)
		}
		this.hue = hue
	}

	addTo(map: L.Map | L.LayerGroup) {
		this.locationMarker.addTo(map)
		this.radiusMarker.addTo(map)

		const markerElement = this.locationMarker.getElement()
		if (markerElement) {
			markerElement.style.filter = `hue-rotate(${this.hue}deg)`
		}
	}

	remove() {
		this.locationMarker.remove()
		this.radiusMarker.remove()
	}

	moveTo(latitude: number, longitude: number) {
		this.locationMarker.setLatLng([latitude, longitude])
		this.radiusMarker.setLatLng([latitude, longitude])
	}

	setRadius(radius: number) {
		this.radiusMarker.setRadius(radius)
	}
}
