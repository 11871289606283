import { type HolidayRequestFilter, HolidayRequestStatus } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import { LocalDate } from "@js-joda/core"
import { defineComponent, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"

interface Props {
	lowestYear: number
	filter: HolidayRequestFilter
	updateFilter<K extends keyof HolidayRequestFilter, V extends HolidayRequestFilter[K]>(key: K, value: V): void
}

const HolidayFilter: ReactiveComponent<Props> = (props) => {
	const allYears = $computed(() => {
		const currentYear = LocalDate.now().year()
		return Array.from({ length: currentYear + 1 - props.lowestYear + 1 }, (_, i) => currentYear + 1 - i)
	})

	function handleStatusSelect(event: Event) {
		const selected = (event.target as HTMLSelectElement).value
		props.updateFilter("status", selected === "all" ? null : (selected as HolidayRequestStatus))
	}

	function handleYearSelect(event: Event) {
		const selected = (event.target as HTMLSelectElement).value
		props.updateFilter("year", selected === "all" ? null : Number.parseInt(selected))
	}

	return () => (
		<div class="flex items-center spacing-4">
			<label>
				Status
				<select value={props.filter.status ?? "all"} onInput={handleStatusSelect}>
					<option value="all">All</option>
					{Object.values(HolidayRequestStatus).map((status) => (
						<option key={status} value={status ?? "pending"}>
							{formatEnum(status)}
						</option>
					))}
				</select>
			</label>
			<label>
				Year
				<select value={props.filter.year ?? "all"} onInput={handleYearSelect}>
					<option value="all">All Time</option>
					{allYears.map((year) => (
						<option key={year} value={year}>
							{year}
						</option>
					))}
				</select>
			</label>
			<label>
				User
				<input
					value={props.filter.userSearch}
					type="search"
					onInput={useOnInput((text) => props.updateFilter("userSearch", text))}
				/>
			</label>
		</div>
	)
}

export default defineComponent(HolidayFilter, {
	lowestYear: requiredProp(Number),
	filter: requiredProp(Object),
	updateFilter: requiredProp(Function),
})
