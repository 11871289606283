import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	message: string
}

const infoMessageStyles = css`
	font-size: 1.2rem;
	color: var(--color-info);
	font-weight: 700;
	margin: 0;
`

const InfoMessage: ReactiveComponent<Props> = (props) => {
	return () => <h5 class={infoMessageStyles}>{props.message}</h5>
}

export default defineComponent(InfoMessage, {
	message: requiredProp(String),
})
