export * from "./BasicErrorPage"

import type { Component } from "vue-utils"
import { createStateLoading } from "vue-utils"
import FullScreenLoading from "../utility/FullScreenLoading"
import { BasicErrorPage } from "./BasicErrorPage"

export default createStateLoading({
	LoadingComponent: FullScreenLoading as Component,
	ErrorComponent: BasicErrorPage,
})
