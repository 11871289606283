import { DayOfWeek, LocalDate, YearMonth } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import CalendarDay from "./CalendarDay"
import CalendarNavigation from "./CalendarNavigation"
import { formatDayOfWeek, getDate } from "./formatting"
import type { CalendarEvent } from "./types"
import { groupEventsByDate } from "./utilts"
import { computed } from "vue"

interface Props {
	date: YearMonth
	setDate(date: YearMonth): void
	events?: CalendarEvent[]
	showWeekends?: boolean
	earliest?: YearMonth
	latest?: YearMonth
}

const calendarStyles = css`
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(0, 0, 0, 0.25);
`

const headerStyles = css`
	display: grid;
	grid-template-columns: repeat(var(--days-in-week), 1fr);
	padding: 0.5rem 0;
	background-color: #f6f6f6;
	border-top: 1px solid rgba(0, 0, 0, 0.25);

	th {
		font-weight: 500;
		color: #666;
	}

	tr {
		display: contents;
	}
`

const bodyStyles = css`
	display: grid;
	grid-template-columns: repeat(var(--days-in-week), minmax(0, 1fr));
	grid-auto-rows: 1fr;
	tr {
		display: contents;
	}
`

const Calendar: ReactiveComponent<Props> = (props) => {
	const { showWeekends = false, events = [] } = $(props)

	const days = computed(() =>
		showWeekends
			? DayOfWeek.values()
			: [DayOfWeek.MONDAY, DayOfWeek.TUESDAY, DayOfWeek.WEDNESDAY, DayOfWeek.THURSDAY, DayOfWeek.FRIDAY]
	)
	const eventsByDate = $computed(() => groupEventsByDate(events))

	function getPreviousDate(week: number, dayIndex: number): LocalDate | null {
		if (dayIndex === 0) {
			week--
			dayIndex = days.value.length - 1
		}
		if (week < 1) {
			return null
		}
		return getDate(days.value[dayIndex - 1], week, props.date.month(), props.date.year())
	}

	return () => (
		<div class={calendarStyles}>
			<CalendarNavigation {...props} />
			<table style={{ "--days-in-week": days.value.length }} class="display-contents">
				<thead class={headerStyles}>
					<tr>
						{days.value.map((day) => (
							<th key={day.ordinal()}>{formatDayOfWeek(day)}</th>
						))}
					</tr>
				</thead>
				<tbody class={bodyStyles}>
					{[1, 2, 3, 4, 5, 6].map((week) => (
						<tr key={week}>
							{days.value.map((day, dayIndex) => {
								const date = getDate(day, week, props.date.month(), props.date.year())
								const previousDate = getPreviousDate(week, dayIndex)

								const events = eventsByDate.get(date) ?? []
								const previousEvents = (previousDate && eventsByDate.get(previousDate)) ?? []

								return (
									<CalendarDay
										key={day.ordinal()}
										month={props.date.month()}
										date={date}
										events={events}
										previousEvents={previousEvents}
									/>
								)
							})}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export default defineComponent(Calendar, {
	date: requiredProp(YearMonth),
	setDate: requiredProp(Function),
	showWeekends: optionalProp(Boolean),
	events: optionalProp(Array),
	earliest: optionalProp(YearMonth),
	latest: optionalProp(YearMonth),
})
