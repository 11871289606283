import { useActivities } from "@/data/coreDataStore"
import type { Activity } from "@/models"
import { toRef } from "@vueuse/core"
import { ToggleButton, defineComponent, type ReactiveComponent } from "vue-utils"
import MultiSelect from "../../../components/multi-select"
import useHolidaysPlannerStore from "./store"

const PlannerControls: ReactiveComponent = () => {
	const store = useHolidaysPlannerStore()
	const activities = useActivities()
	const additionalActivities = $computed(() =>
		store.additionalActivities.filter((id) => activities.has(id)).map((id) => activities.get(id) as Activity)
	)

	return () => (
		<div>
			<h4 style={{ margin: 0 }}>Controls</h4>
			<hr />
			<div class="flex flex-col spacing-4">
				<label>
					Filter Staff
					<input type="search" v-model={toRef(store, "userSearch").value} placeholder="Type to search..." />
				</label>
				<label>
					Include Pending Holidays
					<ToggleButton
						toggled={store.includePendingHolidays}
						setToggled={(t) => store.updateControl("includePendingHolidays", t)}
					/>
				</label>
				<label>
					Hide Staff Without Holiday
					<ToggleButton
						toggled={store.hideStaffWithoutHoliday}
						setToggled={(t) => store.updateControl("hideStaffWithoutHoliday", t)}
					/>
				</label>
				<label>
					Additional Activities
					<MultiSelect
						class="w-full"
						options={Array.from(activities.values())}
						selected={additionalActivities}
						setSelected={(t) =>
							store.updateControl(
								"additionalActivities",
								t.map((a) => a.id)
							)
						}
						getKey={(activity) => activity.id}
						getText={(activity) => activity.name}
						noneText="All Activities"
						allText="All Activities"
						someText={(activities) =>
							activities.length === 1 ? activities[0].name : `${activities.length} Activities Selected`
						}
					/>
				</label>
			</div>
		</div>
	)
}

export default defineComponent(PlannerControls)
