import { BasicSelect } from "@/components/basic-form"
import { useSite } from "@/data/coreDataStore"
import type { Id, Site } from "@/models"
import { SiteListType, useSiteList } from "@/utils/composition/useSiteList"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import type { InlineEditFormProps } from "../InlineEditList"

const SiteForm: ReactiveComponent<InlineEditFormProps<{ siteId: Id }, Site>> = (props) => {
	const { siteId, remainingOptions, mode, setData, readonly = false } = $(props)
	const site = useSite(() => siteId)
	const sites = useSiteList(SiteListType.Selection, {
		selectedSite: site,
	})

	return () => (
		<BasicSelect
			options={mode === "create" ? remainingOptions : sites.value}
			value={site.value}
			setValue={(newSite) => setData("siteId", newSite.id)}
			disabled={readonly || mode !== "create"}
			required
		/>
	)
}

export default defineComponent(SiteForm, {
	siteId: requiredProp(Number),
	remainingOptions: requiredProp(Array),
	mode: requiredProp(String),
	setData: requiredProp(Function),
	readonly: optionalProp(Boolean),
})
