import StateLoading from "@/components/state-loading"
import SimpleTable from "@/components/table/SimpleTable"
import SortableHeading from "@/components/table/SortableHeading"
import type { SortDirection } from "@/models"
import { convert } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { onBeforeMount } from "vue"
import { defineComponent, loadLoadable, toLoadable, type ReactiveComponent } from "vue-utils"
import PlannerTableContent from "./PlannerTableContent"
import getWeekDatesInMonth from "./monthDates"
import useHolidaysPlannerStore from "./store"
import { TableSortBy } from "./tableSorting"

const headerStyles = css`
	position: sticky;
	top: 0;
	align-self: start;
	background-color: #fff;
	z-index: 2;

	th {
		background: #eee !important;
	}
`

const tableStyles = css`
	border: none;

	& > tbody,
	& > thead {
		display: grid;
		grid-template-columns: 18em 14em 5.5em 7.5em 5.5em repeat(var(--total-days), 3em);
	}

	tr {
		display: contents;
	}

	tbody tr td:nth-child(1),
	thead > tr:first-child > th:first-child {
		position: sticky;
		left: 0;
		background-color: #fff;
		z-index: 1;
	}

	tbody tr td:nth-child(1) {
		border-right: 2px solid rgba(0, 0, 0, 0.5);
	}

	th {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 500;
	}
	td,
	th {
		border-left: none;
		border-top: none;
	}
	th:first-child,
	td:first-child {
		border-left: thin solid var(--table-border-color);
	}
	thead > tr:first-child > th {
		border-top: thin solid var(--table-border-color);

		&:nth-child(3) {
			padding: 0.25rem;
		}
	}
	thead > tr:nth-child(2) > th {
		padding: 0.25rem;
	}

	td {
		text-align: center;
	}

	td:nth-child(1),
	td:nth-child(2) {
		text-align: left;
	}

	td {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	th:nth-child(n + 5) {
		font-size: 0.92em;
	}

	td:nth-child(5n + 6),
	tr:first-child > th:nth-child(5n + 4) {
		border-left: 1px solid rgba(0, 0, 0, 0.5);
	}

	td:last-child,
	tr:first-child > th:last-child {
		border-right: 1px solid rgba(0, 0, 0, 0.5);
	}

	a {
		color: inherit;
		&:hover {
			color: var(--color-primary);
		}
	}
`

const PlannerTable: ReactiveComponent = () => {
	const store = useHolidaysPlannerStore()
	const days = $computed(() => getWeekDatesInMonth(store.month, store.year))
	const loadable = toLoadable(store.data, () => store.queryData())

	onBeforeMount(() => void loadLoadable(loadable))

	function setDirectionFor(sort: TableSortBy) {
		return (direction: SortDirection) => {
			store.sortBy = sort
			store.sortDirection = direction
		}
	}

	return () =>
		store.data.type === "error" ? (
			<StateLoading stores={[loadable]} />
		) : (
			<>
				<SimpleTable class={tableStyles} style={{ "--total-days": days.length }}>
					<thead class={headerStyles}>
						<tr>
							<SortableHeading
								direction={store.sortBy === TableSortBy.User ? store.sortDirection : null}
								setDirection={setDirectionFor(TableSortBy.User)}
								rowspan={2}
							>
								User
							</SortableHeading>
							<SortableHeading
								direction={store.sortBy === TableSortBy.Trade ? store.sortDirection : null}
								setDirection={setDirectionFor(TableSortBy.Trade)}
								rowspan={2}
							>
								Trade
							</SortableHeading>
							<th colspan={3}>Annual Allowances - {store.year}</th>
							{days.map((day) => (
								<th
									style={day.month().ordinal() === store.month.ordinal() ? undefined : { color: "#808080" }}
									rowspan={2}
									key={day.toEpochDay() + 4}
								>
									{convert(day).toDate().toLocaleDateString(undefined, {
										weekday: "short",
										day: "2-digit",
										month: "2-digit",
									})}
								</th>
							))}
						</tr>
						<tr>
							<th>Total</th>
							<th>Used / Booked</th>
							<th>Available</th>
						</tr>
					</thead>
					<tbody>
						<StateLoading stores={[loadable]}>
							<PlannerTableContent />
						</StateLoading>
					</tbody>
				</SimpleTable>
			</>
		)
}

export default defineComponent(PlannerTable)
