import { useTodaysDate } from "@/utils/composition/useDate"
import { LocalDate, convert } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { ref, toRef, watch, type HTMLAttributes } from "vue"
import { defineComponent, optionalProp, refSetter, requiredProp, type ReactiveComponent } from "vue-utils"
import YearNavigateContainer from "./YearNavigateContainer"

const gridStyles = css`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	margin: 0.125rem;

	flex-grow: 1;
	gap: 1px;

	& > button {
		all: unset;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 0.75rem;
		padding: 0.35rem 0.5rem;
		user-select: none;

		&[data-today="true"] {
			font-weight: bold;
		}
		&[data-selected="true"] {
			background-color: var(--color-primary-light-6);
		}
		&[data-selected="false"]:hover {
			color: white;
			background-color: var(--color-info-dark-3);
			cursor: pointer;
		}
	}
`

interface Props {
	weekStart?: LocalDate
	setWeekStart(weekStart: LocalDate): void
	onBlur?: () => void
}

const WeekPicker: ReactiveComponent<Props, HTMLAttributes> = (props, { attrs }) => {
	const year = ref(props.weekStart?.year() ?? LocalDate.now().year())
	const dateToday = useTodaysDate()
	watch(toRef(props, "weekStart"), (weekStart) => {
		if (weekStart) {
			year.value = weekStart.year()
		}
	})

	function getWeekStart(year: number, weekIndex: number) {
		const first = LocalDate.of(year, 1, 1)
		const firstMonday = first.plusDays((7 - first.dayOfWeek().ordinal()) % 7)
		return firstMonday.plusDays(weekIndex * 7)
	}

	function format(date: LocalDate) {
		const str = convert(date).toDate().toLocaleDateString("en-US", {
			day: "2-digit",
			month: "short",
		})
		const parts = str.split(" ")
		return `${parts[1]} ${parts[0].substring(0, 3)}`
	}

	return () => (
		<YearNavigateContainer year={year.value} setYear={refSetter(year)} onBlur={props.onBlur} {...attrs}>
			<div class={gridStyles}>
				{Array.from({ length: 52 }, (_, i) => {
					const date = getWeekStart(year.value, i)
					return (
						<button
							key={i}
							aria-label={`Select Week Starting ${convert(date).toDate().toLocaleDateString()}`}
							data-selected={String(date.equals(props.weekStart))}
							data-today={String(date.equals(dateToday.value.getStartOfWeek()))}
							onClick={() => props.setWeekStart(date)}
						>
							{format(date)}
						</button>
					)
				})}
			</div>
		</YearNavigateContainer>
	)
}

export default defineComponent(WeekPicker, {
	weekStart: optionalProp(LocalDate),
	setWeekStart: requiredProp(Function),
	onBlur: optionalProp(Function),
})
