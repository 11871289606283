import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import UpdateChecker from "./UpdateChecker"
import UserInformation from "./UserInformation"
import Version from "./Version"

const ProfileSettingsPage: ReactiveComponent = () => {
	return () => (
		<BasicPage title="Profile & Settings">
			<div class="flex flex-col spacing-8">
				<UpdateChecker />
				<UserInformation />
				<Version />
			</div>
		</BasicPage>
	)
}

export default defineComponent(ProfileSettingsPage)
