import type { HolidayRequest, HolidayRequestWorkingTimes, Id, Nullable, TimeSheetEntry } from "@/models"
import { useLoading } from "@/utils/composition/useLoading"
import { computed, watch } from "vue"
import { LoadingError, mergePromises, RefQueries, useQueryRef } from "vue-utils"
import { getHolidayRequest, getHolidayRequestTimes, getHolidayRequestTimeSheets } from "../service"

interface HolidayWorkingResult {
	request: HolidayRequest
	timeSheets: TimeSheetEntry[]
	times: Nullable<HolidayRequestWorkingTimes>
}

export const useHolidayRequestTimes = () => {
	const { runAction } = useLoading()

	const requestIdRef = useQueryRef("requestId", null, RefQueries.NullableInt)
	let selectedRequestData = $shallowRef<HolidayWorkingResult | null>(null)

	async function loadRequest(id: Id) {
		let request: HolidayRequest | null
		try {
			request = await getHolidayRequest(id)
			if (!request) {
				throw new LoadingError("Unknown Holiday Request", "The holiday request specified does not exist")
			}
		} catch (e) {
			requestIdRef.value = null
			throw e
		}

		const timeSheetsPromise = getHolidayRequestTimeSheets(request.id)
		const timesPromise = request.approval ? Promise.resolve(null) : getHolidayRequestTimes(request.id)

		try {
			selectedRequestData = {
				request,
				...(await mergePromises({
					timeSheets: timeSheetsPromise,
					times: timesPromise,
				})),
			}
		} catch (e) {
			requestIdRef.value = null
			throw e
		}
	}

	watch(
		requestIdRef,
		(reqId) => {
			if (reqId === null) {
				selectedRequestData = null
			} else {
				void runAction(loadRequest(reqId))
			}
		},
		{ immediate: true }
	)

	return {
		requestId: requestIdRef,
		selectedRequest: computed(() => selectedRequestData),
	}
}
