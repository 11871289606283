import AuthorizePermission from "@/authentication/AuthorizePermission"
import StateLoading from "@/components/state-loading"
import { Permission } from "@/models"
import { LocalDate } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { watch } from "vue"
import { useRoute } from "vue-router"
import {
	defineComponent,
	ensureLoadingHasResult,
	loadLoadable,
	RefQueries,
	ShadowContainer,
	useLoadableRef,
	useQueryRef,
	type ReactiveComponent,
	runWithMinimumDelay,
} from "vue-utils"
import PrintablePage from "../../PrintablePage"
import AbsenceCalendar from "./AbsenceCalendar"
import AbsenceCalendarKey from "./AbsenceCalendarKey"
import { getStaffAbsenceInformation } from "./requests"

const containerStyles = css`
	display: grid;
	grid-template-columns: auto 15rem;
	gap: 0.75rem;
	height: 100%;

	* {
		-webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
		color-adjust: exact !important; /* Firefox 48 – 96 */
		print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
	}

	h2 {
		margin-top: 0;
		margin-bottom: 1rem;
	}

	@media screen and (max-width: 56rem), print and (max-width: 22cm) {
		grid-template-columns: auto;
	}

	@media print {
		.vu-shadow-container {
			box-shadow: none !important;
			border: 1px solid rgba(0, 0, 0, 0.1);
			padding: 0.5rem;
		}
	}
`

const StaffAbsenceCalendar: ReactiveComponent = () => {
	const currentRoute = useRoute()
	const yearRef = useQueryRef("year", LocalDate.now().year(), RefQueries.Int)
	const requestRef = useLoadableRef(() =>
		runWithMinimumDelay(
			getStaffAbsenceInformation(Number.parseInt(String(currentRoute.params.userId)), yearRef.value),
			750
		)
	)

	watch(yearRef, () => void loadLoadable(requestRef))

	return () => (
		<PrintablePage title="Staff Absence Calendar">
			<AuthorizePermission permissions={[Permission.StaffAbsenceCalendar]}>
				<div class={containerStyles}>
					<ShadowContainer>
						<StateLoading stores={[requestRef]}>
							<AbsenceCalendar
								year={yearRef.value}
								setYear={(newYear) => (yearRef.value = newYear)}
								{...ensureLoadingHasResult(requestRef)}
							/>
						</StateLoading>
					</ShadowContainer>
					<ShadowContainer>
						<AbsenceCalendarKey />
					</ShadowContainer>
				</div>
			</AuthorizePermission>
		</PrintablePage>
	)
}

export default defineComponent(StaffAbsenceCalendar)
