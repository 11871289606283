import { renderSlot } from "vue"
import { defineComponent, requiredProp, type Component, type ReactiveComponent } from "vue-utils"

interface Props {
	title: string | Component
}

const BasicScreen: ReactiveComponent<Props> = (props, { slots }) => {
	return () => (
		<>
			{typeof props.title === "string" ? <h2>{props.title}</h2> : <props.title />}
			<div class="flex flex-col flex-auto items-center" style={{ padding: "0 1.5rem" }}>
				{renderSlot(slots, "default")}
			</div>
		</>
	)
}

export default defineComponent(BasicScreen, {
	title: requiredProp(String, Object, Function),
})
