import { BasicSelect } from "@/components/basic-form"
import { useActivities, useActivity } from "@/data/coreDataStore"
import type { Activity, Id, Nullable, Site } from "@/models"
import { showErrorMessage } from "@/utils/composition/useLoading"
import { SiteListType, useSiteList } from "@/utils/composition/useSiteList"
import { css } from "vite-css-in-js"
import { shallowRef } from "vue"
import { Required, defineComponent, optionalProp, refSetter, requiredProp, type ReactiveComponent } from "vue-utils"
import LargeButton from "../shared/clock/components/LargeButton"
import BasicScreen from "../shared/clock/screens/BasicScreen"

interface Props {
	defaultActivityId: Nullable<Id>
	lockedSite?: Site

	clockIn(site: Site, activity: Activity): void
	showHolidays?: () => void
	cancel(): void
}

const containerStyles = css`
	flex-grow: 1;
	font-size: 1.125rem;

	display: flex;
	flex-direction: column;

	label {
		margin-top: 2.5rem;
		font-size: 1.2rem;
	}

	h4 {
		margin-top: 2.5em;
		margin-bottom: 0;
		font-weight: normal;
		font-size: 1.1em;
	}
`

const ClockIn: ReactiveComponent<Props> = (props) => {
	const allActivities = useActivities()

	const activities = $computed(() =>
		Array.from(allActivities.values()).filter((activity) => activity.canClockInWith && !activity.minimumViewRole)
	)

	let activity = $ref(useActivity(props.defaultActivityId).value)
	const site = shallowRef(props.lockedSite ?? null)
	const sites = useSiteList(SiteListType.NonArchived)

	const handleClockIn = () => {
		if (!site.value) {
			void showErrorMessage("No Activity Selected", "Please select a site")
		} else if (!activity) {
			void showErrorMessage("No Activity Selected", "Please select an activity")
		} else {
			props.clockIn(site.value, activity)
		}
	}

	return () => (
		<BasicScreen title="Clock In">
			<div class={containerStyles}>
				<h4>Please select your current activity before clocking in.</h4>

				<label>
					<Required label="Select Activity" />
					<BasicSelect
						options={activities}
						value={activity}
						setValue={(newActivity) => (activity = newActivity)}
						defaultText="Select an Activity"
					/>
				</label>

				{!props.lockedSite && (
					<label>
						<Required label="Select Site" />
						<BasicSelect
							options={sites.value}
							value={site.value}
							setValue={refSetter(site)}
							defaultText="Select a Site"
						/>
					</label>
				)}

				<div class="flex justify-center items-center flex-auto">
					<LargeButton color="primary" disabled={!activity} onClick={handleClockIn}>
						Clock In
					</LargeButton>
				</div>
				<div class="flex justify-center items-center flex-1">
					<LargeButton color="secondary" onClick={props.cancel}>
						Quit
					</LargeButton>
				</div>
				{props.showHolidays && (
					<>
						<hr></hr>
						<div class="flex justify-center items-center flex-auto">
							<LargeButton color="light" onClick={props.showHolidays}>
								Request Holiday
							</LargeButton>
						</div>
					</>
				)}
			</div>
		</BasicScreen>
	)
}

export default defineComponent(ClockIn, {
	defaultActivityId: requiredProp(Number, null),
	lockedSite: optionalProp(Object),

	clockIn: requiredProp(Function),
	showHolidays: optionalProp(Function),
	cancel: requiredProp(Function),
})
