import type { Id, Nullable, Site, SwitchedSiteRecord, TimeSheetEntry } from "@/models"
import { formatTimeInUserTimeZone } from "@/utils/timeUtils"
import { useLocalStorage, StorageSerializers } from "@vueuse/core"
import { defineStore } from "pinia"
import type { Ref } from "vue"
import { defineGlobals, LoadingError } from "vue-utils"
import { Screen } from "./Screen"
import {
	clockIn,
	clockOut,
	getClockedInByPin,
	switchSiteOrActivity,
	updateTimeSheetNotes,
	type CurrentlyClockedInInformation,
} from "./service"

export interface SuccessDetails {
	title: string
	message: string
}

interface State {
	kioskEnabled: Ref<boolean>
	kioskPin: Ref<Nullable<string>>
	assignedSiteId: Ref<Nullable<Id>>
	screen: Screen
	userDetails: CurrentlyClockedInInformation | null
	successDetails: SuccessDetails
}

export const useKioskModeStore = defineStore("kiosk-mode", {
	state: (): State => ({
		kioskEnabled: useLocalStorage("kiosk-mode-enabled", false),
		kioskPin: useLocalStorage("kiosk-mode-pin", null, {
			serializer: StorageSerializers.string,
		}),
		assignedSiteId: useLocalStorage("assigned-site", null, {
			serializer: StorageSerializers.number,
		}),
		screen: Screen.Pin,
		userDetails: null as Nullable<CurrentlyClockedInInformation>,
		successDetails: {
			title: "",
			message: "",
		},
	}),
	getters: {
		hasClockedInInfo(): boolean {
			return this.userDetails !== null && !!this.userDetails.activeTimeSheet
		},
	},
	actions: {
		logout() {
			this.$reset()
		},
		enterKioskMode() {
			this.kioskEnabled = true
			void document.documentElement.requestFullscreen()
		},
		async disableKioskMode() {
			this.kioskEnabled = false
			try {
				await document.exitFullscreen()
			} catch (e) {
				//Do nothing - error may just be not already full screen
			}
		},
		getAssignedSite(sites: Map<Id, Site>): Nullable<Site> {
			if (this.assignedSiteId === null) {
				return null
			}
			return sites.get(this.assignedSiteId) ?? null
		},
		kioskModePinMatches(pin: string): boolean {
			if (!this.kioskPin) {
				throw new Error("No kiosk mode pin")
			}
			return this.kioskPin === pin
		},
		clearLocalStorage() {
			this.kioskEnabled = false
			this.kioskPin = null
		},
		switchScreen(screen: Screen) {
			this.screen = screen
		},
		showSuccessMessage(title: string, message: string) {
			this.successDetails = {
				title,
				message,
			}
			this.switchScreen(Screen.Success)
		},
		getDetailsOrThrow(): CurrentlyClockedInInformation {
			const details = this.userDetails
			if (!details) {
				throw new Error("Internal state error - no currently clocked in information")
			}
			return details as CurrentlyClockedInInformation
		},
		getActiveTimeSheetOrThrow(): TimeSheetEntry {
			const details = this.getDetailsOrThrow()
			const latest = details.activeTimeSheet
			if (!latest) {
				throw new Error("Internal state error - no active time sheet")
			}
			return latest
		},
		patchDetails(patchAction: (details: CurrentlyClockedInInformation) => void) {
			this.getDetailsOrThrow()
			this.$patch((state) => {
				patchAction(state.userDetails as CurrentlyClockedInInformation)
			})
		},
		async loadDetails(pin: string): Promise<void> {
			const details = await getClockedInByPin(pin)
			if (!details) {
				throw new LoadingError("Unknown PIN", "No user found with that PIN. Try again")
			}
			if (!details.user.kioskEnabled) {
				throw new LoadingError("Clock in disabled", "You are no longer permitted to manage your time sheets")
			}
			this.userDetails = details
			this.screen = Screen.VerifyDetails
		},
		async clockIn(siteId: Id, activityId: Id): Promise<TimeSheetEntry> {
			const details = this.getDetailsOrThrow()
			const timeSheet = await clockIn(details.user.id, siteId, activityId)

			this.patchDetails((details) => {
				details.lastActivityId = activityId
				details.activeTimeSheet = timeSheet
			})

			this.showSuccessMessage(
				"Successfully clocked in",
				timeSheet.clockIn.entered ? `You clocked in at ${formatTimeInUserTimeZone(timeSheet.clockIn.entered)}` : ""
			)
			return timeSheet
		},
		async clockOut(): Promise<TimeSheetEntry> {
			const details = this.getDetailsOrThrow()
			const closedTimeSheet = await clockOut(details.user.id)

			this.patchDetails((details) => {
				details.lastActivityId = closedTimeSheet.activityId
				details.activeTimeSheet = null
			})

			this.showSuccessMessage(
				"Successfully clocked out",
				closedTimeSheet.clockOut.entered
					? `You clocked out at ${formatTimeInUserTimeZone(closedTimeSheet.clockOut.entered)}`
					: ""
			)
			return closedTimeSheet
		},
		async switchSite(newSiteId: Id, newActivityId: Id): Promise<SwitchedSiteRecord> {
			const userId = this.getDetailsOrThrow().user.id
			const record = await switchSiteOrActivity(userId, newSiteId, newActivityId)

			this.patchDetails((details) => {
				details.lastActivityId = record.newTimeSheet.activityId
				details.activeTimeSheet = record.newTimeSheet
			})

			this.showSuccessMessage("Success", "Successfully switched site & activity")
			return record
		},
		async editNotes(newNotes: string): Promise<TimeSheetEntry> {
			const activeTimeSheet = this.getActiveTimeSheetOrThrow()
			const newEntry = await updateTimeSheetNotes(activeTimeSheet.id, newNotes)

			this.patchDetails((details) => {
				details.lastActivityId = newEntry.activityId
				details.activeTimeSheet = newEntry
			})

			this.showSuccessMessage("Successfully updated notes", "")
			return newEntry
		},
	},
})

defineGlobals({
	useKioskModeStore,
})
