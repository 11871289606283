import InfoMessage from "@/components/InfoMessage"
import StateLoading from "@/components/state-loading"
import { onBeforeMount, watch } from "vue"
import {
	defineComponent,
	ensureLoadingHasResult,
	loadLoadable,
	RefQueries,
	ShadowContainer,
	useLoadableRef,
	useQueryRef,
	type ReactiveComponent,
} from "vue-utils"
import ClockEventsTable from "./ClockEventsTable"
import { getRejectedClockEvents } from "./service"

const ClockEvents: ReactiveComponent = () => {
	let page = $(useQueryRef("page", 1, RefQueries.Int))

	const loadable = useLoadableRef(() => getRejectedClockEvents(page - 1))
	const refreshData = () => void loadLoadable(loadable)

	watch($$(page), refreshData)
	onBeforeMount(refreshData)

	function renderContent() {
		const data = ensureLoadingHasResult(loadable)
		if (data.totalItems === 0) {
			return (
				<div class="flex-auto flex justify-center items-center">
					<InfoMessage message="No rejected clock events were found" />
				</div>
			)
		}
		return <ClockEventsTable {...data} page={page} setPage={(newPage) => (page = newPage)} />
	}

	return () => (
		<ShadowContainer class="flex flex-col flex-1" style={{ minWidth: "72rem" }}>
			<StateLoading stores={[loadable]}>{{ default: renderContent }}</StateLoading>
		</ShadowContainer>
	)
}

export default defineComponent(ClockEvents)
