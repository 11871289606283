import { useTrades } from "@/data/coreDataStore"
import type { UserMetadata } from "@/models"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import TradeInformation from "./misc/TradeInformation"

interface Props {
	user: UserMetadata

	selected?: boolean
	onClick?: () => void
}

const nameStyles = css`
	&[data-selected="true"] {
		font-weight: 700;
		color: var(--color-primary);
	}
`

const buttonStyles = css`
	all: unset;
	cursor: pointer;

	&:hover {
		color: var(--color-primary);
	}
`

const UserNameDisplay: ReactiveComponent<Props> = (props) => {
	const trades = useTrades()
	const trade = computed(() => {
		if (props.user.tradeId === null) {
			return null
		}
		return trades.get(props.user.tradeId) ?? null
	})

	function renderName() {
		return (
			<span class={nameStyles} data-selected={String(props.selected ?? false)}>
				{props.user.lastName}, {props.user.firstName}
			</span>
		)
	}

	return () => (
		<TradeInformation trade={trade.value}>
			{props.onClick ? (
				<button class={buttonStyles} onClick={props.onClick}>
					{renderName()}
				</button>
			) : (
				renderName()
			)}
		</TradeInformation>
	)
}

export default defineComponent(UserNameDisplay, {
	user: requiredProp(Object),
	selected: optionalProp(Boolean),
	onClick: optionalProp(Function),
})
