import type { PermissionTab } from "@/components/PermissionTabs"
import PermissionTabs from "@/components/PermissionTabs"
import StateLoading from "@/components/state-loading"
import useCoreDataStore from "@/data/coreDataStore"
import Permission from "@/models/Permission"
import { assignLoadable, defineComponent, loadLoadable, useLoadableRef, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import Activities from "./activities"
import ClockEvents from "./clock-events"
import CompanyHolidays from "./company-holidays"
import HolidaySettingsPage from "./holiday-settings"
import Sites from "./sites"
import Trades from "./trades"
import Users from "./users"

const tabs: PermissionTab[] = [
	{
		id: "users",
		name: "Users",
		renderContent: Users,
		permission: Permission.ViewUsers,
	},
	{
		id: "activities",
		name: "Activities",
		renderContent: Activities,
		permission: Permission.ViewActivities,
	},
	{
		id: "sites",
		name: "Sites",
		renderContent: Sites,
		permission: Permission.ViewSites,
	},
	{
		id: "trades",
		name: "Trades",
		renderContent: Trades,
		permission: Permission.ViewTrades,
	},
	{
		id: "clock-events",
		name: "Rejected Clock Events",
		renderContent: ClockEvents,
		permission: Permission.ViewRejectedClockEvents,
	},
	{
		id: "company-holidays",
		name: "Company Holidays",
		renderContent: CompanyHolidays,
		permission: Permission.ViewCompanyHolidays,
	},
	{
		id: "holiday-settings",
		name: "Holiday Settings",
		renderContent: HolidaySettingsPage,
		permission: Permission.EditHolidaySettings,
	},
]

const SystemManagementPage: ReactiveComponent = () => {
	//Refresh core data store
	const dataStore = useCoreDataStore()
	const refreshRef = useLoadableRef(async () => {
		const data = await dataStore.queryData()
		assignLoadable(dataStore, {
			type: "done",
			result: data,
		})
		return data
	})
	void loadLoadable(refreshRef)

	return () => (
		<BasicPage title="Administration" class="flex flex-col" style={{ minWidth: "48rem" }}>
			<StateLoading stores={[refreshRef]}>
				<PermissionTabs tabs={tabs} type="query" />
			</StateLoading>
		</BasicPage>
	)
}

export default defineComponent(SystemManagementPage)
