import {
	mapApiHolidayRequest,
	mapApiHolidayRequestContext,
	mapHolidayDayTimes,
	type HolidayAllowance,
	type HolidayDayTimes,
	type HolidayRequest,
	type HolidayRequestContext,
	type HolidayRequestFilter,
	type HolidayRequestValidation,
	type HolidayRequestWorkingTimes,
	type Id,
	type TimeSheetEntry,
} from "@/models"
import {
	RequestMethod,
	httpJsonRequest,
	httpJsonRequestJsonResponse,
	httpRequestJsonResponse,
	sanitiseQueryParams,
} from "@/services/httpService"
import { mapApiEntry } from "@/services/timesheetsService"
import { LocalDate } from "@js-joda/core"

export const getHolidayRequests = async (userId?: Id): Promise<HolidayRequest[]> => {
	const requests = await httpRequestJsonResponse<HolidayRequest[]>(
		`/api/holiday-requests/${userId ?? ""}`,
		RequestMethod.GET
	)
	return requests.map(mapApiHolidayRequest)
}

export async function getHolidayRequestContext(userId?: Id): Promise<HolidayRequestContext> {
	const context = await httpRequestJsonResponse<HolidayRequestContext>(
		`/api/holiday-requests/context/${userId ?? ""}`,
		RequestMethod.GET
	)
	return mapApiHolidayRequestContext(context)
}

export const validateHolidayRequest = async (request: HolidayRequest): Promise<HolidayRequestValidation> =>
	await httpJsonRequestJsonResponse<HolidayRequestValidation>(
		"/api/holiday-requests/validate-request",
		request,
		RequestMethod.POST
	)

export async function getRemainingAllowance(options: { userId?: Id; year?: number }): Promise<HolidayAllowance> {
	const { userId, year = LocalDate.now().year() } = options

	if (userId) {
		return await httpRequestJsonResponse<HolidayAllowance>(
			`/api/holiday-requests/remaining-allowance/${userId}?year=${year}`,
			RequestMethod.GET
		)
	}
	return await httpRequestJsonResponse<HolidayAllowance>(
		`/api/holiday-requests/remaining-allowance?year=${year}`,
		RequestMethod.GET
	)
}

export function formatAllowance(allowance: HolidayAllowance) {
	if (allowance === "unlimited") {
		return "Unlimited"
	}
	if (allowance === 1) {
		return "1 Day"
	}
	return `${allowance} Days`
}

export const createHolidayRequest = async (request: HolidayRequest): Promise<HolidayRequest> => {
	const createdRequest = await httpJsonRequestJsonResponse<HolidayRequest>(
		"/api/holiday-requests/create",
		request,
		RequestMethod.POST
	)
	return mapApiHolidayRequest(createdRequest)
}

export const listHolidayRequests = async (filter: HolidayRequestFilter): Promise<HolidayRequest[]> => {
	const result = await httpJsonRequestJsonResponse<HolidayRequest[]>(
		"/api/holiday-requests/management",
		filter,
		RequestMethod.POST
	)
	return result.map(mapApiHolidayRequest)
}

export const getHolidayRequest = async (requestId: Id): Promise<HolidayRequest | null> => {
	const result = await httpRequestJsonResponse<HolidayRequest | null>(
		`/api/holiday-requests/management/${requestId}`,
		RequestMethod.GET
	)
	return result ? mapApiHolidayRequest(result) : null
}

export const getHolidayRequestTimes = async (
	holidayRequestId: Id,
	siteId?: Id
): Promise<HolidayRequestWorkingTimes> => {
	const params = sanitiseQueryParams({
		holidayRequestId,
		siteId,
	})

	const result = await httpRequestJsonResponse<HolidayRequestWorkingTimes>(
		`/api/holiday-requests/management/times?${params}`,
		RequestMethod.GET
	)
	return mapHolidayDayTimes(result)
}

export const getHolidayRequestTimeSheets = async (holidayRequestId: Id): Promise<TimeSheetEntry[]> => {
	const result = await httpRequestJsonResponse<TimeSheetEntry[]>(
		`/api/holiday-requests/management/timesheets/${holidayRequestId}`,
		RequestMethod.GET
	)
	return result.map(mapApiEntry)
}

export interface ApproveRequestData {
	requestId: Id
	siteId: Id
	activityId: Id
	comments: string
	days: HolidayDayTimes[]
}

export interface RejectRequestData {
	requestId: Id
	comments: string
}

export interface CancelRequestData {
	requestId: Id
	reason: string
}

export const approveHolidayRequest = async (data: ApproveRequestData) => {
	await httpJsonRequest("/api/holiday-requests/management/approve", data, RequestMethod.POST)
}

export const rejectHolidayRequest = async (data: RejectRequestData) => {
	await httpJsonRequest("/api/holiday-requests/management/reject", data, RequestMethod.POST)
}

export const cancelHolidayRequest = async (data: CancelRequestData) => {
	await httpJsonRequest("/api/holiday-requests/management/cancel", data, RequestMethod.POST)
}
