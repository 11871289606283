import { css } from "vite-css-in-js"
import { defineComponent, optionalProp, requiredProp, useTimeout, type ReactiveComponent } from "vue-utils"

const numberStyles = css`
	transition: all 0.2s linear;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 1.2em;
	width: 0.75em;
	height: 1.25em;
	color: #161616;

	&.hidden {
		background-color: #161616;
		border-radius: 100%;
		padding: 0.375em 0.125em;

		background-clip: content-box;
		width: 0.5em;
		height: 0.5em;
	}
`

interface Props {
	character: string
	visibleTime?: number
}

const HiddenCharacter: ReactiveComponent<Props> = (props) => {
	let visible = $ref(true)

	useTimeout(props.visibleTime ?? 1000, () => {
		visible = false
	})

	return () => <div class={[numberStyles, visible ? "" : "hidden"]}>{visible && props.character}</div>
}

export default defineComponent(HiddenCharacter, {
	character: requiredProp(String),
	visibleTime: optionalProp(Number),
})
