import PlainIconButton from "@/components/PlainIconButton"
import createEnumSelect from "@/components/basic-form/EnumSelect"
import StateLoading from "@/components/state-loading"
import type { OnSiteNowUser, UserMetadata } from "@/models"
import { RequestMethod, httpRequestJsonResponse } from "@/services/httpService"
import { formatEnum } from "@/utils/formatEnum"
import { faSync } from "@fortawesome/free-solid-svg-icons"
import { watch } from "vue"
import {
	defineComponent,
	ensureLoadingHasResult,
	loadLoadable,
	useLoadableRef,
	type Loadable,
	type ReactiveComponent,
	useQueryRef,
	RefQueries,
} from "vue-utils"
import OnSiteNowTable from "./OnSiteNowTable"
import NotClockedInTable from "./NotClockedInTable"

enum OnSiteSortBy {
	User = "user",
	Site = "site",
}

enum Page {
	OnSiteNow = "onSiteNow",
	NotClockedIn = "notClockedIn",
}

const SortBySelect = createEnumSelect<OnSiteSortBy>()
const PageSelect = createEnumSelect<Page>()

const Dashboard: ReactiveComponent = () => {
	let sortBy = $(useQueryRef("sort-by", OnSiteSortBy.User, RefQueries.enum(OnSiteSortBy)))
	let page = $(useQueryRef("page", Page.NotClockedIn, RefQueries.enum(Page)))

	const onSiteNowLoading = useLoadableRef<OnSiteNowUser[]>(() =>
		httpRequestJsonResponse(`/api/dashboard/on-site-now?sort-by=${sortBy}`, RequestMethod.GET)
	)
	const notClockedInLoading = useLoadableRef<UserMetadata[]>(() =>
		httpRequestJsonResponse("/api/dashboard/not-clocked-in", RequestMethod.GET)
	)
	const currentLoading = $computed<Loadable<unknown>>(() => {
		switch (page) {
			case Page.NotClockedIn:
				return notClockedInLoading
			case Page.OnSiteNow:
				return onSiteNowLoading
		}
		throw new Error("Unknown page")
	})

	const refresh = () => void loadLoadable(currentLoading)
	watch([$$(sortBy), $$(page)], refresh)
	refresh()

	function CurrentPage() {
		switch (page) {
			case Page.OnSiteNow:
				return <OnSiteNowTable users={ensureLoadingHasResult(onSiteNowLoading)} />
			case Page.NotClockedIn:
				return <NotClockedInTable users={ensureLoadingHasResult(notClockedInLoading)} />
		}
		throw new Error("Unknown page")
	}

	return () => (
		<>
			<div class="flex flex-wrap spacing-2 items-center justify-between" style={{ marginTop: "1rem" }}>
				<h2 style={{ margin: "0" }}>{formatEnum(page)}</h2>
				<div class="flex flex-wrap items-center" style={{ gap: "0.5rem 2rem" }}>
					<label class="flex flex-row items-center">
						<span>Show Page</span>
						<PageSelect items={Page} value={page} setValue={(p) => (page = p)} required />
					</label>
					<label class="flex flex-row items-center">
						<span>Sort By</span>
						<SortBySelect
							items={OnSiteSortBy}
							value={sortBy}
							setValue={(sb) => (sortBy = sb)}
							style={{ minWidth: "8rem" }}
							disabled={page !== Page.OnSiteNow}
							required
						/>
					</label>
					<PlainIconButton
						icon={faSync}
						title="Refresh"
						onClick={refresh}
						disabled={currentLoading.type !== "done" && currentLoading.type !== "error"}
					/>
				</div>
			</div>
			<hr />
			<StateLoading key="loading" stores={[currentLoading]}>
				<CurrentPage />
			</StateLoading>
		</>
	)
}

export default defineComponent(Dashboard)
