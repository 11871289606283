import SimpleTable from "@/components/table/SimpleTable"
import { useActivities, useSites } from "@/data/coreDataStore"
import type { TimeSheetEntry } from "@/models"
import { getUsedTime } from "@/services/timesheetsService"
import { formatDuration, formatTimeInUserTimeZone, formatYearDate } from "@/utils/timeUtils"
import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	timeSheets: TimeSheetEntry[]
}

const tableStyles = css`
	table-layout: auto !important;
	th {
		text-align: left;
	}
`

const CreatedTimeSheetsTable: ReactiveComponent<Props> = (props) => {
	const sites = useSites()
	const activities = useActivities()

	return () => (
		<SimpleTable class={tableStyles}>
			<thead>
				<tr>
					<th>Day</th>
					<th>Site</th>
					<th>Activity</th>
					<th>Clock In</th>
					<th>Clock Out</th>
					<th>Break</th>
				</tr>
			</thead>
			<tbody>
				{props.timeSheets.map((ts) => (
					<tr key={ts.id}>
						<td>{formatYearDate(getUsedTime(ts.clockIn))}</td>
						<td>{sites.get(ts.siteId)?.name ?? "-"}</td>
						<td>{activities.get(ts.activityId)?.name ?? "-"}</td>
						<td>{formatTimeInUserTimeZone(getUsedTime(ts.clockIn))}</td>
						<td>{formatTimeInUserTimeZone(getUsedTime(ts.clockOut))}</td>
						<td>{formatDuration(ts.break.calculated)}</td>
					</tr>
				))}
			</tbody>
		</SimpleTable>
	)
}

export default defineComponent(CreatedTimeSheetsTable, {
	timeSheets: requiredProp(Array),
})
