import { parseDuration, parseTime } from "@/utils/timeUtils"
import { LocalDate, type Duration, type LocalTime } from "@js-joda/core"
import type { ApiTypeOf, HolidayAllowance, Id, Nullable } from "."

export interface HolidayDateRange {
	start: HolidayDate
	end: HolidayDate
}

export interface HolidayDate {
	date: LocalDate
	timePoint: TimePoint
}

export enum TimePoint {
	StartOfDay = "startOfDay",
	MidDay = "midDay",
	EndOfDay = "endOfDay",
}

export enum HolidayDayType {
	Morning = "morning",
	Afternoon = "afternoon",
	FullDay = "fullDay",
}

export interface CalculatedHolidayDayTimes {
	date: LocalDate
	type: HolidayDayType
	startTime: Nullable<LocalTime>
	endTime: Nullable<LocalTime>
	breakDuration: Nullable<Duration>
}

export function mapApiCalculatedHolidayDayTimes(
	times: ApiTypeOf<CalculatedHolidayDayTimes>
): CalculatedHolidayDayTimes {
	return {
		...times,
		date: LocalDate.parse(times.date),
		startTime: times.startTime ? parseTime(times.startTime) : null,
		endTime: times.endTime ? parseTime(times.endTime) : null,
		breakDuration: times.breakDuration ? parseDuration(times.breakDuration) : null,
	}
}

export interface HolidayDayTimes {
	date: LocalDate
	type: HolidayDayType
	startTime: LocalTime
	endTime: LocalTime
	breakDuration: Duration
}

export interface HolidayRequestWorkingTimes {
	siteId: Nullable<Id>
	defaultActivityId: Nullable<Id>
	times: GeneratingHolidayDayTimes[]
	totalAllowance: HolidayAllowance
	allowanceToUse: number
	allowanceCurrentlyRemaining: HolidayAllowance
	allowanceRemainingAfter: HolidayAllowance
}

export const mapHolidayDayTimes = (result: ApiTypeOf<HolidayRequestWorkingTimes>): HolidayRequestWorkingTimes => ({
	...result,
	times: result.times.map(mapApiGeneratingHolidayDayTimes),
})

export interface GeneratingHolidayDayTimes extends CalculatedHolidayDayTimes {
	name: Nullable<string>
	activityId: Nullable<Id>
	isGenerated: boolean
	holidayType: HolidayType
}

export function mapApiGeneratingHolidayDayTimes(
	times: ApiTypeOf<GeneratingHolidayDayTimes>
): GeneratingHolidayDayTimes {
	return {
		...times,
		...mapApiCalculatedHolidayDayTimes(times),
	}
}

export enum HolidayType {
	Company = "company",
	Staff = "staff",
}

export enum DayPartType {
	Morning = "morning",
	Afternoon = "afternoon",
}

export interface DayPart {
	date: LocalDate
	type: DayPartType
}

export interface HolidayDay {
	date: LocalDate
	type: HolidayDayType
}

export function mapApiHolidayDay(day: ApiTypeOf<HolidayDay>): HolidayDay {
	return {
		date: LocalDate.parse(day.date),
		type: day.type,
	}
}
