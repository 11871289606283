import { ref, type FormHTMLAttributes, type FunctionalComponent } from "vue"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent, renderSlot } from "vue-utils"
import BootstrapButton, { type BootstrapButtonType } from "../BootstrapButton"
import GridRow from "../utility/GridRow"
import ErrorMessage from "../utility/ErrorMessage"

export type ValidationResult = string | boolean | Promise<string | boolean>

export interface FormButton {
	id: string
	onSubmit: (event: Event) => void | Promise<void>
	validate?: () => ValidationResult
	validateForm?: boolean

	render: FunctionalComponent<{
		onClick: (e: UIEvent) => void
		disabled: boolean
		isSubmitting: boolean
	}>
}

interface Props {
	customErrorMessage?: string
	showDivider?: boolean
	buttons: FormButton[]
}

export const basicFormButton = (
	options: Omit<FormButton, "render"> & { color: BootstrapButtonType; text: string }
): FormButton => {
	const { color, text, ...buttonOptions } = options
	return {
		render: (props) => (
			<BootstrapButton color={color} {...props}>
				{text}
			</BootstrapButton>
		),
		...buttonOptions,
	}
}

const BasicForm: ReactiveComponent<Props, FormHTMLAttributes> = (props, { attrs, slots }) => {
	const formRef = ref<HTMLFormElement>()
	let buttonSubmitting = $shallowRef<FormButton | null>(null)
	let errorMessage = $ref<string | null>(null)

	async function runSubmit(event: Event, button: FormButton) {
		buttonSubmitting = button
		try {
			await button.onSubmit(event)
		} catch (e) {
			errorMessage = (e as Error).message
			console.error(e)
		} finally {
			buttonSubmitting = null
		}
	}

	async function handleSubmit(event: Event, button: FormButton) {
		event.preventDefault()
		event.stopImmediatePropagation()
		if (buttonSubmitting !== null) {
			return
		}

		const formElement = formRef.value as HTMLFormElement
		if (button.validateForm && formElement && !formElement.reportValidity()) {
			return
		}

		if (button.validate) {
			const validationResult = await button.validate()

			if (validationResult !== true) {
				if (typeof validationResult === "string") {
					errorMessage = validationResult
				}
				return
			}
		}

		errorMessage = ""
		await runSubmit(event, button)
	}

	const renderButton = (button: FormButton) => (
		<button.render
			key={button.id}
			disabled={buttonSubmitting !== null}
			isSubmitting={buttonSubmitting !== null && buttonSubmitting.id === button.id}
			onClick={(e) => void handleSubmit(e, button)}
		/>
	)

	return () => (
		<form
			ref={formRef}
			class="basic-form"
			{...attrs}
			onSubmit={(e) => {
				e.preventDefault()
				e.stopImmediatePropagation()
				return false
			}}
		>
			{
				//https://stackoverflow.com/questions/895171/prevent-users-from-submitting-a-form-by-hitting-enter
			}
			<button type="submit" disabled style="display: none" aria-hidden="true"></button>

			{renderSlot(slots)}
			{props.showDivider !== false ? <hr class="w-full" /> : <br />}
			<div class="flex items-center spacing-4" style={{ marginTop: "1rem" }}>
				<div class="color-error w-full flex-1 text-right">
					<ErrorMessage text={props.customErrorMessage ?? errorMessage ?? ""} />
				</div>
				<GridRow elementCount={props.buttons.length}>{props.buttons.map(renderButton)}</GridRow>
			</div>
		</form>
	)
}

export default defineComponent(BasicForm, {
	customErrorMessage: optionalProp(String),
	showDivider: optionalProp(Boolean),
	buttons: requiredProp(Array),
})
