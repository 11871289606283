import type {
	ActivityOverride,
	HolidayAllowance,
	IArchivable,
	Id,
	IIdentifier,
	INamed,
	Nullable,
	YearHolidayAllowance,
} from "."

export interface User extends IIdentifier, IArchivable {
	firstName: string
	lastName: string
	email: Nullable<string>
	personalEmail: Nullable<string>
	tradeId: Nullable<Id>
	userTypeId: Id
	role: UserRole
	notes: string
	pin: string
	payrollId: Nullable<string>
	activityOverrides: ActivityOverride[]
	siteIds: number[]
	holidayAllowance: HolidayAllowance
	allowanceOverrides: YearHolidayAllowance[]
	isHolidayApprover: boolean
	specialArrangement: boolean
	applyGeoFencing: boolean
	loginEnabled: boolean
	kioskEnabled: boolean
	hideOnPlanner: boolean
	hideOnTimeSheets: boolean
}

export interface UserType extends IIdentifier, INamed {
	name: string
	description: string
	color?: string
}

export enum UserRole {
	SuperUser = "superUser",
	Administrator = "administrator",
	StaffSiteManager = "staffSiteManager",
	SiteManager = "siteManager",
	Staff = "staff",
}

const roleValues: Record<UserRole, number> = {
	[UserRole.SuperUser]: 4,
	[UserRole.Administrator]: 3,
	[UserRole.StaffSiteManager]: 2,
	[UserRole.SiteManager]: 1,
	[UserRole.Staff]: 0,
}

export type UserMetadata = Pick<
	User,
	"id" | "firstName" | "lastName" | "email" | "userTypeId" | "tradeId" | "role" | "specialArrangement" | "kioskEnabled"
>

/**
 * @returns 0 if roles are identical, < 0 if role1 is lower than role2, and > 0 if role2 is higher than role2
 */
export function compareRoles(role1: UserRole, role2: UserRole): number {
	return roleValues[role1] - roleValues[role2]
}
