import { defineGlobals } from "vue-utils"

enum Permission {
	ViewUsers = "viewUsers",
	EditUser = "editUser",
	CreateUser = "createUser",
	UnarchiveUser = "unarchiveUser",
	DeleteUser = "deleteUser",
	EditLogin = "editLogin",

	ViewActivities = "viewActivities",
	EditActivity = "editActivity",
	CreateActivity = "createActivity",
	DeleteActivity = "deleteActivity",
	ViewHiddenActivities = "viewHiddenActivities",

	ViewTrades = "viewTrades",
	EditTrade = "editTrade",
	CreateTrade = "createTrade",
	DeleteTrade = "deleteTrade",

	ViewSites = "viewSites",
	EditSite = "editSite",
	CreateSite = "createSite",
	DeleteSite = "deleteSite",

	ViewCompanyHolidays = "viewCompanyHolidays",
	CreateCompanyHoliday = "createCompanyHoliday",
	DeleteCompanyHoliday = "deleteCompanyHoliday",

	EditHolidaySettings = "editHolidaySettings",
	HolidayPlanner = "holidayPlanner",
	StaffAbsenceCalendar = "staffAbsenceCalendar",
	RequestHoliday = "requestHoliday",

	ViewRuleSets = "viewRuleSets",

	KioskMode = "kioskMode",

	ExportTimeSheets = "exportTimeSheets",

	ViewTimeSheets = "viewTimeSheets",
	ManagedUserTimeSheets = "managedUserTimeSheets",
	AdjustTimeSheets = "adjustTimeSheets",
	ApproveTimeSheets = "approveTimeSheets",
	CreateTimeSheets = "createTimeSheets",
	UnApproveTimeSheets = "unApproveTimeSheets",
	DeleteTimeSheets = "deleteTimeSheets",
	BypassTimeSheetTimeRestrictions = "bypassTimeSheetTimeRestrictions",

	AllSites = "allSites",

	OnSiteNow = "onSiteNow",
	ViewRejectedClockEvents = "viewRejectedClockEvents",
}

defineGlobals({
	Permission,
})

export default Permission
