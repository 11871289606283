import { defineComponent, type ReactiveComponent } from "vue-utils"
import { useLoading } from "@/utils/composition/useLoading"
import BasicScreen from "../shared/clock/screens/BasicScreen"
import ExitButton from "./components/ExitButton"
import PinEnter from "./components/PinEnter"
import { Screen } from "./Screen"
import { useKioskModeStore } from "./store"

const EnterPin: ReactiveComponent = () => {
	const store = useKioskModeStore()
	let pinReset = $ref(0)

	const { wrapAction } = useLoading()

	const getUserDetails = wrapAction(async (pin: string) => {
		try {
			await store.loadDetails(pin)
		} catch (e) {
			pinReset++
			throw e
		}
	})

	return () => (
		<BasicScreen title="Enter Your Pin">
			<PinEnter key={`enter-${pinReset}`} submit={(pin) => void getUserDetails(pin)} />
			<ExitButton onClick={() => store.switchScreen(Screen.ExitKiosk)}>Exit Kiosk Mode</ExitButton>
		</BasicScreen>
	)
}

export default defineComponent(EnterPin)
