import type { MSALConfig } from "@/data/systemConfigStore"
import { MsalPlugin } from "msal-vue"
import { shallowRef, type ShallowRef } from "vue"

const plugin: ShallowRef<MsalPlugin | undefined> = shallowRef(undefined)

export const registerMSAL = (config: MSALConfig): MsalPlugin => {
	plugin.value = new MsalPlugin({
		auth: {
			clientId: config.clientId,
			authority: config.authority,
			redirectUri: "/",
			postLogoutRedirectUri: "/",
			knownAuthorities: config.authorities,
		},
		cache: {
			cacheLocation: "localStorage",
		},
	})

	return plugin.value
}

export const isMsalRegistered = () => {
	return !!plugin.value
}

export const getMsalPlugin = () => {
	if (!plugin.value) {
		throw new Error("MSAL not yet registered")
	}
	return plugin.value
}
