import { useLoggedInUser } from "@/data/loggedInUserStore"
import type { Id } from "@/models"
import { SiteListType, useSiteList } from "@/utils/composition/useSiteList"
import { defineComponent, useStoreValue, type ReactiveComponent } from "vue-utils"
import useTimeSheetManagementStore from "../store"

export type SelectedSite = "all" | Id

const SiteSelector: ReactiveComponent = () => {
	const store = useTimeSheetManagementStore()

	const user = useLoggedInUser()
	const allSites = useSiteList(SiteListType.AllPrioritised)
	const availableSites = $computed(() => user.listAssignedSites(allSites.value))

	let siteId = $(useStoreValue(store, "siteId"))

	return () => (
		<select
			class="w-full"
			value={siteId}
			onInput={(e) => {
				const newValue = (e.target as HTMLSelectElement).value
				siteId = newValue === "all" ? "all" : Number.parseInt(newValue)
			}}
		>
			<option value="all">All Assigned Sites</option>
			{availableSites.map((site) => (
				<option key={site.id} value={site.id}>
					{site.name}
				</option>
			))}
		</select>
	)
}

export default defineComponent(SiteSelector)
