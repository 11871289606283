import type { HolidaySettings } from "@/models"
import { RequestMethod, httpJsonRequestJsonResponse, httpRequestJsonResponse } from "./httpService"

export const getHolidaySettings = async (): Promise<HolidaySettings> => {
	return await httpRequestJsonResponse<HolidaySettings>("/api/holiday-settings", RequestMethod.GET)
}

export const saveHolidaySettings = async (newSettings: HolidaySettings): Promise<HolidaySettings> => {
	return await httpJsonRequestJsonResponse<HolidaySettings>("/api/holiday-settings", newSettings, RequestMethod.PUT)
}
