import type { CoordinateRadius, Id, Nullable, SwitchedSiteRecord, TimeSheetEntry } from "@/models"
import { httpJsonRequestJsonResponse, httpRequestJsonResponse, RequestMethod } from "@/services/httpService"
import { mapApiEntry } from "@/services/timesheetsService"

export const clockIn = async (
	siteId: Id,
	activityId: Id,
	position: Nullable<CoordinateRadius>
): Promise<TimeSheetEntry> => {
	const result = await httpJsonRequestJsonResponse<TimeSheetEntry>(
		"/api/self-service/clock-in",
		{
			siteId,
			activityId,
			position,
		},
		RequestMethod.POST
	)
	return mapApiEntry(result)
}

export const clockOut = async (position: Nullable<CoordinateRadius>): Promise<TimeSheetEntry> => {
	const result = await httpJsonRequestJsonResponse<TimeSheetEntry>(
		"/api/self-service/clock-out",
		{
			position,
		},
		RequestMethod.POST
	)
	return mapApiEntry(result)
}

export const switchSite = async (
	newSiteId: Id,
	newActivityId: Id,
	position: Nullable<CoordinateRadius>
): Promise<SwitchedSiteRecord> => {
	const result = await httpJsonRequestJsonResponse<SwitchedSiteRecord>(
		"/api/self-service/switch-site",
		{
			newSiteId,
			newActivityId,
			position,
		},
		RequestMethod.POST
	)
	return {
		newTimeSheet: mapApiEntry(result.newTimeSheet),
		closedTimeSheet: mapApiEntry(result.closedTimeSheet),
	}
}

export const updateNotes = async (entryId: Id, notes: string): Promise<TimeSheetEntry> => {
	const result = await httpJsonRequestJsonResponse<TimeSheetEntry>(
		"/api/self-service/set-notes",
		{
			entryId,
			notes,
		},
		RequestMethod.POST
	)
	return mapApiEntry(result)
}

export interface ClockedInInformation {
	activeTimeSheet: Nullable<TimeSheetEntry>
	lastActivityId: Nullable<number>
	lastSiteId: Nullable<number>
}

export const getClockedInInformation = async (): Promise<ClockedInInformation> => {
	const result = await httpRequestJsonResponse<ClockedInInformation>(
		"/api/self-service/clocked-in-information",
		RequestMethod.GET
	)
	return {
		activeTimeSheet: result.activeTimeSheet ? mapApiEntry(result.activeTimeSheet) : null,
		lastActivityId: result.lastActivityId,
		lastSiteId: result.lastSiteId,
	}
}
