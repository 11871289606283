import { css } from "vite-css-in-js"
import { renderSlot } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

const buttonStyle = css`
	all: unset;
	cursor: pointer;

	color: white;

	&:hover {
		color: var(--color-primary);
	}

	position: fixed;
	bottom: calc(0.75rem + env(safe-area-inset-bottom, 0));
	left: calc(0.75rem + env(safe-area-inset-left, 0));
	font-size: 1.125rem !important;
`

interface Props {
	onClick: () => void
}

const ExitButton: ReactiveComponent<Props> = (props, { slots }) => {
	return () => (
		<button class={buttonStyle} onClick={props.onClick}>
			{renderSlot(slots, "default")}
		</button>
	)
}

export default defineComponent(ExitButton, {
	onClick: requiredProp(Function),
})
