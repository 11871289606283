import { css } from "vite-css-in-js"
import type { Component } from "vue-utils"

const svgStyles = css`
	@keyframes loading-rotate {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}

	height: 1em;
	width: 1em;
	animation: loading-rotate 1.8s linear infinite;
`

const pathStyles = css`
	@keyframes loading-dash {
		0% {
			stroke-dasharray: 1, 200;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -40px;
		}
		to {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -120px;
		}
	}

	animation: loading-dash 1.5s ease-in-out infinite;
	stroke-dasharray: 90, 150;
	stroke-dashoffset: 0;
	stroke-width: 5px;
	stroke: var(--color-primary);
	stroke-linecap: round;
`

const LoadingSpinner: Component = () => (
	<div>
		<svg class={svgStyles} viewBox="25 25 50 50">
			<circle class={pathStyles} cx="50" cy="50" r="20" fill="none" />
		</svg>
	</div>
)

export default LoadingSpinner
