import Icon from "@/components/Icon"
import { createMappedStringColumn, stringFilter, stringSort } from "@/components/data/DataTable"
import { useCoreDataValue } from "@/data/coreDataStore"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import { UserRole, type Id, type User } from "@/models"
import Permission from "@/models/Permission"
import { createUser, deleteUser, editUser, generateRandomUniquePIN, userComparator } from "@/services/usersService"
import { formatEnum } from "@/utils/formatEnum"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { ref } from "vue"
import {
	ShadowContainer,
	ToggleButton,
	defineComponent,
	refSetter,
	requiredProp,
	type ReactiveComponent,
} from "vue-utils"
import createCrudTable from "../CrudTable"
import Form from "./Form"

const CrudTable = createCrudTable<User, User>()

interface Props {
	users: Map<Id, User>
	showArchived: boolean
	setShowArchived(showArchived: boolean): void
}

const UsersTable: ReactiveComponent<Props> = (props) => {
	const user = useLoggedInUser()

	const userTypes = useCoreDataValue("userTypes")
	const trades = useCoreDataValue("trades")
	const holidaySettings = useCoreDataValue("holidaySettings")

	const search = ref("")

	async function getDefaultData(): Promise<User> {
		const pin = await generateRandomUniquePIN()

		return {
			id: 0,
			firstName: "",
			lastName: "",
			email: "",
			personalEmail: "",
			userTypeId: 1,
			tradeId: null,
			notes: "",
			pin,
			payrollId: null,
			role: UserRole.Staff,
			activityOverrides: [],
			siteIds: [],
			holidayAllowance: holidaySettings.defaultAllowance,
			allowanceOverrides: [],
			isHolidayApprover: false,
			applyGeoFencing: false,
			specialArrangement: false,
			loginEnabled: false,
			kioskEnabled: true,
			isArchived: false,
			hideOnPlanner: false,
			hideOnTimeSheets: false,
		}
	}

	async function updateUser(user: User): Promise<void> {
		const updatedUser = await editUser(user)
		if (updatedUser.isArchived !== props.showArchived) {
			props.users.delete(updatedUser.id)
		} else {
			props.users.set(updatedUser.id, updatedUser)
		}
	}

	async function handleDeleteUser(userId: Id) {
		await deleteUser(userId)
		if (!props.showArchived) {
			props.users.delete(userId)
		}
	}

	return () => (
		<ShadowContainer>
			<div class="flex items-center spacing-2 w-full">
				<Icon icon={faSearch} />
				<label class="flex-1">
					<input type="search" v-model={search.value} placeholder="Type to search..." />
				</label>
				<label class="flex-row">
					<ToggleButton toggled={props.showArchived} setToggled={props.setShowArchived} />
					Show Only Archived Users
				</label>
			</div>

			<CrudTable
				name="Users"
				getDefaultValue={getDefaultData}
				mapValue={(user) => ({
					...user,
					activityOverrides: user.activityOverrides.map((ao) => ({ ...ao })),
					siteIds: [...user.siteIds],
					allowanceOverrides: [...user.allowanceOverrides],
				})}
				renderForm={Form}
				values={Array.from(props.users.values()).sort(userComparator)}
				createText="Create New User"
				create={async (user) => {
					const createdUser = await createUser(user)
					props.users.set(createdUser.id, createdUser)
				}}
				edit={async (user, formData) =>
					await updateUser({
						...user,
						...formData,
					})
				}
				delete={async (user) => await handleDeleteUser(user.id)}
				columns={{
					lastName: {
						label: "Surname",
						filter: stringFilter,
						sort: stringSort,
					},
					firstName: {
						label: "Forenames",
						filter: stringFilter,
						sort: stringSort,
					},
					role: createMappedStringColumn("Role", (r) => formatEnum(r)),
					userTypeId: createMappedStringColumn("User Type", (typeId) => {
						return userTypes.get(typeId)?.name ?? ""
					}),
					tradeId: createMappedStringColumn("Trade", (tradeId) => {
						if (tradeId == null) {
							return ""
						}
						return trades.get(tradeId)?.name ?? "-"
					}),
				}}
				tableOptions={{
					search: {
						search: search.value,
						setSearch: refSetter(search),
						showSearchBar: false,
					},
				}}
				disableEdit={!user.hasPermission(Permission.EditUser)}
				disableCreate={!user.hasPermission(Permission.CreateUser) || props.showArchived}
			/>
		</ShadowContainer>
	)
}

export default defineComponent(UsersTable, {
	users: requiredProp(Map),
	showArchived: requiredProp(Boolean),
	setShowArchived: requiredProp(Function),
})
