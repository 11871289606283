/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import type { Id, Site } from "."
import Permission from "./Permission"
import type { User } from "./Users"

export interface LoggedInUser extends User {
	permissions: Permission[]
}

export class LoggedInUser {
	constructor(user: User, permissions: Permission[]) {
		Object.assign(this, user)
		this.permissions = permissions
	}

	isAssignedToSite(site: Site): boolean
	isAssignedToSite(siteId: Id): boolean
	isAssignedToSite(site: Site | Id): boolean {
		const siteId = typeof site === "number" ? site : site.id
		return this.hasPermission(Permission.AllSites) || this.siteIds.includes(siteId)
	}

	hasPermission(permission: Permission): boolean {
		return this.permissions.includes(permission)
	}

	listAssignedSites(allSites: Site[] | Map<Id, Site>): Site[] {
		const sites = allSites instanceof Array ? allSites : Array.from(allSites.values())
		return sites.filter((site) => this.isAssignedToSite(site))
	}
}
