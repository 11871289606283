import type { AbsenceCalendarResult, Id } from "@/models"
import { httpRequestJsonResponse, RequestMethod } from "@/services/httpService"
import { parseDuration } from "@/utils/timeUtils"
import { LocalDate } from "@js-joda/core"

export const getStaffAbsenceInformation = async (userId: Id, year: number): Promise<AbsenceCalendarResult> => {
	const result = await httpRequestJsonResponse<AbsenceCalendarResult>(
		`/api/absence-calendar/${userId}/${year}`,
		RequestMethod.GET
	)
	return {
		...result,
		entries: result.entries.map((entry) => ({
			date: LocalDate.parse(entry.date),
			activities: entry.activities.map((activity) => ({
				activityId: activity.activityId,
				workingTime: parseDuration(activity.workingTime),
			})),
		})),
	}
}
