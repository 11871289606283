import type { Instant } from "@js-joda/core"
import type { Id, Nullable, UserMetadata } from "."

export interface HolidayApproval {
	status: ApprovalStatus
	comments: string
	reviewedById: Id
	reviewedBy: Nullable<UserMetadata>
	reviewedAt: Instant
}

export enum ApprovalStatus {
	Rejected = "rejected",
	Approved = "approved",
	Cancelled = "cancelled",
}
