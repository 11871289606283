import Icon from "@/components/Icon"
import { faNoteSticky } from "@fortawesome/free-regular-svg-icons"
import { css } from "vite-css-in-js"
import type { FunctionalComponent } from "vue"

interface Props {
	notes: string
	managerNotes: string
	onClick: () => void
}

const noteStyles = css`
	position: relative;
	cursor: pointer;

	svg {
		font-size: 1.25rem;
	}

	span:first-child {
		position: absolute;
		width: 1.25rem;
		height: 1.25rem;
		font-size: 1.125rem;
		border-radius: 100%;
		color: white;
		background-color: var(--color-danger);
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid black;
		font-weight: bold;

		top: -0.75rem;
		right: -0.5rem;
	}
`

const NotesButton: FunctionalComponent<Props> = ({ notes, managerNotes, onClick }) => (
	<button class={noteStyles} title="Click to view notes" onClick={onClick} tabindex="-1">
		{(notes.length > 0 || managerNotes.length > 0) && <span>!</span>}
		<Icon icon={faNoteSticky} />
	</button>
)

export default NotesButton
