import StateLoading from "@/components/state-loading"
import { useLoading } from "@/utils/composition/useLoading"
import { h, Transition } from "vue"
import { defineComponent, useWindowListener, type Component, type ReactiveComponent } from "vue-utils"
import ClockedInOverview from "../shared/clock/screens/ClockedInOverview"
import EditNotes from "../shared/clock/screens/EditNotes"
import Success from "../shared/clock/screens/Success"
import SwitchSite from "../shared/clock/screens/SwitchSite"
import NotClockedIn from "./NotClockedIn"
import { Screen } from "./Screen"
import { useSelfServiceStore } from "./store"
import Welcome from "./Welcome"

const SelfService: ReactiveComponent = () => {
	const { runAction } = useLoading()
	const store = useSelfServiceStore()
	void store.reloadDetails()

	useWindowListener("visibilitychange", () => {
		if (!document.hidden) {
			void store.reloadDetails()
		}
	})

	const screens: Record<Screen, Component> = {
		[Screen.Welcome]: () => <Welcome />,
		[Screen.Overview]: () =>
			store.getDetailsOrThrow().activeTimeSheet ? (
				<ClockedInOverview
					activeTimeSheet={store.getActiveTimeSheetOrThrow()}
					clockOut={() => void runAction(store.clockOut())}
					editNotes={() => store.switchScreen(Screen.EditNotes)}
					switchSite={() => store.switchScreen(Screen.SwitchSite)}
					refresh={() => void store.reloadDetails()}
				/>
			) : (
				<NotClockedIn />
			),
		[Screen.SwitchSite]: () => (
			<SwitchSite
				activeTimeSheet={store.getActiveTimeSheetOrThrow()}
				mode="self-service"
				back={() => store.showOverview()}
				switchSite={(site, activity) => void runAction(store.switchSite(site.id, activity.id))}
			/>
		),
		[Screen.Success]: () => (
			<Success
				title={store.successDetails.title}
				message={store.successDetails.message}
				finish={() => store.switchScreen(Screen.Welcome)}
				showOverview={() => store.showOverview()}
				isClockedIn={!!store.getDetailsOrThrow().activeTimeSheet}
			/>
		),
		[Screen.EditNotes]: () => (
			<EditNotes
				latestTimeSheet={store.getActiveTimeSheetOrThrow()}
				back={() => store.showOverview()}
				editNotes={(notes) => void runAction(store.editNotes(notes))}
			/>
		),
	}

	const renderScreen = () => {
		if (
			!store.getDetailsOrThrow().activeTimeSheet &&
			[Screen.EditNotes, Screen.Overview, Screen.SwitchSite].includes(store.screen)
		) {
			return <NotClockedIn />
		}
		return h(screens[store.screen])
	}
	const renderContent = () => {
		if (store.screen === Screen.Welcome) {
			return <Welcome />
		}
		return <StateLoading stores={[store.userDetails]}>{h(renderScreen)}</StateLoading>
	}

	return () => (
		<Transition name="fade" mode="out-in">
			<div class="flex flex-col h-full" key={store.screen}>
				{renderContent()}
			</div>
		</Transition>
	)
}

export default defineComponent(SelfService)
