import Modal from "@/components/Modal"
import StandardForm from "@/components/StandardForm"
import { css } from "vite-css-in-js"
import { defineComponent, ModalTitle, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	notes: string
	managerNotes: string
	readonly: boolean

	cancel: () => void
	updateNotes: (notes: string) => void
}

const notesStyles = css`
	display: flex;
	align-items: stretch;
	gap: 1rem;

	@media (max-width: 48rem) {
		flex-direction: column;
	}

	& > label {
		flex-grow: 1;
	}
`

const ViewNotes: ReactiveComponent<Props> = (props) => {
	const managerNotes = $ref(props.managerNotes)

	return () => (
		<Modal style={{ width: "55rem" }}>
			<ModalTitle title="Time Sheet Notes" />
			<hr />
			<StandardForm
				submit={
					props.readonly
						? undefined
						: {
								onClick: () => props.updateNotes(managerNotes),
								text: "Save",
						  }
				}
				cancel={{
					onClick: props.cancel,
					text: props.readonly ? "Close" : "Cancel",
				}}
			>
				<div class={notesStyles}>
					<label>
						Employee Notes
						<textarea readonly disabled value={props.notes} rows={10} />
					</label>
					<label>
						Manager Notes
						<textarea readonly={props.readonly} disabled={props.readonly} v-model={managerNotes} rows={10} />
					</label>
				</div>
			</StandardForm>
		</Modal>
	)
}

export default defineComponent(ViewNotes, {
	notes: requiredProp(String),
	managerNotes: requiredProp(String),
	readonly: requiredProp(Boolean),
	cancel: requiredProp(Function),
	updateNotes: requiredProp(Function),
})
