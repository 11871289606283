import type { LocalDate } from "@js-joda/core"
import type { CalendarEvent } from "./types"

export function groupEventsByDate(events: CalendarEvent[]): Map<LocalDate, CalendarEvent[]> {
	function getKey(date: LocalDate): number {
		return date.toEpochDay()
	}

	const results = new Map<number, CalendarEvent[]>()
	for (const event of events) {
		const dayEvents = getOrPutDefault(results, getKey(event.date), () => [])
		dayEvents.push(event)
	}
	return {
		get(key) {
			return results.get(getKey(key))
		},
		has(key) {
			return results.has(getKey(key))
		},
		delete(key) {
			return results.delete(getKey(key))
		},
		clear() {
			results.clear()
		},
		set(key, value) {
			results.set(getKey(key), value)
			return this
		},
	} as Map<LocalDate, CalendarEvent[]>
}

function getOrPutDefault<K, V>(map: Map<K, V>, key: K, defaultValue: () => V) {
	if (!map.has(key)) {
		const result = defaultValue()
		map.set(key, result)
		return result
	}
	return map.get(key) as V
}
