import type {ApiTypeOf, Comparator, User, UserMetadata} from "@/models"
import createCrudEndpoints from "./createCrudEndpoint"
import {httpRequestJsonResponse, RequestMethod} from "./httpService"

const endpoints = createCrudEndpoints<User>("/api/users")

export async function listUsers(includeArchived?: boolean): Promise<ApiTypeOf<User>[]> {
	if (includeArchived === undefined) {
		return await endpoints.list()
	}
	return await httpRequestJsonResponse(`/api/users?show-archived=${String(includeArchived)}`, RequestMethod.GET)
}
export const getUser = endpoints.get
export const createUser = endpoints.create
export const editUser = endpoints.edit
export const deleteUser = endpoints.delete

export const userComparator: Comparator<UserMetadata> = (u1, u2) => {
	const u1Name = `${u1.lastName} ${u1.firstName}`
	const u2Name = `${u2.lastName} ${u2.firstName}`

	return u1Name.localeCompare(u2Name)
}

export const generateRandomUniquePIN = async (): Promise<string> => {
	const result = await httpRequestJsonResponse<{ pin: string }>("/api/users/generate-pin", RequestMethod.POST)
	return result.pin
}
