import AutoColumns from "@/components/AutoColumns"
import { Required } from "@/components/basic-form"
import useCoreDataStore from "@/data/coreDataStore"
import usePopups from "@/data/popupsStore"
import type { Site } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import {
	ToggleButton,
	defineComponent,
	ensureLoadingHasResult,
	useOnInput,
	type ReactiveComponent,
	Alert,
} from "vue-utils"
import { crudTableFormPropTypes, type CrudTableFormProps } from "../CrudTable"

const BasicDetails: ReactiveComponent<CrudTableFormProps<Site>> = (props) => {
	const site = proxyProp(props, "data")
	const popups = usePopups()
	const ruleSets = $computed(() => ensureLoadingHasResult(useCoreDataStore().ruleSets))

	function updateJobNumber(e: Event) {
		const inputElement = e.target as HTMLInputElement
		const newValue = inputElement.value.toUpperCase().replace(/[^A-Z0-9_-]/g, "")

		site.jobNumber = newValue
		inputElement.value = newValue
	}

	async function setArchived(archived: boolean) {
		if (archived === site.isArchived) {
			return
		}
		let confirmation: boolean
		if (archived) {
			confirmation = await popups.confirm(() => (
				<Alert title="Confirm Archival">
					Are you sure you wish to set this site to archived? It will become hidden for new data entries.
				</Alert>
			))
		} else {
			confirmation = await popups.confirm(() => (
				<Alert title="Confirm Unarchival">
					Are you sure you wish to un-archive this site? It will become visible again for new data entries.
				</Alert>
			))
		}
		if (confirmation) {
			site.isArchived = archived
		}
	}

	return () => (
		<div class="flex flex-col spacing-6 flex-auto">
			<label>
				<Required label="Name" />
				<input v-model={site.name} disabled={props.readonly} required />
			</label>
			<AutoColumns class="spacing-4" size="16rem">
				<label>
					Rule Set
					<select
						disabled={props.readonly}
						value={site.ruleSetId}
						onInput={useOnInput((newValue) => (site.ruleSetId = newValue === "" ? null : Number.parseInt(newValue)))}
					>
						<option value="">No Rule Set</option>
						{Array.from(ruleSets.values()).map((ruleSet) => (
							<option key={ruleSet.id} value={ruleSet.id}>
								{ruleSet.name}
							</option>
						))}
					</select>
				</label>

				<label>
					<Required label="Job Number" />
					<input
						pattern="[A-Z0-9\\-_]+"
						value={site.jobNumber}
						onInput={updateJobNumber}
						disabled={props.readonly}
						required
					/>
				</label>

				<label>
					<Required label="Is Archived?" />
					<ToggleButton toggled={site.isArchived} setToggled={(archived) => void setArchived(archived)} />
				</label>
			</AutoColumns>

			<label class="flex-auto">
				Notes
				<textarea class="flex-auto" v-model={site.notes} disabled={props.readonly} />
			</label>
		</div>
	)
}

export default defineComponent(BasicDetails, crudTableFormPropTypes())
