import type { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { useRouter } from "vue-router"
import { defineComponent, Link, requiredProp, type ReactiveComponent, stylesheet } from "vue-utils"
import Icon from "../Icon"
import "./styles.scss"

interface Props {
	routes: NavEntry[]
}

export interface NavEntry {
	name: string
	icon: IconDefinition
	route: string
}

const generateMediaQuery = (items: number) => stylesheet`
	@media (max-width: ${items * 6.5}rem) {
		.navbar > ul {
			width: 100%;
			display: grid;
		}

		.navbar > ul > li > a {
			width: 100%;
			padding: 1rem;
		}

		.navbar > ul > li > a > svg {
			font-size: 1.6em;
		}
		.navbar > ul > li > a > span {
			display: none;
		}
	}
`

const Navbar: ReactiveComponent<Props> = (props) => {
	const { routes } = $(props)
	const router = useRouter()

	const currentPath = $computed(() => router.currentRoute.value.path)
	function vibrate() {
		try {
			navigator.vibrate(50)
		} catch (e) {
			//Ignore the error
		}
	}

	return () => (
		<footer class="navbar">
			<ul style={{ gridTemplateColumns: `repeat(${routes.length}, 1fr)` }} data-break={routes.length}>
				{routes.map((route) => (
					<li key={route.name}>
						<Link
							onClick={vibrate}
							class={{
								"router-link-active": route.route === currentPath || currentPath.startsWith(`${route.route}/`),
							}}
							href={route.route}
						>
							<Icon icon={route.icon} />
							<span>{route.name}</span>
						</Link>
					</li>
				))}
			</ul>
			<style>{generateMediaQuery(routes.length)}</style>
		</footer>
	)
}

export default defineComponent(Navbar, {
	routes: requiredProp(Array),
})
