import { Duration } from "@js-joda/core"
import { isAuthenticated } from "msal-vue"
import { computed, createApp } from "vue"
import type { Loadable } from "vue-utils"
import TimeLordAppContent from "./TimeLordAppContent"
import { getMsalPlugin, isMsalRegistered } from "./authentication"
import Popups from "./components/Popups"
import ValidateSession from "./components/ValidateSession"
import StateLoading from "./components/state-loading"
import useLoggedInUserStore from "./data/loggedInUserStore"
import useSystemConfigStore from "./data/systemConfigStore"
import LoginPage from "./pages/login"
import { useStoreRefresh } from "./utils/composition/useStoreRefresh"

export const TimeLordApp = createApp({
	name: "TimeLord",
	setup() {
		const loggedInUserStore = useLoggedInUserStore()
		const systemConfigStore = useSystemConfigStore()

		const isUserAuthenticated = computed(() => isMsalRegistered() && isAuthenticated(getMsalPlugin().accounts.value))

		const stores = computed((): Record<string, Loadable<unknown>> => {
			if (systemConfigStore.type !== "done" || !isUserAuthenticated.value) {
				return { systemConfigStore }
			}

			return {
				systemConfigStore,
				loggedInUserStore,
			}
		})

		useStoreRefresh(stores, Duration.ofHours(1))

		return () => (
			<>
				<StateLoading stores={Object.values(stores.value)}>
					{isUserAuthenticated.value ? (
						<ValidateSession>
							<TimeLordAppContent />
						</ValidateSession>
					) : (
						<LoginPage />
					)}
				</StateLoading>
				<Popups />
			</>
		)
	},
})
