import type { EditFormMode } from "@/components/SimpleEditForm"
import type { Props as DataTableProps, UnwrapColumns } from "@/components/data/DataTable"
import createDataTable from "@/components/data/DataTable"
import type { IIdentifier } from "@/models"
import { useLoading } from "@/utils/composition/useLoading"
import { reactive, shallowRef } from "vue"
import type { ReactiveComponent, ReactiveProps } from "vue-utils"
import { anyProp, defineComponent, optionalProp, requiredProp, type Component } from "vue-utils"

export interface CrudTableOptions<DataType, FormType> {
	name: string

	columns: Partial<UnwrapColumns<DataType>>

	values: DataType[]
	create(data: FormType): Promise<void>
	edit(item: DataType, data: FormType): Promise<void>
	delete(item: DataType, data: FormType): Promise<void>

	getDefaultValue(): FormType | Promise<FormType>
	mapValue(data: DataType): FormType | Promise<FormType>

	tableOptions?: Partial<Omit<DataTableProps<DataType>, "getKey" | "columns" | "editClick" | "readonly" | "addItem">>

	createText: string

	disableCreate?: boolean
	disableEdit?: boolean

	renderForm: Component<CrudTableFormProps<FormType>>
}

export interface CrudTableFormProps<T> {
	mode: EditFormMode
	data: T

	readonly: boolean

	cancel(): void
	save?: () => Promise<void>
	delete?: () => Promise<void>
}

export function crudTableFormPropTypes<T>(): ReactiveProps<CrudTableFormProps<T>> {
	return {
		mode: requiredProp(String),
		data: anyProp(),
		readonly: requiredProp(Boolean),
		cancel: requiredProp(Function),
		save: optionalProp(Function),
		delete: optionalProp(Function),
	}
}

export interface CrudOperations<DataType, FormType> {
	create: (data: FormType) => Promise<void>
	edit: (item: DataType, data: FormType) => Promise<void>
	delete: (item: DataType, data: FormType) => Promise<void>
}

export function getFormTitle(mode: EditFormMode, objectName: string) {
	switch (mode) {
		case "create":
			return `Create ${objectName}`
		case "edit":
			return `Edit ${objectName}`
		case `view`:
			return `View ${objectName}`
	}
	return ""
}

const createCrudTable = <DataType extends IIdentifier, FormType extends object>(): Component<
	CrudTableOptions<DataType, FormType>
> => {
	const DataTable = createDataTable<DataType>()

	const CrudTable: ReactiveComponent<CrudTableOptions<DataType, FormType>> = (props) => {
		const { disableCreate = false, disableEdit = false } = $(props)
		const { runAction } = useLoading()

		const addingItem = shallowRef<FormType | null>(null)
		const editingItem = shallowRef<FormType | null>(null)
		const editingData = shallowRef<DataType | null>(null)

		async function openNewEditForm() {
			const newValue = await runAction(Promise.resolve(props.getDefaultValue()))
			addingItem.value = reactive(newValue) as FormType
		}
		async function openEditForm(item: DataType) {
			const formValue = await runAction(Promise.resolve(props.mapValue(item)))

			editingData.value = item
			editingItem.value = reactive(formValue) as FormType
		}

		function closeEdit() {
			editingData.value = null
			editingItem.value = null
		}

		return () => (
			<>
				<DataTable
					{...props.tableOptions}
					entities={props.values}
					columns={props.columns}
					getKey={(item) => item.id.toString()}
					readonly={() => disableEdit}
					editClick={(item) => void openEditForm(item)}
					addItem={{
						text: props.createText,
						onClick: () => void openNewEditForm(),
					}}
				/>

				{!!addingItem.value && !disableCreate && (
					<props.renderForm
						key="create"
						mode="create"
						readonly={false}
						data={addingItem.value}
						cancel={() => (addingItem.value = null)}
						save={async () => {
							if (addingItem.value) {
								await props.create(addingItem.value)
								addingItem.value = null
							}
						}}
					/>
				)}

				{!!editingItem.value && !!editingData.value && (
					<props.renderForm
						key="edit"
						mode={disableEdit ? "view" : "edit"}
						readonly={disableEdit}
						data={editingItem.value}
						cancel={closeEdit}
						save={async () => {
							if (!disableEdit && editingData.value && editingItem.value) {
								await props.edit(editingData.value, editingItem.value)
							}
							closeEdit()
						}}
						delete={async () => {
							if (editingData.value && editingItem.value) {
								await props.delete(editingData.value, editingItem.value)
							}
							closeEdit()
						}}
					/>
				)}
			</>
		)
	}

	return defineComponent(CrudTable, {
		name: requiredProp(String),
		columns: requiredProp(Object),

		values: requiredProp(Array),
		create: requiredProp(Function),
		edit: requiredProp(Function),
		delete: requiredProp(Function),

		createText: requiredProp(String),

		getDefaultValue: requiredProp(Function),
		mapValue: requiredProp(Function),
		tableOptions: optionalProp(Object),

		disableCreate: optionalProp(Boolean),
		disableEdit: optionalProp(Boolean),

		renderForm: requiredProp(Function, Object),
	})
}

export default createCrudTable
