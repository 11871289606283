import { Duration } from "@js-joda/core"
import { RouterView } from "vue-router"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import StateLoading from "./components/state-loading"
import useCoreDataStore from "./data/coreDataStore"
import { useStoreRefresh } from "./utils/composition/useStoreRefresh"

const TimeLordAppContent: ReactiveComponent = () => {
	const coreDataStore = useCoreDataStore()
	useStoreRefresh({ coreDataStore }, Duration.ofHours(1))

	return () => (
		<StateLoading stores={[coreDataStore]}>
			<RouterView />
		</StateLoading>
	)
}

export default defineComponent(TimeLordAppContent)
