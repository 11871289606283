import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import { BasicSelect } from "@/components/basic-form"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import type { Id, TimeSheetEntry } from "@/models"
import { LoadingError, showErrorMessage, useLoading } from "@/utils/composition/useLoading"
import { SiteListType, useSiteList } from "@/utils/composition/useSiteList"
import { formatDate } from "@/utils/timeUtils"
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons"
import { DayOfWeek, Duration, Instant, ZoneId, ZonedDateTime, type LocalDate, type LocalTime } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { computed, reactive, shallowReactive, watch } from "vue"
import { defineComponent, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"
import { OverrideType, queryDefaultRuleTimes } from "../service"
import ApprovedBy from "./components/ApprovedBy"
import EditDuration from "./components/EditDuration"
import EditTime from "./components/EditTime"
import NotesButton from "./components/NotesButton"
import ViewNotes from "./components/ViewNotes"
import { useModifyableTimeSheetActivities } from "./composition/availableActivities"
import { getAvailableSites } from "./composition/availableSites"
import validateOverride from "./utils/validateOverride"

interface Props {
	userId: Id
	startOfWeek: LocalDate
	createTimeSheet: (entry: TimeSheetEntry) => Promise<void>
	cancel: () => void
}

interface NewTimeSheetData {
	siteId: Id
	activityId: Id
	managerNotes: string
	day: LocalDate
	clockIn: LocalTime | null
	clockOut: LocalTime | null
	break: Duration | null
}

const controlButtonsStyles = css`
	display: flex;
	width: 100%;
	gap: 0.5rem;

	button {
		padding: 0;
		font-size: 1.25em;
		margin: 0 !important;
		width: 2.125em;
		height: 1.75em;
	}
`

const NewTimeSheetRow: ReactiveComponent<Props> = (props) => {
	const { userId, startOfWeek, createTimeSheet, cancel } = $(props)

	const { runAction } = useLoading()
	const loggedInUser = useLoggedInUser()

	const allSites = useSiteList(SiteListType.NonArchived)
	const availableSites = computed(() => getAvailableSites(loggedInUser, allSites.value))
	const activities = $(useModifyableTimeSheetActivities())

	const newTimeSheet = reactive<NewTimeSheetData>({
		siteId: 0,
		activityId: 0,
		managerNotes: "",
		day: startOfWeek,
		clockIn: null,
		clockOut: null,
		break: null,
	})

	let showNotes = $ref<boolean>(false)
	const data = shallowReactive({
		site: availableSites.value.length > 0 ? availableSites.value[0] : null,
		activity: activities.length > 0 ? activities[0] : null,
		day: 0,
		hasChangedTimes: false,
	})

	function timeToInstant(time: LocalTime | null): Instant | null {
		if (!time) {
			return null
		}
		const dateTime = startOfWeek.plusDays(data.day).atTime(time)
		return ZonedDateTime.ofLocal(dateTime, ZoneId.systemDefault()).toInstant()
	}

	async function queryTimes() {
		if (data.site && data.activity) {
			const times = await runAction(
				queryDefaultRuleTimes({
					activityId: data.activity.id,
					siteId: data.site.id,
					date: startOfWeek.plusDays(data.day),
				})
			)
			newTimeSheet.clockIn = times.clockIn
			newTimeSheet.clockOut = times.clockOut
			newTimeSheet.break = times.break
		}
	}

	watch(data, () => {
		if (!data.hasChangedTimes) {
			void queryTimes()
		}
	})
	void queryTimes()

	const updateClock = async (type: OverrideType, time: LocalTime) => {
		try {
			validateOverride(newTimeSheet.clockIn, newTimeSheet.clockOut, type, time)
		} catch (e) {
			if (e instanceof LoadingError) {
				await showErrorMessage(e.title, e.message)
			}
			throw e
		}
		newTimeSheet[type] = time
		data.hasChangedTimes = true
	}

	const updateBreak = (duration: Duration) => {
		newTimeSheet.break = duration
		data.hasChangedTimes = true
	}

	const isValid = $computed(
		() =>
			data.site !== null && data.activity !== null && newTimeSheet.clockIn !== null && newTimeSheet.clockOut !== null
	)

	const onSave = () => {
		const sheet = newTimeSheet
		sheet.activityId = data.activity?.id as Id
		sheet.siteId = data.site?.id as Id

		const newSheet: TimeSheetEntry = {
			id: 0,
			userId,
			siteId: sheet.siteId,
			activityId: sheet.activityId,
			clockIn: {
				entered: null,
				autoAdjusted: null,
				override: timeToInstant(sheet.clockIn),
				location: null,
			},
			clockOut: {
				entered: null,
				autoAdjusted: null,
				override: timeToInstant(sheet.clockOut),
				location: null,
			},
			break: {
				adjusted: sheet.break,
				calculated: Duration.ZERO,
			},
			notes: "",
			managerNotes: sheet.managerNotes,
			approvedById: null,
			approvedBy: null,
			approvalDate: null,
			timeLoggedExcludingBreaks: null,
			timeLoggedIncludingBreaks: null,
			isPaid: true,
			isWorking: true,
		}
		void createTimeSheet(newSheet)
	}

	return () => (
		<tr>
			<td>
				<select value={data.day} onInput={useOnInput((input) => (data.day = Number.parseInt(input)))}>
					{DayOfWeek.values().map((_, i) => {
						const date = startOfWeek.plusDays(i)
						return (
							<option key={i} value={i}>
								{formatDate(date)}
							</option>
						)
					})}
				</select>
			</td>
			<td>
				<BasicSelect
					options={availableSites.value}
					value={data.site}
					setValue={(newSite) => (data.site = newSite)}
					defaultText="Select a Site"
					class="w-full"
					style={{ margin: "0 0.25em" }}
					required
				/>
			</td>
			<td>
				<BasicSelect
					options={activities}
					value={data.activity}
					setValue={(newActivity) => (data.activity = newActivity)}
					defaultText="Select an Activity"
					class="w-full"
					style={{ margin: "0 0.25em" }}
					required
				/>
			</td>
			<td>
				<NotesButton notes="" managerNotes={newTimeSheet.managerNotes} onClick={() => (showNotes = true)} />
				{showNotes && (
					<ViewNotes
						notes=""
						managerNotes={newTimeSheet.managerNotes}
						readonly={false}
						cancel={() => (showNotes = false)}
						updateNotes={(notes) => {
							newTimeSheet.managerNotes = notes
							showNotes = false
						}}
					/>
				)}
			</td>
			<td></td>
			<td></td>
			<EditTime value={newTimeSheet.clockIn} save={(time) => updateClock(OverrideType.ClockIn, time)} />
			<td></td>
			<td></td>
			<td></td>
			<EditTime value={newTimeSheet.clockOut} save={(time) => updateClock(OverrideType.ClockOut, time)} />
			<td></td>
			<td>-</td>
			<EditDuration
				value={newTimeSheet.break}
				save={(duration) => {
					updateBreak(duration)
					return Promise.resolve()
				}}
			/>
			<td></td>
			<td>0</td>
			<ApprovedBy approvalDate={null} approvedBy={null} />
			<td>
				<div class={controlButtonsStyles}>
					<BootstrapButton color="secondary" onClick={cancel}>
						<Icon icon={faTimes} />
					</BootstrapButton>
					<BootstrapButton color="primary" disabled={!isValid} onClick={onSave}>
						<Icon icon={faSave} />
					</BootstrapButton>
				</div>
			</td>
		</tr>
	)
}

export default defineComponent(NewTimeSheetRow, {
	userId: requiredProp(Number),
	startOfWeek: requiredProp(Object),
	createTimeSheet: requiredProp(Function),
	cancel: requiredProp(Function),
})
