import { Required } from "@/components/basic-form"
import { Permission, type Trade } from "@/models"

import SimpleEditForm from "@/components/SimpleEditForm"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import { proxyProp } from "@/utils/proxyProp"
import { ColumnLayout, defineComponent, type ReactiveComponent } from "vue-utils"
import { crudTableFormPropTypes, getFormTitle, type CrudTableFormProps } from "../CrudTable"
import showDeleteConfirmation from "../showDeleteConfirmation"

const Form: ReactiveComponent<CrudTableFormProps<Trade>> = (props) => {
	const user = useLoggedInUser()
	const trade = proxyProp(props, "data")

	return () => (
		<SimpleEditForm
			{...props}
			title={getFormTitle(props.mode, "Trade")}
			validateSave={() => {
				if (!trade.name.trim().length) return "Please enter a name"
				return true
			}}
			validateDelete={() => showDeleteConfirmation("trade")}
			delete={user.hasPermission(Permission.DeleteTrade) ? props.delete : undefined}
		>
			<div class="flex flex-col spacing-6">
				<ColumnLayout columns={2}>
					<label>
						<Required label="Name" />
						<input v-model={trade.name} required disabled={props.readonly} />
					</label>

					<label>
						Colour
						<input type="color" v-model={trade.colour} disabled={props.readonly} />
					</label>
				</ColumnLayout>

				<label>
					Notes
					<textarea v-model={trade.notes} disabled={props.readonly} rows={5} />
				</label>
			</div>
		</SimpleEditForm>
	)
}

export default defineComponent(Form, crudTableFormPropTypes())
