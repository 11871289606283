import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import UserNameDisplay from "@/components/UserNameDisplay"
import { useActivity, useSite } from "@/data/coreDataStore"
import type { TimeSheetEntry, UserMetadata } from "@/models"
import NotesButton from "@/pages/timesheet-management/view/components/NotesButton"
import { getPaidHours, getUsedTime } from "@/services/timesheetsService"
import { formatDuration, formatTimeInUserTimeZone, formatYearDate } from "@/utils/timeUtils"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import UserLocationModal from "../administration/clock-events/UserLocationModal"
import ApprovedBy from "../timesheet-management/view/components/ApprovedBy"
import ViewNotes from "../timesheet-management/view/components/ViewNotes"

interface Props {
	timeSheet: TimeSheetEntry
}

const TimeSheetRow: ReactiveComponent<Props> = (props) => {
	const { timeSheet } = $(props)
	const site = $(useSite(() => timeSheet.siteId))
	const activity = $(useActivity(() => timeSheet.activityId))
	const user = $computed(() => timeSheet.user as UserMetadata)
	const date = $computed(() => getUsedTime(timeSheet.clockIn) ?? getUsedTime(timeSheet.clockOut))
	const hasNotes = $computed(() => timeSheet.notes.length > 0 || timeSheet.managerNotes.length > 0)

	let notesOpen = $ref(false)
	let clockInLocOpen = $ref(false)
	let clockOutLocOpen = $ref(false)

	return () => (
		<tr>
			<td>{formatYearDate(date)}</td>
			<td>
				<UserNameDisplay user={user} />
			</td>
			<td>{site?.name}</td>
			<td>{activity?.name}</td>
			<td>
				{hasNotes ? (
					<NotesButton
						notes={timeSheet.notes}
						managerNotes={timeSheet.managerNotes}
						onClick={() => (notesOpen = true)}
					/>
				) : (
					"-"
				)}
				{notesOpen && (
					<ViewNotes
						notes={timeSheet.notes}
						managerNotes={timeSheet.managerNotes}
						cancel={() => (notesOpen = false)}
						readonly
						updateNotes={() => void 0}
					/>
				)}
			</td>
			<td>{formatTimeInUserTimeZone(timeSheet.clockIn.entered)}</td>
			<td>{formatTimeInUserTimeZone(timeSheet.clockIn.autoAdjusted)}</td>
			<td>{formatTimeInUserTimeZone(timeSheet.clockIn.override)}</td>
			<td>
				{!!timeSheet.clockIn.location && (
					<BootstrapButton color="danger" title="View on Map" onClick={() => (clockInLocOpen = true)}>
						<Icon icon={faMapMarkerAlt} />
					</BootstrapButton>
				)}
				{clockInLocOpen && !!timeSheet.clockIn.location && !!site && (
					<UserLocationModal
						close={() => (clockInLocOpen = false)}
						siteLocation={site.location ?? undefined}
						userLocation={timeSheet.clockIn.location}
					/>
				)}
			</td>
			<td>{formatTimeInUserTimeZone(timeSheet.clockOut.entered)}</td>
			<td>{formatTimeInUserTimeZone(timeSheet.clockOut.autoAdjusted)}</td>
			<td>{formatTimeInUserTimeZone(timeSheet.clockOut.override)}</td>
			<td>
				{!!timeSheet.clockOut.location && (
					<BootstrapButton color="danger" title="View on Map" onClick={() => (clockOutLocOpen = true)}>
						<Icon icon={faMapMarkerAlt} />
					</BootstrapButton>
				)}
				{clockOutLocOpen && !!timeSheet.clockOut.location && !!site && (
					<UserLocationModal
						close={() => (clockOutLocOpen = false)}
						siteLocation={site.location ?? undefined}
						userLocation={timeSheet.clockOut.location}
					/>
				)}
			</td>
			<td>{formatDuration(timeSheet.break.calculated)}</td>
			<td>{formatDuration(timeSheet.break.adjusted)}</td>
			<td>{formatDuration(timeSheet.timeLoggedExcludingBreaks, "0")}</td>
			<td>{formatDuration(getPaidHours(timeSheet))}</td>
			<ApprovedBy approvedBy={timeSheet.approvedBy ?? null} approvalDate={timeSheet.approvalDate} />
		</tr>
	)
}

export default defineComponent(TimeSheetRow, {
	timeSheet: requiredProp(Object),
})
