import { useSites } from "@/data/coreDataStore"
import { type Id, type Site } from "@/models"
import { watchImmediate } from "@vueuse/core"
import { computed, isRef, reactive, type MaybeRef, type Ref } from "vue"

export enum SiteListType {
	/**
	 * All sites are returned with no restriction
	 */
	All = "all",

	/**
	 * All sites are returned, but non-archived sites are returned at the top of the list
	 */
	AllPrioritised = "allPrioritised",

	/**
	 * Non archived sites are returned, but also includes any sites which get selected.
	 * Useful for HTML selects where the starting value is an archived site
	 */
	Selection = "selection",

	/**
	 * Only non-archived sites are returned
	 */
	NonArchived = "nonArchived",
}

export interface SiteListArguments {
	[SiteListType.All]: never
	[SiteListType.AllPrioritised]: never
	[SiteListType.Selection]: {
		selectedSite: MaybeRef<Site | Id | null>
	}
	[SiteListType.NonArchived]: never
}

//Syntax looks a little odd, but its essentially saying that if the specified arguments for the type is "never", its optional rather than required
export function useSiteList<T extends SiteListType>(
	...[type, _args]: SiteListArguments[T] extends never
		? [type: T, payload?: SiteListArguments[T]]
		: [type: T, payload: SiteListArguments[T]]
): Ref<Site[]> {
	const allSites = useSites()
	const sortedSites = computed(() => Array.from(allSites.values()).sort((s1, s2) => s1.name.localeCompare(s2.name)))
	const args = _args as SiteListArguments[T]

	if (type === SiteListType.All) {
		return sortedSites
	}

	if (type === SiteListType.AllPrioritised) {
		return computed(() =>
			sortedSites.value.sort((s1, s2) => {
				if (s1.isArchived && !s2.isArchived) {
					return 1
				}
				if (!s1.isArchived && s2.isArchived) {
					return -1
				}
				return 0
			})
		)
	}

	if (type === SiteListType.NonArchived) {
		return computed(() => sortedSites.value.filter((site) => !site.isArchived))
	}

	if (type === SiteListType.Selection) {
		const additionalSiteIds = reactive(new Set<Id>())

		const addSite = (site: Site | Id | null) => {
			if (site === null) {
				return
			}
			const siteId = typeof site === "number" ? site : site.id
			additionalSiteIds.add(siteId)
		}

		if (isRef(args.selectedSite)) {
			watchImmediate(args.selectedSite, addSite)
		}

		return computed(() => sortedSites.value.filter((site) => !site.isArchived || additionalSiteIds.has(site.id)))
	}

	throw new Error(`Unknown type ${type}`)
}
