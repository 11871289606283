import PlainIconButton from "@/components/PlainIconButton"
import { useActivity, useSite } from "@/data/coreDataStore"
import type { TimeSheetEntry } from "@/models"
import { formatTimeInUserTimeZone } from "@/utils/timeUtils"
import { faSync } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import LargeButton from "../components/LargeButton"
import BasicScreen from "./BasicScreen"

interface Props {
	activeTimeSheet: TimeSheetEntry

	editNotes(): void
	switchSite(): void
	clockOut(): void

	showHolidays?: () => void
	quit?: () => void
	refresh?: () => void
}

const infoStyles = css`
	display: grid;
	grid-template-columns: max-content 1fr;
	column-gap: 1rem;
	row-gap: 0.5rem;
	font-size: 1.125rem;
	padding: 1rem 0;

	& > div:nth-child(odd) {
		font-weight: bold;
	}
`

const ClockedInOverview: ReactiveComponent<Props> = (props) => {
	const site = $(useSite(() => props.activeTimeSheet.siteId))
	const activity = $(useActivity(() => props.activeTimeSheet.activityId))

	const clockedInTime = $computed(() => {
		const clockedIn = props.activeTimeSheet?.clockIn?.entered
		return formatTimeInUserTimeZone(clockedIn)
	})

	return () => (
		<BasicScreen
			title={() => (
				<h2 class="flex justify-center spacing-2" style={{ alignItems: "end" }}>
					Currently Clocked In
					{props.refresh && <PlainIconButton icon={faSync} title="Refresh" onClick={props.refresh} />}
				</h2>
			)}
		>
			<div class="flex flex-col flex-auto w-full justify-evenly">
				<div class={infoStyles}>
					<div>Clocked In:</div>
					<div>{clockedInTime}</div>

					<div>Site:</div>
					<div>{site?.name ?? "-"}</div>

					<div>Activity:</div>
					<div>{activity?.name ?? "-"}</div>
				</div>

				<LargeButton color="light" onClick={props.editNotes}>
					View or Edit Notes
				</LargeButton>
				<LargeButton color="light" onClick={props.switchSite}>
					Switch Activity or Site
				</LargeButton>
				{!!props.showHolidays && (
					<LargeButton color="light" onClick={props.showHolidays}>
						Request Holiday
					</LargeButton>
				)}

				<div class="w-full" style={{ flexGrow: 0.1 }} />

				<LargeButton color="primary" onClick={props.clockOut}>
					Clock Out
				</LargeButton>
				{!!props.quit && (
					<LargeButton color="secondary" onClick={props.quit}>
						Quit
					</LargeButton>
				)}
			</div>
		</BasicScreen>
	)
}

export default defineComponent(ClockedInOverview, {
	activeTimeSheet: requiredProp(Object),

	editNotes: requiredProp(Function),
	switchSite: requiredProp(Function),
	clockOut: requiredProp(Function),

	showHolidays: optionalProp(Function),
	quit: optionalProp(Function),
	refresh: optionalProp(Function),
})
