import BootstrapButton from "@/components/BootstrapButton"
import { useSites } from "@/data/coreDataStore"
import usePopups from "@/data/popupsStore"
import { css } from "vite-css-in-js"
import { useRouter } from "vue-router"
import { Alert, defineComponent, type ReactiveComponent } from "vue-utils"
import { useKioskModeStore } from "../kiosk-mode/store"

const enterKioskModeStyles = css`
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	font-size: 1.125rem;
`

const EnterKioskModeButton: ReactiveComponent = () => {
	const router = useRouter()
	const popups = usePopups()
	const sites = useSites()
	const store = useKioskModeStore()

	const enterKioskClick = async () => {
		if (!store.kioskPin) {
			void popups.showAlertPopup(() => (
				<Alert title="No PIN set">Please set a Kiosk Mode PIN to enter kiosk mode</Alert>
			))
			return
		}
		const assignedId = store.assignedSiteId
		if (assignedId === null || !sites.has(assignedId)) {
			void popups.showAlertPopup(() => (
				<Alert title="No Site Assigned">Please assign a site to the device to enter kiosk mode</Alert>
			))
			return
		}
		store.enterKioskMode()
		await router.replace("/pin")
	}

	return () => (
		<>
			<BootstrapButton class={enterKioskModeStyles} color="primary" onClick={() => void enterKioskClick()}>
				Enter Kiosk Mode
			</BootstrapButton>
		</>
	)
}

export default defineComponent(EnterKioskModeButton)
