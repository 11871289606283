import { useLoggedInUser } from "@/data/loggedInUserStore"
import { faCog } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { useRouter } from "vue-router"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import Icon from "../Icon"

const buttonStyles = css`
	display: flex;
	align-items: center;
	gap: 1rem;
	border: none;
	background-color: transparent;
	padding: 0.35rem;
	padding-left: 1rem;
	padding-right: 1rem;
	transition: 0.25s;

	&:hover:enabled {
		background-color: rgba(109, 119, 148, 0.3);
		cursor: pointer;
	}

	& > span {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid rgba(0, 0, 0, 0.5);
		background-color: var(--color-tl-primary);
		color: var(--color-tl-primary-contrast);
		border-radius: 100%;
		padding: 0.25rem;
		font-size: 1rem;
		width: 1.5rem;
		height: 1.5rem;
	}

	& > svg {
		font-size: 1.25rem;
		color: #333;
	}
`

function firstLetter(str: string) {
	return str.length === 0 ? "" : str[0]
}

const ProfileButton: ReactiveComponent = () => {
	const router = useRouter()

	const loggedInUser = useLoggedInUser()
	const userInitials = computed(() => {
		return firstLetter(loggedInUser.firstName) + firstLetter(loggedInUser.lastName)
	})

	const isOnPage = computed(() => router.currentRoute.value.path === "/profile-settings")

	function onClick() {
		void router.push("/profile-settings")
	}

	return () => (
		<button
			onClick={onClick}
			class={buttonStyles}
			title={isOnPage.value ? "" : "Open Profile & Settings"}
			disabled={isOnPage.value}
		>
			<Icon icon={faCog} />
			<span>{userInitials.value}</span>
		</button>
	)
}

export default defineComponent(ProfileButton)
