import type { CoordinateRadius, Id, SwitchedSiteRecord, TimeSheetEntry } from "@/models"
import { GeoLocationException, requestGeoLocation } from "@/utils/geoLocation"
import { formatTimeInUserTimeZone } from "@/utils/timeUtils"
import { defineStore } from "pinia"
import { ensureLoadingHasResult, LoadingError, loadLoadable, type Loadable, runWithMinimumDelay } from "vue-utils"
import { Screen } from "./Screen"
import {
	clockIn,
	clockOut,
	getClockedInInformation,
	switchSite,
	updateNotes,
	type ClockedInInformation,
} from "./service"

export interface SuccessDetails {
	title: string
	message: string
}

interface State {
	userDetails: Loadable<ClockedInInformation>
	successDetails: SuccessDetails
	screen: Screen
}

export const useSelfServiceStore = defineStore("self-service", {
	state: (): State => ({
		userDetails: {
			type: "initial",
			queryData: async () => {
				//Artificial delay so it's obvious to the user that *something* is happening
				return await runWithMinimumDelay(getClockedInInformation(), 500)
			},
		},
		successDetails: {
			title: "",
			message: "",
		},
		screen: Screen.Welcome,
	}),
	getters: {
		hasClockedInInfo(): boolean {
			return this.userDetails.type === "done"
		},
	},
	actions: {
		async reloadDetails() {
			if (this.userDetails.type !== "initial") {
				await loadLoadable(this.userDetails)
			}
		},
		showOverview() {
			this.switchScreen(Screen.Overview)
		},
		switchScreen(screen: Screen) {
			this.screen = screen
		},
		showSuccessMessage(title: string, message: string) {
			this.successDetails = {
				title,
				message,
			}
			this.switchScreen(Screen.Success)
		},
		getDetailsOrThrow(): ClockedInInformation {
			const details = this.userDetails
			if (details.type !== "done") {
				throw new Error("Internal state error - no currently clocked in information")
			}
			return details.result
		},
		getActiveTimeSheetOrThrow(): TimeSheetEntry {
			const details = this.getDetailsOrThrow()
			const latest = details.activeTimeSheet
			if (!latest) {
				throw new Error("Internal state error - no active time sheet")
			}
			return latest
		},
		patchDetails(patchAction: (details: ClockedInInformation) => void) {
			this.getDetailsOrThrow()
			this.$patch((state) => {
				patchAction(ensureLoadingHasResult(state.userDetails))
			})
		},
		async clockIn(siteId: Id, activityId: Id): Promise<TimeSheetEntry> {
			const position = await this.requestPosition()
			const timeSheet = await clockIn(siteId, activityId, position)

			this.patchDetails((details) => {
				details.lastActivityId = timeSheet.activityId
				details.lastSiteId = timeSheet.siteId
				details.activeTimeSheet = timeSheet
			})

			this.showSuccessMessage(
				"Successfully clocked in",
				timeSheet.clockIn.entered ? `You clocked in at ${formatTimeInUserTimeZone(timeSheet.clockIn.entered)}` : ""
			)
			return timeSheet
		},
		async clockOut(): Promise<TimeSheetEntry> {
			const position = await this.requestPosition()
			const closedTimeSheet = await clockOut(position)

			this.patchDetails((details) => {
				details.lastActivityId = closedTimeSheet.activityId
				details.lastSiteId = closedTimeSheet.siteId
				details.activeTimeSheet = null
			})

			this.showSuccessMessage(
				"Successfully clocked out",
				closedTimeSheet.clockOut.entered
					? `You clocked out at ${formatTimeInUserTimeZone(closedTimeSheet.clockOut.entered)}`
					: ""
			)
			return closedTimeSheet
		},
		async switchSite(newSiteId: Id, newActivityId: Id): Promise<SwitchedSiteRecord> {
			const position = await this.requestPosition()
			const record = await switchSite(newSiteId, newActivityId, position)

			this.patchDetails((details) => {
				details.lastActivityId = record.newTimeSheet.activityId
				details.lastSiteId = record.newTimeSheet.siteId
				details.activeTimeSheet = record.newTimeSheet
			})

			this.showSuccessMessage("Success", "Successfully switched site & activity")
			return record
		},
		async editNotes(newNotes: string): Promise<TimeSheetEntry> {
			const activeTimeSheet = this.getActiveTimeSheetOrThrow()
			const newEntry = await updateNotes(activeTimeSheet.id, newNotes)

			this.patchDetails((details) => {
				details.lastActivityId = newEntry.activityId
				details.lastSiteId = newEntry.siteId
				details.activeTimeSheet = newEntry
			})

			this.showSuccessMessage("Successfully updated notes", "")
			return newEntry
		},
		async requestPosition(): Promise<CoordinateRadius> {
			try {
				const position = await requestGeoLocation({
					enableHighAccuracy: true,
					maximumAge: 60_000,
					timeout: 30_000,
				})
				return {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
					radiusMetres: Math.floor(position.coords.accuracy),
				}
			} catch (e) {
				if (e instanceof GeoLocationException) {
					switch (e.code) {
						case e.PERMISSION_DENIED:
							throw new LoadingError("Failed to get location", "You must enable geolocation to be able to clock in")
						case e.TIMEOUT:
							throw new LoadingError(
								"Still trying to find your device",
								`Its taking longer than expected to get your device's location. Please try again`
							)
						default:
							throw new LoadingError(
								"Failed to get location",
								`Unable to get device location. Please try again or contact an administrator. Error Message: ${e.message}`
							)
					}
				} else {
					throw new LoadingError(
						"Failed to get location",
						`An unexpected error occurred trying to get your device's location. Please try again or contact an administrator.`
					)
				}
			}
		},
	},
})
