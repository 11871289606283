import BasicForm, { basicFormButton } from "@/components/basic-form"
import DescriptionList from "@/components/DescriptionList"
import UserNameDisplay from "@/components/UserNameDisplay"
import { ApprovalStatus, getHolidayRequestStatus, type HolidayRequest, type TimeSheetEntry } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import { formatDateInUserTimeZone } from "@/utils/timeUtils"
import { css } from "vite-css-in-js"
import { ConfirmResult, defineComponent, ModalTitle, requiredProp, type ReactiveComponent } from "vue-utils"
import { formatHolidayDate } from "../utils"
import { showConfirmCancelRequestPopup } from "./ConfirmCancelRequestPopup"
import CreatedTimeSheetsTable from "./CreatedTimeSheetsTable"

interface Props {
	request: HolidayRequest
	timeSheets: TimeSheetEntry[]

	close(): void
	cancelRequest(reason: string): Promise<void>
}

const formContentStyles = css`
	min-width: 40rem;
	overflow: auto;
	padding-right: 1rem;
`

const ApprovedRequestInformation: ReactiveComponent<Props> = (props) => {
	const closeBtn = basicFormButton({
		color: "secondary",
		id: "close",
		text: "Close",
		onSubmit: () => props.close(),
		validateForm: false,
	})
	const cancelBtn = basicFormButton({
		color: "danger",
		id: "cancel",
		text: "Cancel",
		validateForm: false,
		validate: verifyCancel,
		onSubmit: () => props.cancelRequest(cancelReason),
	})

	const buttons = $computed(() =>
		props.request.approval?.status === ApprovalStatus.Approved ? [closeBtn, cancelBtn] : [closeBtn]
	)

	let cancelReason = ""
	async function verifyCancel() {
		const result = await showConfirmCancelRequestPopup()
		if (result.type === ConfirmResult.Cancel) {
			cancelReason = ""
			return false
		}
		cancelReason = result.reason
		return true
	}

	return () => (
		<BasicForm buttons={buttons} style={{ display: "contents" }}>
			<ModalTitle title="View Holiday Request" />
			<hr />

			<div class={formContentStyles}>
				<DescriptionList style={{ marginTop: 0 }}>
					<dt>Created By</dt>
					<dd>{props.request.user ? <UserNameDisplay user={props.request.user} /> : "Unknown"}</dd>

					<dt>Date Created</dt>
					<dd>{formatDateInUserTimeZone(props.request.dateCreated)}</dd>

					<dt>Start Date</dt>
					<dd>{formatHolidayDate(props.request.dateRange.start)}</dd>

					<dt>End Date</dt>
					<dd>{formatHolidayDate(props.request.dateRange.end)}</dd>
				</DescriptionList>
				<hr />
				<DescriptionList>
					<dt>Approval Status</dt>
					<dd>{formatEnum(getHolidayRequestStatus(props.request))}</dd>

					<dt>Reviewed By</dt>
					<dd>
						{props.request.approval?.reviewedBy
							? `${props.request.approval?.reviewedBy.lastName}, ${props.request.approval?.reviewedBy.firstName}`
							: "Unknown"}
					</dd>

					<dt>Reviewed Date</dt>
					<dd>
						{props.request.approval?.reviewedAt ? formatDateInUserTimeZone(props.request.approval?.reviewedAt) : "N/A"}
					</dd>
				</DescriptionList>
				<hr />

				{props.timeSheets.length > 0 && (
					<label style={{ marginBottom: "1rem" }}>
						Generated Time Sheets
						<CreatedTimeSheetsTable timeSheets={props.timeSheets} />
					</label>
				)}
				<label>
					Comments
					<textarea value={props.request.approval?.comments ?? ""} rows={3} disabled />
				</label>
			</div>
		</BasicForm>
	)
}

export default defineComponent(ApprovedRequestInformation, {
	request: requiredProp(Object),
	timeSheets: requiredProp(Array),

	close: requiredProp(Function),
	cancelRequest: requiredProp(Function),
})
