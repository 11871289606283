import Icon from "@/components/Icon"
import { createMappedStringColumn, stringFilter, stringSort } from "@/components/data/DataTable"
import StateLoading from "@/components/state-loading"
import useCoreDataStore, { useSites } from "@/data/coreDataStore"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import type { Site } from "@/models"
import Permission from "@/models/Permission"
import { createSite, deleteSite, editSite } from "@/services/sitesService"
import findById from "@/utils/findById"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { ref } from "vue"
import {
	ShadowContainer,
	ToggleButton,
	defineComponent,
	ensureLoadingHasResult,
	refSetter,
	type ReactiveComponent,
} from "vue-utils"
import createCrudTable from "../CrudTable"
import FormContent from "./Form"

const CrudTable = createCrudTable<Site, Site>()

const Sites: ReactiveComponent = () => {
	const user = useLoggedInUser()
	const sites = useSites()
	const ruleSets = $computed(() => ensureLoadingHasResult(useCoreDataStore().ruleSets))

	const search = ref("")
	const showArchived = ref(false)

	const siteList = $computed(() => {
		const list = Array.from(sites.values()).sort((s1, s2) => s1.name.localeCompare(s2.name))
		return list.filter((site) => site.isArchived == showArchived.value)
	})

	return () => (
		<StateLoading stores={[useCoreDataStore().ruleSets]}>
			<ShadowContainer>
				<div class="flex items-center spacing-2 w-full">
					<Icon icon={faSearch} />
					<label class="flex-1">
						<input type="search" v-model={search.value} placeholder="Type to search..." />
					</label>
					<label class="flex-row">
						<ToggleButton toggled={showArchived.value} setToggled={refSetter(showArchived)} />
						Show Only Archived Sites
					</label>
				</div>
				<CrudTable
					name="Sites"
					getDefaultValue={() => ({
						id: 0,
						name: "",
						jobNumber: "",
						notes: "",
						location: null,
						ruleSetId: null,
						isArchived: false,
					})}
					mapValue={(site) => ({ ...site, location: site.location ? { ...site.location } : null })}
					createText="Create Site"
					renderForm={FormContent}
					values={siteList}
					create={async (site) => {
						const newSite = await createSite(site)
						sites.set(newSite.id, newSite)
					}}
					edit={async (site, formData) => {
						const newSite = await editSite({
							...site,
							...formData,
						})
						sites.set(newSite.id, newSite)
					}}
					delete={async (site) => {
						await deleteSite(site.id)
						sites.delete(site.id)
					}}
					columns={{
						name: {
							label: "Name",
							filter: stringFilter,
							sort: stringSort,
						},
						jobNumber: createMappedStringColumn("Job Number", (x) => x ?? ""),
						ruleSetId: createMappedStringColumn("Rule Set", (id) => {
							if (id === null) {
								return ""
							}
							return findById(ruleSets, id)?.name ?? ""
						}),
					}}
					tableOptions={{
						search: {
							search: search.value,
							setSearch: refSetter(search),
							showSearchBar: false,
						},
					}}
					disableEdit={!user.hasPermission(Permission.EditSite)}
					disableCreate={!user.hasPermission(Permission.CreateSite)}
				/>
			</ShadowContainer>
		</StateLoading>
	)
}

export default defineComponent(Sites)
