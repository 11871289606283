import { css } from "vite-css-in-js"
import { renderSlot } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	imageUrl: string
}

const backgroundStyles = css`
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

	position: relative;
	overflow: hidden;

	& > * {
		z-index: 1;
	}

	& > div:nth-child(1) {
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-position: center;

		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		filter: blur(5px);
		transform: scale(1.025);
	}
`

const PageBackground: ReactiveComponent<Props> = (props, { slots }) => {
	return () => (
		<div class={backgroundStyles}>
			<div key="background" style={{ backgroundImage: `url(${props.imageUrl})` }} />
			{renderSlot(slots, "default")}
		</div>
	)
}

export default defineComponent(PageBackground, {
	imageUrl: requiredProp(String),
})
