import {
	mapApiCalculatedHolidayDayTimes,
	type CalculatedHolidayDayTimes,
	type CompanyHoliday,
	type HolidayDayType,
	type Id,
	mapApiCompanyHoliday,
} from "@/models"
import {
	RequestMethod,
	httpJsonRequestJsonResponse,
	httpRequest,
	httpRequestJsonResponse,
} from "@/services/httpService"
import { LocalDate } from "@js-joda/core"

export const listCompanyHolidays = async (includePrevious = false): Promise<CompanyHoliday[]> => {
	let url = "/api/company-holidays"
	if (includePrevious) {
		url = `${url}?include-previous=true`
	}
	const result = await httpRequestJsonResponse<CompanyHoliday[]>(url, RequestMethod.GET)
	return result.map(mapApiCompanyHoliday)
}

export const createCompanyHoliday = async (holiday: CompanyHoliday): Promise<CompanyHoliday> => {
	const result = await httpJsonRequestJsonResponse<CompanyHoliday>("/api/company-holidays", holiday, RequestMethod.POST)
	return mapApiCompanyHoliday(result)
}

export const deleteCompanyHoliday = async (holidayId: Id): Promise<void> => {
	await httpRequest(`/api/company-holidays/${holidayId}`, RequestMethod.DELETE)
}

interface CalculateHolidayTimesData {
	date: LocalDate
	type: HolidayDayType
	siteId: Id
}

export const calculateHolidayTimes = async (data: CalculateHolidayTimesData): Promise<CalculatedHolidayDayTimes> => {
	const result = await httpJsonRequestJsonResponse<CalculatedHolidayDayTimes>(
		"/api/company-holidays/times",
		data,
		RequestMethod.POST
	)
	return mapApiCalculatedHolidayDayTimes(result)
}

export const countAssociatedHolidayTimeSheets = async (holidayId: Id): Promise<number> =>
	await httpRequestJsonResponse(`/api/company-holidays/count-attached-timesheets/${holidayId}`, RequestMethod.GET)
