import BootstrapButton, { type BootstrapButtonType } from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import { RequestMethod, httpRequest } from "@/services/httpService"
import { useFileDownload } from "@/utils/composition/useFileDownload"
import { faPrint } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	text: string
	fileName?: string
	url?: string
	request?: () => Promise<Response>
	color?: BootstrapButtonType
	action?: () => void
}

const printIconStyles = css`
	font-size: 0.95rem;

	svg {
		font-size: 1.25rem;
		margin-left: 0.25rem;
	}
`

const PrintButton: ReactiveComponent<Props> = (props) => {
	const { isDownloading, downloadFile } = useFileDownload()

	async function handleClick() {
		if (props.action) {
			props.action()
			return
		}
		if (props.request) {
			await downloadFile(props.request())
		}
		if (props.url) {
			await downloadFile(httpRequest(props.url, RequestMethod.GET))
		}
	}

	return () => (
		<BootstrapButton
			color={props.color ?? "secondary"}
			class={printIconStyles}
			onClick={() => void handleClick()}
			isSubmitting={isDownloading.value}
		>
			{props.text}
			<Icon icon={faPrint} />
		</BootstrapButton>
	)
}

export default defineComponent(PrintButton, {
	text: requiredProp(String),
	fileName: optionalProp(String),
	url: optionalProp(String),
	request: optionalProp(Function),
	color: optionalProp(String),
	action: optionalProp(Function),
})
