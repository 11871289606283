import { css } from "vite-css-in-js"
import { Transition, type HTMLAttributes } from "vue"
import { defineComponent, optionalProp, propsWithDefaults, renderSlot, type ReactiveComponent } from "vue-utils"

interface Props {
	zIndex?: number
	closeDefault?(): void
}

const modalStyles = css`
	position: absolute;
	inset: 0;
	background: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(0.5px);

	display: flex;
	align-items: center;
	justify-content: center;
`

const modalContentStyles = css`
	&.vu-modal {
		display: flex !important;
	}

	position: initial;
	display: flex;
	flex-direction: column;
	background-color: white;

	min-width: 10rem;
	min-height: 10rem;

	max-width: calc(100vw - 1.5rem);
	max-height: calc(92vh - 1.5rem);
`

const Modal: ReactiveComponent<Props, HTMLAttributes> = (initialProps, { attrs, slots }) => {
	const props = propsWithDefaults(initialProps, {
		zIndex: 3,
	})

	return () => (
		<Transition name="vu-modal-fade">
			<div class={modalStyles} style={{ zIndex: props.zIndex }}>
				<div class={["vu-modal", modalContentStyles]} {...attrs}>
					{renderSlot(slots)}
				</div>
			</div>
		</Transition>
	)
}

export default defineComponent(Modal, {
	zIndex: optionalProp(Number),
	closeDefault: optionalProp(Function),
})
