import type { ApiTypeOf, Id, PlannerHolidayDay, PlannerStaffEntry } from "@/models"
import { RequestMethod, httpJsonRequestJsonResponse } from "@/services/httpService"
import { LocalDate } from "@js-joda/core"

export interface HolidaysPlannerRequest extends HolidaysPlannerControls {
	year: number
	month: number
}

export interface HolidaysPlannerControls {
	includePendingHolidays: boolean
	hideStaffWithoutHoliday: boolean
	additionalActivities: Id[]
}

const mapHolidayDay = (input: ApiTypeOf<PlannerHolidayDay>): PlannerHolidayDay => ({
	...input,
	date: LocalDate.parse(input.date),
})

const mapStaffEntry = (input: ApiTypeOf<PlannerStaffEntry>): PlannerStaffEntry => ({
	...input,
	holidays: input.holidays.map(mapHolidayDay),
})

export const queryHolidayPlannerResults = async (request: HolidaysPlannerRequest): Promise<PlannerStaffEntry[]> => {
	const response = await httpJsonRequestJsonResponse<PlannerStaffEntry[]>(
		"/api/planner/holidays",
		request,
		RequestMethod.POST
	)
	return response.map(mapStaffEntry)
}
