import type { AdjustedTime, TimeSheetEntry } from "@/models"
import { Duration, type Instant } from "@js-joda/core"

export const getUsedTime = (time: AdjustedTime): Instant | null => time.override ?? time.autoAdjusted ?? time.entered

export const getPaidHours = (entry: TimeSheetEntry): Duration => {
	if (!entry.isPaid) {
		return Duration.ZERO
	}
	return entry.timeLoggedExcludingBreaks ?? Duration.ZERO
}
