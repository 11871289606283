import { css } from "vite-css-in-js"
import { defineComponent, optionalProp, useTimeout, type ReactiveComponent } from "vue-utils"
import LoadingSpinner from "./LoadingSpinner"

const backgroundStyles = css`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;

	font-size: 3rem;

	display: flex;
	align-items: center;
	justify-content: center;

	transition: background-color 0.5s;

	z-index: 3;
`

interface Props {
	hideTime?: number
	opacity?: number
}

const FullScreenLoading: ReactiveComponent<Required<Props>> = (props) => {
	const { hideTime = 100, opacity = 0.1 } = $(props)

	let visible = $ref(false)
	useTimeout(hideTime, () => {
		visible = true
	})
	const usedOpacity = $computed(() => (visible ? opacity : 0))

	return () => (
		<div
			class={backgroundStyles}
			style={{
				backgroundColor: `rgba(0, 0, 0, ${usedOpacity})`,
			}}
		>
			{visible && <LoadingSpinner />}
		</div>
	)
}

export default defineComponent<Props>(FullScreenLoading as ReactiveComponent<Props>, {
	hideTime: optionalProp(Number),
	opacity: optionalProp(Number),
})
