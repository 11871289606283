import type { User } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { ref } from "vue"
import {
	FormTabs,
	ScrollPane,
	defineComponent,
	refSetter,
	renderSlot,
	requiredProp,
	type Component,
	type FormTab,
	type ReactiveComponent,
} from "vue-utils"
import BasicDetails from "./BasicDetails"
import ActivityOverrides from "./activities/ActivityOverrides"
import HolidaySettings from "./holiday/HolidaySettings"
import UserSites from "./sites/UserSites"

interface Props {
	user: User
	readonly: boolean
}

const FormScrollPane: Component = (_, { slots }) => (
	<ScrollPane
		style={{
			width: "100%",
			height: "29rem",
		}}
	>
		{renderSlot(slots, "default")}
	</ScrollPane>
)

const FormContents: ReactiveComponent<Props> = (props) => {
	const user = proxyProp(props, "user")
	const tab = ref("details")

	const tabs: FormTab[] = [
		{
			id: "details",
			name: "Basic Details",
			renderContent: () => (
				<FormScrollPane>
					<BasicDetails {...props} />
				</FormScrollPane>
			),
		},
		{
			id: "sites",
			name: "Sites",
			renderContent: () => (
				<FormScrollPane>
					<UserSites
						userId={user.id}
						siteIds={user.siteIds}
						setSiteIds={(siteIds) => (user.siteIds = siteIds)}
						readonly={props.readonly}
					/>
				</FormScrollPane>
			),
		},
		{
			id: "overrides",
			name: "Activities",
			renderContent: () => (
				<FormScrollPane>
					<ActivityOverrides
						userId={user.id}
						activityOverrides={user.activityOverrides}
						setActivityOverrides={(overrides) => (user.activityOverrides = overrides)}
						readonly={props.readonly}
					/>
				</FormScrollPane>
			),
		},
		{
			id: "holiday",
			name: "Holiday Settings",
			renderContent: () => (
				<FormScrollPane>
					<HolidaySettings
						allowance={user.holidayAllowance}
						overrides={user.allowanceOverrides}
						isHolidayApprover={user.isHolidayApprover}
						readonly={props.readonly}
						setAllowance={(allowance) => (user.holidayAllowance = allowance)}
						setOverrides={(overrides) => (user.allowanceOverrides = overrides)}
						setIsHolidayApprover={(isApprover) => (user.isHolidayApprover = isApprover)}
					/>
				</FormScrollPane>
			),
		},
	]
	return () => (
		<FormTabs
			tabs={tabs}
			selectedTabId={tab.value}
			setSelectedTab={refSetter(tab)}
			controlAttributes={{ style: { marginTop: 0 } }}
		/>
	)
}

export default defineComponent(FormContents, {
	user: requiredProp(Object),
	readonly: requiredProp(Boolean),
})
