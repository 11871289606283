import type { Id } from "@/models"
import { LocalDate } from "@js-joda/core"
import { parsingNullableString, type RouterQueryRefOptions } from "vue-utils"

export const NullableLocalDateQuery: RouterQueryRefOptions<LocalDate | null> = {
	parse: parsingNullableString((str) => {
		if (!str || !str.trim().length) {
			return null
		}
		try {
			return LocalDate.parse(str)
		} catch {
			return null
		}
	}),
	stringify: (x) => (x === null ? "" : x.toJSON()),
}

export const IdArrayQuery: RouterQueryRefOptions<Id[]> = {
	parse: (query) => {
		const array = query instanceof Array ? query : [query]
		return array
			.filter((item) => !!item)
			.map((item) => Number.parseInt((item as string).trim()))
			.filter(Number.isSafeInteger)
	},
	stringify: (item) => item.join(","),
}
