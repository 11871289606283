import { getCoreDataValue } from "@/data/coreDataStore"
import {
	chainComparators,
	comparatorWithSort,
	createComparator,
	type Comparator,
	type SortDirection,
	type TimeSheetEntry,
} from "@/models"
import { getUsedTime } from "@/services/timesheetsService"
import { Instant } from "@js-joda/core"

export enum TimeSheetSortBy {
	Date = "date",
	Site = "site",
	Activity = "activity",
}

/**
 * Sorts the time sheets in place using the specified criteria
 */
export function sortTimeSheets(timeSheets: TimeSheetEntry[], sortBy: TimeSheetSortBy, direction: SortDirection): void {
	let comparator: Comparator<TimeSheetEntry>
	if (sortBy === TimeSheetSortBy.Site) {
		comparator = chainComparators(comparatorWithSort(siteComparator, direction), dateComparator)
	} else if (sortBy === TimeSheetSortBy.Activity) {
		comparator = chainComparators(comparatorWithSort(activityComparator, direction), dateComparator)
	} else {
		comparator = comparatorWithSort(dateComparator, direction)
	}

	timeSheets.sort(comparator)
}

const dateComparator: Comparator<TimeSheetEntry> = createComparator(
	(ts) => getUsedTime(ts.clockIn) ?? getUsedTime(ts.clockOut) ?? Instant.EPOCH,
	(date1, date2) => date1.compareTo(date2)
)
const siteComparator: Comparator<TimeSheetEntry> = createComparator(
	(ts) => {
		const sites = getCoreDataValue("sites")
		return sites.get(ts.siteId)?.name ?? ""
	},
	(name1, name2) => name1.localeCompare(name2)
)
const activityComparator: Comparator<TimeSheetEntry> = createComparator(
	(ts) => {
		const activities = getCoreDataValue("activities")
		return activities.get(ts.activityId)?.name ?? ""
	},
	(name1, name2) => name1.localeCompare(name2)
)
