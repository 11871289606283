import { Instant, LocalDate } from "@js-joda/core"
import type { ApiTypeOf, HolidayAllowance, Id, IIdentifier, Nullable, UserMetadata } from "."
import type { HolidayApproval } from "./HolidayApproval"
import type { HolidayDateRange, HolidayType } from "./HolidayDay"

export interface HolidayRequest extends IIdentifier {
	holidayType: HolidayType.Staff

	userId: Id

	user: Nullable<UserMetadata>

	/***
	 * Any additional information the user has provided about their request
	 */
	userDescription: string

	dateCreated: Instant

	dateRange: HolidayDateRange

	approval: Nullable<HolidayApproval>
}

export interface HolidayRequestValidation {
	errors: string[]
	warnings: string[]
	priorAllowance: HolidayAllowance
	newAllowance: HolidayAllowance
	workingDays: number
}

export interface HolidayRequestFilter {
	userSearch: Nullable<string>
	year: Nullable<number>
	status: Nullable<HolidayRequestStatus>
}

export enum HolidayRequestStatus {
	Pending = "pending",
	Approved = "approved",
	Rejected = "rejected",
	Cancelled = "cancelled",
}

export function getHolidayRequestStatus(request: HolidayRequest): HolidayRequestStatus {
	if (!request.approval) {
		return HolidayRequestStatus.Pending
	}
	return request.approval.status as string as HolidayRequestStatus
}

export const mapApiHolidayRequest = (request: ApiTypeOf<HolidayRequest>): HolidayRequest => ({
	...request,
	dateCreated: Instant.parse(request.dateCreated),
	dateRange: {
		start: {
			...request.dateRange.start,
			date: LocalDate.parse(request.dateRange.start.date),
		},
		end: {
			...request.dateRange.end,
			date: LocalDate.parse(request.dateRange.end.date),
		},
	},
	approval: request.approval
		? {
				...request.approval,
				reviewedAt: Instant.parse(request.approval.reviewedAt),
		  }
		: null,
})
