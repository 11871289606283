import AuthorizePermission from "@/authentication/AuthorizePermission"
import Permission from "@/models/Permission"
import { css } from "vite-css-in-js"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import Dashboard from "./Dashboard"

const dashboardStyles = css`
	margin: auto;
	width: 100%;
	max-width: 72rem;
`

const DashboardPage: ReactiveComponent = () => {
	return () => (
		<BasicPage title="Dashboard">
			<div class={dashboardStyles}>
				<AuthorizePermission permissions={[Permission.OnSiteNow, Permission.AllSites]} silent>
					<Dashboard />
				</AuthorizePermission>
			</div>
		</BasicPage>
	)
}

export default defineComponent(DashboardPage)
