import { useLoading } from "@/utils/composition/useLoading"
import { LocalDate } from "@js-joda/core"
import { onMounted, watch } from "vue"
import {
	RefQueries,
	defineComponent,
	useQueryRef,
	useQueryStoreRef,
	useStoreValue,
	type ReactiveComponent,
} from "vue-utils"
import OnSiteNowButton from "./components/OnSiteNowButton"
import SiteSelector from "./components/SiteSelector"
import SiteSummaryButton from "./components/SiteSummaryButton"
import UserList from "./components/UserList"
import WeekSelector from "./components/WeekSelector"
import { SiteQuery } from "./queries"
import useTimeSheetManagementStore from "./store"
import "./styles.scss"
import TimeSheetView from "./view/TimeSheetView"

const TimeSheetsManagement: ReactiveComponent = () => {
	const { wrapAction, runAction } = useLoading()

	const store = useTimeSheetManagementStore()

	const queryUserId = useQueryRef("user", store.selectedUser?.id ?? null, RefQueries.NullableInt)

	const selectedSiteId = useQueryStoreRef("site", store, "siteId", SiteQuery)
	const selectedUser = useStoreValue(store, "selectedUser")
	useQueryStoreRef("week", store, "week", {
		stringify: (d) => d.toJSON(),
		parse: (x) => LocalDate.parse(x as string).getStartOfWeek(),
	})
	const filterUnapproved = useQueryStoreRef("filter-unapproved", store, "filterUnapproved", RefQueries.Boolean)
	const listedUsers = useStoreValue(store, "listedUsers")

	watch(selectedUser, (newSelectedUser) => {
		const newId = newSelectedUser ? newSelectedUser.id : null
		if (queryUserId.value !== newId) {
			queryUserId.value = newId
		}
	})
	watch([queryUserId, listedUsers], ([queryUserId, listedUsers]) => {
		if (queryUserId === null) {
			store.selectedUser = null
			return
		}
		const user = listedUsers.get(queryUserId)
		if (user && store.selectedUser !== user) {
			store.selectedUser = user
		}
	})

	watch([selectedSiteId, filterUnapproved], () => runAction(store.queryRelevantUserList()))
	watch(selectedUser, () => runAction(store.requestTimeSheets()))

	const refreshAllDetails = wrapAction(async () => {
		const userListPromise = store.queryRelevantUserList()
		if (selectedUser.value !== null) {
			await store.requestTimeSheets()
		}
		await userListPromise
	})

	watch(() => store.week, refreshAllDetails)
	onMounted(refreshAllDetails)

	return () => (
		<div class="timesheet-management">
			<div class="flex flex-col">
				<h4>Select a Site</h4>
				<hr />
				<SiteSelector />
			</div>
			<div>
				<div class="flex justify-end items-center spacing-4">
					<OnSiteNowButton />
					<SiteSummaryButton />
				</div>
				<WeekSelector />
			</div>
			<UserList />
			<div class="timesheet-view">
				<TimeSheetView key={store.startOfWeek.toString()} />
			</div>
		</div>
	)
}

export default defineComponent(TimeSheetsManagement)
