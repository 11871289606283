import BootstrapButton from "@/components/BootstrapButton"
import GridRow from "@/components/utility/GridRow"
import usePopups from "@/data/popupsStore"
import { ModalTitle, defineComponent, requiredProp, type PopupContentProps, type ReactiveComponent } from "vue-utils"
import PrintButton from "./PrintButton"

const SortBySelection = defineComponent<PopupContentProps>({
	name: "SortBySelection",
	props: {
		close: requiredProp(Function),
		popup: requiredProp(Object),
	},
	setup: (props) => {
		return () => (
			<>
				<ModalTitle title="On Site Now" />
				<hr />
				<p>Show everyone currently clocked in sorted by:</p>
				<div class="flex items-center justify-end">
					<GridRow elementCount={3}>
						<PrintButton color="primary" text="By Site" url="/api/reports/on-site-now/site" />
						<PrintButton color="primary" text="By User" url="/api/reports/on-site-now/user" />
						<BootstrapButton color="danger" onClick={props.close}>
							Close
						</BootstrapButton>
					</GridRow>
				</div>
			</>
		)
	},
})

const OnSiteNowButton: ReactiveComponent = () => {
	const popups = usePopups()
	const open = () =>
		void popups.openPopup({
			content: (props) => <SortBySelection {...props} />,
			width: "27.5rem",
		})

	return () => <PrintButton text="On Site Now" action={open} />
}

export default defineComponent(OnSiteNowButton)
