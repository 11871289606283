import { useLocalDateInput } from "@/utils/timeUtils"
import { LocalDate } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { defineComponent, type ReactiveComponent, requiredProp } from "vue-utils"

interface Props {
	from: LocalDate | null
	to: LocalDate | null

	setFrom(from: LocalDate | null): void
	setTo(from: LocalDate | null): void
}

const filterStyles = css`
	display: grid;
	grid-template-columns: auto auto;
	gap: 0.125em 0.5em;

	label {
		display: contents;
		gap: 0.125em;
	}
	input {
		padding: 0.25em 0.125em;
		padding-right: 0;
	}
`

const DateRangeFilter: ReactiveComponent<Props> = (props) => {
	const { from, to } = $(props)
	return () => (
		<div class={filterStyles}>
			<label>
				From
				<input
					value={from?.toHTMLInput()}
					onChange={useLocalDateInput(props.setFrom)}
					max={to?.toHTMLInput()}
					type="date"
				/>
			</label>
			<label>
				To
				<input
					value={to?.toHTMLInput()}
					onChange={useLocalDateInput(props.setTo)}
					min={from?.toHTMLInput()}
					type="date"
				/>
			</label>
		</div>
	)
}

export default defineComponent(DateRangeFilter, {
	from: requiredProp(LocalDate, null),
	to: requiredProp(LocalDate, null),
	setFrom: requiredProp(Function),
	setTo: requiredProp(Function),
})
