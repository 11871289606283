import MultiSelect from "@/components/multi-select"
import { useActivities } from "@/data/coreDataStore"
import type { Activity } from "@/models"
import { SortDirection } from "@/models"
import { SiteListType, useSiteList } from "@/utils/composition/useSiteList"
import { ToggleButton, defineComponent, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"
import DateRangeFilter from "./components/DateRangeFilter"
import SortOrderHeader from "./components/SortOrderHeader"
import { TimeSheetOrderBy, type TimeSheetExportFilter } from "./model"

interface Props {
	filter: TimeSheetExportFilter
	setFilter: (filter: TimeSheetExportFilter) => void
}

const TableHeader: ReactiveComponent<Props> = (props) => {
	const sites = useSiteList(SiteListType.AllPrioritised)
	const activities = useActivities()
	const { filter } = $(props)

	function setFilterProperty<K extends keyof TimeSheetExportFilter>(key: K, value: TimeSheetExportFilter[K]) {
		props.setFilter({
			...props.filter,
			[key]: value,
		})
	}

	function updateSort(field: TimeSheetOrderBy, direction: SortDirection) {
		props.setFilter({
			...props.filter,
			orderBy: field,
			orderDirection: direction,
		})
	}

	const selectedSites = $computed(() => sites.value.filter((site) => filter.siteIds.includes(site.id)))
	const selectedActivities = $computed(() =>
		filter.activityIds.filter((id) => activities.has(id)).map((id) => activities.get(id) as Activity)
	)

	return () => (
		<thead>
			<tr>
				<SortOrderHeader
					text="Date"
					rowspan={2}
					direction={filter.orderDirection}
					orderBy={filter.orderBy}
					field={TimeSheetOrderBy.Date}
					setSorting={updateSort}
					defaultDirection={SortDirection.Descending}
				/>
				<SortOrderHeader
					text="User"
					rowspan={2}
					direction={filter.orderDirection}
					orderBy={filter.orderBy}
					field={TimeSheetOrderBy.User}
					setSorting={updateSort}
				/>
				<SortOrderHeader
					text="Site"
					rowspan={2}
					direction={filter.orderDirection}
					orderBy={filter.orderBy}
					field={TimeSheetOrderBy.Site}
					setSorting={updateSort}
				/>
				<SortOrderHeader
					text="Activity"
					rowspan={2}
					direction={filter.orderDirection}
					orderBy={filter.orderBy}
					field={TimeSheetOrderBy.Activity}
					setSorting={updateSort}
				/>
				<th rowspan={2}>Notes</th>
				<th colspan={4}>Clock In</th>
				<th colspan={4}>Clock Out</th>
				<th colspan={2}>Break Duration</th>
				<th rowspan={2}>
					<div>
						<span>Hours</span>
						<br />
						<span>Logged</span>
					</div>
				</th>
				<th rowspan={2}>
					<div>
						<span>Hours</span>
						<br />
						<span>Paid</span>
					</div>
				</th>
				<th rowspan={2}>Approval</th>
			</tr>
			<tr>
				<th>Entered</th>
				<th>Auto</th>
				<th>Override</th>
				<th>Loc</th>

				<th>Entered</th>
				<th>Auto</th>
				<th>Override</th>
				<th>Loc</th>

				<th>Auto</th>
				<th>Override</th>
			</tr>
			<tr>
				<th>
					<DateRangeFilter
						from={filter.from}
						to={filter.to}
						setFrom={(from) => setFilterProperty("from", from)}
						setTo={(to) => setFilterProperty("to", to)}
					/>
				</th>
				<th>
					<label>
						Search Users
						<input value={filter.user} onInput={useOnInput((txt) => setFilterProperty("user", txt))} type="search" />
					</label>
				</th>
				<th>
					<label>
						Select Sites
						<MultiSelect
							options={sites.value}
							selected={selectedSites}
							setSelected={(sites) =>
								setFilterProperty(
									"siteIds",
									sites.map((site) => site.id)
								)
							}
							getKey={(site) => site.id}
							getText={(site) => site.name}
							noneText="All Sites"
							allText="All Sites"
							someText={(sites) => (sites.length === 1 ? sites[0].name : `${sites.length} Sites Selected`)}
							dropDownAttributes={{ style: { minWidth: "24rem" } }}
							allowSearch
						/>
					</label>
				</th>
				<th>
					<label>
						Select Activities
						<MultiSelect
							options={Array.from(activities.values())}
							selected={selectedActivities}
							setSelected={(activities) =>
								setFilterProperty(
									"activityIds",
									activities.map((activity) => activity.id)
								)
							}
							getKey={(activity) => activity.id}
							getText={(activity) => activity.name}
							noneText="All Activities"
							allText="All Activities"
							someText={(activities) =>
								activities.length === 1 ? activities[0].name : `${activities.length} Activities Selected`
							}
							dropDownAttributes={{ style: { minWidth: "24rem" } }}
							allowSearch
						/>
					</label>
				</th>
				<th>
					<label>
						Notes?
						<ToggleButton toggled={filter.notes} setToggled={(n) => setFilterProperty("notes", n)} />
					</label>
				</th>
				{Array.from({ length: 12 }).map((_, i) => (
					<th class="no-filter" key={i} style={{ cursor: "not-allowed" }} />
				))}
				<th>
					<label>
						Search Users
						<input
							value={filter.approvedUser}
							onInput={useOnInput((txt) => setFilterProperty("approvedUser", txt))}
							type="search"
						/>
					</label>
				</th>
			</tr>
		</thead>
	)
}

export default defineComponent(TableHeader, {
	filter: requiredProp(Object),
	setFilter: requiredProp(Function),
})
