import type { HolidayRequest } from "@/models"
import { css } from "vite-css-in-js"
import { defineComponent, groupBy, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import HolidayRequestCard from "./HolidayRequestCard"

interface Props {
	requests: HolidayRequest[]
	noRequestsMessage: string

	showOwner: boolean
	onRequestClick?: (request: HolidayRequest) => void
}

const listStyles = css`
	list-style: none;
	padding: 0 0.5rem;
	margin: 0;

	& > li {
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
		padding-bottom: 1.5rem;

		&:last-child {
			padding: 0;
			border: none;
		}
	}

	& > li > ul {
		list-style: none;
		margin: 0;
		padding: 0;

		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(100%, 27.5rem), 1fr));
		gap: 1rem;
	}
	& > li > h2 {
		font-weight: 400;
		font-size: 1.25rem;
		text-align: left;
	}
`

const HolidayRequestList: ReactiveComponent<Props> = (props) => {
	const byYear = $computed(() => {
		const grouped = groupBy(props.requests, (req) => req.dateRange.start.date.year())
		for (const year in grouped) {
			grouped[year].sort((r1, r2) => r2.dateRange.start.date.compareTo(r1.dateRange.start.date))
		}
		return grouped
	})

	return () =>
		props.requests.length === 0 ? (
			<p>{props.noRequestsMessage}</p>
		) : (
			<ol class={listStyles}>
				{Object.entries(byYear)
					.sort((e1, e2) => Number.parseInt(e2[0]) - Number.parseInt(e1[0]))
					.map(([year, requests]) => (
						<li key={year}>
							<h2>{year}</h2>
							<ul>
								{requests.map((request) => (
									<li key={request.id}>
										<HolidayRequestCard
											request={request}
											showOwner={props.showOwner}
											onClick={props.onRequestClick ? () => props.onRequestClick?.(request) : undefined}
										/>
									</li>
								))}
							</ul>
						</li>
					))}
			</ol>
		)
}

export default defineComponent(HolidayRequestList, {
	requests: requiredProp(Array),
	noRequestsMessage: requiredProp(String),

	showOwner: requiredProp(Boolean),
	onRequestClick: optionalProp(Function),
})
