import { defineComponent, type ReactiveComponent } from "vue-utils"
import useTimeSheetManagementStore from "../store"
import PrintButton from "./PrintButton"

const SiteSummaryButton: ReactiveComponent = () => {
	const store = useTimeSheetManagementStore()

	const start = $computed(() => encodeURIComponent(store.startOfWeek.toInstant().toJSON()))
	const end = $computed(() => encodeURIComponent(store.endOfWeek.toInstant().toJSON()))

	return () => (
		<PrintButton text="Site Summary" fileName="Site Summary.pdf" url={`/api/reports/job-summary/${start}/${end}`} />
	)
}

export default defineComponent(SiteSummaryButton)
