import { useActivities } from "@/data/coreDataStore"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import { canModifyActivityWithRole, canViewActivityWithRole, type Activity } from "@/models"
import { computed, type ComputedRef } from "vue"

export function useVisibleTimeSheetActivities(): ComputedRef<Activity[]> {
	const user = useLoggedInUser()
	const allActivities = useActivities()

	return computed(() =>
		Array.from(allActivities.values()).filter((activity) => canViewActivityWithRole(activity, user.role))
	)
}

export function useModifyableTimeSheetActivities(): ComputedRef<Activity[]> {
	const user = useLoggedInUser()
	const allActivities = useActivities()

	return computed(() =>
		Array.from(allActivities.values()).filter(
			(activity) => canViewActivityWithRole(activity, user.role) && canModifyActivityWithRole(activity, user.role)
		)
	)
}
