import { useActivity } from "@/data/coreDataStore"
import type { GeneratingHolidayDayTimes } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import { proxyProp } from "@/utils/proxyProp"
import { useLocalTimeInput } from "@/utils/timeUtils"
import { Duration, convert, type LocalDate } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, type ReactiveComponent, calculateForegroundColor } from "vue-utils"

interface Props {
	dayTimes: GeneratingHolidayDayTimes
}

const formatDate = (date: LocalDate) =>
	convert(date).toDate().toLocaleDateString(undefined, {
		day: "numeric",
		month: "short",
		weekday: "short",
	})

const holidayRowStyles = css`
	& > td {
		color: var(--fg);
		background-color: var(--bg);
	}
	input[type="time"] {
		cursor: not-allowed;
	}
`

const DayTimesRow: ReactiveComponent<Props> = (props) => {
	const dayTimes = proxyProp(props, "dayTimes")
	const activity = useActivity(dayTimes.activityId)

	return () => {
		if (!dayTimes.isGenerated) {
			const backgroundColor = activity.value?.highlightColor ?? "#ffffff"
			return (
				<tr
					class={holidayRowStyles}
					title={dayTimes.name ?? "Existing Holiday"}
					style={{
						"--bg": backgroundColor,
						"--fg": calculateForegroundColor(backgroundColor),
					}}
				>
					<td>{formatDate(dayTimes.date)}</td>
					<td>{formatEnum(dayTimes.type)}</td>
					<td>{dayTimes.name ?? "Existing Holiday"}</td>
					<td>{dayTimes.startTime?.toHTMLInput()}</td>
					<td>{dayTimes.endTime?.toHTMLInput()}</td>
					<td>{dayTimes.breakDuration?.toHTMLInput()}</td>
				</tr>
			)
		}
		return (
			<tr style={{ backgroundColor: "rgb(238, 255, 231)" }}>
				<td>{formatDate(dayTimes.date)}</td>
				<td>{formatEnum(dayTimes.type)}</td>
				<td>
					<i>To Create</i>
				</td>
				<td>
					<input
						type="time"
						value={dayTimes.startTime?.toHTMLInput()}
						onChange={useLocalTimeInput((time) => (dayTimes.startTime = time))}
						max={dayTimes.endTime?.toHTMLInput()}
						required
					/>
				</td>
				<td>
					<input
						type="time"
						value={dayTimes.endTime?.toHTMLInput()}
						onChange={useLocalTimeInput((time) => (dayTimes.endTime = time))}
						min={dayTimes.startTime?.toHTMLInput()}
						required
					/>
				</td>
				<td>
					<input
						type="time"
						value={dayTimes.breakDuration?.toHTMLInput()}
						onChange={useLocalTimeInput(
							(time) => (dayTimes.breakDuration = time ? Duration.ofSeconds(time.toSecondOfDay()) : null)
						)}
						required
					/>
				</td>
			</tr>
		)
	}
}

export default defineComponent(DayTimesRow, {
	dayTimes: requiredProp(Object),
})
