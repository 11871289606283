import AuthorizePermission from "@/authentication/AuthorizePermission"
import Permission from "@/models/Permission"
import { defineComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import TimeSheetExport from "./TimeSheetExport"

export default defineComponent({
	name: "TimeSheetExport",
	setup: () => () => (
		<BasicPage title="Time Logs" class="flex">
			<AuthorizePermission permissions={[Permission.ExportTimeSheets]}>
				<TimeSheetExport />
			</AuthorizePermission>
		</BasicPage>
	),
})
