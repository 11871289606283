import { renderSlot } from "vue"
import { useRouter } from "vue-router"
import {
	FormTabControls,
	defineComponent,
	optionalProp,
	requiredProp,
	type FormTab,
	type ReactiveComponent,
	propsWithDefaults,
} from "vue-utils"

interface Props {
	type?: "hash" | "query"
	tabs: FormTab[]
	onSelected?: (tab: FormTab) => void
}

const RouterTabs: ReactiveComponent<Props> = (initialProps, { slots }) => {
	const props = propsWithDefaults(initialProps, {
		type: "hash",
	})
	const router = useRouter()

	const tab = $computed(() => {
		let selectedId: string | null = null
		if (props.type === "hash") {
			const hash = router.currentRoute.value.hash
			if (hash.length > 0 && hash[0] === "#") {
				selectedId = hash.substring(1)
			}
		} else {
			const queryVal = router.currentRoute.value.query.tab
			if (queryVal === null || typeof queryVal === "string") {
				selectedId = queryVal
			}
		}

		let tab = props.tabs.find((tab) => tab.id === selectedId)
		if (!tab) {
			tab = props.tabs[0]
			setTab(tab.id)
		}
		return tab
	})

	const setTab = (id: string) => {
		const current = router.currentRoute.value
		if (props.type === "hash") {
			void router.replace({
				...current,
				hash: `#${id}`,
			})
		} else {
			void router.replace({
				...current,
				query: {
					...(current.query ?? {}),
					tab: id,
				},
			})
		}

		if (props.onSelected) {
			const tab = props.tabs.find((t) => t.id === id)
			if (tab) {
				props.onSelected(tab)
			}
		}
	}

	function renderContent() {
		const Content = tab.renderContent
		return Content ? <Content /> : renderSlot(slots, tab.id)
	}

	return () => (
		<>
			<FormTabControls tabs={props.tabs} selectedTabId={tab.id} setSelectedTab={setTab} />
			{renderContent()}
		</>
	)
}

export default defineComponent(RouterTabs, {
	type: optionalProp(String),
	tabs: requiredProp(Array),
	onSelected: optionalProp(String),
})
