/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { getHolidayRequestStatus, TimePoint, type HolidayApproval, type HolidayRequest, type Nullable } from "@/models"
import { formatDateInUserTimeZone } from "@/utils/timeUtils"
import { convert, type LocalDate } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	request: HolidayRequest
	showOwner: boolean

	onClick?: () => void
}

const cardStyles = css`
	display: flex;
	height: 100%;
	flex-direction: column;
	border-radius: 0.5rem;
	overflow: hidden;
	border: 1px solid rgba(0, 0, 0, 0.25);
	background-color: white;

	&[data-clickable="true"] {
		cursor: pointer;

		&:hover,
		&:focus-visible {
			box-shadow: 0 0 10px var(--color-primary);
			outline: thin solid var(--color-primary);
		}
	}

	& > div {
		padding: 0.35rem 0.5rem;
		text-align: center;
		background: #5c5c5c;
		color: white;
		font-weight: 600;
	}

	h3 {
		padding: 0.35rem 0.5rem;
		font-weight: 500;
		font-size: 1.05rem;
		margin: 0;

		&[data-approval="approved"] {
			background-color: #289728;
			color: white;
		}
		&[data-approval="rejected"] {
			background-color: #c51729;
			color: white;
		}
		&[data-approval="pending"] {
			background-color: #e0e0e0;
		}
		&[data-approval="cancelled"] {
			background-color: #dddddd;
			text-decoration: line-through;
		}
	}

	pre {
		font-family: inherit;
		margin: 0;
		white-space: break-spaces;
	}
`

const descriptionStyles = css`
	padding: 0.75rem 0.5rem;
	min-height: 2.5rem;
	width: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);

	&[data-approval="cancelled"] {
		text-decoration: line-through;
	}
	pre {
		height: 100%;
	}
`

const reviewedStyles = css`
	padding: 0.5rem;

	h4 {
		font-weight: 600;
		margin: 0;
		margin-bottom: 0.5rem;
	}

	& > pre {
		padding: 0.35rem 0.5rem;
		margin-top: 0.75rem;
		border: 1px solid rgba(0, 0, 0, 0.25);
		background-color: #f5f5f5;
		border-radius: 0.5rem;
		font-style: italic;
	}
`

const HolidayRequestCard: ReactiveComponent<Props> = (props) => {
	const { request } = $(props)

	const dateRangeStr = $computed(() => {
		const start = request.dateRange.start
		const end = request.dateRange.end

		const formatDate = (date: LocalDate) =>
			convert(date).toDate().toLocaleDateString(undefined, {
				day: "numeric",
				month: "short",
			})
		let text = formatDate(start.date)
		if (start.timePoint === TimePoint.MidDay) {
			text += " (Afternoon)"
		}
		if (!start.date.equals(end.date)) {
			text += ` → ${formatDate(end.date)}`
			if (end.timePoint === TimePoint.MidDay) {
				text += " (Morning)"
			}
		}
		return text
	})

	function renderApproval(approval: Nullable<HolidayApproval>) {
		if (!approval) {
			return <i>The request has not yet been reviewed.</i>
		}

		let message = ""
		if (approval.reviewedBy) {
			message += ` by ${approval.reviewedBy.firstName} ${approval.reviewedBy.lastName}`
		}
		message += ` on ${formatDateInUserTimeZone(approval.reviewedAt)}`
		return (
			<>
				The request was <strong>{approval.status}</strong>
				{message}.{" "}
				{approval.comments.trim().length > 0 && (
					<>
						The following comments have been left on the request:
						<pre>{approval.comments}</pre>
					</>
				)}
			</>
		)
	}

	return () => (
		<article
			class={cardStyles}
			data-clickable={(!!props.onClick).toString()}
			onClick={props.onClick}
			onKeyup={(e) => e.key === "Enter" && props.onClick?.()}
			tabindex={0}
		>
			{props.showOwner && !!props.request.user && (
				<div style={{ padding: "0.35rem 0.5rem", textAlign: "center" }}>
					{props.request.user.lastName}, {props.request.user.firstName}
				</div>
			)}
			<h3 data-approval={getHolidayRequestStatus(props.request)}>{dateRangeStr}</h3>
			<section
				class={descriptionStyles}
				data-approval={getHolidayRequestStatus(props.request)}
				aria-label="Description"
			>
				{request.userDescription.trim().length > 0 ? (
					<pre>{request.userDescription}</pre>
				) : (
					<i style={{ color: "var(--color-info)" }}>No Description</i>
				)}
			</section>
			<section class={reviewedStyles} aria-label="Approval Status">
				<h4>Approval Status</h4>
				{renderApproval(request.approval)}
			</section>
		</article>
	)
}

export default defineComponent(HolidayRequestCard, {
	request: requiredProp(Object),
	showOwner: requiredProp(Boolean),

	onClick: optionalProp(Function),
})
