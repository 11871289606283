import { useTodaysDate } from "@/utils/composition/useDate"
import { Month, YearMonth } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { computed, ref, toRef, watch, type HTMLAttributes } from "vue"
import { defineComponent, optionalProp, refSetter, requiredProp, type ReactiveComponent } from "vue-utils"
import { formatMonth } from "../calendar/formatting"
import YearNavigateContainer from "./YearNavigateContainer"

const gridStyles = css`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin: 0.125rem;

	flex-grow: 1;
	gap: 1px;

	& > button {
		all: unset;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 0.75rem;
		padding: 0.35rem 0.5rem;
		font-weight: 500;
		user-select: none;

		&[data-current="true"] {
			font-weight: bold;
		}
		&[data-selected="true"] {
			background-color: var(--color-primary-light-6);
		}
		&[data-selected="false"]:hover {
			color: white;
			background-color: var(--color-info-dark-3);
			cursor: pointer;
		}
	}
`

interface Props {
	month?: YearMonth
	setMonth(month: YearMonth): void
	onBlur?: () => void
}

const MonthPicker: ReactiveComponent<Props, HTMLAttributes> = (props, { attrs }) => {
	const year = ref(props.month?.year() ?? YearMonth.now().year())
	const dateToday = useTodaysDate()
	const monthToday = computed(() => YearMonth.from(dateToday.value))

	watch(toRef(props, "month"), (month) => {
		if (month) {
			year.value = month.year()
		}
	})

	return () => (
		<YearNavigateContainer year={year.value} setYear={refSetter(year)} onBlur={props.onBlur} {...attrs}>
			<div class={gridStyles}>
				{Array.from({ length: 12 }, (_, i) => {
					const month = Month.of(i + 1)
					const date = YearMonth.of(year.value, month)

					return (
						<button
							key={i}
							aria-label={`Select ${month.displayName()} ${date.year()}`}
							data-selected={String(date.equals(props.month))}
							data-current={String(date.equals(monthToday.value))}
							onClick={() => props.setMonth(date)}
						>
							{formatMonth(month)}
						</button>
					)
				})}
			</div>
		</YearNavigateContainer>
	)
}

export default defineComponent(MonthPicker, {
	month: optionalProp(YearMonth),
	setMonth: requiredProp(Function),
	onBlur: optionalProp(Function),
})
