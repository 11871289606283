import { useCoreDataValue } from "@/data/coreDataStore"
import type { HolidayAllowance, YearHolidayAllowance } from "@/models"
import { createEditOperations } from "@/utils/editOperations"
import { computed } from "vue"
import { Required, ToggleButton, defineComponent, getUnique, requiredProp, type ReactiveComponent } from "vue-utils"
import createInlineEditList from "../InlineEditList"
import AllowanceInput from "./AllowanceInput"
import HolidayYearForm from "./HolidayYearForm"
import { useUnusedYears } from "./composables"

interface Props {
	allowance: HolidayAllowance
	overrides: YearHolidayAllowance[]
	isHolidayApprover: boolean

	readonly: boolean

	setAllowance(allowance: HolidayAllowance): void
	setOverrides(overrides: YearHolidayAllowance[]): void
	setIsHolidayApprover(isHolidayApprover: boolean): void
}

const HolidayOverrideList = createInlineEditList<YearHolidayAllowance, number>()

const HolidaySettings: ReactiveComponent<Props> = (props) => {
	const overrides = $computed(() => [...props.overrides].sort((o1, o2) => o1.year - o2.year))

	const usedYears = computed(() => getUnique(overrides.map((override) => override.year)))
	const remainingYears = $(useUnusedYears(usedYears))

	const holidaySettings = useCoreDataValue("holidaySettings")
	const defaultAllowance = $computed(() =>
		typeof holidaySettings.defaultAllowance === "number" ? holidaySettings.defaultAllowance : 0
	)

	return () => (
		<div class="flex flex-col spacing-3">
			<div class="flex flex-wrap" style={{ columnGap: "3.5rem", rowGap: "1.5rem" }}>
				<label>
					<Required label="Holiday Allowance" />
					<AllowanceInput
						allowance={props.allowance}
						setAllowance={props.setAllowance}
						defaultDays={defaultAllowance}
						readonly={props.readonly}
					/>
				</label>
				<label>
					<Required label="Can Approve Holidays?" />
					<ToggleButton
						toggled={props.isHolidayApprover}
						setToggled={props.setIsHolidayApprover}
						disabled={props.readonly}
					/>
				</label>
			</div>

			<hr />

			<div>
				<div>Holiday Allowance Overrides</div>
				<HolidayOverrideList
					entities={overrides}
					remainingOptions={remainingYears}
					getKey={(override) => override.year}
					columnHeaders={["Year", "Allowance"]}
					formGrid="auto auto"
					Form={(formProps) => <HolidayYearForm {...formProps} defaultAllowance={defaultAllowance} />}
					createData={(year) => ({ year, allowance: props.allowance })}
					{...createEditOperations(
						() => props.overrides,
						props.setOverrides,
						(o) => o.year
					)}
					addText="Add Override"
					noneText="No holiday allowance overrides have been specified for this user"
					readonly={props.readonly}
				/>
			</div>
		</div>
	)
}

export default defineComponent(HolidaySettings, {
	allowance: requiredProp(String, Number),
	overrides: requiredProp(Array),
	isHolidayApprover: requiredProp(Boolean),
	readonly: requiredProp(Boolean),
	setAllowance: requiredProp(Function),
	setOverrides: requiredProp(Function),
	setIsHolidayApprover: requiredProp(Function),
})
