import type { ApiTypeOf, DefaultRuleSetTimes, Id, TimeSheetEntry, UserMetadata } from "@/models"
import {
	RequestMethod,
	httpJsonRequest,
	httpJsonRequestJsonResponse,
	httpRequest,
	httpRequestJsonResponse,
} from "@/services/httpService"
import { mapApiEntry } from "@/services/timesheetsService"
import { parseDuration, parseTime } from "@/utils/timeUtils"
import type { Duration, Instant, LocalDate } from "@js-joda/core"

export const createTimeSheet = async (timeSheet: TimeSheetEntry): Promise<TimeSheetEntry> => {
	const result = await httpJsonRequestJsonResponse<TimeSheetEntry>("/api/timesheets", timeSheet, RequestMethod.POST)
	return mapApiEntry(result)
}

export const approveTimeSheet = async (timeSheetId: Id): Promise<TimeSheetEntry> => {
	const response = await httpRequestJsonResponse<TimeSheetEntry>(
		`/api/timesheets/approve/${timeSheetId}`,
		RequestMethod.POST
	)
	return mapApiEntry(response)
}

export const unApproveTimeSheet = async (timeSheetId: Id): Promise<void> => {
	await httpRequest(`/api/timesheets/un-approve/${timeSheetId}`, RequestMethod.POST)
}

export const deleteTimeSheet = async (timeSheetId: Id): Promise<void> => {
	await httpRequest(`/api/timesheets/${timeSheetId}`, RequestMethod.DELETE)
}

export enum OverrideType {
	ClockIn = "clockIn",
	ClockOut = "clockOut",
}

export const setTimeSheetSite = async (timeSheetId: Id, siteId: Id): Promise<TimeSheetEntry> => {
	const entry = await httpJsonRequestJsonResponse<TimeSheetEntry>(
		"/api/timesheets/set-site",
		{
			timeSheetId,
			siteId,
		},
		RequestMethod.POST
	)
	return mapApiEntry(entry)
}

export const setTimeSheetActivity = async (timeSheetId: Id, activityId: Id): Promise<TimeSheetEntry> => {
	const entry = await httpJsonRequestJsonResponse<TimeSheetEntry>(
		"/api/timesheets/set-activity",
		{
			timeSheetId,
			activityId,
		},
		RequestMethod.POST
	)
	return mapApiEntry(entry)
}

export const setTimeSheetOverride = async (options: {
	timeSheetId: Id
	type: OverrideType
	time: Instant
}): Promise<TimeSheetEntry> => {
	const response = await httpJsonRequestJsonResponse<TimeSheetEntry>(
		"/api/timesheets/set-override",
		options,
		RequestMethod.POST
	)
	return mapApiEntry(response)
}

export const setTimeSheetBreakOverride = async (options: {
	timeSheetId: Id
	duration: Duration
}): Promise<TimeSheetEntry> => {
	const response = await httpJsonRequestJsonResponse<TimeSheetEntry>(
		"/api/timesheets/set-break-override",
		options,
		RequestMethod.POST
	)
	return mapApiEntry(response)
}

export const updateTimeSheetManagerNotes = async (timeSheetId: Id, notes: string): Promise<TimeSheetEntry> => {
	const entry = await httpJsonRequestJsonResponse<TimeSheetEntry>(
		"/api/timesheets/set-manager-notes",
		{
			entryId: timeSheetId,
			notes,
		},
		RequestMethod.POST
	)
	return mapApiEntry(entry)
}

export const queryTimeSheetsForUser = async (options: {
	userId: Id
	from: Instant
	to: Instant
}): Promise<TimeSheetEntry[]> => {
	const result = await httpJsonRequestJsonResponse<TimeSheetEntry[]>(
		"/api/timesheets/get-entries-by-user",
		options,
		RequestMethod.POST
	)
	return result.map(mapApiEntry)
}

export const queryRelevantUsers = async (options: {
	siteIds: Id[]
	from: Instant
	to: Instant
	showApproved: boolean
}): Promise<UserMetadata[]> => {
	return await httpJsonRequestJsonResponse("/api/timesheets/query-relevant-users", options, RequestMethod.POST)
}

type ApiDefaultRuleSetTimes = ApiTypeOf<DefaultRuleSetTimes>

export const mapDefaultRuleSetTimes = (times: ApiDefaultRuleSetTimes): DefaultRuleSetTimes => ({
	clockIn: times.clockIn ? parseTime(times.clockIn) : null,
	clockOut: times.clockOut ? parseTime(times.clockOut) : null,
	break: times.break ? parseDuration(times.break) : null,
})

export const queryDefaultRuleTimes = async (options: {
	siteId: Id
	activityId: Id
	date: LocalDate
}): Promise<DefaultRuleSetTimes> => {
	const result = await httpJsonRequestJsonResponse<ApiDefaultRuleSetTimes>(
		"/api/timesheets/default-rule-times",
		options,
		RequestMethod.POST
	)
	return mapDefaultRuleSetTimes(result)
}

export interface WeekTimeSheetsReportOptions {
	week: LocalDate
	userIds: Id[]
	siteIds: Id[]
}

export const generateTimeSheetsWeekReport = async (options: WeekTimeSheetsReportOptions): Promise<Response> =>
	await httpJsonRequest("/api/reports/week-time-sheets", options, RequestMethod.POST)
