import { useCurrentYear } from "@/utils/composition/useDate"
import { computed, type ComputedRef, type Ref } from "vue"

export const useUnusedYears = (usedYears: Ref<number[]>, maxYears = 5): ComputedRef<number[]> => {
	const currentYear = useCurrentYear()

	return computed(() => {
		const years = new Set<number>()
		const thisYear = currentYear.value
		for (let i = 0; i < maxYears; i++) {
			years.add(thisYear + i)
		}
		usedYears.value.forEach((year) => years.delete(year))
		return Array.from(years)
	})
}
