import StandardForm from "@/components/StandardForm"
import { HolidayType, TimePoint, type HolidayRequest, type HolidayRequestContext, type Id } from "@/models"
import { useLocalDateInput } from "@/utils/timeUtils"
import { Instant, LocalDate } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { shallowReactive } from "vue"
import { Required, ToggleButton, defineComponent, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"
import ExistingHolidayCalendar from "./ExistingHolidayCalendar"
import { validateUserHolidayRequest } from "./validateRequest"

interface Props {
	userId: Id
	onSubmit(request: HolidayRequest): Promise<void>
	cancel(): void

	context: HolidayRequestContext
}

const formStyles = css`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	width: 100%;

	fieldset {
		display: flex;
		flex-wrap: wrap;
		gap: 2.5rem;
		border-radius: 0.5rem;
		border: 1px solid rgba(0, 0, 0, 0.15);
		padding: 1rem 1rem;
		flex-grow: 1;
		max-width: 24rem;

		legend {
			padding: 0 0.5rem;
			font-weight: 500;
		}
	}

	input[type="date"] {
		min-width: 10rem;
	}

	& > div:first-of-type {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		width: 100%;
	}

	& > div:last-of-type {
		margin-top: 0 !important;
	}

	label {
		font-size: 1rem;
	}
`

const NewRequestForm: ReactiveComponent<Props> = (props) => {
	const data = shallowReactive({
		userDescription: "",

		startDate: null as LocalDate | null,
		startPoint: TimePoint.StartOfDay,

		endDate: null as LocalDate | null,
		endPoint: TimePoint.EndOfDay,
	})

	async function handleSubmitClick() {
		const request: HolidayRequest = {
			id: 0,
			user: null,
			userId: props.userId,
			approval: null,
			dateCreated: Instant.now(),
			dateRange: {
				start: {
					date: data.startDate!,
					timePoint: data.startPoint,
				},
				end: {
					date: data.endDate!,
					timePoint: data.endPoint,
				},
			},
			userDescription: data.userDescription,
			holidayType: HolidayType.Staff,
		}
		const validation = await validateUserHolidayRequest(request)
		if (!validation) {
			return
		}
		await props.onSubmit(request)
	}

	return () => (
		<section>
			<h2 style={{ "margin-top": "0.5rem" }}>New Holiday Request</h2>
			<hr />
			<StandardForm class={formStyles} submit={handleSubmitClick} cancel={props.cancel} showDivider={false}>
				<div>
					<fieldset>
						<legend>Start Date</legend>
						<label>
							<Required label="Date" />
							<input
								type="date"
								min={props.context.minimumDate.toHTMLInput()}
								max={(data.endDate ?? props.context.maximumDate).toHTMLInput()}
								value={data.startDate?.toHTMLInput()}
								onChange={useLocalDateInput((date) => (data.startDate = date))}
								required
							/>
						</label>
						<label>
							<Required label="Starts at midday?" />
							<ToggleButton
								toggled={data.startPoint === TimePoint.MidDay}
								setToggled={(t) => (data.startPoint = t ? TimePoint.MidDay : TimePoint.StartOfDay)}
								disabled={
									!!data.startDate &&
									!!data.endDate &&
									data.startDate.equals(data.endDate) &&
									data.endPoint === TimePoint.MidDay
								}
							/>
						</label>
					</fieldset>
					<fieldset>
						<legend>End Date</legend>
						<label>
							<Required label="Date" />
							<input
								type="date"
								min={(data.startDate ?? props.context.minimumDate).toHTMLInput()}
								max={props.context.maximumDate.toHTMLInput()}
								value={data.endDate?.toHTMLInput()}
								onChange={useLocalDateInput((date) => (data.endDate = date))}
								required
							/>
						</label>
						<label>
							<Required label="Ends at midday?" />
							<ToggleButton
								toggled={data.endPoint === TimePoint.MidDay}
								setToggled={(t) => (data.endPoint = t ? TimePoint.MidDay : TimePoint.EndOfDay)}
								disabled={
									!!data.startDate &&
									!!data.endDate &&
									data.startDate.equals(data.endDate) &&
									data.startPoint === TimePoint.MidDay
								}
							/>
						</label>
					</fieldset>
				</div>
				<label>
					Description of Request
					<textarea
						rows={3}
						value={data.userDescription}
						onInput={useOnInput((desc) => (data.userDescription = desc))}
					/>
				</label>
			</StandardForm>
			<br />
			<ExistingHolidayCalendar context={props.context} />
		</section>
	)
}

export default defineComponent(NewRequestForm, {
	userId: requiredProp(Number),
	onSubmit: requiredProp(Function),
	cancel: requiredProp(Function),
	context: requiredProp(Object),
})
