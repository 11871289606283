import { DayPartType } from "@/models"
import { LocalDate, Month } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import type { CalendarEvent } from "./types"
import CalendarEventDisplay from "./CalendarEventDisplay"

interface Props {
	month: Month
	date: LocalDate
	events: CalendarEvent[]
	previousEvents: CalendarEvent[]
}

const dayStyles = css`
	display: grid;
	grid-template-rows: auto 1fr;
	overflow-y: hidden;

	padding: 0;
	min-height: 4.5rem;
	border-top: thin solid rgba(0, 0, 0, 0.2);
	border-right: thin solid rgba(0, 0, 0, 0.2);

	& > div:first-child {
		padding: 0.25rem;
		text-align: right;
		font-size: 0.92rem;
	}

	& > ol {
		display: grid;
		grid-template-columns: 1fr 1fr;

		padding: 0;
		margin: 0;
		list-style: none;

		& > li {
			padding: 0;
			font-size: 0.92rem;
		}
	}

	&[data-ismonth="false"] {
		color: #a0a0a0;
	}
	&:last-child {
		border-right: none;
	}
`

const CalendarDay: ReactiveComponent<Props> = (props) => {
	//Used for days which aren't in the current month but still visible
	const isDayInMonth = computed(() => props.date.month().equals(props.month))

	function getEvent(events: CalendarEvent[], type: DayPartType): CalendarEvent | null {
		return events.filter((e) => e.type === type).at(0) ?? null
	}

	return () => (
		<div class={dayStyles} data-ismonth={String(isDayInMonth.value)}>
			<div>{props.date.dayOfMonth()}</div>
			<ol>
				<li>
					<CalendarEventDisplay
						event={getEvent(props.events, DayPartType.Morning)}
						previousEvent={getEvent(props.previousEvents, DayPartType.Afternoon)}
					/>
				</li>
				<li>
					<CalendarEventDisplay
						event={getEvent(props.events, DayPartType.Afternoon)}
						previousEvent={getEvent(props.events, DayPartType.Morning)}
					/>
				</li>
			</ol>
		</div>
	)
}

export default defineComponent(CalendarDay, {
	month: requiredProp(Month),
	date: requiredProp(Object),
	events: requiredProp(Array),
	previousEvents: requiredProp(Array),
})
