import type { HolidayDate } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import { convert, type LocalDate } from "@js-joda/core"

export const formatDate = (date: LocalDate) =>
	convert(date).toDate().toLocaleDateString(undefined, {
		day: "numeric",
		month: "short",
		weekday: "short",
	})

export function formatHolidayDate(day: HolidayDate) {
	return `${formatDate(day.date)} (${formatEnum(day.timePoint)})`
}
