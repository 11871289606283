import { defineComponent, type ReactiveComponent } from "vue-utils"
import LargeButton from "../shared/clock/components/LargeButton"
import BasicScreen from "../shared/clock/screens/BasicScreen"
import { useSelfServiceStore } from "./store"

const Welcome: ReactiveComponent = () => {
	const store = useSelfServiceStore()

	return () => (
		<BasicScreen title="Self Service">
			<div>
				<p style={{ margin: "2rem 0 4rem" }}>
					The self service functionality allows you to use your own device to log your time sheets.
				</p>
				<LargeButton
					color="primary"
					onClick={() => {
						void store.reloadDetails()
						store.showOverview()
					}}
				>
					Get Started
				</LargeButton>
			</div>
		</BasicScreen>
	)
}

export default defineComponent(Welcome)
