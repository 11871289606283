import { css } from "vite-css-in-js"
import type { FunctionalComponent } from "vue"

const entryStyles = css`
	margin: 0;
	margin-top: 2rem;

	h3 {
		font-size: 1.1em;
		font-weight: 600;
		margin: 0;
		margin-bottom: 0.3rem;
	}
	h4 {
		font-size: 1.1em;
		font-weight: 500;
		margin: 0;
	}
`

interface Props {
	name: string
	value: string
}

const Entry: FunctionalComponent<Props> = ({ name, value }) => (
	<div class={entryStyles}>
		<h3>{name}:</h3>
		<h4>{value}</h4>
	</div>
)

export default Entry
