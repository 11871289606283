import TradeInformation from "@/components/misc/TradeInformation"
import { useTrades } from "@/data/coreDataStore"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import {
	HolidayDayType,
	Permission,
	PlannerHolidayType,
	type HolidayAllowance,
	type Id,
	type PlannerHolidayDay,
	type UserMetadata,
} from "@/models"
import { LocalDate } from "@js-joda/core"
import { debouncedRef, toRef } from "@vueuse/core"
import { watch } from "vue"
import { Link, defineComponent, ensureLoadingHasResult, type ReactiveComponent } from "vue-utils"
import HolidayCache from "./HolidayCache"
import PlannerCell from "./PlannerCell"
import getWeekDatesInMonth from "./monthDates"
import useHolidaysPlannerStore from "./store"
import { useTableSorting } from "./tableSorting"

const PlannerTypesPriority = new Map<PlannerHolidayType, number>([
	[PlannerHolidayType.Company, 0],
	[PlannerHolidayType.Approved, 1],
	[PlannerHolidayType.Pending, 2],
])

const PlannerTableContent: ReactiveComponent = () => {
	const store = useHolidaysPlannerStore()
	const trades = useTrades()
	const days = $computed(() => getWeekDatesInMonth(store.month, store.year))
	const loggedInUser = useLoggedInUser()

	const cache = new HolidayCache()
	function updateCache() {
		if (store.data.type === "done") {
			cache.clear()
			for (const entry of ensureLoadingHasResult(store.data)) {
				cache.addStaffHolidays(entry.user, ...entry.holidays)
			}
		}
	}
	updateCache()
	watch(store, updateCache)

	const sorting = useTableSorting()
	const userSearch = debouncedRef(toRef(store, "userSearch"), 750)

	const sortedUsers = $computed(() => {
		let users = ensureLoadingHasResult(store.data)
		const search = userSearch.value.trim().toLowerCase()
		if (search.length > 0) {
			users = users.filter((user) => {
				const name1 = `${user.user.firstName} ${user.user.lastName}`.toLowerCase()
				const name2 = `${user.user.lastName} ${user.user.firstName}`.toLowerCase()

				return name1.includes(search) || name2.includes(search)
			})
		}

		return sorting.sortEntries(users, store.sortBy, store.sortDirection)
	})

	function renderTrade(user: UserMetadata) {
		if (user.tradeId === null) {
			return "-"
		}
		const trade = trades.get(user.tradeId) ?? null
		return <TradeInformation trade={trade}>{trade?.name}</TradeInformation>
	}

	function renderTableCell(date: LocalDate, userId: Id) {
		const holidays = cache.getHolidays(userId, date)
		const morning = pickCellData(holidays, HolidayDayType.Morning, HolidayDayType.FullDay)
		const afternoon = pickCellData(holidays, HolidayDayType.Afternoon, HolidayDayType.FullDay)

		if (!morning && !afternoon) {
			return <td key={date.toEpochDay() + 4} />
		}
		return <PlannerCell key={date.toEpochDay() + 4} morning={morning} afternoon={afternoon} />
	}

	function pickCellData(holidays: PlannerHolidayDay[], ...types: HolidayDayType[]) {
		if (holidays.length === 0) {
			return undefined
		}
		const valid = holidays.filter((h) => types.includes(h.dayType))
		if (valid.length === 0) {
			return undefined
		}
		if (valid.length === 1) {
			return valid[0]
		}
		valid.sort((h1, h2) => {
			if (h1.dayType !== HolidayDayType.FullDay && h2.dayType === HolidayDayType.FullDay) {
				return -1
			} else if (h1.dayType === HolidayDayType.FullDay && h2.dayType !== HolidayDayType.FullDay) {
				return 1
			}
			return PlannerTypesPriority.get(h1.type)! - PlannerTypesPriority.get(h2.type)!
		})
		return valid[0]
	}

	function userNameText(user: UserMetadata) {
		return `${user.lastName}, ${user.firstName}`
	}

	function formatAllowance(allowance: HolidayAllowance, unlimited = "Unlimited"): string {
		if (typeof allowance === "number") {
			return allowance === 1 ? "1 Day" : `${allowance} Days`
		}
		return unlimited
	}

	return () =>
		sortedUsers.map((data) => (
			<tr key={data.user.id}>
				<td>
					{loggedInUser.hasPermission(Permission.StaffAbsenceCalendar) ? (
						<Link href={`/holidays/staff-absence-calendar/${data.user.id}`} title="Click to view personal calendar">
							{userNameText(data.user)}
						</Link>
					) : (
						userNameText(data.user)
					)}
				</td>
				<td>{renderTrade(data.user)}</td>
				<td>{formatAllowance(data.totalAllowance)}</td>
				<td>{formatAllowance(data.usedAllowance, "0 Days")}</td>
				<td>{formatAllowance(data.remainingAllowance)}</td>
				{days.map((date) => renderTableCell(date, data.user.id))}
			</tr>
		))
}

export default defineComponent(PlannerTableContent)
