import type { ApiTypeOf, Coordinate } from "@/models"
import { sendHttpRequest } from "@/services/httpHelper"
import { proxyProp } from "@/utils/proxyProp"
import { Instant } from "@js-joda/core"
import { defineStore } from "pinia"
import { defineGlobals, ensureLoadingHasResult, piniaLoadingState, type Loading } from "vue-utils"

interface BuildInfo {
	version: string
	date: Instant
}

export interface SystemConfig {
	msal: MSALConfig
	build: BuildInfo
	timeZone: string
	mapLocation: Coordinate
	mapZoom: number
	defaultSiteRadius: number
}

export interface MSALConfig {
	clientId: string
	authority: string
	authorities: string[]
	scopes: string[]
}

type OnLoadListener = (config: SystemConfig) => void

type State = Loading<SystemConfig> & {
	listeners: OnLoadListener[]
	updateAvailable: boolean
	updateTimerId: number | null
}

const loadSystemConfig = async (): Promise<SystemConfig> => {
	const result = await sendHttpRequest("/api/config")
	const jsonResult = (await result.json()) as ApiTypeOf<SystemConfig>
	return {
		...jsonResult,
		build: {
			...jsonResult.build,
			date: Instant.parse(jsonResult.build.date),
		},
	}
}

const useSystemConfigStore = defineStore("systemConfig", {
	state: (): State =>
		({
			...piniaLoadingState<SystemConfig>(),
			listeners: [],
			updateAvailable: false,
			updateTimerId: null,
		}) as State,
	actions: {
		addOnLoadListener(onLoad: OnLoadListener) {
			this.listeners = [...this.listeners, onLoad]
			if (this.type === "done") {
				onLoad(this.result)
			}
		},
		async queryData() {
			const result = await loadSystemConfig()

			for (const listener of this.listeners) {
				listener(result)
			}

			if (this.type === "done") {
				const currentBuildDate = this.result.build.date
				const newBuildDate = result.build.date

				if (newBuildDate.isAfter(currentBuildDate)) {
					this.updateAvailable = true
				}
			}
			return result
		},
		async checkForUpdate(): Promise<boolean> {
			await this.queryData()
			return this.updateAvailable
		},
		async updateApp(): Promise<void> {
			try {
				await this.clearCaches()
			} catch (e) {
				console.error(e)
			}

			location.reload(true)
		},
		async clearCaches() {
			try {
				const keys = await window.caches.keys()
				await Promise.all(keys.map((key) => window.caches.delete(key)))
			} catch (e) {
				console.error(e)
			}
			if (navigator.serviceWorker) {
				const registrations = await navigator.serviceWorker.getRegistrations()
				for (const registration of registrations) {
					try {
						await registration.unregister()
					} catch (e) {
						console.error(e)
					}
				}
			}
		},
	},
})

export function useSystemConfig() {
	const store = useSystemConfigStore()
	return proxyProp(() => ensureLoadingHasResult(store))
}

defineGlobals({
	useSystemConfigStore,
})

export default useSystemConfigStore
