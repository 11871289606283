import { css } from "vite-css-in-js"
import { nextTick, ref } from "vue"
import { defineComponent, optionalProp, requiredProp, type Component, type ReactiveComponent } from "vue-utils"

interface Props {
	displayText: string
	renderContent: Component<{
		hide(): void
	}>
	autoFocus?: boolean
	readonly?: boolean
	title?: string
}

const editCellStyles = css`
	padding: 0 !important;
	font-size: 1em;
`

const editButtonStyles = css`
	margin: 0;
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
	font-size: 1em;
	background-color: #acf0c3;
	cursor: pointer;
`

const DisplayEdit: ReactiveComponent<Props> = (props) => {
	const { displayText, title = "Click to Edit", autoFocus = true, readonly = false } = $(props)

	let editing = $ref(false)
	const tdRef = ref<HTMLElement>()

	function startEdit() {
		editing = true

		void nextTick(() => {
			const element = tdRef.value
			if (element && autoFocus) {
				const firstFormInput = element.querySelector("input,select,textarea") as HTMLOrSVGElement | null
				firstFormInput?.focus()
			}
		})
	}

	function renderContent() {
		if (readonly) {
			return displayText
		}
		if (editing) {
			return <props.renderContent hide={() => (editing = false)} />
		}
		return (
			<button
				key="openEditButton"
				class={editButtonStyles}
				onClick={startEdit}
				onFocus={startEdit}
				title={title ?? "Click to Edit"}
			>
				{displayText}
			</button>
		)
	}

	return () => (
		<td ref={tdRef} class={editCellStyles}>
			{renderContent()}
		</td>
	)
}

export default defineComponent(DisplayEdit, {
	displayText: requiredProp(String),
	renderContent: requiredProp(Function, Object),
	autoFocus: optionalProp(Boolean),
	readonly: optionalProp(Boolean),
	title: optionalProp(String),
})
