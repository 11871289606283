import type { Trade } from "@/models"
import createCrudEndpoints from "./createCrudEndpoint"

const endpoints = createCrudEndpoints<Trade>("/api/trades")

export const listTrades = endpoints.list
export const getTrade = endpoints.get
export const createTrade = endpoints.create
export const editTrade = endpoints.edit
export const deleteTrade = endpoints.delete
