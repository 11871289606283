import { formatDuration } from "@/utils/timeUtils"
import { Duration } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	hoursLogged: Duration
	hoursPaid: Duration
}

const rowStyles = css`
	& > td {
		border: none !important;
		font-weight: 600;
		color: #9b2626;
	}
	& > td:nth-child(16) {
		border: 1px solid var(--color-border) !important;
		border-left: none !important;
		border-top: none !important;
	}
	& > td:nth-child(15) {
		border: 1px solid var(--color-border) !important;
		border-top: none !important;
		margin-left: -1px;
	}
`

const TotalHoursRow: ReactiveComponent<Props> = (props) => {
	return () => (
		<tr class={rowStyles}>
			{Array.from({ length: 14 }, (_, i) => (
				<td key={i}></td>
			))}
			<td key="hours-logged">{formatDuration(props.hoursLogged)}</td>
			<td key="hours-paid">{formatDuration(props.hoursPaid)}</td>
			<td key={17}></td>
			<td key={18}></td>
		</tr>
	)
}

export default defineComponent(TotalHoursRow, {
	hoursLogged: requiredProp(Duration),
	hoursPaid: requiredProp(Duration),
})
