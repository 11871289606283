import Icon from "@/components/Icon"
import type { Nullable, User } from "@/models"
import { formatDateInUserTimeZone } from "@/utils/timeUtils"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import type { Instant } from "@js-joda/core"
import { css } from "vite-css-in-js"
import type { ReactiveComponent } from "vue-utils"
import { defineComponent, requiredProp } from "vue-utils"

interface Props {
	approvedBy: Nullable<User>
	approvalDate: Nullable<Instant>
}

const approvedStyles = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	& > div {
		display: flex;
		align-items: center;
		gap: 0.25rem;
	}

	small {
		font-size: 0.92em;
	}

	svg {
		color: #2b994c;
		font-weight: bold;
		display: flex;
	}
`

const unapprovedStyles = css`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.25rem;

	small {
		font-size: 0.92em;
	}

	svg {
		color: #962727;
		font-weight: bold;
	}
`

const ApprovedBy: ReactiveComponent<Props> = (props) => {
	const { approvedBy, approvalDate } = $(props)

	const approvedByName = $computed(() => {
		if (!approvalDate) {
			return "Unapproved"
		}
		return approvedBy ? `${approvedBy.lastName}, ${approvedBy.firstName}` : "Unknown"
	})

	const approvalTimeStr = $computed(() => {
		if (!approvalDate) {
			return ""
		}
		return formatDateInUserTimeZone(approvalDate)
	})

	return () => (
		<td>
			{approvalDate !== null ? (
				<div class={approvedStyles}>
					<div>
						<small>{approvalTimeStr}</small>
						<Icon icon={faCheck} />
					</div>
					<small>{approvedByName}</small>
				</div>
			) : (
				<div class={unapprovedStyles}>
					<small>Not Approved</small>
					<Icon icon={faTimes} />
				</div>
			)}
		</td>
	)
}

export default defineComponent(ApprovedBy, {
	approvedBy: requiredProp(Object, null),
	approvalDate: requiredProp(Object, null),
})
