import type { Id, Site } from "@/models"
import { SiteListType, useSiteList } from "@/utils/composition/useSiteList"
import { createEditOperations } from "@/utils/editOperations"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import createInlineEditList from "../InlineEditList"
import SiteForm from "./SiteForm"

interface Props {
	userId: Id
	siteIds: Id[]
	setSiteIds: (sites: Id[]) => void
	readonly: boolean
}

const mapId = (siteId: Id) => ({ siteId })
const mapObj = ({ siteId }: { siteId: Id }) => siteId

const UserSitesList = createInlineEditList<{ siteId: Id }, Site>()
const UserSites: ReactiveComponent<Props> = (props) => {
	const allSites = useSiteList(SiteListType.NonArchived)
	const unusedSites = $computed(() => allSites.value.filter((site) => !props.siteIds.includes(site.id)))

	return () => (
		<UserSitesList
			entities={props.siteIds.map(mapId)}
			remainingOptions={unusedSites}
			getKey={(x) => x.siteId}
			columnHeaders={["Site"]}
			formGrid="auto"
			Form={SiteForm}
			createData={(site) => ({ siteId: site.id })}
			{...createEditOperations(
				() => props.siteIds.map(mapId),
				(x) => props.setSiteIds(x.map(mapObj)),
				mapObj
			)}
			addText="Add Site"
			noneText="No sites have been assigned to this user"
			readonly={props.readonly}
		/>
	)
}

export default defineComponent(UserSites, {
	userId: requiredProp(Number),
	siteIds: requiredProp(Array),
	setSiteIds: requiredProp(Function),
	readonly: requiredProp(Boolean),
})
