import { css } from "vite-css-in-js"
import { renderSlot, type FunctionalComponent, type HTMLAttributes } from "vue"

const frostedGlassStyles = css`
	padding: 1.5rem;
	border-radius: 1.5rem;
	background-color: rgba(255, 255, 255, 0.666);

	display: flex;
	flex-direction: column;

	box-shadow: 8px 8px 5px rgba(0, 0, 0, 0.35);

	width: 21rem;
	height: 32rem;
	margin: 1rem;

	font-size: 1.08rem;

	h2 {
		text-align: center;
		margin-bottom: 0;
		margin-top: 0.5rem;
		font-size: 1.5em;
	}
	option {
		font-size: 1rem;
	}
`

const FrostedGlass: FunctionalComponent<HTMLAttributes> = (props, { slots }) => {
	return (
		<div class={frostedGlassStyles} {...props}>
			{renderSlot(slots, "default")}
		</div>
	)
}

export default FrostedGlass
