import { parsingString, type RouterQueryRefOptions } from "vue-utils"
import type { SelectedSite } from "./components/SiteSelector"

export const SiteQuery: RouterQueryRefOptions<SelectedSite> = {
	parse: parsingString((query) => {
		if (query === "all") {
			return "all"
		}
		return Number.parseInt(query)
	}),
	stringify: (site) => site.toString(),
}
