import StandardForm from "@/components/StandardForm"
import { BasicSelect } from "@/components/basic-form"
import { useActivities, useActivity, useCoreDataValue, useSite } from "@/data/coreDataStore"
import usePopups from "@/data/popupsStore"
import { saveHolidaySettings } from "@/services/holidaySettingsService"
import { SiteListType, useSiteList } from "@/utils/composition/useSiteList"
import { css } from "vite-css-in-js"
import { reactive } from "vue"
import { Alert, Required, ShadowContainer, defineComponent, type ReactiveComponent } from "vue-utils"
import AllowanceInput from "../users/holiday/AllowanceInput"

const formStyles = css`
	display: flex;
	flex-direction: column;
	gap: 2rem;

	legend {
		font-weight: 600;
	}

	fieldset {
		border-radius: 0.5rem;
		border: thin solid rgba(0, 0, 0, 0.2);
		padding: 1.25rem 1rem;

		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(100%, 21rem), 1fr));
		align-items: center;
		gap: 1.5rem;

		label {
			margin-left: 1px;
		}
	}
`

const HolidaySettingsPage: ReactiveComponent = () => {
	const popups = usePopups()
	const activities = useActivities()
	const holidaySettings = useCoreDataValue("holidaySettings")
	const editingSettings = reactive({
		...holidaySettings,
	})

	const chSite = useSite(() => editingSettings.companyHolidaySiteId)
	const chActivity = useActivity(() => editingSettings.companyHolidayActivityId)
	const chSites = useSiteList(SiteListType.Selection, {
		selectedSite: chSite,
	})

	const hSite = useSite(() => editingSettings.holidaySiteId)
	const hActivity = useActivity(() => editingSettings.holidayActivityId)
	const hSites = useSiteList(SiteListType.Selection, {
		selectedSite: hSite,
	})

	async function saveSettings() {
		const newSettings = await saveHolidaySettings(editingSettings)
		Object.assign(holidaySettings, newSettings)
		Object.assign(editingSettings, newSettings)

		void popups.showConfirmPopup(() => <Alert title="Success">Successfully saved holiday settings</Alert>)
	}

	return () => (
		<ShadowContainer>
			<StandardForm
				showDivider={false}
				style={{ maxWidth: "52rem", margin: "0 auto" }}
				submit={{
					text: "Save",
					onClick: saveSettings,
				}}
				cancel={{
					text: "Revert",
					onClick: () => {
						Object.assign(editingSettings, holidaySettings)
					},
				}}
			>
				<div class={formStyles}>
					<fieldset>
						<legend>Company Holiday Settings</legend>
						<label>
							Activity
							<BasicSelect
								options={Array.from(activities.values())}
								value={chActivity.value}
								setValue={(newActivity) => (editingSettings.companyHolidayActivityId = newActivity?.id ?? null)}
								defaultText="No activity"
							/>
						</label>
						<label>
							Site
							<BasicSelect
								options={chSites.value}
								value={chSite.value}
								setValue={(newSite) => (editingSettings.companyHolidaySiteId = newSite?.id ?? null)}
								defaultText="No site"
							/>
						</label>
					</fieldset>
					<fieldset>
						<legend>Staff Holiday Settings</legend>
						<label>
							Activity
							<BasicSelect
								options={Array.from(activities.values())}
								value={hActivity.value}
								setValue={(newActivity) => (editingSettings.holidayActivityId = newActivity?.id ?? null)}
								defaultText="No activity"
							/>
						</label>
						<label>
							Site
							<BasicSelect
								options={hSites.value}
								value={hSite.value}
								setValue={(newSite) => (editingSettings.holidaySiteId = newSite?.id ?? null)}
								defaultText="No site"
							/>
						</label>
						<label>
							<Required label="Holiday Length Warning" />
							<input v-model={editingSettings.totalWorkingDaysWarning} type="number" min={0} max={365} required />
						</label>
						<label>
							<Required label="Days Notice Required" />
							<input v-model={editingSettings.minimumDaysNoticeWarning} type="number" min={0} max={365} required />
						</label>
						<label>
							Default Holiday Allowance
							<AllowanceInput
								allowance={editingSettings.defaultAllowance}
								defaultDays={
									typeof holidaySettings.defaultAllowance === "number" ? holidaySettings.defaultAllowance : 0
								}
								setAllowance={(newAllowance) => (editingSettings.defaultAllowance = newAllowance)}
							/>
						</label>
					</fieldset>
				</div>
			</StandardForm>
		</ShadowContainer>
	)
}

export default defineComponent(HolidaySettingsPage)
