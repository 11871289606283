import Icon from "@/components/Icon"
import usePopups, { ConfirmResult } from "@/data/popupsStore"
import { faWarning } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { Alert } from "vue-utils"

const popupStyles = css`
	display: flex;
	align-items: center;
	gap: 0.5rem;

	svg {
		font-size: 1.5rem;
		color: var(--color-warning);
	}
`

const showDeleteConfirmation = async (noun: string): Promise<boolean> => {
	const popups = usePopups()
	const result = await popups.showConfirmPopup(() => (
		<Alert title="Confirm Deletion">
			<div class={popupStyles}>
				<Icon icon={faWarning} />
				<span>Are you sure you wish to delete this {noun}?</span>
			</div>
		</Alert>
	))

	return result === ConfirmResult.Confirm
}

export default showDeleteConfirmation
