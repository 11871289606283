import type { AdjustedTime, ApiTypeOf, TimeSheetEntry } from "@/models"
import { parseDuration } from "@/utils/timeUtils"
import { Instant } from "@js-joda/core"

export const mapAdjustedTime = (entry: ApiTypeOf<AdjustedTime>): AdjustedTime => ({
	entered: entry.entered ? Instant.parse(entry.entered) : null,
	autoAdjusted: entry.autoAdjusted ? Instant.parse(entry.autoAdjusted) : null,
	override: entry.override ? Instant.parse(entry.override) : null,
	location: entry.location,
})

export const mapApiEntry = (entry: ApiTypeOf<TimeSheetEntry>): TimeSheetEntry => ({
	...entry,
	clockIn: mapAdjustedTime(entry.clockIn),
	clockOut: mapAdjustedTime(entry.clockOut),
	break: {
		adjusted: entry.break.adjusted ? parseDuration(entry.break.adjusted) : null,
		calculated: parseDuration(entry.break.calculated),
	},
	approvalDate: entry.approvalDate ? Instant.parse(entry.approvalDate) : null,
	timeLoggedIncludingBreaks: entry.timeLoggedIncludingBreaks ? parseDuration(entry.timeLoggedIncludingBreaks) : null,
	timeLoggedExcludingBreaks: entry.timeLoggedExcludingBreaks ? parseDuration(entry.timeLoggedExcludingBreaks) : null,
})
