import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { LocalDate } from "@js-joda/core"
import { onClickOutside } from "@vueuse/core"
import { css } from "vite-css-in-js"
import { ref, type HTMLAttributes } from "vue"
import { defineComponent, delay, optionalProp, renderSlot, requiredProp, type ReactiveComponent } from "vue-utils"
import PlainIconButton from "../PlainIconButton"

interface Props {
	year: number
	setYear(year: number): void

	minYear?: number
	maxYear?: number

	onBlur?(): void
}

const containerStyles = css`
	background-color: white;
	width: 25rem;

	display: flex;
	flex-direction: column;

	border-radius: 0.25rem;
	overflow: hidden;
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-radius: 0.5rem;

	font-size: 0.92rem;

	box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
`

const headerStyles = css`
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 0.25rem;
	background-color: var(--color-fill-darker);
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
	width: 100%;

	& > span {
		width: 5rem;
		text-align: center;
	}
`

const YearNavigateContainer: ReactiveComponent<Props, HTMLAttributes> = (props, { attrs, slots }) => {
	const containerRef = ref<HTMLElement>()
	async function triggerBlur() {
		await delay(1)
		props.onBlur?.()
	}

	onClickOutside(containerRef, () => void triggerBlur())

	return () => (
		<div ref={containerRef} class={containerStyles} {...attrs}>
			<div class={headerStyles}>
				<PlainIconButton
					aria-label="Previous Year"
					icon={faChevronLeft}
					disabled={typeof props.minYear === "number" && props.year <= props.minYear}
					onClick={() => props.setYear(props.year - 1)}
				/>
				<span
					aria-label="Selected Year"
					style={{ fontWeight: props.year === LocalDate.now().year() ? "bold" : undefined }}
				>
					{props.year}
				</span>
				<PlainIconButton
					aria-label="Next Year"
					icon={faChevronRight}
					disabled={typeof props.maxYear === "number" && props.year >= props.maxYear}
					onClick={() => props.setYear(props.year + 1)}
				/>
			</div>
			{renderSlot(slots)}
		</div>
	)
}

export default defineComponent(YearNavigateContainer, {
	year: requiredProp(Number),
	setYear: requiredProp(Function),

	minYear: optionalProp(Number),
	maxYear: optionalProp(Number),

	onBlur: optionalProp(Function),
})
