import type { IPagedResult, RejectedClockEvent } from "@/models"
import { httpRequestJsonResponse, RequestMethod } from "@/services/httpService"

export interface PagedRejectedClockEvents extends IPagedResult {
	events: RejectedClockEvent[]
}

export const getRejectedClockEvents = async (page = 1) =>
	await httpRequestJsonResponse<PagedRejectedClockEvents>(
		`/api/clock-events/rejected-events?page=${page}`,
		RequestMethod.GET
	)
