import { createMappedStringColumn, stringFilter, stringSort } from "@/components/data/DataTable"
import { useActivities } from "@/data/coreDataStore"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import { compareActivities, type Activity } from "@/models"
import Permission from "@/models/Permission"
import { createActivity, deleteActivity, editActivity } from "@/services/activitiesService"
import { css } from "vite-css-in-js"
import type { HTMLAttributes } from "vue"
import { ShadowContainer, calculateForegroundColor, defineComponent, type ReactiveComponent } from "vue-utils"
import createCrudTable from "../CrudTable"
import FormContent from "./Form"

const CrudTable = createCrudTable<Activity, Activity>()

function booleanColumn<T>(label: string) {
	return createMappedStringColumn<T>(label, (bool) => (bool ? "Yes" : "No"))
}

const highlightStyles = css`
	& > td {
		background-color: var(--activity-highlight) !important;
		color: var(--activity-highlight-foreground) !important;
	}
`

const Activities: ReactiveComponent = () => {
	const user = useLoggedInUser()
	const activities = useActivities()

	function getRowAttributes(activity: Activity): HTMLAttributes {
		if (!activity.highlightColor) {
			return {}
		}
		return {
			class: highlightStyles,
			style: {
				"--activity-highlight": activity.highlightColor,
				"--activity-highlight-foreground": calculateForegroundColor(activity.highlightColor),
			},
		}
	}

	return () => (
		<ShadowContainer>
			<CrudTable
				name="Activities"
				getDefaultValue={() => ({
					id: 0,
					name: "",
					addsToPaidHours: true,
					canHaveOverride: false,
					canClockInWith: false,
					autoPopulateTimes: true,
					minimumModifyRole: null,
					minimumViewRole: null,
					isWorkingActivity: true,
					sortOrder: 0,
					highlightColor: null,
					notes: "",
				})}
				mapValue={(activity) => ({ ...activity })}
				createText="Create Activity"
				renderForm={FormContent}
				values={Array.from(activities.values()).sort(compareActivities)}
				create={async (activity) => {
					const newActivity = await createActivity(activity)
					activities.set(activity.id, newActivity)
				}}
				edit={async (activity, formData) => {
					const newActivity = await editActivity({
						...activity,
						...formData,
					})
					activities.set(newActivity.id, newActivity)
				}}
				delete={async (activity) => {
					await deleteActivity(activity.id)
					activities.delete(activity.id)
				}}
				columns={{
					name: {
						label: "Name",
						filter: stringFilter,
						sort: stringSort,
					},
					addsToPaidHours: booleanColumn("Adds to Paid Hours?"),
					canHaveOverride: booleanColumn("Can Be Overridden Per User?"),
					canClockInWith: booleanColumn("Can Clock In With?"),
					isWorkingActivity: booleanColumn("Is Working Activity?"),
				}}
				tableOptions={{
					rowAttributes: getRowAttributes,
				}}
				disableEdit={!user.hasPermission(Permission.EditActivity)}
				disableCreate={!user.hasPermission(Permission.CreateActivity)}
			/>
		</ShadowContainer>
	)
}

export default defineComponent(Activities)
