import { useLoggedInUser } from "@/data/loggedInUserStore"
import { SortDirection, type Activity, type Site, type TimeSheetEntry } from "@/models"
import Permission from "@/models/Permission"
import { getPaidHours, getUsedTime } from "@/services/timesheetsService"
import { useLoading } from "@/utils/composition/useLoading"
import { sumDuration } from "@/utils/timeUtils"
import { Duration, ZoneId, ZonedDateTime, type Instant } from "@js-joda/core"
import { reactive } from "vue"
import { defineComponent, requiredProp, useStoreValue, type ReactiveComponent } from "vue-utils"
import type { OverrideType } from "../service"
import useTimeSheetManagementStore from "../store"
import NewTimeSheetRow from "./NewTimeSheetRow"
import TimeSheetRow from "./TimeSheetRow"
import TotalHoursRow from "./TotalHoursRow"
import TableHeader from "./components/TableHeader"
import { useVisibleTimeSheetActivities } from "./composition/availableActivities"
import { TimeSheetSortBy, sortTimeSheets } from "./sorting"
import validateOverride from "./utils/validateOverride"

interface Props {
	addingNewRow: boolean
	setAddingRow(adding: boolean): void
}

const TimeSheetTable: ReactiveComponent<Props> = (props) => {
	const { runAction } = useLoading()
	const store = useTimeSheetManagementStore()
	const loggedInUser = useLoggedInUser()
	const selectedUser = $(useStoreValue(store, "selectedUser"))
	const sort = reactive({
		type: TimeSheetSortBy.Date,
		direction: SortDirection.Ascending,
	})
	const visibleActivities = useVisibleTimeSheetActivities()

	const timeSheets = $computed(() => {
		const filteredTimeSheets = store.weekTimeSheets.filter(
			(timeSheet) =>
				visibleActivities.value.some((a) => a.id === timeSheet.activityId) &&
				(!!getUsedTime(timeSheet.clockIn) || !!getUsedTime(timeSheet.clockOut))
		)
		sortTimeSheets(filteredTimeSheets, sort.type, sort.direction)
		return filteredTimeSheets
	})
	const hoursLogged = $computed(() => {
		const durations = store.weekTimeSheets.map((ts) => ts.timeLoggedExcludingBreaks ?? Duration.ZERO)
		return sumDuration(...durations)
	})
	const hoursPaid = $computed(() => sumDuration(...store.weekTimeSheets.map(getPaidHours)))

	async function createTimeSheet(timeSheet: TimeSheetEntry) {
		await runAction(store.createNewTimeSheet(timeSheet))
		props.setAddingRow(false)
	}

	async function setSite(timeSheet: TimeSheetEntry, site: Site) {
		await runAction(store.setSite(timeSheet.id, site.id))
	}

	async function setActivity(timeSheet: TimeSheetEntry, activity: Activity) {
		await runAction(store.setActivity(timeSheet.id, activity.id))
	}

	async function setManagerNotes(timeSheet: TimeSheetEntry, notes: string) {
		await runAction(store.updateManagerNotes(timeSheet.id, notes))
	}

	async function setBreakOverride(timeSheet: TimeSheetEntry, duration: Duration) {
		await runAction(store.addBreakOverride(timeSheet.id, duration))
	}

	async function setClockOverride(timeSheet: TimeSheetEntry, type: OverrideType, override: Instant) {
		validateOverride(getUsedTime(timeSheet.clockIn), getUsedTime(timeSheet.clockOut), type, override)
		await runAction(store.addOverride(timeSheet.id, type, override))
	}

	return () => (
		<table cellspacing={0} cellpadding={0}>
			<TableHeader
				sortBy={sort.type}
				direction={sort.direction}
				setSortBy={(s) => (sort.type = s)}
				setDirection={(d) => (sort.direction = d)}
			/>
			<tbody>
				{timeSheets.map((entry) => (
					<TimeSheetRow
						day={ZonedDateTime.ofInstant(
							getUsedTime(entry.clockIn) ?? (getUsedTime(entry.clockOut) as Instant),
							ZoneId.systemDefault()
						).toLocalDate()}
						entry={entry}
						key={entry.id}
						setBreakOverride={(override) => setBreakOverride(entry, override)}
						setClockOverride={(type, override) => setClockOverride(entry, type, override)}
						updateManagerNotes={(notes) => setManagerNotes(entry, notes)}
						setActivity={(activity) => setActivity(entry, activity)}
						setSite={(site) => setSite(entry, site)}
					/>
				))}

				{props.addingNewRow && loggedInUser.hasPermission(Permission.CreateTimeSheets) && !!selectedUser && (
					<NewTimeSheetRow
						userId={selectedUser.id}
						startOfWeek={store.startOfWeek.toLocalDate()}
						createTimeSheet={createTimeSheet}
						cancel={() => props.setAddingRow(false)}
					/>
				)}

				<TotalHoursRow hoursLogged={hoursLogged} hoursPaid={hoursPaid} />
			</tbody>
		</table>
	)
}

export default defineComponent(TimeSheetTable, {
	addingNewRow: requiredProp(Boolean),
	setAddingRow: requiredProp(Function),
})
