import StandardForm from "@/components/StandardForm"
import { BasicSelect } from "@/components/basic-form"
import { useSites } from "@/data/coreDataStore"
import type { Site } from "@/models"
import { getScaling, setScaling } from "@/services/scalingService"
import { useLoading } from "@/utils/composition/useLoading"
import { SiteListType, useSiteList } from "@/utils/composition/useSiteList"
import { css } from "vite-css-in-js"
import { shallowRef } from "vue"
import { Required, defineComponent, delay, refSetter, type ReactiveComponent } from "vue-utils"
import { useKioskModeStore } from "../kiosk-mode/store"

const pinInputCss = css`
	max-width: 7.5rem;
`

const formStyles = css`
	padding: 0.25rem;
`

const Form: ReactiveComponent = () => {
	const { runAction } = useLoading()

	const kioskStore = useKioskModeStore()

	let scaleValue = $ref(getScaling())

	let kioskPin = $ref(kioskStore.kioskPin ?? "")

	const siteValue = shallowRef<Site | null>(kioskStore.getAssignedSite(useSites()))
	const sites = useSiteList(SiteListType.Selection, {
		selectedSite: siteValue,
	})

	async function handleSubmit() {
		await runAction(delay(1000))
		if (kioskPin !== null && kioskPin.length > 0) {
			kioskStore.kioskPin = kioskPin
		}

		kioskStore.assignedSiteId = (siteValue.value as Site).id
		setScaling(scaleValue)
	}

	function validateInputs() {
		if (siteValue.value === null) {
			return "Please specify the assigned site"
		}
		if (kioskPin === null || kioskPin === "") {
			return true
		}
		if (!kioskPin.match(/\d+/)) {
			return "The PIN must only contain numbers"
		}
		if (kioskPin.length < 4) {
			return "The PIN must contain at least 4 digits"
		}
		if (kioskPin.length > 10) {
			return "The PIN must contain fewer than 10 digits"
		}
		return true
	}

	function resetInputs() {
		scaleValue = getScaling()
		kioskPin = ""
	}

	return () => (
		<StandardForm
			submit={{
				onClick: () => void handleSubmit(),
				text: "Apply",
				validate: validateInputs,
			}}
			cancel={resetInputs}
			class={formStyles}
		>
			<h3>Kiosk Mode Settings</h3>
			<div class="flex flex-col spacing-8">
				<label>
					<Required label="Kiosk Mode PIN" />
					<input
						class={pinInputCss}
						inputmode="numeric"
						minlength={4}
						maxlength={10}
						pattern="[0-9]+"
						v-model={kioskPin}
						onKeypress={(e) => {
							if (!/^[0-9]$/i.test(e.key)) {
								e.preventDefault()
							}
						}}
						required
					/>
				</label>

				<label style="max-width: 18rem">
					<Required label="Assigned Site" />
					<BasicSelect
						options={sites.value}
						value={siteValue.value}
						setValue={refSetter(siteValue)}
						defaultText=""
						required
					/>
				</label>

				<label style={{ maxWidth: "min(60rem, calc(100% - 2.5rem))" }}>
					UI Scaling
					<input type="range" v-model={scaleValue} list="scaling-values" min={75} max={150} step={5} />
					<datalist id="scaling-values" class="flex justify-between text-center">
						<option value={75} label="75%"></option>
						<option value={80}></option>
						<option value={85}></option>
						<option value={90}></option>
						<option value={95}></option>
						<option value={100} label="100%"></option>
						<option value={105}></option>
						<option value={110}></option>
						<option value={115}></option>
						<option value={120}></option>
						<option value={125} label="125%"></option>
						<option value={130}></option>
						<option value={135}></option>
						<option value={140}></option>
						<option value={145}></option>
						<option value={150} label="150%"></option>
					</datalist>
				</label>
			</div>
		</StandardForm>
	)
}

export default defineComponent(Form)
