export enum Screen {
	Pin = "pin",
	ExitKiosk = "exit_kiosk",
	VerifyDetails = "verify_details",
	ClockIn = "clock_in",
	ClockedInOverview = "overview",
	SwitchSite = "switch_site",
	EditNotes = "edit_notes",
	Success = "success",
	Holiday = "holiday",
}
