import "leaflet/dist/leaflet.css"
import "vue-utils/dist/style.css"
import "./bootstrap.scss"
import "./styles.scss"
import "./variables.scss"

import "@js-joda/timezone/dist/js-joda-timezone-2017-2027.esm.js"
import { createPinia } from "pinia"
import { loadLoadable } from "vue-utils"
import { TimeLordApp } from "./TimeLord"
import { isMsalRegistered, registerMSAL } from "./authentication"
import useLoggedInUserStore from "./data/loggedInUserStore"
import useSystemConfigStore from "./data/systemConfigStore"
import router from "./pages/routes"
import { applyScaling } from "./services/scalingService"
import "./utils/extensions"

async function initialiseApp() {
	applyScaling()

	const pinia = createPinia()
	TimeLordApp.use(pinia)
	TimeLordApp.use(router)

	const config = useSystemConfigStore()
	config.addOnLoadListener((config) => {
		if (!isMsalRegistered()) {
			const plugin = registerMSAL(config.msal)
			TimeLordApp.use(plugin)
			useLoggedInUserStore().registerListeners(plugin)
		}
	})
	void loadLoadable(config)

	TimeLordApp.mount("#app")

	try {
		if (navigator.serviceWorker) {
			await navigator.serviceWorker.register("/sw.js")
			console.log("Registered service worker")
		} else {
			console.log("Service worker not available")
		}
	} catch (e) {
		console.error("Failed to load service worker:", e)
	}
}

/*
	When TimeLord is opened as a popup, its almost certainly as a result of the user being re-authenticated by MSAL.
	
	In this scenario, its crucial the TimeLord app isn't initialised to avoid the URL being modified and losing the 
	login token information
*/
if (!window.opener || window.opener === window) {
	void initialiseApp()
}
