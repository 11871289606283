import { defineComponent, type ReactiveComponent, requiredProp, useOnInputNumber } from "vue-utils"
import { useCurrentYear } from "@/utils/composition/useDate"

interface Props {
	year: number
	setYear(year: number): void
}

const AbsenceYearSelect: ReactiveComponent<Props> = (props) => {
	const currentYear = useCurrentYear()
	const years = $computed(() => {
		const yearsSet = new Set<number>()
		yearsSet.add(props.year)
		yearsSet.add(currentYear.value)

		for (let i = 1; i >= -3; i--) {
			yearsSet.add(currentYear.value + i)
		}
		return Array.from(yearsSet).sort((y1, y2) => y2 - y1)
	})
	return () => (
		<select value={props.year} onInput={useOnInputNumber(props.setYear)}>
			{years.map((year) => (
				<option key={year} value={year}>
					{year}
				</option>
			))}
		</select>
	)
}

export default defineComponent(AbsenceYearSelect, {
	year: requiredProp(Number),
	setYear: requiredProp(Function),
})
