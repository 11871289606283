import logo from "@/assets/logo.png"
import PageBackground from "@/components/PageBackground"
import { css } from "vite-css-in-js"
import type { ReactiveComponent } from "vue-utils"
import { defineComponent } from "vue-utils"
import FrostedGlass from "../shared/clock/components/FrostedGlass"
import LoginButton from "./LoginButton"
import backgroundImg from "@/assets/background.webp"
import BasicPage from "../BasicPage"

const contentStyles = css`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 80%;
	margin: 0 auto;

	img {
		margin-top: 0.75rem;
		margin-bottom: 3rem;
		padding: 0 0.5rem;
	}
	h3 {
		font-weight: normal;
		margin-bottom: 2rem;
		text-align: center;
	}
	button {
		width: 100%;
		font-size: 1.125rem;
		padding: 0.75rem 0;
		border-radius: 1.5rem;
	}
`

const LoginPage: ReactiveComponent = () => () => (
	<BasicPage noPadding noNav title="TimeLord">
		<PageBackground imageUrl={backgroundImg}>
			<FrostedGlass>
				<div class={contentStyles}>
					<h2>Welcome To</h2>
					<img src={logo} alt="Logo" />
					<h3>Please click the button below to log in to the application</h3>
					<LoginButton />
				</div>
			</FrostedGlass>
		</PageBackground>
	</BasicPage>
)

export default defineComponent(LoginPage)
