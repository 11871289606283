import backgroundImg from "@/assets/background.webp"
import PageBackground from "@/components/PageBackground"
import { useSites } from "@/data/coreDataStore"
import type { UserMetadata } from "@/models"
import { showErrorMessage, useLoading } from "@/utils/composition/useLoading"
import { css } from "vite-css-in-js"
import { Transition } from "vue"
import { defineComponent, useWindowListener, type Component, type ReactiveComponent } from "vue-utils"
import MyHolidays from "../holiday/my-requests/MyHolidays"
import FrostedGlass from "../shared/clock/components/FrostedGlass"
import ClockedInOverview from "../shared/clock/screens/ClockedInOverview"
import EditNotes from "../shared/clock/screens/EditNotes"
import Success from "../shared/clock/screens/Success"
import SwitchSite from "../shared/clock/screens/SwitchSite"
import ClockIn from "./ClockIn"
import EnterPin from "./EnterPin"
import ExitKioskMode from "./ExitKioskMode"
import { Screen } from "./Screen"
import VerifyDetails from "./VerifyDetails"
import { useKioskModeStore } from "./store"

const regularPageStyles = css`
	margin: 0 auto;
	width: calc(100% - 1.5rem);
	max-width: 72rem;
	padding: 0.75rem;
	padding-top: 0;
`

const KioskMode: ReactiveComponent = () => {
	const { runAction } = useLoading()
	const sites = useSites()
	const store = useKioskModeStore()
	store.$reset()

	const renderContent = () => {
		const Screen = screenComponents[store.screen]
		return <Screen />
	}

	useWindowListener("visibilitychange", () => {
		if (!document.hidden && window.location.hostname !== "localhost") {
			store.logout()
		}
	})

	const screenComponents: Record<Screen, Component> = {
		[Screen.Pin]: EnterPin,
		[Screen.ExitKiosk]: ExitKioskMode,
		[Screen.VerifyDetails]: VerifyDetails,
		[Screen.ClockIn]: () => {
			const site = store.getAssignedSite(sites)
			if (!site) {
				void showErrorMessage(
					"No Site Assigned",
					"Please ask your site manager to assign a site to this device before clocking in"
				)
				store.switchScreen(Screen.VerifyDetails)
				return null
			}
			return (
				<ClockIn
					defaultActivityId={store.getDetailsOrThrow().lastActivityId}
					cancel={() => store.logout()}
					clockIn={(site, activity) => void runAction(store.clockIn(site.id, activity.id))}
					lockedSite={site}
					showHolidays={() => store.switchScreen(Screen.Holiday)}
				/>
			)
		},
		[Screen.ClockedInOverview]: () => (
			<ClockedInOverview
				activeTimeSheet={store.getActiveTimeSheetOrThrow()}
				editNotes={() => store.switchScreen(Screen.EditNotes)}
				switchSite={() => store.switchScreen(Screen.SwitchSite)}
				clockOut={() => void runAction(store.clockOut())}
				quit={() => store.logout()}
				showHolidays={() => store.switchScreen(Screen.Holiday)}
			/>
		),
		[Screen.SwitchSite]: () => (
			<SwitchSite
				activeTimeSheet={store.getActiveTimeSheetOrThrow()}
				mode="kiosk"
				back={() => store.switchScreen(Screen.ClockedInOverview)}
				switchSite={(site, activity) => void runAction(store.switchSite(site.id, activity.id))}
			/>
		),
		[Screen.EditNotes]: () => (
			<EditNotes
				latestTimeSheet={store.getActiveTimeSheetOrThrow()}
				back={() => store.switchScreen(Screen.ClockedInOverview)}
				editNotes={(newNotes) => void runAction(store.editNotes(newNotes))}
			/>
		),
		[Screen.Success]: () => (
			<Success
				isClockedIn={!!store.getDetailsOrThrow().activeTimeSheet}
				title={store.successDetails.title}
				message={store.successDetails.message}
				showOverview={() => store.switchScreen(Screen.ClockedInOverview)}
				finish={() => store.logout()}
			/>
		),
		[Screen.Holiday]: () => (
			<MyHolidays
				user={store.userDetails?.user as UserMetadata}
				back={() => store.switchScreen(store.hasClockedInInfo ? Screen.ClockedInOverview : Screen.ClockIn)}
			/>
		),
	}

	function GlassPage() {
		return (
			<PageBackground imageUrl={backgroundImg}>
				<FrostedGlass>
					<Transition name="fade" mode="out-in">
						<div class="flex flex-col h-full" key={store.screen}>
							{renderContent()}
						</div>
					</Transition>
				</FrostedGlass>
			</PageBackground>
		)
	}

	function RegularPage() {
		return <div class={regularPageStyles}>{renderContent()}</div>
	}

	return () => (store.screen === Screen.Holiday ? <RegularPage /> : <GlassPage />)
}

export default defineComponent(KioskMode)
