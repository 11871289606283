import type { Activity } from "@/models"
import createCrudEndpoints from "./createCrudEndpoint"

const endpoints = createCrudEndpoints<Activity>("/api/activities")

export const listActivities = endpoints.list
export const getActivity = endpoints.get
export const createActivity = endpoints.create
export const editActivity = endpoints.edit
export const deleteActivity = endpoints.delete
