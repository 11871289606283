import { LoadingWrapper } from "@/utils/composition/useLoading"
import { renderSlot, type HTMLAttributes } from "vue"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import TitleBar from "../components/titlebar"
import TimeLordNavBar from "./TimeLordNavBar"

interface Props {
	title: string
	noPadding?: boolean
	noNav?: boolean
}

const BasicPage: ReactiveComponent<Props, HTMLAttributes> = (props, { slots, attrs }) => {
	return () => (
		<>
			<TitleBar title={props.title} />
			<main class="overflow-auto flex flex-col">
				<div class="flex-1" style={props.noPadding ? "" : "padding: 0.75rem"} {...attrs}>
					<LoadingWrapper>{renderSlot(slots, "default")}</LoadingWrapper>
				</div>
			</main>
			{!props.noNav && <TimeLordNavBar />}
		</>
	)
}

export default defineComponent(BasicPage, {
	title: requiredProp(String),
	noPadding: optionalProp(Boolean),
	noNav: optionalProp(Boolean),
})
