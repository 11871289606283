import { BasicSelect } from "@/components/basic-form"
import type { Activity, Nullable } from "@/models"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import { useModifyableTimeSheetActivities } from "../composition/availableActivities"
import DisplayEdit from "./DisplayEdit"
import { useOnTabbed } from "./useOnTabbed"

interface Props {
	value: Nullable<Activity>
	save(activity: Activity): Promise<void>
	readonly?: boolean
}

const EditActivity: ReactiveComponent<Props> = (props) => {
	const activities = $(useModifyableTimeSheetActivities())

	async function handleSave(activity: Activity, hide: () => void) {
		if (activity.id !== props.value?.id) {
			await props.save(activity)
		}
		hide()
	}

	return () => (
		<DisplayEdit
			displayText={props.value?.name ?? ""}
			renderContent={({ hide }) => (
				<BasicSelect
					options={Array.from(activities.values())}
					value={props.value}
					setValue={(newActivity) => void handleSave(newActivity, hide)}
					{...useOnTabbed(hide)}
					onKeyup={(e: KeyboardEvent) => e.key === "Escape" && hide()}
					style={{ margin: "0 0.25em" }}
					class="w-full"
					required
				/>
			)}
			readonly={props.readonly}
		/>
	)
}

export default defineComponent(EditActivity, {
	value: requiredProp(null, Object),
	save: requiredProp(Function),
	readonly: optionalProp(Boolean),
})
