import { canVisitPageInKioskMode } from "@/services/kioskModeService"
import { createRouter, createWebHistory, type RouteComponent, type RouteRecordRaw } from "vue-router"
import Administration from "./administration"
import Dashboard from "./dashboard"
import Holidays from "./holiday"
import KioskMode from "./kiosk-mode"
import KioskModeSettings from "./kiosk-mode-settings"
import { useKioskModeStore } from "./kiosk-mode/store"
import NotFound from "./NotFound"
import ProfileSettings from "./profile-settings"
import SelfService from "./self-service"
import TimeSheetExport from "./timesheet-export"
import TimeSheetManagement from "./timesheet-management"
import HolidaysPlanner from "@/pages/holiday/planner"
import StaffAbsenceCalendar from "./holiday/absence-calendar"

const simpleRoutes: Record<string, RouteComponent> = {
	"/dashboard": Dashboard,
	"/system-management": Administration,
	"/administration": Administration,
	"/holidays": Holidays,
	"/holidays/create-request": Holidays,
	"/holidays/planner": HolidaysPlanner,
	"/holidays/staff-absence-calendar/:userId": StaffAbsenceCalendar,
	"/kiosk-mode-settings": KioskModeSettings,
	"/kiosk": KioskMode,
	"/pin": KioskMode,
	"/self-service": SelfService,
	"/timesheets": TimeSheetManagement,
	"/timesheet-export": TimeSheetExport,
	"/profile-settings": ProfileSettings,
	"/:pathMatch(.*)*": NotFound,
}
const routes: RouteRecordRaw[] = [
	...Object.entries(simpleRoutes).map(([path, component]) => ({ path, component })),
	{
		path: "/",
		redirect: "/dashboard",
	},
]

const router = createRouter({
	routes,
	history: createWebHistory(),
})

router.beforeEach((to) => {
	const kioskStore = useKioskModeStore()
	if (kioskStore.kioskEnabled && !canVisitPageInKioskMode(to.path)) {
		return "/pin"
	}
	return undefined
})

export default router
