import useLoggedInUserStore from "@/data/loggedInUserStore"
import { css } from "vite-css-in-js"
import type { ReactiveComponent } from "vue-utils"
import { defineComponent, requiredProp } from "vue-utils"
import LoggedInDetails from "./LoggedInDetails"

const titleBarStyles = css`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	font-size: 1.125rem;
	letter-spacing: 0.0125rem;
	background-color: white;
	height: 2.75rem;
	font-weight: 500;
	box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.12);
	z-index: 2;

	border-top: env(safe-area-inset-top, 0px) solid var(--color-tl-primary);

	padding-left: max(env(safe-area-inset-left, 0.5rem), 0.5rem);
	padding-right: env(safe-area-inset-right, 0);

	div {
		font-size: 0.95rem;
	}
`

const TitleBar: ReactiveComponent<{ title: string }> = (props) => {
	const hasUser = $computed(() => useLoggedInUserStore().type === "done")

	return () => (
		<header class={titleBarStyles}>
			<img src="/icons/favicon-256.png" alt="logo" style={{ height: "1.75rem" }} />
			<span>{props.title}</span>
			<div class="flex-1" />
			{hasUser && <LoggedInDetails />}
		</header>
	)
}

export default defineComponent(TitleBar, {
	title: requiredProp(String),
})
