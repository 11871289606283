import { getLinkedAccount } from "@/authentication/authenticationService"
import type { LoggedInUser } from "@/models/LoggedInUser"
import { toReactive } from "@vueuse/core"
import type { MsalPlugin } from "msal-vue"
import { defineStore } from "pinia"
import { toRef } from "vue"
import { defineGlobals, ensureLoadingHasResult, loadLoadable, piniaLoadableState } from "vue-utils"

const useLoggedInUserStore = defineStore("loggedInUser", {
	state: () => piniaLoadableState(getLinkedAccount),
	actions: {
		registerListeners(plugin: MsalPlugin) {
			plugin.addLoginCallback(() => {
				void loadLoadable(this)
			})
			plugin.addLogoutCallback(() => {
				this.$reset()
			})
		},
	},
})

export function useLoggedInUser(): LoggedInUser {
	const store = useLoggedInUserStore()
	return toReactive(
		toRef(() => {
			const user = ensureLoadingHasResult(store)
			if (!user) {
				throw new Error("No logged in user")
			}
			return user
		})
	)
}

defineGlobals({
	useLoggedInUserStore,
})

export default useLoggedInUserStore
