import type { Trade } from "@/models"
import { css } from "vite-css-in-js"
import type { FunctionalComponent } from "vue"
import { requiredProp } from "vue-utils"

const blobStyles = css`
	width: 1em;
	height: 1em;
	min-width: 1em;
	min-height: 1em;
	border-radius: 100%;
	align-self: center;
`

const TradeBlob: FunctionalComponent<{ trade: Trade | null }> = ({ trade }) =>
	trade ? (
		<div class={blobStyles} style={`background-color: ${trade.colour}`} title={trade.name} />
	) : (
		<div class={blobStyles} />
	)

TradeBlob.props = {
	trade: requiredProp(Object, null),
}

export default TradeBlob
