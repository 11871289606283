import usePopups from "@/data/popupsStore"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import Modal from "./Modal"

const Popups: ReactiveComponent = () => {
	const popups = usePopups()
	const popup = $computed(() => (popups.popups.length === 0 ? null : popups.popups[popups.popups.length - 1]))

	return () => {
		if (!popup) {
			return null
		}
		const Content = popup.content
		return (
			<Modal style={{ minWidth: `min(${popup.width ?? 28}rem, calc(100vw - 4rem))`, width: popup.width ?? "22.5%" }}>
				<Content close={() => popups.closePopup(popup)} popup={popup} />
			</Modal>
		)
	}
}

export default defineComponent(Popups)
