import LeafletMap from "@/components/mapping/LeafletMap"
import SiteMarker from "@/components/mapping/SiteMarker"
import useLocationMarker from "@/components/mapping/useLocationMarker"
import { useSystemConfig } from "@/data/systemConfigStore"
import type { CoordinateRadius, Nullable } from "@/models"
import { css } from "vite-css-in-js"
import { computed, shallowRef, toRef } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import LocationControls from "./LocationControls"

interface Props {
	initialLocation: Nullable<CoordinateRadius>
	newLocation: Nullable<CoordinateRadius>
	setLocation(location: Nullable<CoordinateRadius>): void
}

const leafletStyles = css`
	cursor: crosshair;

	.leaflet-interactive {
		cursor: crosshair;
	}
`

function createNewMarker(location: CoordinateRadius) {
	return new SiteMarker({
		latitude: location.latitude,
		longitude: location.longitude,
		radius: location.radiusMetres,
		hue: -65,
		tooltipHtml: "New Site Location",
		radiusOptions: {
			color: "#3ed531",
		},
	})
}

function createExistingMarker(location: CoordinateRadius) {
	return new SiteMarker({
		latitude: location.latitude,
		longitude: location.longitude,
		radius: location.radiusMetres,
		hue: 150,
		tooltipHtml: "Current Site Location",
	})
}

const LocationSelect: ReactiveComponent<Props> = (props) => {
	const mapRef = shallowRef<L.Map | null>(null)
	const systemConfig = useSystemConfig()

	function initMap(map: L.Map, container: HTMLElement) {
		if (props.initialLocation) {
			const containerSize = Math.min(container.clientWidth, container.clientHeight)
			const zoomLevel = Math.floor(-Math.log2(props.initialLocation.radiusMetres / (containerSize * 50_000)))

			map.setView([props.initialLocation.latitude, props.initialLocation.longitude], zoomLevel)
		}

		map.on("click", (mapClickEvent) => {
			props.setLocation({
				latitude: mapClickEvent.latlng.lat,
				longitude: mapClickEvent.latlng.lng,
				radiusMetres:
					props.newLocation?.radiusMetres ?? props.initialLocation?.radiusMetres ?? systemConfig.defaultSiteRadius,
			})
		})
		mapRef.value = map
	}

	useLocationMarker({
		createMarker: createExistingMarker,
		mapRef,
		locationRef: toRef(props, "initialLocation"),
	})
	useLocationMarker({
		createMarker: createNewMarker,
		mapRef,
		locationRef: computed(() => {
			const initial = props.initialLocation
			const newLoc = props.newLocation
			if (!initial || !newLoc) {
				return newLoc
			}
			if (
				initial.latitude === newLoc.latitude &&
				initial.longitude === newLoc.longitude &&
				initial.radiusMetres === newLoc.radiusMetres
			) {
				return null
			}
			return newLoc
		}),
	})

	return () => (
		<div class="flex flex-col flex-1" style={{ maxWidth: "100%", width: "100%" }}>
			<LocationControls
				hasExisting={!!props.initialLocation}
				location={props.newLocation}
				setLocation={props.setLocation}
			/>
			<LeafletMap class={["flex-1", leafletStyles]} initMap={initMap} />
		</div>
	)
}

export default defineComponent(LocationSelect, {
	initialLocation: requiredProp(Object, null),
	newLocation: requiredProp(Object, null),
	setLocation: requiredProp(Function),
})
