export const createEditOperations = <T>(
	getItems: () => T[],
	setItems: (items: T[]) => void,
	getId: (item: T) => string | number
) => ({
	add(item: T) {
		const itemId = getId(item)
		const items = getItems()
		if (items.some((existing) => getId(existing) === itemId)) {
			setItems(items.map((existing) => (getId(existing) === itemId ? item : existing)))
		} else {
			setItems([...items, item])
		}
	},
	edit(item: T) {
		const itemId = getId(item)
		const items = getItems()
		if (!items.some((existing) => getId(existing) === itemId)) {
			throw new Error("Item is not in the list")
		}
		setItems(items.map((existing) => (getId(existing) === itemId ? item : existing)))
	},
	remove(item: T) {
		const itemId = getId(item)
		const items = getItems()
		const newItems = items.filter((existing) => getId(existing) !== itemId)
		if (newItems.length === items.length) {
			throw new Error("Item was not in the list")
		}
		setItems(newItems)
	},
})
