import type { CoordinateRadius } from "@/models"
import { watch, type Ref } from "vue"
import type SiteMarker from "./SiteMarker"

export default function useLocationMarker(options: {
	createMarker: (location: CoordinateRadius) => SiteMarker
	locationRef: Ref<CoordinateRadius | null | undefined>
	mapRef: Ref<L.Map | null | undefined>
}): Ref<SiteMarker | null> {
	const { createMarker, locationRef, mapRef } = options

	let marker = $shallowRef<SiteMarker | null>(null)

	function updateLocation(location: CoordinateRadius | null | undefined) {
		if (location && marker) {
			marker.moveTo(location.latitude, location.longitude)
			marker.setRadius(location.radiusMetres)
		} else if (location && !marker) {
			marker = createMarker(location)
			if (mapRef.value) {
				marker.addTo(mapRef.value)
			}
		} else if (!location && marker) {
			marker.remove()
			marker = null
		}
	}

	watch(locationRef, updateLocation)
	updateLocation(locationRef.value)

	watch(mapRef, (map) => {
		if (map && marker) {
			marker.addTo(map)
		} else if (!map && marker) {
			marker.remove()
		}
	})

	return $$(marker)
}
