import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import PinDisplay from "./PinDisplay"
import PinInput from "./PinInput"

interface Props {
	submit: (pin: string) => void
}

const PinEnter: ReactiveComponent<Props> = (props) => {
	let pin = $ref("")

	const deleteClick = () => {
		if (pin.length > 0) {
			pin = pin.substring(0, pin.length - 1)
		}
	}

	const numberClick = (number: number) => {
		pin += number.toString()
	}

	return () => (
		<div>
			<PinDisplay pin={pin} />
			<PinInput
				okClick={() => props.submit(pin)}
				deleteClick={deleteClick}
				numberClick={numberClick}
				okDisabled={pin.length < 4}
				deleteDisabled={pin.length === 0}
				inputDisabled={pin.length >= 10}
			/>
		</div>
	)
}

export default defineComponent(PinEnter, {
	submit: requiredProp(Function),
})
