import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import { faWarning } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, ShadowContainer, type ReactiveComponent } from "vue-utils"

interface Props {
	update(): void
}

const headerStyles = css`
	display: flex;
	flex-direction: column;
	border-left: 0.5rem solid var(--color-warning);

	& > div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid var(--color-border-light);
		padding-bottom: 0.75rem;
	}
	h3 {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		margin: 0;
	}
	p {
		margin-top: 0.75rem;
		margin-bottom: 0;
	}
`

const UpdateMessage: ReactiveComponent<Props> = (props) => {
	return () => (
		<ShadowContainer class={headerStyles}>
			<div>
				<h3>
					<Icon icon={faWarning} />
					Update Available
				</h3>
				<BootstrapButton color="primary" onClick={props.update}>
					Update Now
				</BootstrapButton>
			</div>
			<p>An update to Time Lord is available. Please update the system as soon as possible</p>
		</ShadowContainer>
	)
}

export default defineComponent(UpdateMessage, {
	update: requiredProp(Function),
})
