import AutoColumns from "@/components/AutoColumns"
import SimpleEditForm from "@/components/SimpleEditForm"
import { BasicSelect } from "@/components/basic-form"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import { Permission, UserRole, type Activity } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import { proxyProp } from "@/utils/proxyProp"
import { Required, ToggleButton, defineComponent, type ReactiveComponent } from "vue-utils"
import { crudTableFormPropTypes, getFormTitle, type CrudTableFormProps } from "../CrudTable"
import showDeleteConfirmation from "../showDeleteConfirmation"

const FormContent: ReactiveComponent<CrudTableFormProps<Activity>> = (props) => {
	const activity = proxyProp(props, "data")
	const user = useLoggedInUser()

	return () => (
		<SimpleEditForm
			{...props}
			title={getFormTitle(props.mode, "Activity")}
			validateSave={() => {
				if (!activity.name.trim().length) return "Please enter a name"
				return true
			}}
			validateDelete={() => showDeleteConfirmation("activity")}
			delete={user.hasPermission(Permission.DeleteActivity) ? props.delete : undefined}
		>
			<div class="flex flex-col spacing-6 overflow-auto">
				<label style={{ gridColumnStart: "span 3" }}>
					<Required label="Name" />
					<input v-model={activity.name} disabled={props.readonly} required />
				</label>
				<AutoColumns class="spacing-6" size="13rem">
					<label>
						Adds To Paid Hours
						<ToggleButton
							disabled={props.readonly}
							toggled={activity.addsToPaidHours}
							setToggled={(value) => (activity.addsToPaidHours = value)}
						/>
					</label>

					<label>
						Can Be Overridden Per User
						<ToggleButton
							disabled={props.readonly}
							toggled={activity.canHaveOverride}
							setToggled={(value) => (activity.canHaveOverride = value)}
						/>
					</label>

					<label>
						Auto-Complete Times
						<ToggleButton
							disabled={props.readonly}
							toggled={activity.autoPopulateTimes}
							setToggled={(value) => (activity.autoPopulateTimes = value)}
						/>
					</label>

					<label>
						Show for Clock In?
						<ToggleButton
							disabled={props.readonly}
							toggled={activity.canClockInWith}
							setToggled={(value) => (activity.canClockInWith = value)}
						/>
					</label>

					<label>
						Is Working Activity?
						<ToggleButton
							toggled={activity.isWorkingActivity}
							setToggled={(value) => (activity.isWorkingActivity = value)}
							disabled={props.readonly}
						/>
					</label>

					<label>
						Highlight Color
						<input
							type="color"
							disabled={props.readonly}
							value={activity.highlightColor ?? "#ffffff"}
							onInput={(e) => {
								const color = (e.target as HTMLInputElement).value
								if (color.length === 0 || color.toLowerCase() === "#ffffff") {
									activity.highlightColor = null
								} else {
									activity.highlightColor = color
								}
							}}
						/>
					</label>

					<label>
						Minimum Time Sheet View Role
						<BasicSelect
							options={Object.values(UserRole) as UserRole[]}
							getText={formatEnum}
							value={activity.minimumViewRole}
							setValue={(role) => (activity.minimumViewRole = role)}
							defaultText="-"
						/>
					</label>

					<label>
						Minimum Time Sheet Modify Role
						<BasicSelect
							options={Object.values(UserRole) as UserRole[]}
							getText={formatEnum}
							value={activity.minimumModifyRole}
							setValue={(role) => (activity.minimumModifyRole = role)}
							defaultText="-"
						/>
					</label>

					<label>
						Sort Order
						<input type="number" step="1" v-model={activity.sortOrder} disabled={props.readonly} />
					</label>
				</AutoColumns>

				<label>
					Notes
					<textarea v-model={activity.notes} disabled={props.readonly} rows={5} />
				</label>
			</div>
		</SimpleEditForm>
	)
}

export default defineComponent(FormContent, crudTableFormPropTypes())
