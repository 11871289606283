import type { TimeSheetEntry } from "@/models"
import { shallowRef } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import LargeButton from "../components/LargeButton"

interface Props {
	latestTimeSheet: TimeSheetEntry
	editNotes(newNotes: string): void
	back(): void
}

const EditNotes: ReactiveComponent<Props> = (props) => {
	const notes = shallowRef(props.latestTimeSheet.notes)

	return () => (
		<>
			<h2>Edit Notes</h2>
			<textarea v-model={notes.value} class="w-full flex-auto" style="margin: 2rem 0" />

			<div class="flex justify-center items-center w-full spacing-8">
				<LargeButton color="secondary" onClick={props.back}>
					Cancel
				</LargeButton>
				<LargeButton color="primary" onClick={() => props.editNotes(notes.value)}>
					Save
				</LargeButton>
			</div>
		</>
	)
}

export default defineComponent(EditNotes, {
	latestTimeSheet: requiredProp(Object),
	editNotes: requiredProp(Function),
	back: requiredProp(Function),
})
