import Icon from "@/components/Icon"
import { faBackspace } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import type { FunctionalComponent } from "vue"
import { defineComponent, optionalProp, requiredProp, useWindowListener, type ReactiveComponent } from "vue-utils"

const pinInputStyles = css`
	display: inline-grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1.5em;

	& > button {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;

		width: 2.5em;
		height: 2.5em;

		color: white;
		background-color: #161616;
		border: none;
		outline: none;

		font-size: 1.5em;
		transition:
			background-color 0.125s,
			font-size 0.125s;

		user-select: none;

		&:active:enabled {
			background-color: #474747;
			box-shadow: 0.25em 0.25em 0.25em rgba(0, 0, 0, 0.25);

			& > span {
				font-size: 1.1em;
			}
		}

		&.back-icon > * {
			font-size: 0.9em;
			margin-right: 0.1em;
		}

		&:disabled {
			color: #b1b1b1;
			background-color: #242424;
		}
	}
`

const PinInput: ReactiveComponent<Props> = (props) => {
	const {
		numberClick,
		deleteClick,
		okClick,
		okDisabled = false,
		deleteDisabled = false,
		inputDisabled = false,
	} = $(props)

	const wrapVibrate = (action: () => unknown) => () => {
		try {
			navigator.vibrate(100)
		} catch (e) {
			//Ignore the error
		}
		action()
	}

	useWindowListener("keyup", (event) => {
		if (!(event.target instanceof HTMLInputElement)) {
			if (/^[0-9]$/.test(event.key)) {
				props.numberClick(Number.parseInt(event.key))
			} else if (event.key === "Enter" && !props.okDisabled) {
				props.okClick()
			} else if (event.key === "Backspace" && !props.deleteDisabled) {
				props.deleteClick()
			}
		}
	})

	const NumberInput: FunctionalComponent<{ number: number }> = ({ number }) => (
		<button onClick={wrapVibrate(() => numberClick(number))} disabled={inputDisabled}>
			<span>{number}</span>
		</button>
	)

	return () => (
		<div class={pinInputStyles}>
			{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
				<NumberInput key={number} number={number} />
			))}
			<button class="back-icon" onClick={wrapVibrate(deleteClick)} disabled={deleteDisabled}>
				<Icon icon={faBackspace} />
			</button>
			<NumberInput number={0} />
			<button onClick={wrapVibrate(okClick)} disabled={okDisabled}>
				<span style="font-size: 0.7em">Ok</span>
			</button>
		</div>
	)
}

interface Props {
	okClick: () => void
	deleteClick: () => void
	numberClick: (number: number) => void

	okDisabled?: boolean
	deleteDisabled?: boolean
	inputDisabled?: boolean
}

export default defineComponent(PinInput, {
	okClick: requiredProp(Function),
	deleteClick: requiredProp(Function),
	numberClick: requiredProp(Function),
	okDisabled: optionalProp(Boolean),
	deleteDisabled: optionalProp(Boolean),
	inputDisabled: optionalProp(Boolean),
})
