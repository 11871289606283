import { useSystemConfig } from "@/data/systemConfigStore"
import { getMsalPlugin, isMsalRegistered } from "."

export type AuthTokenResult =
	| {
			status: "not-logged-in"
	  }
	| {
			status: "success"
			token: string
	  }
	| {
			status: "error"
			error: Error
	  }
	| {
			status: "not-initialized"
	  }

let currentAuthTokenPromise: Promise<AuthTokenResult> | null = null

export const getAuthToken = async (): Promise<AuthTokenResult> => {
	if (currentAuthTokenPromise) {
		return currentAuthTokenPromise
	}
	currentAuthTokenPromise = getAuthTokenInternal()
	try {
		return await currentAuthTokenPromise
	} finally {
		currentAuthTokenPromise = null
	}
}

const getAuthTokenInternal = async (): Promise<AuthTokenResult> => {
	if (!isMsalRegistered()) {
		return {
			status: "not-initialized",
		}
	}
	const msalPlugin = getMsalPlugin()
	const account = msalPlugin.application.getActiveAccount()
	if (!account) {
		return {
			status: "not-logged-in",
		}
	}
	const tokenRequest = {
		scopes: useSystemConfig().msal.scopes,
		forceRefresh: false,
	}

	try {
		const response = await msalPlugin.application.acquireTokenSilent({
			...tokenRequest,
			account,
		})
		return {
			status: "success",
			token: response.accessToken,
		}
	} catch (e) {
		//Do nothing, try popup
	}

	try {
		const response = await msalPlugin.application.acquireTokenPopup({
			...tokenRequest,
			account,
		})
		return {
			status: "success",
			token: response.accessToken,
		}
	} catch (e) {
		console.error("Failed to obtain auth token", e)
		return {
			status: "error",
			error: e as Error,
		}
	}
}
