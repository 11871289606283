import type { Nullable } from "@/models"
import { computed } from "vue"
import { calculateForegroundColor, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import type { CalendarEvent } from "./types"
import { css } from "vite-css-in-js"

interface Props {
	event: Nullable<CalendarEvent>
	previousEvent: Nullable<CalendarEvent>
}

const displayStyles = css`
	height: 100%;
	padding: 0.25rem;
`

function eventMatches(event1: CalendarEvent, event2: CalendarEvent): boolean {
	return event1.color === event2.color && event1.title === event2.title
}

const CalendarEventDisplay: ReactiveComponent<Props> = (props) => {
	const eventText = computed(() => {
		if (!props.event || (props.previousEvent && eventMatches(props.event, props.previousEvent))) {
			return ""
		}
		return props.event.title
	})
	const foregroundColor = computed(() => {
		const color = props.event?.color
		return color ? calculateForegroundColor(color) : undefined
	})

	return () => (
		<div class={displayStyles} style={{ backgroundColor: props.event?.color, color: foregroundColor.value }}>
			{eventText.value}
		</div>
	)
}

export default defineComponent(CalendarEventDisplay, {
	event: requiredProp(Object, null),
	previousEvent: requiredProp(Object, null),
})
