import { LoadingWrapper } from "@/utils/composition/useLoading"
import { css } from "vite-css-in-js"
import { renderSlot } from "vue"
import { anyProp, defineComponent, requiredProp, ScrollPane, type ReactiveComponent } from "vue-utils"
import TitleBar from "../components/titlebar"
import TimeLordNavBar from "./TimeLordNavBar"

interface Props {
	title: string
	class?: string
}

const pageStyles = css`
	display: contents;

	@media print {
		& > *:first-child,
		& > *:last-child {
			display: none;
		}
		& > main > div,
		& > main > div > div {
			display: contents;
		}
	}
`

const BasicPage: ReactiveComponent<Props> = (props, { slots }) => {
	return () => (
		<div class={pageStyles}>
			<TitleBar title={props.title} />
			<main>
				<ScrollPane class="h-full">
					<div class={["flex-auto", props.class]} style={"padding: 0.75rem"}>
						<LoadingWrapper>{renderSlot(slots, "default")}</LoadingWrapper>
					</div>
				</ScrollPane>
			</main>
			<TimeLordNavBar />
		</div>
	)
}

export default defineComponent(BasicPage, {
	title: requiredProp(String),
	class: anyProp(),
})
