import backgroundImg from "@/assets/background.webp"
import PageBackground from "@/components/PageBackground"
import { defineComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import FrostedGlass from "../shared/clock/components/FrostedGlass"
import SelfService from "./SelfService"

export default defineComponent({
	name: "SelfServicePage",
	setup: () => () => (
		<BasicPage title="Self Service" noPadding>
			<PageBackground imageUrl={backgroundImg}>
				<FrostedGlass>
					<SelfService />
				</FrostedGlass>
			</PageBackground>
		</BasicPage>
	),
})
