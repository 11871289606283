import Icon from "@/components/Icon"
import UserNameDisplay from "@/components/UserNameDisplay"
import type { UserMetadata } from "@/models"
import { faStarOfLife } from "@fortawesome/free-solid-svg-icons"
import { convert, type LocalDate } from "@js-joda/core"
import { css } from "vite-css-in-js"
import type { ReactiveComponent } from "vue-utils"
import { defineComponent, requiredProp } from "vue-utils"
import PrintTimeSheets from "./PrintTimeSheets"

interface Props {
	user: UserMetadata
	hasSpecialArrangement: boolean
	weekStarting: LocalDate
}

const startIconStyles = css`
	margin-left: 0.5rem;
	color: #7d6a00;
`

const Heading: ReactiveComponent<Props> = (props) => {
	return () => (
		<div>
			<PrintTimeSheets />
			<h3 class="text-center">
				Time Sheets for <UserNameDisplay user={props.user} /> on week starting{" "}
				<span>{convert(props.weekStarting).toDate().toLocaleDateString()}</span>
				{props.hasSpecialArrangement && (
					<span class={startIconStyles}>
						<Icon icon={faStarOfLife} title="User has a special arrangement" />
					</span>
				)}
			</h3>
		</div>
	)
}

export default defineComponent(Heading, {
	user: requiredProp(Object),
	hasSpecialArrangement: requiredProp(Boolean),
	weekStarting: requiredProp(Object),
})
