import { useActivities } from "@/data/coreDataStore"
import { css } from "vite-css-in-js"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const listStyles = css`
	display: grid;
	grid-template-columns: max-content auto;
	row-gap: 0.5em;
	column-gap: 1em;
	align-items: center;
	margin: 0;

	dt {
		width: 1.5em;
		height: 1.5em;
	}
	dd {
		margin: 0;
	}
`

const AbsenceCalendarKey: ReactiveComponent = () => {
	const nonWorkingActivities = $computed(() =>
		Array.from(useActivities().values()).filter((activity) => !activity.isWorkingActivity)
	)

	return () => (
		<>
			<h4 style={{ margin: 0 }}>Key</h4>
			<hr />
			<dl class={listStyles}>
				<dt style={{ backgroundColor: "#c0c0c070" }} />
				<dd>Weekend</dd>
			</dl>
			<hr />
			<dl class={listStyles}>
				{nonWorkingActivities.map((activity) => (
					<>
						<dt style={{ backgroundColor: activity.highlightColor ?? "" }} />
						<dd>{activity.name}</dd>
					</>
				))}
			</dl>
		</>
	)
}

export default defineComponent(AbsenceCalendarKey)
