import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import PlainIconButton from "@/components/PlainIconButton"
import { MonthPicker } from "@/components/date-pickers"
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons"
import { faCalendarAlt, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { YearMonth } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { ref } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import useHolidaysPlannerStore from "./store"

const navStyles = css`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-grow: 1;
`

const navButtonStyles = css`
	font-size: 1.125rem;
	padding: 0.2rem 0.5rem;
`

const pickerContainerStyles = css`
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 0.125rem;

	font-size: 1.125rem;
	font-weight: 600;

	& > button {
		all: unset;
		cursor: pointer;
		text-align: center;
		border-radius: 0.5rem;
		padding: 0.25rem 1rem;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;

		&:hover {
			background-color: var(--color-info-light-6);
		}
	}
`

const controlsButtonStyles = css`
	min-width: 10em;
	@media (max-width: 38rem) {
		min-width: auto;
		padding: 0.35rem 0.5rem;
		font-size: 1.125rem;

		span {
			display: none;
		}
	}
`

const PlannerNavigation: ReactiveComponent = () => {
	const store = useHolidaysPlannerStore()
	const pickingDate = ref(false)

	return () => (
		<div class="flex items-center">
			<nav class={navStyles}>
				<BootstrapButton
					class={navButtonStyles}
					color="primary"
					title="View Previous Month"
					onClick={() => store.previousMonth()}
				>
					<PlainIconButton icon={faChevronLeft} />
				</BootstrapButton>

				<div class={pickerContainerStyles}>
					<button title="Open Month Picker" onClick={() => (pickingDate.value = !pickingDate.value)}>
						{store.month.displayName()} {store.year}
						<Icon icon={faCalendarAlt} />
					</button>
					{pickingDate.value && (
						<MonthPicker
							class="absolute"
							style={{ top: "100%", zIndex: 3 }}
							month={YearMonth.of(store.year, store.month)}
							setMonth={(month) => {
								store.setDate(month.month(), month.year())
								pickingDate.value = false
							}}
							onBlur={() => (pickingDate.value = false)}
						/>
					)}
				</div>

				<BootstrapButton
					class={navButtonStyles}
					color="primary"
					title="View Next Month"
					onClick={() => store.nextMonth()}
				>
					<PlainIconButton icon={faChevronRight} />
				</BootstrapButton>
			</nav>

			<BootstrapButton
				class={controlsButtonStyles}
				color={store.showControls ? "danger" : "success"}
				onClick={() => (store.showControls = !store.showControls)}
			>
				<span>{store.showControls ? "Hide" : "Show"} Controls</span>
				<Icon icon={store.showControls ? faEyeSlash : faEye} />
			</BootstrapButton>
		</div>
	)
}

export default defineComponent(PlannerNavigation)
