import { Permission, type Site } from "@/models"
import { renderSlot } from "vue"

import SimpleEditForm from "@/components/SimpleEditForm"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import usePopups from "@/data/popupsStore"
import { proxyProp } from "@/utils/proxyProp"
import {
	Alert,
	FormTabs,
	ScrollPane,
	defineComponent,
	type Component,
	type FormTab,
	type ReactiveComponent,
} from "vue-utils"
import { crudTableFormPropTypes, getFormTitle, type CrudTableFormProps } from "../CrudTable"
import BasicDetails from "./BasicDetails"
import LocationSelect from "./LocationSelect"

const FormScrollPane: Component = (_, { slots }) => (
	<ScrollPane
		style={{
			width: "100%",
			height: "32rem",
		}}
	>
		{renderSlot(slots, "default")}
	</ScrollPane>
)

const Form: ReactiveComponent<CrudTableFormProps<Site>> = (props) => {
	const site = proxyProp(props, "data")
	const user = useLoggedInUser()
	const popups = usePopups()

	const initialLocation = site.location ? { ...site.location } : null
	let tab = $ref("details")

	async function confirmDelete() {
		return await popups.confirm(() => (
			<Alert title="Confirm Deletion">
				<p>
					Are you sure you wish to delete this site? You will only be able to do so if the site is now used within the
					system.
				</p>
				<p>
					<strong>Please set the site to archived instead if it is no longer active.</strong>
				</p>
			</Alert>
		))
	}

	const tabs: FormTab[] = [
		{
			id: "details",
			name: "Basic Details",
			renderContent: () => (
				<FormScrollPane>
					<BasicDetails {...props} />
				</FormScrollPane>
			),
		},
		{
			id: "location",
			name: "Location",
			renderContent: () => (
				<FormScrollPane>
					<LocationSelect
						initialLocation={initialLocation}
						newLocation={site.location}
						setLocation={(loc) => (site.location = loc)}
					/>
				</FormScrollPane>
			),
		},
	]

	return () => (
		<SimpleEditForm
			{...props}
			formWidth={60}
			title={getFormTitle(props.mode, "Site")}
			validateSave={() => {
				if (!site.name.trim().length) return "Please enter a name"
				return true
			}}
			validateDelete={confirmDelete}
			delete={user.hasPermission(Permission.DeleteSite) ? props.delete : undefined}
		>
			<FormTabs tabs={tabs} selectedTabId={tab} setSelectedTab={(t) => (tab = t)} />
		</SimpleEditForm>
	)
}

export default defineComponent(Form, crudTableFormPropTypes())
