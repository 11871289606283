import type { HolidayAllowance } from "@/models"
import { css } from "vite-css-in-js"
import { toRef, watch } from "vue"
import {
	defineComponent,
	optionalProp,
	requiredProp,
	ToggleButton,
	useOnInputNumber,
	type ReactiveComponent,
} from "vue-utils"

interface Props {
	allowance: HolidayAllowance
	setAllowance(allowance: HolidayAllowance): void
	defaultDays: number
	readonly?: boolean
}

const inputStyles = css`
	display: flex;
	align-items: center;
	flex-grow: 1;
	max-width: 14rem;

	border-radius: 0.25rem;
	border: var(--input-border);
	box-sizing: border-box;

	&[data-disabled=""] {
		background-color: var(--color-disabled-bg);
		border-color: var(--color-border-disabled);
		color: var(--color-disabled);
		cursor: not-allowed;
	}

	& > span {
		color: gray;
		margin: 0 0.5rem;
		width: 2.5rem;
	}
	& > input {
		border: none;
		padding-right: 0;
		flex-grow: 1;
		width: 6rem;
	}
`

const AllowanceInput: ReactiveComponent<Props> = (props) => {
	const { allowance, setAllowance, defaultDays, readonly = false } = $(props)

	let lastDays = $ref(typeof allowance === "number" ? allowance : defaultDays)
	watch(toRef(props, "allowance"), (allowance) => {
		if (typeof allowance === "number") {
			lastDays = allowance
		}
	})

	return () => (
		<div class="flex flex-wrap items-center spacing-6">
			<div class={inputStyles} data-disabled={readonly || allowance === "unlimited" ? "" : undefined}>
				<input
					type={typeof allowance === "number" ? "number" : "text"}
					step="0.5"
					min={0}
					max={366}
					value={typeof allowance === "number" ? allowance : "Unlimited"}
					onInput={useOnInputNumber((days) => props.setAllowance(days))}
					disabled={readonly || allowance === "unlimited"}
					required
				/>
				<span>{typeof allowance === "number" && "Days"}</span>
			</div>
			<div class="flex items-center spacing-4">
				<ToggleButton
					toggled={props.allowance === "unlimited"}
					setToggled={(unlimited) => (unlimited ? setAllowance("unlimited") : setAllowance(lastDays))}
					disabled={readonly}
				/>
				<span>Unlimited?</span>
			</div>
		</div>
	)
}

export default defineComponent(AllowanceInput, {
	allowance: requiredProp(String, Number),
	setAllowance: requiredProp(Function),
	defaultDays: requiredProp(Number),
	readonly: optionalProp(Boolean),
})
