import type { ApiTypeOf, Id, IIdentifier } from "@/models"
import { httpJsonRequestJsonResponse, httpRequest, httpRequestJsonResponse, RequestMethod } from "./httpService"

interface CrudEndpoints<T extends IIdentifier> {
	list: () => Promise<ApiTypeOf<T>[]>
	get: (id: Id) => Promise<ApiTypeOf<T>>
	create: (item: T) => Promise<ApiTypeOf<T>>
	edit: (item: T) => Promise<ApiTypeOf<T>>
	delete: (itemId: number) => Promise<void>
}

const createCrudEndpoints = <T extends IIdentifier>(rootUrl: string): CrudEndpoints<T> => ({
	list: () => httpRequestJsonResponse<T[]>(rootUrl, RequestMethod.GET),
	get: (id) => httpRequestJsonResponse(`${rootUrl}/${id}`, RequestMethod.GET),
	create: (item) => httpJsonRequestJsonResponse(rootUrl, item, RequestMethod.POST),
	edit: (item) => httpJsonRequestJsonResponse(rootUrl, item, RequestMethod.PUT),
	delete: async (itemId) => {
		await httpRequest(`${rootUrl}/${itemId}`, RequestMethod.DELETE)
	},
})

export default createCrudEndpoints
