import BootstrapButton from "@/components/BootstrapButton"
import { BasicForm, type FormButton } from "@/components/basic-form"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import usePopups from "@/data/popupsStore"
import { Permission, type User } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { Alert, ConfirmResult, ModalTitle, defineComponent, type ReactiveComponent } from "vue-utils"
import { crudTableFormPropTypes, getFormTitle, type CrudTableFormProps } from "../CrudTable"
import FormContents from "./FormContents"
import Modal from "@/components/Modal"

const FormContent: ReactiveComponent<CrudTableFormProps<User>> = (props) => {
	const user = proxyProp(props, "data")

	//Store initial value to avoid options changing when a user tries to unarchive a user
	const isArchived = user.isArchived

	const popups = usePopups()
	const loggedInUser = useLoggedInUser()

	async function confirmArchival() {
		const result = await popups.showConfirmPopup(() => (
			<Alert title="Confirm Archival">
				<p>Are you sure you wish to archive this user?</p>
				<strong>Neither the user nor their time sheets will continue to be accessible in the system.</strong>
			</Alert>
		))
		return result === ConfirmResult.Confirm
	}

	async function confirmUnarchive() {
		const result = await popups.showConfirmPopup(() => (
			<Alert title="Confirm Unarchival">
				<p>Are you sure you wish to unarchive this user?</p>
				<p>
					Any missing time sheets for company holidays will automatically be created, and the user will show up in
					reports once again.
				</p>
			</Alert>
		))
		return result === ConfirmResult.Confirm
	}

	function validateSave() {
		if (!user.lastName.trim().length) return "Please enter a surname"
		if (!user.firstName.trim().length) return "Please enter a forename"

		//Email validation is always a massive pain...this'll do for now
		if (user.email && user.email.trim().length && !user.email?.includes("@"))
			return "Please enter a valid email address"
		if (user.personalEmail && user.personalEmail.trim().length && !user.personalEmail?.includes("@"))
			return "Please enter a valid personal email address"

		return true
	}

	const buttons = $computed(() => {
		const buttons: FormButton[] = []
		if (props.delete && loggedInUser.hasPermission(Permission.DeleteUser) && !isArchived) {
			buttons.push({
				id: "archive",
				onSubmit: props.delete,
				validateForm: false,
				validate: confirmArchival,
				render(btnProps) {
					return (
						<BootstrapButton color="danger" {...btnProps}>
							Archive
						</BootstrapButton>
					)
				},
			})
		}
		buttons.push({
			id: "cancel",
			onSubmit: props.cancel,
			validateForm: false,
			render(btnProps) {
				return (
					<BootstrapButton color="secondary" {...btnProps}>
						{props.mode === "view" || isArchived ? "Close" : "Cancel"}
					</BootstrapButton>
				)
			},
		})
		if (props.save && !isArchived) {
			buttons.push({
				id: "save",
				onSubmit: props.save,
				validateForm: true,
				validate: validateSave,
				render(btnProps) {
					return (
						<BootstrapButton color="primary" {...btnProps}>
							{props.mode === "create" ? "Create" : "Save"}
						</BootstrapButton>
					)
				},
			})
		} else if (props.save && isArchived && loggedInUser.hasPermission(Permission.UnarchiveUser)) {
			buttons.push({
				id: "unarchive",
				onSubmit: async () => {
					user.isArchived = false
					return await props.save?.()
				},
				validateForm: false,
				validate: confirmUnarchive,
				render(btnProps) {
					return (
						<BootstrapButton color="success" {...btnProps}>
							Unarchive User
						</BootstrapButton>
					)
				},
			})
		}
		return buttons
	})

	return () => (
		<Modal class="flex flex-col overflow-auto" style={{ width: "75rem" }}>
			<BasicForm buttons={buttons} showDivider={false} class="flex flex-col" style={{ maxHeight: "80vh" }}>
				<ModalTitle title={getFormTitle(props.mode, "User")} />
				<hr />
				<FormContents user={props.data} readonly={props.readonly || isArchived} />
			</BasicForm>
		</Modal>
	)
}

export default defineComponent(FormContent, crudTableFormPropTypes())
