import { SortDirection } from "@/models"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import type { ThHTMLAttributes } from "vue"
import { renderSlot } from "vue"
import { defineComponent, optionalProp, type ReactiveComponent, requiredProp } from "vue-utils"
import Icon from "../Icon"

interface Props {
	direction: SortDirection | null
	setDirection(direction: SortDirection): void
	defaultDirection?: SortDirection
}

const headerStyles = css`
	padding: 0 !important;

	button {
		user-select: none;
		padding: 0.25em;

		all: unset;
		width: 100%;
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.25rem;

		cursor: pointer;
		&:hover {
			background-color: #b1dbb5ae;
		}
	}
	span {
		user-select: none;
	}

	svg {
		margin-top: 0.25rem;
		font-size: 0.85em;
	}
`

const SortableHeading: ReactiveComponent<Props, ThHTMLAttributes> = (props, { slots, attrs }) => {
	function toggle() {
		if (props.direction === SortDirection.Ascending) {
			props.setDirection(SortDirection.Descending)
		} else if (props.direction === SortDirection.Descending) {
			props.setDirection(SortDirection.Ascending)
		} else {
			props.setDirection(props.defaultDirection ?? SortDirection.Ascending)
		}
	}

	return () => (
		<th class={headerStyles} {...attrs}>
			<button onClick={toggle}>
				<span>{renderSlot(slots, "default")}</span>
				{props.direction ? (
					<Icon icon={props.direction === SortDirection.Ascending ? faChevronDown : faChevronUp} />
				) : (
					<div />
				)}
			</button>
		</th>
	)
}

export default defineComponent(SortableHeading, {
	direction: requiredProp(String, null),
	defaultDirection: optionalProp(String),
	setDirection: requiredProp(Function),
})
