import Icon from "@/components/Icon"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, useTimeout, type ReactiveComponent } from "vue-utils"
import LargeButton from "../components/LargeButton"
import BasicScreen from "./BasicScreen"

interface Props {
	title: string
	message: string

	isClockedIn: boolean
	showOverview(): void
	finish(): void
}

const successStyles = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;

	svg {
		color: var(--color-success);
		font-size: 5rem;
	}

	h2 {
		font-weight: 600;
	}

	h3 {
		margin-top: 1rem;
		font-weight: normal;
	}
`

const Success: ReactiveComponent<Props> = (props) => {
	useTimeout(30_000, () => props.finish())

	return () => (
		<BasicScreen
			title={() => (
				<div class={successStyles}>
					<Icon icon={faCheckCircle} />
					<h2>{props.title}</h2>
					<h3>{props.message}</h3>
				</div>
			)}
		>
			<div class="w-full flex flex-col items-center spacing-6">
				{props.isClockedIn && (
					<LargeButton color="secondary" onClick={props.showOverview}>
						Back to Overview
					</LargeButton>
				)}
				<div />
				<LargeButton color="primary" onClick={props.finish}>
					Finish
				</LargeButton>
			</div>
		</BasicScreen>
	)
}

export default defineComponent(Success, {
	title: requiredProp(String),
	message: requiredProp(String),

	isClockedIn: requiredProp(Boolean),
	showOverview: requiredProp(Function),
	finish: requiredProp(Function),
})
