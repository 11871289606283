import { LocalDate, Month } from "@js-joda/core"
import type { DayOfWeek } from "@js-joda/core"

export function formatDayOfWeek(day: DayOfWeek) {
	const date = new Date(0)
	date.setDate(day.ordinal() + 5)
	return date.toLocaleDateString(undefined, {
		weekday: "short",
	})
}

export function formatMonth(month: Month) {
	const date = new Date()
	date.setDate(1)
	date.setMonth(month.ordinal())
	return date.toLocaleDateString(undefined, {
		month: "long",
	})
}

export function getDate(day: DayOfWeek, week: number, month: Month, year: number): LocalDate {
	let date = LocalDate.of(year, month.ordinal() + 1, 1).getStartOfWeek()
	date = date.plusWeeks(week - 1)
	date = date.plusDays(day.ordinal())
	return date
}
