import { css } from "vite-css-in-js"
import { type HTMLAttributes, renderSlot } from "vue"
import { defineComponent, type NoProps, type ReactiveComponent } from "vue-utils"

const descriptorStyles = css`
	display: grid;
	grid-template-columns: max-content auto;
	row-gap: 0.35rem;
	column-gap: 1rem;
	align-items: center;

	dt {
		font-weight: 600;
	}
	dt::after {
		content: ":";
	}
	dd {
		margin: 0;
	}
`

const DescriptionList: ReactiveComponent<NoProps, HTMLAttributes> = (_, { attrs, slots }) => {
	return () => (
		<dl class={descriptorStyles} {...attrs}>
			{renderSlot(slots, "default")}
		</dl>
	)
}

export default defineComponent(DescriptionList)
