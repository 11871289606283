import { SortDirection, type Comparator } from "@/models"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { watchImmediate } from "@vueuse/core"
import { css } from "vite-css-in-js"
import { ref, toRef, type FunctionalComponent, type HTMLAttributes, type TdHTMLAttributes } from "vue"
import {
	defineComponent,
	getPageCount,
	optionalProp,
	paged,
	requiredProp,
	useOnInput,
	watchDebounce,
	type Component,
	type ReactiveComponent,
} from "vue-utils"
import BootstrapButton from "../BootstrapButton"
import Icon from "../Icon"
import Pagination from "../table/Pagination"
import SortableHeading from "../table/SortableHeading"

export interface SearchFilter<T> {
	(searchTerm: string, item: T): boolean
}

export interface DataTableSearchOptions {
	search: string
	setSearch(search: string): void
	showSearchBar?: boolean
}

export interface TableColumnOptions<T> {
	label: string
	renderCell?: FunctionalComponent<{ item: T; index: number }>
	filter?: SearchFilter<T>
	sort?: Comparator<T>
}

export type UnwrapColumns<T> = {
	[K in keyof T]: TableColumnOptions<T[K]>
}

export type GetTdAttributes<T, K extends keyof T = keyof T> = (item: T, column: K, value: T[K]) => TdHTMLAttributes

export interface Props<T> {
	entities: T[]
	getKey(item: T): string
	columns: Partial<UnwrapColumns<T>>
	search?: DataTableSearchOptions
	editClick(item: T): void
	readonly?: (item: T) => boolean
	addItem?: {
		text: string
		onClick(): void
	}
	pageSize?: number
	rowAttributes?: HTMLAttributes | ((item: T) => HTMLAttributes)
	tdAttributes?: TdHTMLAttributes | GetTdAttributes<T>
}

const tableStyles = css`
	border-collapse: collapse;
	width: 100%;
	table-layout: fixed;
	font-size: 0.92rem;
	margin-top: 0.75rem;

	display: grid;
	grid-template-columns: repeat(var(--dt-columns), 1fr) max-content;
	border: thin solid var(--table-border-color);
	border-radius: 0.25rem;

	& > thead,
	& > tbody,
	& > * > tr {
		display: contents;
	}

	th,
	td {
		display: flex;
		align-items: center;
		padding: 0.25rem 0.5rem !important;
	}

	th {
		display: flex;
		align-items: center;
		font-weight: 600;
		text-align: left;
	}
	th:not(:last-child) > button {
		background-color: transparent;
		justify-content: flex-start;
	}

	th:last-child,
	td:last-child {
		display: flex;
		justify-content: flex-end;

		& > button {
			padding: 0.35rem 0.5rem;
		}
	}
	td,
	th {
		background-color: white;
	}
	tr:nth-child(2n) > td,
	th {
		background-color: var(--color-fill-lighter);
	}
`

const createDataTable = <T,>(): Component<Props<T>> => {
	const DataTable: ReactiveComponent<Props<T>> = (props) => {
		const { entities, columns, editClick, readonly = false, pageSize = 12 } = $(props)

		const searchTerm = ref("")
		const searchTermToUse = ref("")
		let page = $ref(1)

		let sort = $shallowRef<{ column: keyof T; direction: SortDirection } | null>(null)

		function updateSearch(search: string) {
			if (props.search) {
				props.search.setSearch(search)
			} else {
				searchTerm.value = search
			}
		}

		watchImmediate(toRef(props, "search"), (searchData) => {
			if (searchData) {
				searchTerm.value = searchData.search
			}
		})

		function filterItem(item: T, search: string): boolean {
			for (const key in item) {
				const column = (columns as UnwrapColumns<T>)[key]
				if (column && column.filter && column.filter(search, item[key])) {
					return true
				}
			}
			return false
		}

		const filtered = $computed<T[]>(() => {
			const searchLower = searchTermToUse.value.toLocaleLowerCase()
			if (searchLower.length === 0) {
				return entities
			}
			return entities.filter((item) => filterItem(item, searchLower))
		})

		const sortedItems = $computed<T[]>(() => {
			const sortData = sort
			if (!sortData) {
				return filtered
			}
			const comparator = props.columns[sortData.column]?.sort
			if (!comparator) {
				return filtered
			}
			const multiplier = sortData.direction === SortDirection.Ascending ? 1 : -1
			return [...filtered].sort((item1, item2) => {
				const value1 = item1[sortData.column]
				const value2 = item2[sortData.column]
				return comparator(value1, value2) * multiplier
			})
		})

		const pagedItems = $computed<T[]>(() => paged(page, pageSize, sortedItems))
		const pageCount = $computed(() => getPageCount(pageSize, filtered.length))

		watchDebounce(searchTerm, () => {
			searchTermToUse.value = searchTerm.value
			page = 1
		})

		function renderRow(item: T) {
			const rowAttrs = typeof props.rowAttributes === "function" ? props.rowAttributes(item) : props.rowAttributes ?? {}
			return (
				<tr key={props.getKey(item)} {...rowAttrs}>
					{Object.entries(props.columns).map(([key, column], i) =>
						renderCell(i, item, key as keyof T, column as TableColumnOptions<T[keyof T]>)
					)}
					<td>
						<BootstrapButton color="secondary" onClick={() => editClick(item)}>
							{readonly && readonly(item) ? "View" : "Edit"}
						</BootstrapButton>
					</td>
				</tr>
			)
		}

		function renderCell<K extends keyof T>(index: number, item: T, key: K, column: TableColumnOptions<T[K]>) {
			const tdAttrs =
				typeof props.tdAttributes === "function" ? props.tdAttributes(item, key, item[key]) : props.tdAttributes ?? {}
			if (column.renderCell) {
				return (
					<td key={key} {...tdAttrs}>
						<column.renderCell index={index} item={item[key]} />
					</td>
				)
			}
			return <td key={key}>{item[key]}</td>
		}

		function renderHeader<K extends keyof T>(key: K, column: TableColumnOptions<T[K]>) {
			if (column.sort) {
				return (
					<SortableHeading
						key={key}
						direction={sort && sort.column === key ? sort.direction : null}
						setDirection={(dir) => (sort = { column: key, direction: dir })}
					>
						{column.label}
					</SortableHeading>
				)
			}
			return <th key={key}>{column.label}</th>
		}

		return () => (
			<>
				{(!props.search || props.search.showSearchBar !== false) && (
					<div class="flex items-center spacing-2 w-full">
						<Icon icon={faSearch} />
						<label class="flex-1">
							<input
								type="search"
								value={searchTerm.value}
								onInput={useOnInput(updateSearch)}
								placeholder="Type to search..."
							/>
						</label>
					</div>
				)}
				<table
					class={tableStyles}
					style={{
						"--dt-columns": Object.keys(props.columns).length,
					}}
				>
					<thead>
						<tr>
							{Object.entries(props.columns).map(([key, column]) =>
								renderHeader(key as keyof T, column as TableColumnOptions<T[keyof T]>)
							)}
							<th>
								{props.addItem && (
									<BootstrapButton
										color="success"
										onClick={() => props.addItem?.onClick()}
										style={{ fontSize: "0.92rem" }}
									>
										{props.addItem.text}
									</BootstrapButton>
								)}
							</th>
						</tr>
					</thead>
					<tbody>{pagedItems.map(renderRow)}</tbody>
				</table>

				<div class="flex justify-end">
					<Pagination page={page} totalPages={pageCount} setPage={(newPage) => (page = newPage)} listSize={11} />
				</div>
			</>
		)
	}
	return defineComponent(DataTable, {
		entities: requiredProp(Array),
		getKey: requiredProp(Function),
		columns: requiredProp(Object),
		search: optionalProp(Object),
		editClick: requiredProp(Function),
		readonly: optionalProp(Function),
		addItem: optionalProp(Object),
		pageSize: optionalProp(Number),
		rowAttributes: optionalProp(Function, Object),
		tdAttributes: optionalProp(Function, Object),
	})
}

export const stringFilter: SearchFilter<string> = (searchTerm, item) => {
	return item.toLocaleLowerCase().includes(searchTerm)
}
export const stringSort: Comparator<string> = (item1, item2) => item1.localeCompare(item2)

export const createMappedStringColumn = <T,>(label: string, getString: (item: T) => string): TableColumnOptions<T> => ({
	label,
	renderCell: ({ item }) => <span>{getString(item)}</span>,
	filter: (searchTerm, item) => stringFilter(searchTerm, getString(item)),
	sort: (e1, e2) => getString(e1).localeCompare(getString(e2)),
})

export default createDataTable
