import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import InfoMessage from "@/components/InfoMessage"
import TableControls from "@/components/table/TableControls"
import GridRow from "@/components/utility/GridRow"
import { SortDirection } from "@/models"
import { useLoading } from "@/utils/composition/useLoading"
import { faDownload, faFilter } from "@fortawesome/free-solid-svg-icons"
import { LocalDate } from "@js-joda/core"
import { onMounted, reactive, watch } from "vue"
import { useRouter } from "vue-router"
import {
	defineComponent,
	RefQueries,
	ShadowContainer,
	useQueryRef,
	watchDebounce,
	type ReactiveComponent,
} from "vue-utils"
import { TimeSheetOrderBy, type TimeSheetExportFilter, type TimeSheetExportResult } from "./model"
import { IdArrayQuery, NullableLocalDateQuery } from "./queries"
import { exportTimeSheetsCSV, searchTimeSheetsPaged } from "./service"
import "./styles.scss"
import "./table.scss"
import TableHeader from "./TableHeader"
import TimeSheetRow from "./TimeSheetRow"
import { useFileDownload } from "@/utils/composition/useFileDownload"

const TimeSheetExport: ReactiveComponent = () => {
	const router = useRouter()
	const { runAction } = useLoading()
	const { isDownloading, downloadFile } = useFileDownload()

	let result = $shallowRef<TimeSheetExportResult>()

	const from = useQueryRef("from", null, NullableLocalDateQuery)
	const to = useQueryRef("to", LocalDate.now(), NullableLocalDateQuery)
	const siteIds = useQueryRef("sites", [], IdArrayQuery)
	const activityIds = useQueryRef("activities", [], IdArrayQuery)
	const user = useQueryRef("user", "", RefQueries.String)
	const notes = useQueryRef("notes", false, RefQueries.Boolean)
	const approvedUser = useQueryRef("approved-by", "", RefQueries.String)

	const orderBy = useQueryRef("order-by", TimeSheetOrderBy.Date, RefQueries.enum(TimeSheetOrderBy))
	const orderDirection = useQueryRef("order", SortDirection.Descending, RefQueries.enum(SortDirection))
	const page = useQueryRef("page", 1, RefQueries.Int)

	function clearFilters() {
		const current = router.currentRoute.value
		void router.replace({
			path: current.path,
			query: {},
		})
	}

	const filter: TimeSheetExportFilter = reactive({
		from,
		to,
		siteIds,
		activityIds,
		user,
		notes,
		approvedUser,
		orderBy,
		orderDirection,
	})

	function onFilterChanged() {
		if (page.value === 1) void searchResults()
		else page.value = 1
	}

	async function searchResults() {
		result = await runAction(
			searchTimeSheetsPaged({
				page: Math.max(0, page.value - 1),
				filter,
			})
		)
	}

	watchDebounce(
		reactive({
			from,
			to,
			siteIds,
			activityIds,
			notes,
			orderBy,
			orderDirection,
		}),
		onFilterChanged,
		250
	)
	watchDebounce(reactive({ user, approvedUser }), onFilterChanged, 1000)
	watch(page, () => void searchResults())

	//Run once to load data on mount
	onMounted(() => void searchResults())

	function renderBody(result: TimeSheetExportResult) {
		return (
			<tbody>
				{result.entries.map((entry) => (
					<TimeSheetRow key={entry.id} timeSheet={entry} />
				))}
			</tbody>
		)
	}

	return () => (
		<ShadowContainer class="timesheet-export">
			<div class="flex justify-between items-center" style="margin-bottom: 1rem">
				<h2 style={{ margin: "0" }}>Search for and filter time sheets</h2>
				<GridRow elementCount={2}>
					<BootstrapButton color="danger" onClick={clearFilters}>
						<Icon icon={faFilter} />
						Clear Filters
					</BootstrapButton>
					<BootstrapButton
						color="success"
						onClick={() => void downloadFile(exportTimeSheetsCSV(filter))}
						isSubmitting={isDownloading.value}
					>
						<Icon icon={faDownload} />
						Export as CSV
					</BootstrapButton>
				</GridRow>
			</div>
			<table>
				<TableHeader filter={filter} setFilter={(f) => Object.assign(filter, f)} />
				{result && renderBody(result)}
			</table>

			{!!result && result.entries.length === 0 && (
				<div class="flex-auto flex justify-center items-center">
					<InfoMessage message="No time sheets were found matching that criteria" />
				</div>
			)}
			{!!result && result.entries.length > 0 && (
				<TableControls
					entityName="Time Sheets"
					page={page.value}
					totalItems={result.totalItems}
					pageSize={result.pageSize}
					setPage={(p) => (page.value = p)}
				/>
			)}
		</ShadowContainer>
	)
}

export default defineComponent(TimeSheetExport)
