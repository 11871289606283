import BasicForm, { basicFormButton, BasicSelect, type FormButton } from "@/components/basic-form"
import SimpleTable from "@/components/table/SimpleTable"
import { useActivities, useActivity, useSite } from "@/data/coreDataStore"
import type {
	Activity,
	GeneratingHolidayDayTimes,
	HolidayDayTimes,
	HolidayRequest,
	HolidayRequestWorkingTimes,
	Site,
} from "@/models"
import { useLoading } from "@/utils/composition/useLoading"
import { SiteListType, useSiteList } from "@/utils/composition/useSiteList"
import { proxyProp } from "@/utils/proxyProp"
import { css } from "vite-css-in-js"
import { ref, shallowRef, watch } from "vue"
import { defineComponent, ModalTitle, requiredProp, runWithMinimumDelay, type ReactiveComponent } from "vue-utils"
import { getHolidayRequestTimes, type ApproveRequestData } from "../../service"
import DayTimesRow from "./DayTimesRow"
import RequestDescriptor from "./RequestDescriptor"
import { verifyApproveRequest, verifyRejectRequest } from "./verifyReview"
import AutoColumns from "@/components/AutoColumns"

interface Props {
	request: HolidayRequest
	workingTimes: HolidayRequestWorkingTimes

	approve(data: ApproveRequestData): Promise<void>
	reject(reason: string): Promise<void>
	onCancel(): void
}

const formStyles = css`
	display: contents;

	& > div:first-of-type {
		min-width: 40rem;
		flex: 1 1 auto;
		overflow: auto;
		padding-right: 1rem;
	}

	td:nth-child(4),
	td:nth-child(5),
	td:nth-child(6) {
		text-align: center;
	}
`

const ReviewRequest: ReactiveComponent<Props> = (props) => {
	const { runAction } = useLoading()
	const workingTimes = proxyProp(props, "workingTimes")

	const site = shallowRef(useSite(workingTimes.siteId).value)
	const sites = useSiteList(SiteListType.Selection, {
		selectedSite: site,
	})

	const activity = shallowRef(useActivity(workingTimes.defaultActivityId).value)
	const activities = useActivities()

	const dayTimes = ref<GeneratingHolidayDayTimes[]>(workingTimes.times.map((d) => ({ ...d })))
	const comments = shallowRef("")

	const buttons: FormButton[] = [
		basicFormButton({
			id: "cancel",
			color: "secondary",
			onSubmit: () => props.onCancel(),
			text: "Cancel",
			validateForm: false,
		}),
		basicFormButton({
			id: "reject",
			color: "danger",
			onSubmit: () => props.reject(comments.value),
			text: "Reject",
			validateForm: true,
			validate: () => verifyRejectRequest(comments.value),
		}),
		basicFormButton({
			id: "approve",
			color: "primary",
			onSubmit: () =>
				props.approve({
					activityId: (activity.value as Activity).id,
					siteId: (site.value as Site).id,
					requestId: props.request.id,
					comments: comments.value,
					days: dayTimes.value.filter((d) => d.isGenerated) as HolidayDayTimes[],
				}),
			text: "Approve",
			validateForm: true,
			validate: verifyApproveRequest,
		}),
	]

	async function updateTimes() {
		const result = await runAction(runWithMinimumDelay(getHolidayRequestTimes(props.request.id, site.value?.id), 750))
		site.value = useSite(result.siteId).value
		dayTimes.value = result.times
	}

	watch(site, () => void updateTimes())

	return () => (
		<BasicForm buttons={buttons} class={formStyles}>
			<ModalTitle title="Review Holiday Request" />
			<hr />

			<div>
				<RequestDescriptor
					request={props.request}
					totalAllowance={workingTimes.totalAllowance}
					allowanceToUse={workingTimes.allowanceToUse}
					allowanceCurrentlyRemaining={workingTimes.allowanceCurrentlyRemaining}
					allowanceRemainingAfter={workingTimes.allowanceRemainingAfter}
				/>
				<hr />
				<h4 style={{ fontWeight: 500, fontSize: "1.125rem", margin: "1rem 0" }}>Time Sheets To Generate:</h4>

				<AutoColumns size="16rem" class="spacing-4">
					<label>
						Site
						<BasicSelect options={sites.value} value={site.value} setValue={(s) => (site.value = s)} required />
					</label>
					<label>
						Activity
						<BasicSelect
							options={Array.from(activities.values())}
							value={activity.value}
							setValue={(a) => (activity.value = a)}
							required
						/>
					</label>
				</AutoColumns>
				<div class="flex items-center spacing-4 flex-wrap"></div>

				<br />

				<SimpleTable style={{ tableLayout: "auto" }}>
					<thead>
						<tr>
							<th>Day</th>
							<th>Type</th>
							<th>Holiday</th>
							<th>Clock In</th>
							<th>Clock Out</th>
							<th>Break</th>
						</tr>
					</thead>
					<tbody>
						{dayTimes.value.map((dayTime) => (
							<DayTimesRow key={`${dayTime.date.toEpochDay()}-${dayTime.type}`} dayTimes={dayTime} />
						))}
					</tbody>
				</SimpleTable>
				<br />
				<label>
					Comments (Shown to Staff)
					<textarea v-model={comments.value} rows={3} />
				</label>
			</div>
		</BasicForm>
	)
}

export default defineComponent(ReviewRequest, {
	request: requiredProp(Object),
	workingTimes: requiredProp(Object),

	onCancel: requiredProp(Function),
	approve: requiredProp(Function),
	reject: requiredProp(Function),
})
