import { formatTimeInUserTimeZone } from "@/utils/timeUtils"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import LargeButton from "../shared/clock/components/LargeButton"
import BasicScreen from "../shared/clock/screens/BasicScreen"
import Entry from "./components/Entry"
import { Screen } from "./Screen"
import { useKioskModeStore } from "./store"

const VerifyDetails: ReactiveComponent = () => {
	const store = useKioskModeStore()
	const details = $computed(() => store.getDetailsOrThrow())

	const clockedInTime = $computed(() => {
		const clockedIn = details.activeTimeSheet?.clockIn?.entered
		if (!clockedIn) {
			return "Not yet clocked in today"
		}
		return formatTimeInUserTimeZone(clockedIn)
	})

	const userFullName = $computed(() => {
		const { firstName, lastName = "" } = details.user
		return `${firstName} ${lastName}`
	})

	const confirm = () => {
		const newScreen = store.hasClockedInInfo ? Screen.ClockedInOverview : Screen.ClockIn
		store.switchScreen(newScreen)
	}

	return () => (
		<BasicScreen title="Confirm Your Identity">
			<div class="w-full" style="margin: 1rem 0">
				<div style="padding: 0 1rem">
					<Entry name="Name" value={userFullName} />
					<Entry name="Clocked In At" value={clockedInTime} />
				</div>
			</div>

			<div class="flex flex-col flex-auto items-center justify-evenly w-full">
				<LargeButton color="success" onClick={confirm}>
					That's Me
				</LargeButton>
				<LargeButton color="danger" onClick={() => store.logout()}>
					Not Me
				</LargeButton>
			</div>
		</BasicScreen>
	)
}

export default defineComponent(VerifyDetails)
