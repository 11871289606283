import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import MultiSelect from "@/components/multi-select"
import GridRow from "@/components/utility/GridRow"
import type { Site, UserMetadata } from "@/models"
import { useFileDownload } from "@/utils/composition/useFileDownload"
import { faPrint } from "@fortawesome/free-solid-svg-icons"
import { LocalDate } from "@js-joda/core"
import { ModalTitle, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { generateTimeSheetsWeekReport } from "../../service"

interface Props {
	week: LocalDate
	sites: Site[]
	users: UserMetadata[]
	cancel(): void
}

const PrintAllTimeSheetsModal: ReactiveComponent<Props> = (props) => {
	const { isDownloading, downloadFile } = useFileDownload()

	let selectedSites = $shallowRef<Site[]>([])
	let selectedUsers = $shallowRef<UserMetadata[]>([])

	function handleSubmit(e: Event) {
		e.preventDefault()
		void downloadFile(
			generateTimeSheetsWeekReport({
				week: props.week,
				siteIds: selectedSites.map((s) => s.id),
				userIds: selectedUsers.map((u) => u.id),
			})
		)
	}

	return () => (
		<div
			style={{
				position: "absolute",
				inset: 0,
				zIndex: 2,
				background: "rgba(0, 0, 0, 0.4)",
				backdropFilter: "blur(0.5px)",
			}}
			class="flex flex-col items-center"
		>
			<div
				class="vu-modal flex-col"
				style={{
					display: "initial !important",
					marginTop: "10vh",
					width: "min(40rem, calc(100vw - 2.5rem))",
				}}
			>
				<ModalTitle title="Print Multiple Users" />
				<hr />

				<form class="flex flex-col spacing-4 flex-auto" onSubmit={handleSubmit}>
					<div class="flex flex-col spacing-2">
						Include Sites
						<MultiSelect
							class="w-full"
							options={props.sites}
							selected={selectedSites}
							setSelected={(newSites) => (selectedSites = newSites)}
							getKey={(s) => s.id}
							getText={(s) => s.name}
							allText="All Sites"
							noneText="All Sites"
							someText={(sites) =>
								sites.length <= 3 ? sites.map((s) => s.name).join(" | ") : `${sites.length} Sites Selected`
							}
							allowSearch
						/>
					</div>
					<br />

					<div class="flex flex-col spacing-2">
						Include Users
						<MultiSelect
							class="w-full"
							options={props.users}
							selected={selectedUsers}
							setSelected={(newUsers) => (selectedUsers = newUsers)}
							getKey={(u) => u.id}
							getText={(u) => `${u.lastName}, ${u.firstName}`}
							allText="All Users"
							noneText="All Users"
							someText={(users) =>
								users.length <= 3
									? users.map((u) => `${u.lastName}, ${u.firstName}`).join(" | ")
									: `${users.length} Users Selected`
							}
							allowSearch
						/>
					</div>

					<div class="flex items-center justify-end">
						<GridRow elementCount={2}>
							<BootstrapButton type="button" color="secondary" onClick={props.cancel}>
								Cancel
							</BootstrapButton>
							<BootstrapButton type="submit" color="primary" isSubmitting={isDownloading.value}>
								Print
								<Icon icon={faPrint} />
							</BootstrapButton>
						</GridRow>
					</div>
				</form>
			</div>
		</div>
	)
}

export default defineComponent(PrintAllTimeSheetsModal, {
	week: requiredProp(LocalDate),
	sites: requiredProp(Array),
	users: requiredProp(Array),
	cancel: requiredProp(Function),
})
