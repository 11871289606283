import { renderSlot, type FunctionalComponent } from "vue"
import { requiredProp } from "vue-utils"

const GridRow: FunctionalComponent<{ elementCount: number }> = ({ elementCount }, { slots }) => (
	<div
		class="grid spacing-3"
		style={{
			gridTemplateColumns: `repeat(${elementCount}, 1fr)`,
		}}
	>
		{renderSlot(slots, "default")}
	</div>
)
GridRow.props = {
	elementCount: requiredProp(Number),
}

export default GridRow
