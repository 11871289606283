import Icon from "@/components/Icon"
import type { Nullable } from "@/models"
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons"
import { DateTimeFormatter, LocalTime } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { useOnTabbed } from "./useOnTabbed"

interface Props {
	value: Nullable<LocalTime>
	save(time: LocalTime): Promise<void>
	cancel(): void
}

const editStyles = css`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;

	button {
		margin: 0;
		padding: 0 0.25em;
		border: none;
		outline: none;
		background-color: transparent;
	}

	button:hover:not([disabled]) {
		cursor: pointer;
	}

	svg {
		font-size: 1.25rem;
	}

	input {
		flex: 1 1 0;
		width: 0;
		margin: 0 0.25em;
	}

	.fa-xmark {
		color: var(--color-error);
	}
	.fa-check {
		color: var(--color-success);
	}
	button:disabled {
		.fa-check {
			color: #c7cec4;
		}
		cursor: not-allowed;
	}
`

const TIME_PATTERN = DateTimeFormatter.ofPattern("HH:mm")

const LocalTimeEdit: ReactiveComponent<Props> = (props) => {
	let strValue = $ref(props.value?.format(TIME_PATTERN) ?? "")
	const inputTabAttrs = useOnTabbed(() => void saveContent())

	async function saveContent() {
		if (!isValidValue()) {
			return
		}
		try {
			const time = LocalTime.parse(strValue, TIME_PATTERN)
			if (!time.equals(props.value)) {
				await props.save(time)
			}
		} catch (e) {
			console.error(e)
		} finally {
			props.cancel()
		}
	}

	function onKeyUp(e: KeyboardEvent) {
		if (e.key === "Enter" && isValidValue()) {
			void saveContent()
		} else if (e.key === "Escape") {
			props.cancel()
		}
	}

	function isValidValue() {
		try {
			LocalTime.parse(strValue, TIME_PATTERN)
			return true
		} catch (e) {
			return false
		}
	}

	return () => (
		<div class={editStyles}>
			<input
				type="time"
				value={strValue}
				onInput={(e) => (strValue = (e.target as HTMLInputElement).value)}
				onKeyup={onKeyUp}
				{...inputTabAttrs}
			/>
			<button title="Cancel" onClick={props.cancel} tabindex="-1">
				<Icon icon={faXmark} />
			</button>
			<button
				title={isValidValue() ? "Save" : "Please enter a valid value"}
				disabled={!isValidValue}
				onClick={() => void saveContent()}
				tabindex="-1"
			>
				<Icon icon={faCheck} />
			</button>
		</div>
	)
}

export default defineComponent(LocalTimeEdit, {
	value: requiredProp(null, LocalTime),
	save: requiredProp(Function),
	cancel: requiredProp(Function),
})
