import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import { useActivities } from "@/data/coreDataStore"
import type { AbsenceCalendarEntry, Activity, Id, UserMetadata } from "@/models"
import { arrayToMap } from "@/utils/arrayToMap"
import { faPrint } from "@fortawesome/free-solid-svg-icons"
import { DayOfWeek, Duration, LocalDate, Month } from "@js-joda/core"
import { css } from "vite-css-in-js"
import type { CSSProperties } from "vue"
import { calculateForegroundColor, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import AbsenceYearSelect from "./AbsenceYearSelect"

interface Props {
	year: number
	setYear(year: number): void
	user: UserMetadata
	entries: AbsenceCalendarEntry[]
}

const tableStyles = css`
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
	--table-border-color: rgba(0, 0, 0, 0.15);

	@media print {
		--table-border-color: rgba(0, 0, 0, 0.25);
		font-size: 0.9rem;
	}

	th,
	td {
		border: thin solid var(--table-border-color);
	}
	th {
		font-weight: 600;
		border-bottom: 1px solid #a0a0a0;
		text-align: center;
	}
	thead th {
		padding: 0.25rem 0;
		span {
			text-transform: capitalize;
			display: none;
		}
		@media (min-width: 92rem) {
			span:nth-child(1) {
				display: revert;
			}
		}
		@media (min-width: 56rem) and (max-width: calc(92rem - 1px)) {
			span:nth-child(2) {
				display: revert;
			}
		}
		@media (max-width: calc(56rem - 1px)) {
			span:nth-child(3) {
				display: revert;
			}
		}
	}

	th:first-child {
		width: 2.5rem;
	}
	tbody th {
		font-size: 0.85rem;
		padding: 0.075rem;
		border: 1px solid #a0a0a0;
	}

	td {
		border-left: 1px solid #a0a0a0;
		border-right: 1px solid #a0a0a0;
		min-width: 1rem;
		position: relative;
	}
	td > div {
		position: absolute;
		inset: 0;
		display: grid;
		grid-auto-flow: column;

		& > div {
			height: 100%;
			text-align: center;
			font-size: 0.8rem;
			overflow: hidden;

			display: flex;
			justify-content: center;
			align-items: center;
			container: absenceTime / inline-size;

			& > span {
				@container (max-width: 2.5rem) {
					display: none;
				}
			}
		}
	}
`

const printButtonStyles = css`
	@media print {
		display: none;
	}
`

function groupActivitiesByDate(entries: AbsenceCalendarEntry[], activitiesMap: Map<Id, Activity>) {
	function formatDuration(duration: Duration) {
		if (duration.isZero()) {
			return ""
		}
		const minutes = duration.toMinutes() % 60
		if (minutes == 0) {
			return `${duration.toHours()}h`
		}
		return `${duration.toHours()}${String(minutes / 60).substring(1, 4)}h`
	}

	return arrayToMap(
		entries,
		(x) => x.date.toEpochDay(),
		(x) =>
			x.activities.map(({ activityId, workingTime }) => {
				const activity = activitiesMap.get(activityId)

				return {
					activityId,
					name: activity?.name ?? "Unknown",
					color: activity?.highlightColor ? calculateForegroundColor(activity.highlightColor) : "inherit",
					background: activity?.highlightColor ?? "transparent",
					workingTime,
					workingTimeStr: formatDuration(workingTime),
				}
			})
	)
}

function getDaysInMonth(year: number): Map<Month, number> {
	const monthDays = new Map<Month, number>()
	for (const month of Month.values()) {
		const first = LocalDate.of(year, month, 1)
		const firstNext = LocalDate.of(month.equals(Month.DECEMBER) ? year + 1 : year, (month.value() % 12) + 1, 1)
		monthDays.set(month, firstNext.toEpochDay() - first.toEpochDay())
	}
	return monthDays
}

const AbsenceCalendar: ReactiveComponent<Props> = (props) => {
	const activities = useActivities()
	const byDate = $computed(() => groupActivitiesByDate(props.entries, activities))
	const monthDays = $computed(() => getDaysInMonth(props.year))

	function renderCell(month: Month, day: number) {
		const totalDays = monthDays.get(month) as number
		if (day > totalDays) {
			return <td key={day} style={{ border: "none", backgroundColor: "transparent" }} />
		}
		const date = LocalDate.of(props.year, month, day)
		const style: Partial<CSSProperties> = {}
		if (day === totalDays) {
			style.borderBottom = "1px solid #a0a0a0"
		}
		if (date.dayOfWeek().equals(DayOfWeek.SATURDAY) || date.dayOfWeek().equals(DayOfWeek.SUNDAY)) {
			style.backgroundColor = "#c0c0c070"
		}

		const activities = byDate.get(date.toEpochDay()) ?? []
		if (!activities || !activities.length) {
			return <td key={day} style={style} />
		}

		return (
			<td key={day} style={style}>
				<div>
					{activities.map((data) => (
						<div
							key={data.activityId}
							title={data.name}
							style={{ backgroundColor: data.background, color: data.color }}
						>
							<span>{data.workingTimeStr}</span>
						</div>
					))}
				</div>
			</td>
		)
	}

	return () => (
		<div>
			<div class="flex items-center spacing-6 flex-wrap" style={{ marginBottom: "1rem" }}>
				<h2 style={{ margin: 0, fontSize: "1.125rem", flexGrow: 1, fontWeight: 600 }}>
					Absence Calendar - {props.user.lastName}, {props.user.firstName} ({props.year})
				</h2>
				<div class="flex items-center spacing-6">
					<label class="spacing-4" style={{ flexDirection: "row", alignItems: "center" }}>
						<span style={{ fontWeight: 600 }}>Year</span>
						<AbsenceYearSelect year={props.year} setYear={props.setYear} />
					</label>
					<BootstrapButton class={printButtonStyles} color="primary" onClick={() => window.print()}>
						<Icon icon={faPrint} />
						Print Calendar
					</BootstrapButton>
				</div>
			</div>

			<table class={tableStyles}>
				<thead>
					<tr>
						<th style={{ border: "none" }}></th>
						{Month.values().map((month) => (
							<th key={month.toString()}>
								<span>{month.displayName()}</span>
								<span>{month.displayName().substring(0, 3)}</span>
								<span>{month.displayName().substring(0, 1)}</span>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{Array.from({ length: 31 }, (_, i) => (
						<tr key={i}>
							<th>{i + 1}</th>
							{Month.values().map((month) => renderCell(month, i + 1))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export default defineComponent(AbsenceCalendar, {
	year: requiredProp(Number),
	setYear: requiredProp(Function),
	user: requiredProp(Object),
	entries: requiredProp(Array),
})
