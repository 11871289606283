import AuthorizePermission from "@/authentication/AuthorizePermission"
import Permission from "@/models/Permission"
import { defineComponent, ShadowContainer } from "vue-utils"
import BasicPage from "../BasicPage"
import EnterKioskModeButton from "./EnterKioskModeButton"
import Form from "./Form"

export default defineComponent({
	name: "KioskModeSettings",
	setup: () => () => (
		<BasicPage title="Kiosk Mode Settings">
			<AuthorizePermission permissions={[Permission.KioskMode]}>
				<div>
					<EnterKioskModeButton />
				</div>
				<ShadowContainer>
					<Form />
				</ShadowContainer>
			</AuthorizePermission>
		</BasicPage>
	),
})
