import { LoadingError } from "@/utils/composition/useLoading"

export class HttpLoadingError extends LoadingError {
	readonly statusCode: number
	readonly statusText: string

	constructor(response: Response, message: string) {
		super(response.statusText, message)

		this.statusCode = response.status
		this.statusText = response.statusText
	}
}

const statusCodeTexts: Record<number, string> = {
	400: "There was an issue with the data you submitted. Try again or contact an administrator",
	401: "Please refresh your login and try again",
	403: "You do not have sufficient permissions to perform that action",
	404: "A required value was not found. Try again or contact an administrator",
	500: "An internal server error occurred. Try again or contact an administrator",
}

export const sendHttpRequest = async (url: string, request?: RequestInit): Promise<Response> => {
	let result
	try {
		result = await fetch(url, request)
	} catch (e) {
		console.error(e)
		throw new LoadingError(
			"Network Error",
			"A network error occurred performing that action. Check your connectivity and try again"
		)
	}
	const statusCode = result.status
	if (result.ok && statusCode >= 200 && statusCode < 300) {
		return result
	} else if (statusCode >= 400 && statusCode < 600) {
		const message = await tryReadJsonMessage(result)
		throw new HttpLoadingError(
			result,
			message ?? statusCodeTexts[statusCode] ?? "An unexpected error occurred performing that action"
		)
	} else {
		throw new HttpLoadingError(result, "An unexpected error occurred performing that action")
	}
}

const tryReadJsonMessage = async (result: Response): Promise<string | null> => {
	try {
		const json = (await result.json()) as { message?: string }
		return json.message ?? null
	} catch (e) {
		return null
	}
}
