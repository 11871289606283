import usePopups from "@/data/popupsStore"
import useSystemConfigStore from "@/data/systemConfigStore"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { Alert, defineComponent, type ReactiveComponent } from "vue-utils"
import Icon from "../Icon"

const indicatorStyles = css`
	all: unset;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2rem;
	height: 2rem;
	min-width: 2rem;
	min-height: 2rem;
	cursor: pointer;

	& > svg {
		font-size: 1.25rem;
		color: rgb(70, 159, 62);
	}

	&:hover {
		background-color: #cecece;
		border-radius: 1rem;
	}

	& > span {
		position: absolute;
		font-weight: bold;
		background: #e83a3a;
		font-size: 1.125rem;
		right: -0.5rem;
		bottom: 0.85rem;
		color: white;
		width: 1.25rem;
		height: 1.25rem;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

const UpdateIndicator: ReactiveComponent = () => {
	const popups = usePopups()
	const systemConfigStore = useSystemConfigStore()

	async function showMessage() {
		const result = await popups.confirm({
			cancelText: "Dismiss",
			confirmText: "Update Now",
			content: () => (
				<Alert title="Update Available">
					An update to TimeLord is available. Please update the system as soon as possible.
				</Alert>
			),
		})
		if (result) {
			await systemConfigStore.updateApp()
		}
	}

	return () => (
		<button class={indicatorStyles} onClick={() => void showMessage()}>
			<Icon icon={faDownload} />
			<span>!</span>
		</button>
	)
}

export default defineComponent(UpdateIndicator)
