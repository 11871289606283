import StateLoading from "@/components/state-loading"
import type { Id } from "@/models"
import { css } from "vite-css-in-js"
import {
	defineComponent,
	ensureLoadingHasResult,
	loadLoadable,
	useLoadableRef,
	watchDebounce,
	type ReactiveComponent,
} from "vue-utils"
import HolidayRequestList from "../HolidayRequestList"
import {
	approveHolidayRequest,
	cancelHolidayRequest,
	listHolidayRequests,
	rejectHolidayRequest,
	type ApproveRequestData,
	type CancelRequestData,
	type RejectRequestData,
} from "../service"
import HolidayFilter from "./HolidayFilter"
import ReviewRequestForm from "./review-form/ReviewRequestForm"
import { useHolidayRequestFilter } from "./useFilter"
import { useHolidayRequestTimes } from "./useHolidayRequestTimes"
import ReviewedRequestInformation from "./view-existing/ReviewedRequestInformation"
import Modal from "@/components/Modal"

const modalStyles = css`
	display: flex;
	flex-direction: column;
	width: 48rem;
	max-width: calc(100vw - 3rem);
`

const ReviewHolidays: ReactiveComponent = () => {
	const { requestId, selectedRequest } = useHolidayRequestTimes()

	const filter = useHolidayRequestFilter()
	const requestsLoadable = useLoadableRef(() => listHolidayRequests(filter))

	watchDebounce(filter, () => void loadLoadable(requestsLoadable), 750)
	void loadLoadable(requestsLoadable)

	async function approveRequest(data: ApproveRequestData) {
		await approveHolidayRequest(data)
		closeModal()

		void loadLoadable(requestsLoadable)
	}

	async function rejectRequest(data: RejectRequestData) {
		await rejectHolidayRequest(data)
		closeModal()

		void loadLoadable(requestsLoadable)
	}

	async function cancelRequest(data: CancelRequestData) {
		await cancelHolidayRequest(data)
		closeModal()

		void loadLoadable(requestsLoadable)
	}

	function closeModal() {
		requestId.value = null
	}

	return () => (
		<>
			{selectedRequest.value && (
				<Modal class={modalStyles}>
					{selectedRequest.value.times ? (
						<ReviewRequestForm
							request={selectedRequest.value.request}
							workingTimes={selectedRequest.value.times}
							onCancel={closeModal}
							approve={approveRequest}
							reject={(reason) =>
								rejectRequest({ requestId: selectedRequest.value?.request.id as Id, comments: reason })
							}
						/>
					) : (
						<ReviewedRequestInformation
							cancelRequest={(reason) => cancelRequest({ requestId: selectedRequest.value?.request.id as Id, reason })}
							request={selectedRequest.value.request}
							timeSheets={selectedRequest.value.timeSheets}
							close={closeModal}
						/>
					)}
				</Modal>
			)}
			<HolidayFilter filter={filter} updateFilter={(k, v) => (filter[k] = v)} lowestYear={2019} />
			<hr />
			<StateLoading stores={[requestsLoadable]}>
				<HolidayRequestList
					onRequestClick={(request) => (requestId.value = request.id)}
					requests={ensureLoadingHasResult(requestsLoadable)}
					noRequestsMessage="No holiday requests match your selected criteria."
					showOwner
				/>
			</StateLoading>
		</>
	)
}

export default defineComponent(ReviewHolidays)
