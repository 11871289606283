import { css } from "vite-css-in-js"
import { renderSlot, type TableHTMLAttributes } from "vue"
import { defineComponent, type NoProps, type ReactiveComponent } from "vue-utils"

const tableStyles = css`
	border-collapse: collapse;
	width: 100%;
	table-layout: fixed;

	th,
	td {
		border: thin solid var(--table-border-color);
		padding: 0.5rem;
	}
	th {
		font-weight: 600;
	}
	tr:nth-child(2n) {
		background-color: var(--color-fill-lighter);
	}
`

const SimpleTable: ReactiveComponent<NoProps, TableHTMLAttributes> = (_, { attrs, slots }) => {
	return () => (
		<table class={tableStyles} {...attrs}>
			{renderSlot(slots, "default")}
		</table>
	)
}

export default defineComponent(SimpleTable)
