import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import Pagination from "./Pagination"

export interface Props {
	/** Name of the entities which are being displayed in the table. Should be in plural form */
	entityName: string
	page: number
	pageSize: number
	totalItems: number
	setPage(page: number): void
}

const TableControls: ReactiveComponent<Props> = (props) => {
	const { entityName, page, pageSize, totalItems, setPage } = $(props)

	return () => (
		<div class="flex items-center justify-between" style="margin-top: 1rem">
			<span>
				Total {entityName} Found - <strong>{totalItems}</strong>
			</span>
			<Pagination page={page} setPage={setPage} totalPages={Math.ceil(totalItems / pageSize)} />
		</div>
	)
}

export default defineComponent(TableControls, {
	entityName: requiredProp(String),
	page: requiredProp(Number),
	totalItems: requiredProp(Number),
	pageSize: requiredProp(Number),
	setPage: requiredProp(Function),
})
