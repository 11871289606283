/**
 *
 * @param options Optional options
 * @throws {@link GeoLocationException} (Within the promise) if geolocation failed
 * @returns A promise for the GeolocationPosition
 */
export const requestGeoLocation = (options?: PositionOptions): Promise<GeolocationPosition> =>
	new Promise((resolve, reject) =>
		navigator.geolocation.getCurrentPosition(resolve, (error) => reject(new GeoLocationException(error)), options)
	)

export class GeoLocationException extends Error implements GeolocationPositionError {
	readonly code: number
	readonly message: string
	readonly PERMISSION_DENIED: GeolocationPositionError["PERMISSION_DENIED"]
	readonly POSITION_UNAVAILABLE: GeolocationPositionError["POSITION_UNAVAILABLE"]
	readonly TIMEOUT: GeolocationPositionError["TIMEOUT"]

	constructor(error: GeolocationPositionError) {
		super(error.message)

		this.code = error.code
		this.message = error.message
		this.PERMISSION_DENIED = error.PERMISSION_DENIED
		this.POSITION_UNAVAILABLE = error.POSITION_UNAVAILABLE
		this.TIMEOUT = error.TIMEOUT
	}
}
