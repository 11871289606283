import { css } from "vite-css-in-js"
import type { FunctionalComponent } from "vue"
import HiddenCharacter from "./HiddenNumber"

const containerStyles = css`
	height: 3em;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem 0;
`

const PinDisplay: FunctionalComponent<{ pin: string }> = ({ pin }) => (
	<div class={containerStyles}>
		{[...pin].map((ch, i) => (
			<HiddenCharacter key={i} character={ch} />
		))}
	</div>
)

export default PinDisplay
