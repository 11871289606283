import { css } from "vite-css-in-js"
import {
	computed,
	onUpdated,
	shallowRef,
	toRef,
	watch,
	type HTMLAttributes,
	type InputHTMLAttributes,
	type SelectHTMLAttributes,
} from "vue"
import {
	defineComponent,
	optionalProp,
	requiredProp,
	useOnInput,
	watchDebounce,
	type ConsumedProps,
	type ReactiveComponent,
} from "vue-utils"
import DropdownList, { type DropdownListOption } from "./DropdownList"

interface Props<T> {
	options: T[]
	selected: T[]
	setSelected(items: T[]): void
	getKey(item: T): number | string
	getText(item: T): string
	dropDownAttributes?: HTMLAttributes

	noneText: string
	allText: string
	someText(selected: T[]): string
	allowSearch?: boolean
	singleSelect?: boolean
}

const selectStyles = css`
	cursor: default;
	user-select: none;
	text-overflow: ellipsis;
	padding-right: 0.75em !important;
`

export const createMultiSelect = <T,>() => {
	const MultiSelect: ReactiveComponent<Props<T>, SelectHTMLAttributes & InputHTMLAttributes> = (props, { attrs }) => {
		const inputRef = shallowRef<HTMLInputElement | HTMLSelectElement>()
		const searchTerm = shallowRef<string>("")
		let dropdownOpen = $shallowRef<boolean>(false)

		let selectedItems = $shallowRef(props.selected)
		const selectedIds = $computed(() => new Set(selectedItems.map((item) => props.getKey(item))))

		const usedSearch = shallowRef("")
		watchDebounce(searchTerm, () => (usedSearch.value = searchTerm.value))

		const filteredOptions = computed(() => {
			if (!props.allowSearch && usedSearch.value.length === 0) {
				return props.options
			}
			const searchLower = usedSearch.value.toLocaleLowerCase()
			return props.options.filter((option) => props.getText(option).toLocaleLowerCase().includes(searchLower))
		})

		watch(toRef(props, "selected"), (selected) => (selectedItems = selected))

		const inputText = $computed(() => {
			if (selectedItems.length === 0) {
				return props.noneText
			}
			if (selectedItems.length === props.options.length) {
				return props.allText
			}
			return props.someText(selectedItems)
		})

		onUpdated(() => {
			if (dropdownOpen && inputRef.value) {
				inputRef.value.focus()
			}
		})

		function showInput() {
			return dropdownOpen && props.allowSearch
		}

		function showSelect() {
			return !showInput()
		}

		function toggleOption(option: T) {
			const key = props.getKey(option)
			if (selectedIds.has(key)) {
				selectedItems = props.singleSelect ? [option] : selectedItems.filter((option) => props.getKey(option) !== key)
			} else {
				selectedItems = props.singleSelect ? [option] : [...selectedItems, option]
			}
			if (props.singleSelect) closeDropdown()
		}

		function onSelectClick(e: Event) {
			const el = e.target as HTMLSelectElement
			if (dropdownOpen) {
				el.blur()
			} else {
				el.focus()
			}
		}

		function openDropdown() {
			dropdownOpen = true
			searchTerm.value = ""
			usedSearch.value = ""
		}

		function closeDropdown() {
			dropdownOpen = false

			const existingIds = new Set(props.selected.map(props.getKey))
			if (existingIds.size !== selectedIds.size || Array.from(existingIds).some((id) => !selectedIds.has(id))) {
				props.setSelected(selectedItems)
			}
		}

		function renderSelect() {
			return (
				<select
					key="inputSelect"
					ref={inputRef}
					class={selectStyles}
					value=""
					placeholder={inputText}
					onMousedown={(e) => e.preventDefault()}
					onClick={onSelectClick}
					onFocus={openDropdown}
					onBlur={() => showSelect() && closeDropdown()}
					{...attrs}
				>
					<option value="" disabled>
						{inputText}
					</option>
					{props.options.map((option) => (
						<option key={props.getKey(option)} value={props.getKey(option)}>
							{props.getText(option)}
						</option>
					))}
				</select>
			)
		}

		function renderSearch() {
			return (
				<input
					key="inputSearch"
					type="search"
					ref={inputRef}
					value={searchTerm.value}
					class={selectStyles}
					onKeyup={useOnInput((txt) => (searchTerm.value = txt))}
					placeholder="Type to search..."
					onMousedown={(e) => e.preventDefault()}
					onFocus={openDropdown}
					onBlur={() => showInput() && closeDropdown()}
					{...attrs}
				/>
			)
		}

		function renderList() {
			type ExpandedOption = DropdownListOption & { option: T }
			const options = filteredOptions.value.map<ExpandedOption>((option) => ({
				id: props.getKey(option),
				name: props.getText(option),
				selected: selectedIds.has(props.getKey(option)),
				option,
			}))

			return (
				<DropdownList
					{...props.dropDownAttributes}
					options={options}
					toggleSelected={(option) => toggleOption((option as ExpandedOption).option)}
				/>
			)
		}

		return () => (
			<div class="relative">
				{dropdownOpen && props.allowSearch ? renderSearch() : renderSelect()}
				{dropdownOpen && renderList()}
			</div>
		)
	}

	return defineComponent(MultiSelect, {
		options: requiredProp(Array),
		selected: requiredProp(Array),
		setSelected: requiredProp(Function),
		getKey: requiredProp(Function),
		getText: requiredProp(Function),

		noneText: requiredProp(String),
		allText: requiredProp(String),
		someText: requiredProp(Function),
		allowSearch: optionalProp(Boolean),
		dropDownAttributes: optionalProp(Object),
		singleSelect: optionalProp(Boolean),
	})
}

const MultiSelect = createMultiSelect<object>() as unknown as <T>(
	props: ConsumedProps<Props<T>, SelectHTMLAttributes & InputHTMLAttributes>
) => JSX.Element
export default MultiSelect
