import BootstrapButton from "@/components/BootstrapButton"
import usePopups, { ConfirmResult } from "@/data/popupsStore"
import type { TimeSheetEntry } from "@/models"
import { getUsedTime } from "@/services/timesheetsService"
import { useLoading } from "@/utils/composition/useLoading"
import type { ReactiveComponent } from "vue-utils"
import { Alert, defineComponent, requiredProp } from "vue-utils"
import useTimeSheetManagementStore from "../../store"

interface Props {
	timeSheet: TimeSheetEntry
	canApprove: boolean
	canUnApprove: boolean
}

const Approval: ReactiveComponent<Props> = (props) => {
	const { timeSheet, canApprove, canUnApprove } = $(props)

	const store = useTimeSheetManagementStore()
	const { wrapAction, runAction } = useLoading()

	const popups = usePopups()

	const approve = wrapAction(async () => {
		await store.approveTimeSheet(timeSheet.id)
		await store.queryRelevantUserList()
	})

	const unApprove = async () => {
		const result = await popups.showConfirmPopup(() => (
			<Alert title="Confirm un-approval of time sheet">
				<p>Are you sure you wish to un-approve this time sheet?</p>
				<p>All previous approval information will be lost.</p>
			</Alert>
		))

		if (result === ConfirmResult.Confirm) {
			await runAction(store.unApproveTimeSheet(timeSheet.id))
		}
	}

	const approveEnabled = $computed(
		() => canApprove && !timeSheet.approvalDate && !!getUsedTime(timeSheet.clockIn) && !!getUsedTime(timeSheet.clockOut)
	)

	return () => {
		if (canUnApprove) {
			return (
				<div style={{ flexGrow: 1, fontSize: "0.92em" }}>
					<BootstrapButton class="w-full" color="danger" onClick={() => void unApprove()}>
						Unapprove
					</BootstrapButton>
				</div>
			)
		}
		if (canApprove) {
			return (
				<div
					style={{ flexGrow: 1, fontSize: "0.92em" }}
					title={approveEnabled ? "" : "Must provide a clock in & clock out time to approve"}
				>
					<BootstrapButton class="w-full" color="primary" onClick={() => void approve()} disabled={!approveEnabled}>
						Approve
					</BootstrapButton>
				</div>
			)
		}
		return null
	}
}

export default defineComponent(Approval, {
	timeSheet: requiredProp(Object),
	canApprove: requiredProp(Boolean),
	canUnApprove: requiredProp(Boolean),
})
