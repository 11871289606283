import PlainIconButton from "@/components/PlainIconButton"
import { BasicSelect } from "@/components/basic-form"
import MultiSelect from "@/components/multi-select"
import { useActivities, useActivity, useSite } from "@/data/coreDataStore"
import type { Site } from "@/models"
import { showErrorMessage, useLoading } from "@/utils/composition/useLoading"
import { SiteListType, useSiteList } from "@/utils/composition/useSiteList"
import { faSync } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { shallowRef } from "vue"
import { Required, defineComponent, refSetter, type ReactiveComponent } from "vue-utils"
import LargeButton from "../shared/clock/components/LargeButton"
import BasicScreen from "../shared/clock/screens/BasicScreen"
import { useSelfServiceStore } from "./store"

const dropdownStyles = css`
	& > ul {
		max-height: 12rem;
	}
`

function isApple() {
	return (
		["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes("Mac") && "ontouchend" in document)
	)
}

const NotClockedIn: ReactiveComponent = () => {
	const { runAction } = useLoading()

	const store = useSelfServiceStore()
	const useSimpleSelect = isApple()

	const activity = shallowRef(useActivity(store.getDetailsOrThrow().lastActivityId).value)
	const allActivities = useActivities()
	const activities = $computed(() => Array.from(allActivities.values()).filter((activity) => activity.canClockInWith))

	const site = shallowRef<Site | null>(useSite(store.getDetailsOrThrow().lastSiteId).value)
	const sites = useSiteList(SiteListType.NonArchived)

	function clockIn() {
		if (!activity.value) {
			void showErrorMessage("No Activity Selected", "Please select an activity")
		} else if (!site.value) {
			void showErrorMessage("No Site Selected", "Please select a site")
		} else {
			void runAction(store.clockIn(site.value.id, activity.value.id))
		}
	}

	return () => (
		<BasicScreen
			title={() => (
				<h2 class="flex justify-center spacing-2" style={{ alignItems: "end" }}>
					Not Clocked In
					<PlainIconButton icon={faSync} title="Refresh" onClick={() => void store.reloadDetails()} />
				</h2>
			)}
		>
			<div class="flex flex-col spacing-8 w-full">
				<h3 style={{ marginTop: "2rem", marginBottom: 0, fontWeight: 500 }}>You are not currently clocked in</h3>
				<h3 style={{ margin: 0, fontWeight: 500 }}>To clock in, please select your current activity and site.</h3>
				<label>
					<Required label="Activity" />
					<BasicSelect
						options={activities}
						value={activity.value}
						setValue={refSetter(activity)}
						defaultText="Select Your Current Activity"
						required
					/>
				</label>
				<label>
					<Required label="Site" />
					{useSimpleSelect ? (
						<BasicSelect
							options={sites.value}
							value={site.value}
							setValue={refSetter(site)}
							defaultText="Select Your Site"
							required
						/>
					) : (
						<MultiSelect
							class="w-full"
							options={sites.value}
							selected={site.value ? [site.value] : []}
							setSelected={(sites) => (site.value = sites.length === 0 ? null : sites[0])}
							getKey={(site) => site.id}
							getText={(site) => site.name}
							noneText="Select Site"
							allText="All Sites"
							someText={(sites) => (sites.length === 1 ? sites[0].name : `${sites.length} Sites Selected`)}
							dropDownAttributes={{ class: dropdownStyles }}
							allowSearch
							singleSelect
						/>
					)}
				</label>
				<LargeButton color="primary" disabled={!activity.value || !site.value} onClick={clockIn}>
					Clock In
				</LargeButton>
			</div>
		</BasicScreen>
	)
}

export default defineComponent(NotClockedIn)
