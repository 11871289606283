import type { HolidayRequest } from "@/models"
import { reactive } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import HolidayRequestList from "../HolidayRequestList"
import HolidayFilter, { filterHolidays, type HolidayFilter as HolidayFilterData } from "./HolidayFilter"

interface Props {
	requests: HolidayRequest[]
}

const FilterableRequestList: ReactiveComponent<Props> = (props) => {
	const filteredRequests = $computed(() => filterHolidays(props.requests, filter))
	const filter = reactive<HolidayFilterData>({
		status: undefined,
		year: undefined,
	})

	return () => {
		if (props.requests.length === 0) {
			return <p>You have not yet created any holiday requests.</p>
		}
		return (
			<>
				<hr />
				<HolidayFilter requests={props.requests} filter={filter} updateFilter={(k, v) => (filter[k] = v)} />
				<HolidayRequestList
					requests={filteredRequests}
					noRequestsMessage="No holiday requests match your filter"
					showOwner={false}
				/>
			</>
		)
	}
}

export default defineComponent(FilterableRequestList, {
	requests: requiredProp(Array),
})
