import { useLoggedInUser } from "@/data/loggedInUserStore"
import type { LoggedInUser } from "@/models/LoggedInUser"
import Permission from "@/models/Permission"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import { faClock, faHammer, faHome, faLock, faTable, faUserClock } from "@fortawesome/free-solid-svg-icons"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import Navbar, { type NavEntry } from "../components/navbar"

interface PermissionNavEntry extends NavEntry {
	permission?: Permission
	canView?: (user: LoggedInUser) => boolean
}

const routes: PermissionNavEntry[] = [
	{
		name: "Dashboard",
		icon: faHome,
		route: "/dashboard",
		permission: Permission.OnSiteNow, //As more features get added to the dashboard, should remove this check
	},
	{
		name: "Time Sheets",
		icon: faClock,
		route: "/timesheets",
		permission: Permission.ViewTimeSheets,
	},
	{
		name: "Time Logs",
		icon: faTable,
		route: "/timesheet-export",
		permission: Permission.ExportTimeSheets,
	},
	{
		name: "Kiosk Mode",
		icon: faLock,
		route: "/kiosk-mode-settings",
		permission: Permission.KioskMode,
	},

	{
		name: "Administration",
		icon: faHammer,
		canView: (user) =>
			[
				Permission.ViewActivities,
				Permission.ViewSites,
				Permission.ViewTrades,
				Permission.ViewUsers,
				Permission.ViewCompanyHolidays,
			].some((perm) => user.hasPermission(perm)),
		route: "/administration",
	},
	{
		name: "Holidays",
		icon: faCalendar,
		route: "/holidays",
	},
	{
		name: "Self Service",
		icon: faUserClock,
		route: "/self-service",
	},
]

const TimeLordNavBar: ReactiveComponent = () => {
	const user = useLoggedInUser()
	const viewableRoutes = $computed(() =>
		routes.filter((route) => {
			if (route.permission) {
				return user.hasPermission(route.permission)
			}
			if (route.canView && !route.canView(user)) {
				return false
			}
			return true
		})
	)
	return () => <Navbar routes={viewableRoutes} />
}

export default defineComponent(TimeLordNavBar)
