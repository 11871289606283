import TradeInformation from "@/components/misc/TradeInformation"
import SimpleTable from "@/components/table/SimpleTable"
import { useActivities, useSites, useTrades, useUserTypes } from "@/data/coreDataStore"
import type { OnSiteNowUser } from "@/models"
import { formatTimeInUserTimeZone } from "@/utils/timeUtils"
import { Instant } from "@js-joda/core"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	users: OnSiteNowUser[]
}

const OnSiteNowTable: ReactiveComponent<Props> = (props) => {
	const trades = useTrades()
	const sites = useSites()
	const activities = useActivities()
	const userTypes = useUserTypes()

	function renderRow(user: OnSiteNowUser) {
		const trade = user.user.tradeId === null ? null : trades.get(user.user.tradeId) ?? null
		const clockedIn = user.clockedIn.entered ?? user.clockedIn.override ?? user.clockedIn.autoAdjusted
		const clockedInFormatted = clockedIn ? formatTimeInUserTimeZone(Instant.parse(clockedIn)) : "-"

		return (
			<tr key={user.user.id}>
				<td>
					<TradeInformation trade={trade}>{trade?.name ?? "-"}</TradeInformation>
				</td>
				<td>
					{user.user.lastName}, {user.user.firstName}
				</td>
				<td>{sites.get(user.siteId)?.name}</td>
				<td>{activities.get(user.activityId)?.name}</td>
				<td>{clockedInFormatted}</td>
				<td>{userTypes.get(user.user.userTypeId)?.name}</td>
			</tr>
		)
	}

	return () =>
		props.users.length === 0 ? (
			<p>There are no users currently clocked in</p>
		) : (
			<SimpleTable style={{ tableLayout: "auto" }}>
				<thead>
					<tr>
						<th>Trade</th>
						<th>Name</th>
						<th>Site</th>
						<th>Activity</th>
						<th>Clocked In</th>
						<th>User Type</th>
					</tr>
				</thead>
				<tbody>{props.users.map(renderRow)}</tbody>
			</SimpleTable>
		)
}

export default defineComponent(OnSiteNowTable, {
	users: requiredProp(Array),
})
