import UserNameDisplay from "@/components/UserNameDisplay"
import { userComparator } from "@/services/usersService"
import { ScrollPane, ToggleButton, defineComponent, useStoreValue, type ReactiveComponent } from "vue-utils"
import useTimeSheetManagementStore from "../store"

const UserList: ReactiveComponent = () => {
	const store = useTimeSheetManagementStore()
	const filterUnapproved = useStoreValue(store, "filterUnapproved")
	let selectedUser = $(useStoreValue(store, "selectedUser"))

	const users = $(useStoreValue(store, "listedUsers"))

	let search = $ref("")

	const filteredUsers = $computed(() => {
		const searchLower = search.toLocaleLowerCase()

		const filtered = Array.from(users.values()).filter((user) => {
			return (
				(!!selectedUser && selectedUser.id === user.id) ||
				`${user.firstName} ${user.lastName}`.toLocaleLowerCase().includes(searchLower)
			)
		})

		if (!!selectedUser && !users.has(selectedUser.id)) {
			filtered.unshift(selectedUser)
			filtered.sort(userComparator)
		}
		return filtered
	})

	return () => (
		<div class="flex flex-col">
			<h4 style="margin: 0">Assigned Users ({users.size})</h4>
			<hr />
			<div class="flex flex-col spacing-3">
				<input
					placeholder="Search..."
					value={search}
					onInput={(e) => (search = (e.target as HTMLInputElement).value)}
					style="font-size: 0.9rem"
				/>
				<div class="flex spacing-4 items-center">
					<span>Show only staff with unapproved time sheets</span>
					<ToggleButton toggled={filterUnapproved.value} setToggled={(show) => (filterUnapproved.value = show)} />
				</div>
			</div>
			<hr />
			<ScrollPane class="flex-1">
				{filteredUsers.length === 0 && <div>There are no users to display</div>}
				<ul>
					{filteredUsers.map((user) => (
						<li key={user.id}>
							<UserNameDisplay
								user={user}
								selected={user.id === selectedUser?.id}
								onClick={() => (selectedUser = user.id === selectedUser?.id ? null : user)}
							/>
						</li>
					))}
				</ul>
			</ScrollPane>
		</div>
	)
}

export default defineComponent(UserList)
