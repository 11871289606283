import type { Site } from "@/models"
import createCrudEndpoints from "./createCrudEndpoint"

const endpoints = createCrudEndpoints<Site>("/api/sites")

export const listSites = endpoints.list
export const getSite = endpoints.get
export const createSite = endpoints.create
export const editSite = endpoints.edit
export const deleteSite = endpoints.delete
