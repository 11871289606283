import { DateTimeFormatter, LocalTime } from "@js-joda/core"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import type { Nullable } from "@/models"
import DisplayEdit from "./DisplayEdit"
import LocalTimeEdit from "./LocalTimeEdit"

interface Props {
	value: Nullable<LocalTime>
	save(time: LocalTime): Promise<void>
	readonly?: boolean
}

const EditTime: ReactiveComponent<Props> = (props) => {
	return () => (
		<DisplayEdit
			displayText={props.value?.format(DateTimeFormatter.ofPattern("HH:mm")) ?? ""}
			renderContent={({ hide }) => <LocalTimeEdit value={props.value} save={props.save} cancel={hide} />}
			readonly={props.readonly}
		/>
	)
}

export default defineComponent(EditTime, {
	value: requiredProp(null, LocalTime),
	save: requiredProp(Function),
	readonly: optionalProp(Boolean),
})
