import type { FunctionalComponent } from "vue"
import type { ErrorComponentProps } from "vue-utils"
import BootstrapButton from "../BootstrapButton"
import ErrorMessage from "../utility/ErrorMessage"

export const BasicErrorPage: FunctionalComponent<ErrorComponentProps> = ({ reload, error }) => (
	<div style="padding: 1rem">
		<ErrorMessage text="Error Loading System Data" />
		<br />
		<div>An unexpected error occurred trying to load system data.</div>
		<div>Please check your internet connectivity and reload, or contact an administrator</div>

		<br />
		<BootstrapButton color="primary" onClick={() => void reload()}>
			<span style="padding: 0.25rem 1.5rem">Reload Page Content</span>
		</BootstrapButton>

		<br />
		<br />
		<small style="color: var(--color-info)">Error Details: {error.message}</small>
	</div>
)
