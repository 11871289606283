import useSystemConfigStore from "@/data/systemConfigStore"
import type { ReactiveComponent } from "vue-utils"
import { defineComponent } from "vue-utils"
import UpdateMessage from "./UpdateMessage"

declare global {
	interface Location {
		reload(hard?: boolean): void
	}
}

const UpdateChecker: ReactiveComponent = () => {
	const systemStore = useSystemConfigStore()

	return () => systemStore.updateAvailable && <UpdateMessage update={() => void systemStore.updateApp()} />
}

export default defineComponent(UpdateChecker)
