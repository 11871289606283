import { formatEnum } from "@/utils/formatEnum"
import type { SelectHTMLAttributes } from "vue"
import {
	defineComponent,
	requiredProp,
	type Component,
	type ReactiveComponent,
	optionalProp,
	type ReactiveProps,
	useOnInput,
} from "vue-utils"

type Props<T> = {
	items: Record<string, T>
	getName?: (item: T) => string
	value: T | null
} & (
	| {
			required: true
			placeholder?: string
			setValue(value: T): void
	  }
	| {
			required?: false
			placeholder: string
			setValue(value: T | null): void
	  }
)

const createEnumSelect = <T extends string>(): Component<Props<T>, SelectHTMLAttributes> => {
	const EnumSelect: ReactiveComponent<Props<T>> = (props, { attrs }) => {
		const getName = (item: T) => (props.getName ? props.getName(item) : formatEnum(item))

		return () => (
			<select
				value={props.value ?? ""}
				onInput={useOnInput((value) => {
					if (props.required) {
						props.setValue(value as T)
					} else {
						props.setValue(value === "" ? null : (value as T))
					}
				})}
				required={props.required}
				{...attrs}
			>
				{!!props.placeholder && (
					<option disabled={!props.required} value="">
						{props.placeholder}
					</option>
				)}
				{Object.values(props.items).map((item) => (
					<option key={item} value={item}>
						{getName(item)}
					</option>
				))}
			</select>
		)
	}
	return defineComponent<Props<T>, SelectHTMLAttributes>(EnumSelect, {
		items: requiredProp(Object),
		value: requiredProp(String, null),
		setValue: requiredProp(Function),
		placeholder: optionalProp(String),
		required: optionalProp(Boolean),
		getName: optionalProp(Function),
	} as ReactiveProps<Props<T>>)
}

export default createEnumSelect
