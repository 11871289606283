import type { User } from "@/models"
import { LoggedInUser } from "@/models/LoggedInUser"
import type Permission from "@/models/Permission"
import { httpRequestJsonResponse, RequestMethod } from "@/services/httpService"

export const getLinkedAccount = async (): Promise<LoggedInUser | null> => {
	const result = await httpRequestJsonResponse<{ user: User; permissions: Permission[] } | null>(
		"/api/accounts/get-linked",
		RequestMethod.GET
	)
	if (!result) {
		return null
	}
	return new LoggedInUser(result.user, result.permissions)
}
