import { useLoggedInUser } from "@/data/loggedInUserStore"
import type Permission from "@/models/Permission"
import { renderSlot } from "vue"
import type { ReactiveComponent } from "vue-utils"
import { defineComponent, optionalProp, requiredProp } from "vue-utils"

interface Props {
	permissions: Permission[]
	type?: "any" | "every"
	silent?: boolean
}

const AuthorizePermission: ReactiveComponent<Props> = (props, { slots }) => {
	const user = useLoggedInUser()
	const hasPermission = $computed<boolean>(() => {
		if (props.permissions.length === 0) {
			return true
		}
		const type = props.type ?? "any"
		switch (type) {
			case "any":
				return props.permissions.some((perm) => user.hasPermission(perm))
			case "every":
				return props.permissions.every((perm) => user.hasPermission(perm))
		}
		throw new Error("Unknown type")
	})

	// eslint-disable-next-line no-nested-ternary
	return () => {
		if (hasPermission) {
			return renderSlot(slots, "default")
		}
		if (props.silent) {
			return null
		}
		return "You do not have permission to view this page. Please contact an administrator if you believe this is an error"
	}
}

export default defineComponent(AuthorizePermission, {
	permissions: requiredProp(Array),
	type: optionalProp(String),
	silent: optionalProp(Boolean),
})
