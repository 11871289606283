import { getCoreDataValue } from "@/data/coreDataStore"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import { useLoading } from "@/utils/composition/useLoading"
import { Teleport, h, shallowRef } from "vue"
import { defineComponent, type Component, type ReactiveComponent } from "vue-utils"
import PrintButton from "../../components/PrintButton"
import { generateTimeSheetsWeekReport, queryRelevantUsers } from "../../service"
import useTimeSheetManagementStore from "../../store"
import PrintAllTimeSheetsModal from "./PrintAllTimeSheetsModal"

const PrintTimeSheets: ReactiveComponent = () => {
	const allUsersModal = shallowRef<Component | null>(null)

	const { runAction } = useLoading()
	const store = useTimeSheetManagementStore()
	const loggedInUser = useLoggedInUser()

	async function showPrintAllUsersDialog() {
		const assignedSites = loggedInUser.listAssignedSites(getCoreDataValue("sites"))
		const possibleUsers = await runAction(
			queryRelevantUsers({
				...store.dateRange,
				siteIds: assignedSites.map((s) => s.id),
				showApproved: true,
			})
		)

		allUsersModal.value = () => (
			<PrintAllTimeSheetsModal
				cancel={() => (allUsersModal.value = null)}
				week={store.startOfWeek.toLocalDate()}
				sites={assignedSites}
				users={possibleUsers}
			/>
		)
	}

	return () => (
		<div class="flex justify-end items-center spacing-4">
			{allUsersModal.value && <Teleport to="body">{h(allUsersModal.value)}</Teleport>}
			{store.selectedUser && (
				<PrintButton
					text="Print This User"
					fileName={`${store.selectedUser.lastName}, ${store.selectedUser.firstName}.pdf`}
					request={() =>
						generateTimeSheetsWeekReport({
							siteIds: [],
							userIds: store.selectedUser ? [store.selectedUser.id] : [],
							week: store.startOfWeek.toLocalDate(),
						})
					}
				/>
			)}
			<PrintButton text="Print Multiple Users" action={() => void showPrintAllUsersDialog()} />
		</div>
	)
}

export default defineComponent(PrintTimeSheets)
