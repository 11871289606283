import Calendar, { type CalendarEvent } from "@/components/calendar"
import { useActivities } from "@/data/coreDataStore"
import {
	ApprovalStatus,
	HolidayType,
	type Activity,
	type Holiday,
	type HolidayEventDay,
	type HolidayRequestContext,
	type Id,
} from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { YearMonth } from "@js-joda/core"
import { computed, shallowRef } from "vue"
import { defineComponent, refSetter, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	context: HolidayRequestContext
}

function createCalendarEvent(holiday: Holiday, day: HolidayEventDay, activities: Map<Id, Activity>): CalendarEvent {
	let title: string
	let color = activities.get(day.activityId ?? NaN)?.highlightColor ?? undefined

	if (holiday.holidayType === HolidayType.Company) {
		title = holiday.name
	} else if (!holiday.approval) {
		title = "Holiday Request"
		color = "#b6b6b6"
	} else {
		switch (holiday.approval.status) {
			case ApprovalStatus.Approved:
				title = "Holiday"
				break
			case ApprovalStatus.Rejected:
				title = "Rejected Holiday"
				color = "#c51729"
				break
			case ApprovalStatus.Cancelled:
				title = "Cancelled Holiday"
				color = "#b6b6b6"
		}
	}

	return {
		date: day.date,
		type: day.type,
		color,
		title,
	}
}

const ExistingHolidayCalendar: ReactiveComponent<Props> = (props) => {
	const context = proxyProp(props, "context")
	const activities = useActivities()

	const calendarDate = shallowRef(YearMonth.now())
	const events = computed(() =>
		context.holidayEvents.flatMap((event) =>
			event.days.map((day) => createCalendarEvent(event.holiday, day, activities))
		)
	)

	return () => (
		<>
			<h3 style={{ fontWeight: 500 }}>Existing Holidays</h3>
			<Calendar
				date={calendarDate.value}
				setDate={refSetter(calendarDate)}
				events={events.value}
				earliest={YearMonth.from(context.minimumDate)}
				latest={YearMonth.from(context.maximumDate)}
			/>
		</>
	)
}

export default defineComponent(ExistingHolidayCalendar, {
	context: requiredProp(Object),
})
