import useLoggedInUserStore from "@/data/loggedInUserStore"
import useSystemConfigStore from "@/data/systemConfigStore"
import type { UserMetadata } from "@/models"
import { useKioskModeStore } from "@/pages/kiosk-mode/store"
import type { ReactiveComponent } from "vue-utils"
import { defineComponent } from "vue-utils"
import ProfileButton from "./ProfileButton"
import UpdateIndicator from "./UpdateIndicator"

const LoggedInDetails: ReactiveComponent = () => {
	const kioskModeStore = useKioskModeStore()
	const systemConfigStore = useSystemConfigStore()
	const userStore = useLoggedInUserStore()

	const kioskUser = $computed<UserMetadata | null>(() => {
		return kioskModeStore.userDetails?.user ?? null
	})
	const loggedInUser = $computed(() => {
		if (userStore.type === "done") {
			return userStore.result
		}
		return null
	})

	return () => (
		<div class="flex items-center spacing-2">
			{kioskUser !== null && (
				<div style={{ padding: "0.75rem" }}>
					{kioskUser.firstName} {kioskUser.lastName}
				</div>
			)}
			{systemConfigStore.updateAvailable && <UpdateIndicator />}
			{!kioskUser && !!loggedInUser && !kioskModeStore.kioskEnabled && <ProfileButton />}
		</div>
	)
}

export default defineComponent(LoggedInDetails)
