export enum SortDirection {
	Ascending = "ascending",
	Descending = "descending",
}

export interface Comparator<T> {
	(item1: T, item2: T): number
}

export function comparatorWithSort<T>(comparator: Comparator<T>, sortDirection: SortDirection): Comparator<T> {
	if (sortDirection === SortDirection.Ascending) {
		return comparator
	}
	return (item1, item2) => comparator(item1, item2) * -1
}

export function createComparator<T, U>(mapping: (entity: T) => U, comparator: Comparator<U>): Comparator<T> {
	return (entity1, entity2) => {
		const value1 = mapping(entity1)
		const value2 = mapping(entity2)
		return comparator(value1, value2)
	}
}

export function chainComparators<T>(...comparators: Comparator<T>[]): Comparator<T> {
	return (item1, item2) => {
		for (const comparator of comparators) {
			const result = comparator(item1, item2)
			if (result !== 0) {
				return result
			}
		}
		return 0
	}
}
