import SortableHeading from "@/components/table/SortableHeading"

import { SortDirection } from "@/models"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { TimeSheetSortBy } from "../sorting"

interface Props {
	direction: SortDirection
	sortBy: TimeSheetSortBy

	setDirection(direction: SortDirection): void
	setSortBy(sortBy: TimeSheetSortBy): void
}

const TableHeader: ReactiveComponent<Props> = (props) => {
	function getDirection(type: TimeSheetSortBy) {
		if (props.sortBy === type) {
			return props.direction
		}
		return null
	}
	function setDirection(type: TimeSheetSortBy, direction: SortDirection) {
		if (type !== props.sortBy) {
			props.setSortBy(type)
			props.setDirection(SortDirection.Ascending)
		} else {
			props.setDirection(direction)
		}
	}

	return () => (
		<thead>
			<tr>
				<SortableHeading
					direction={getDirection(TimeSheetSortBy.Date)}
					setDirection={(dir) => setDirection(TimeSheetSortBy.Date, dir)}
					rowspan={2}
				>
					Date
				</SortableHeading>
				<SortableHeading
					direction={getDirection(TimeSheetSortBy.Site)}
					setDirection={(dir) => setDirection(TimeSheetSortBy.Site, dir)}
					rowspan={2}
				>
					Site
				</SortableHeading>
				<SortableHeading
					direction={getDirection(TimeSheetSortBy.Activity)}
					setDirection={(dir) => setDirection(TimeSheetSortBy.Activity, dir)}
					rowspan={2}
				>
					Activity
				</SortableHeading>
				<th rowspan={2}>Notes</th>
				<th colspan={4}>Clock In</th>
				<th colspan={4}>Clock Out</th>
				<th colspan={2}>Break Duration</th>
				<th rowspan={2}>
					<div>
						<span>Hours</span>
						<br />
						<span>Logged</span>
					</div>
				</th>
				<th rowspan={2}>
					<div>
						<span>Hours</span>
						<br />
						<span>Paid</span>
					</div>
				</th>
				<th rowspan={2}>Approval</th>
				<th rowspan={2} style={{ border: "none", minWidth: "0" }}></th>
			</tr>
			<tr>
				<th>Entered</th>
				<th>Auto</th>
				<th>Override</th>
				<th>Loc</th>

				<th>Entered</th>
				<th>Auto</th>
				<th>Override</th>
				<th>Loc</th>

				<th>Auto</th>
				<th>Override</th>
			</tr>
		</thead>
	)
}

export default defineComponent(TableHeader, {
	direction: requiredProp(String),
	sortBy: requiredProp(String),
	setDirection: requiredProp(Function),
	setSortBy: requiredProp(Function),
})
