import type { IPagedResult, TimeSheetEntry } from "@/models"
import { RequestMethod, httpJsonRequest, httpJsonRequestJsonResponse } from "@/services/httpService"
import { mapApiEntry } from "@/services/timesheetsService"
import type { TimeSheetExportFilter, TimeSheetExportOptions, TimeSheetExportResult } from "./model"

export const searchTimeSheetsPaged = async (options: TimeSheetExportOptions): Promise<TimeSheetExportResult> => {
	const result = await httpJsonRequestJsonResponse<
		{
			entries: TimeSheetEntry[]
		} & IPagedResult
	>("/api/timesheet-export", options, RequestMethod.POST)

	return {
		...result,
		entries: result.entries.map(mapApiEntry),
	}
}

export const exportTimeSheetsCSV = async (filter: TimeSheetExportFilter): Promise<Response> =>
	await httpJsonRequest("/api/timesheet-export/csv", filter, RequestMethod.POST)
