import type { Nullable } from "@/models"
import { LoadingError } from "@/utils/composition/useLoading"
import { Instant, LocalTime } from "@js-joda/core"
import { OverrideType } from "../../service"

const compare = <T extends LocalTime | Instant>(t1: T, t2: T): number => {
	if (t1 instanceof LocalTime) {
		return t1.compareTo(t2 as LocalTime)
	}
	return t1.compareTo(t2 as Instant)
}

const validateOverride = <T extends LocalTime | Instant>(
	clockIn: Nullable<T>,
	clockOut: Nullable<T>,
	type: OverrideType,
	newValue: T
) => {
	if (type === OverrideType.ClockIn) {
		if (clockOut !== null && compare(newValue, clockOut) > 0) {
			throw new LoadingError("Invalid time", "Can't set a clock in time after clock out")
		}
	} else if (type == OverrideType.ClockOut) {
		if (clockIn !== null && compare(newValue, clockIn) < 0) {
			throw new LoadingError("Invalid time", "Can't set a clock out time before clock in")
		}
	}
}

export default validateOverride
