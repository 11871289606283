import { LocalDate, type Duration, type LocalTime } from "@js-joda/core"
import type { ApiTypeOf, HolidayDayType, HolidayType, IIdentifier, INamed, Id } from "."
import { parseDuration, parseTime } from "@/utils/timeUtils"

export interface CompanyHoliday extends IIdentifier, INamed {
	holidayType: HolidayType.Company
	date: LocalDate
	startTime: LocalTime
	endTime: LocalTime
	breakDuration: Duration
	type: HolidayDayType
	activityId: Id
	siteId: Id
	createdById: Id
	isPaid: boolean
	deductFromAllowance: boolean
}

export const mapApiCompanyHoliday = (holiday: ApiTypeOf<CompanyHoliday>): CompanyHoliday => ({
	...holiday,
	date: LocalDate.parse(holiday.date),
	startTime: parseTime(holiday.startTime),
	endTime: parseTime(holiday.endTime),
	breakDuration: parseDuration(holiday.breakDuration),
})
