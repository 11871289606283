import SimpleEditForm from "@/components/SimpleEditForm"
import { BasicSelect } from "@/components/basic-form"
import { useActivities, useActivity, useSite } from "@/data/coreDataStore"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import usePopups from "@/data/popupsStore"
import { type Id, type Nullable, Permission, HolidayDayType } from "@/models"
import { useTodaysDate } from "@/utils/composition/useDate"
import { useLoading } from "@/utils/composition/useLoading"
import { SiteListType, useSiteList } from "@/utils/composition/useSiteList"
import { proxyProp } from "@/utils/proxyProp"
import { useLocalDateInput, useLocalTimeInput } from "@/utils/timeUtils"
import { Duration, LocalDate, LocalTime } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { computed, toRef, toRefs, watch } from "vue"
import { Alert, defineComponent, type ReactiveComponent, Required, runWithMinimumDelay, ToggleButton } from "vue-utils"
import { type CrudTableFormProps, crudTableFormPropTypes, getFormTitle } from "../CrudTable"
import { countAssociatedHolidayTimeSheets, calculateHolidayTimes } from "./service"
import { reactivePick } from "@vueuse/core"
import { formatEnum } from "@/utils/formatEnum"

export interface CompanyHolidayForm {
	id: Id
	name: string
	date: Nullable<LocalDate>
	type: HolidayDayType
	startTime: Nullable<LocalTime>
	endTime: Nullable<LocalTime>
	breakDuration: Nullable<Duration>
	activityId: Nullable<Id>
	siteId: Nullable<Id>
	deductFromAllowance: boolean
}

const formContentStyles = css`
	display: grid;
	gap: 2rem;
	grid-template-columns: 1fr;
	overflow: auto;

	select {
		width: 100%;
	}

	@media (min-width: 45rem) {
		grid-template-columns: 1fr 1fr;
	}
	@media (min-width: 64rem) {
		grid-template-columns: repeat(3, 1fr);
	}
	padding-bottom: 1rem;
`

const Form: ReactiveComponent<CrudTableFormProps<CompanyHolidayForm>> = (props) => {
	const holiday = proxyProp(props, "data")

	const { runAction } = useLoading()
	const activities = useActivities()
	const popups = usePopups()
	const user = useLoggedInUser()

	const activity = $(useActivity(() => holiday.activityId))
	const site = $(useSite(() => holiday.siteId))

	const sites = useSiteList(SiteListType.Selection, {
		selectedSite: toRef(holiday, "siteId"),
	})

	const dateToday = $(useTodaysDate())
	const maxBreakDuration = computed(() => {
		if (!holiday.startTime || !holiday.endTime) {
			return null
		}
		if (holiday.startTime.compareTo(holiday.endTime) > 0) {
			return null //Prevents a negative break duration
		}
		return Duration.between(holiday.startTime, holiday.endTime)
	})

	async function loadTemplate() {
		if (!holiday.date || holiday.siteId === null) {
			return
		}
		const template = await runAction(
			runWithMinimumDelay(
				calculateHolidayTimes({
					date: holiday.date,
					type: holiday.type,
					siteId: holiday.siteId,
				}),
				750
			)
		)
		holiday.startTime = template.startTime
		holiday.endTime = template.endTime
		holiday.breakDuration = template.breakDuration
	}

	async function showDeleteConfirmation() {
		const totalAttached = await runAction(countAssociatedHolidayTimeSheets(holiday.id))

		const confirmation = await popups.confirm(() => (
			<Alert title="Confirm Deletion of Company Holiday">
				Are you sure you wish to delete this company holiday?
				{totalAttached > 0 && (
					<>
						<br />
						{totalAttached} timesheet(s) will be <strong>permanently</strong> deleted.
					</>
				)}
			</Alert>
		))
		console.log(confirmation)

		return confirmation
	}

	async function showCreateConfirmation() {
		return await popups.confirm(() => (
			<Alert title="Confirm Creation of Company Holiday">
				Are you sure you wish to create this company holiday? Please check the details as it will create time sheets for
				all users.
			</Alert>
		))
	}

	watch(
		Object.values(toRefs(reactivePick(holiday, ["date", "type", "siteId", "activityId"]))),
		() => void loadTemplate()
	)

	return () => (
		<SimpleEditForm
			{...props}
			formWidth={60}
			title={getFormTitle(props.mode, "Company Holiday")}
			validateSave={showCreateConfirmation}
			validateDelete={showDeleteConfirmation}
			delete={user.hasPermission(Permission.DeleteCompanyHoliday) ? props.delete : undefined}
		>
			<div class={formContentStyles}>
				<label>
					<Required label="Name" />
					<input type="text" maxlength={64} v-model={holiday.name} disabled={props.readonly} required />
				</label>
				<label>
					<Required label="Type" />
					<BasicSelect
						options={Object.values(HolidayDayType)}
						value={holiday.type}
						setValue={(t) => (holiday.type = t)}
						getText={formatEnum}
						required
					/>
				</label>
				<label>
					<Required label="Deducts from allowance?" />
					<ToggleButton
						toggled={holiday.deductFromAllowance}
						setToggled={(t) => (holiday.deductFromAllowance = t)}
						disabled={props.readonly}
					/>
				</label>
				<label>
					<Required label="Date" />
					<input
						value={holiday.date?.toHTMLInput()}
						onChange={useLocalDateInput((date) => (holiday.date = date))}
						min={dateToday.toHTMLInput()}
						type="date"
						disabled={props.readonly}
						required
					/>
				</label>
				<label>
					<Required label="Site" />
					<BasicSelect
						options={sites.value}
						value={site}
						setValue={(newSite) => (holiday.siteId = newSite.id)}
						disabled={props.readonly}
						required
					/>
				</label>
				<label>
					<Required label="Activity" />
					<BasicSelect
						options={Array.from(activities.values())}
						value={activity}
						setValue={(newActivity) => (holiday.activityId = newActivity.id)}
						disabled={props.readonly}
						required
					/>
				</label>
				<label>
					<Required label="Start Time" />
					<input
						type="time"
						value={holiday.startTime?.toHTMLInput()}
						onChange={useLocalTimeInput((time) => (holiday.startTime = time))}
						max={holiday.endTime?.toHTMLInput()}
						disabled={props.readonly}
						required
					/>
				</label>
				<label>
					<Required label="End Time" />
					<input
						type="time"
						value={holiday.endTime?.toHTMLInput()}
						onChange={useLocalTimeInput((time) => (holiday.endTime = time))}
						min={holiday.startTime?.toHTMLInput()}
						disabled={props.readonly}
						required
					/>
				</label>
				<label>
					<Required label="Break Duration" />
					<input
						type="time"
						value={holiday.breakDuration?.toHTMLInput()}
						max={maxBreakDuration.value?.toHTMLInput()}
						onChange={useLocalTimeInput(
							(time) => (holiday.breakDuration = time ? Duration.ofSeconds(time.toSecondOfDay()) : null)
						)}
						disabled={props.readonly}
						required
					/>
				</label>
			</div>
		</SimpleEditForm>
	)
}

export default defineComponent(Form, crudTableFormPropTypes())
