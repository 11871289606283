import { LocalDate } from "@js-joda/core"
import type { ApiTypeOf, CompanyHoliday, DayPartType, HolidayRequest, Id, Nullable } from "."
import { mapApiCompanyHoliday } from "./CompanyHoliday"
import { HolidayType } from "./HolidayDay"
import { mapApiHolidayRequest } from "./HolidayRequest"

export type Holiday = CompanyHoliday | HolidayRequest
export function mapHoliday(holiday: ApiTypeOf<Holiday>): Holiday {
	if (holiday.holidayType === HolidayType.Company) {
		return mapApiCompanyHoliday(holiday)
	} else if (holiday.holidayType === HolidayType.Staff) {
		return mapApiHolidayRequest(holiday)
	} else {
		throw new Error("Unknown holiday type")
	}
}

export interface HolidayEvent {
	holiday: Holiday
	days: HolidayEventDay[]
}
export function mapApiHolidayEvent(holidayEvent: ApiTypeOf<HolidayEvent>): HolidayEvent {
	return {
		holiday: mapHoliday(holidayEvent.holiday),
		days: holidayEvent.days.map(mapApiHolidayEventDay),
	}
}

export interface HolidayEventDay {
	date: LocalDate
	type: DayPartType
	activityId: Nullable<Id>
}
export function mapApiHolidayEventDay(day: ApiTypeOf<HolidayEventDay>): HolidayEventDay {
	return {
		...day,
		date: LocalDate.parse(day.date),
	}
}

export interface HolidayRequestContext {
	minimumDate: LocalDate
	maximumDate: LocalDate
	holidayEvents: HolidayEvent[]
}
export function mapApiHolidayRequestContext(context: ApiTypeOf<HolidayRequestContext>): HolidayRequestContext {
	return {
		minimumDate: LocalDate.parse(context.minimumDate),
		maximumDate: LocalDate.parse(context.maximumDate),
		holidayEvents: context.holidayEvents.map(mapApiHolidayEvent),
	}
}
