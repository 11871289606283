import { ModalTitle, defineComponent, optionalProp, renderSlot, requiredProp, type ReactiveComponent } from "vue-utils"
import BootstrapButton from "./BootstrapButton"
import { BasicForm, type FormButton, type ValidationResult } from "./basic-form"
import Modal from "./Modal"

export type EditFormMode = "create" | "edit" | "view"

export interface Props {
	title: string
	mode: EditFormMode

	validateSave?: () => ValidationResult
	validateDelete?: () => ValidationResult

	cancel(): void
	save?: () => Promise<void>
	delete?: () => Promise<void>

	formWidth?: number
}

const EditForm: ReactiveComponent<Props> = (props, { slots }) => {
	const cancelButton = $computed<FormButton>(() => ({
		id: "cancel",
		onSubmit: props.cancel,
		validateForm: false,
		render(btnProps) {
			return (
				<BootstrapButton color="secondary" {...btnProps}>
					{props.mode === "view" ? "Close" : "Cancel"}
				</BootstrapButton>
			)
		},
	}))

	const saveButton = $computed<FormButton | undefined>(() => {
		if (!props.save || props.mode === "view") {
			return undefined
		}
		return {
			id: "save",
			onSubmit: props.save,
			validateForm: true,
			validate: props.validateSave,
			render(btnProps) {
				return (
					<BootstrapButton color="primary" {...btnProps}>
						{props.mode === "create" ? "Create" : "Save"}
					</BootstrapButton>
				)
			},
		}
	})

	const deleteButton = $computed<FormButton | undefined>(() => {
		if (!props.delete) {
			return undefined
		}
		return {
			id: "delete",
			onSubmit: props.delete,
			validateForm: false,
			validate: props.validateDelete,
			render(btnProps) {
				return (
					<BootstrapButton color="danger" {...btnProps}>
						Delete
					</BootstrapButton>
				)
			},
		}
	})

	const buttons = $computed<FormButton[]>(() => {
		const buttons: FormButton[] = []
		if (deleteButton) {
			buttons.push(deleteButton)
		}
		buttons.push(cancelButton)
		if (saveButton) {
			buttons.push(saveButton)
		}
		return buttons
	})

	return () => (
		<Modal class="flex flex-col overflow-auto" style={{ width: `${props.formWidth ?? 55}rem` }}>
			<BasicForm buttons={buttons} showDivider={false} class="flex flex-col" style={{ maxHeight: "80vh" }}>
				<ModalTitle title={props.title} />
				<hr />
				{renderSlot(slots, "default")}
			</BasicForm>
		</Modal>
	)
}

export default defineComponent(EditForm, {
	title: requiredProp(String),
	mode: requiredProp(String),

	validateSave: optionalProp(Function),
	validateDelete: optionalProp(Function),

	cancel: requiredProp(Function),
	save: optionalProp(Function),
	delete: optionalProp(Function),

	formWidth: optionalProp(Number),
})
