export const getScaling = (): number => {
	const scaling = localStorage.getItem("scaling")
	if (scaling === null) {
		return 100
	}
	return Number.parseFloat(scaling)
}

export const setScaling = (scaling: number) => {
	localStorage.setItem("scaling", scaling.toString())
	applyScaling()
}

export const applyScaling = () => {
	document.documentElement.style.fontSize = `${getScaling() / 100}rem`
}
