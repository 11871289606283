import { BasicSelect } from "@/components/basic-form"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import type { Nullable, Site } from "@/models"
import { SiteListType, useSiteList } from "@/utils/composition/useSiteList"
import { computed, toRef } from "vue"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import { getAvailableSites } from "../composition/availableSites"
import DisplayEdit from "./DisplayEdit"
import { useOnTabbed } from "./useOnTabbed"

interface Props {
	value: Nullable<Site>
	save(site: Site): Promise<void>
	readonly?: boolean
}

const EditSite: ReactiveComponent<Props> = (props) => {
	const user = useLoggedInUser()
	const allSites = useSiteList(SiteListType.Selection, {
		selectedSite: toRef(props, "value"),
	})
	const availableSites = computed(() => getAvailableSites(user, allSites.value))

	async function handleSave(site: Site, hide: () => void) {
		if (site.id !== props.value?.id) {
			await props.save(site)
		}
		hide()
	}

	return () => (
		<DisplayEdit
			displayText={props.value?.name ?? ""}
			renderContent={({ hide }) => (
				<BasicSelect
					options={availableSites.value}
					value={props.value}
					setValue={(newSite) => void handleSave(newSite, hide)}
					{...useOnTabbed(hide)}
					onKeyup={(e: KeyboardEvent) => e.key === "Escape" && hide()}
					style={{ margin: "0 0.25em" }}
					class="w-full"
					required
				/>
			)}
			readonly={props.readonly}
		/>
	)
}

export default defineComponent(EditSite, {
	value: requiredProp(null, Object),
	save: requiredProp(Function),
	readonly: optionalProp(Boolean),
})
