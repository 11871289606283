import { useSites } from "@/data/coreDataStore"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import { Permission, type Site } from "@/models"
import type { LoggedInUser } from "@/models/LoggedInUser"
import { computed, type ComputedRef } from "vue"

export function useAvailableSites(): ComputedRef<Site[]> {
	const user = useLoggedInUser()
	const allSites = useSites()

	return computed(() => getAvailableSites(user, Array.from(allSites.values())))
}

export function getAvailableSites(user: LoggedInUser, allSites: Site[]): Site[] {
	if (user.hasPermission(Permission.ManagedUserTimeSheets)) {
		return allSites
	}
	return user.listAssignedSites(allSites)
}
