import { DayOfWeek, LocalDate, Month } from "@js-joda/core"

export function getGetFirstWeekDayOfMonth(month: Month, year: number): LocalDate {
	const first = LocalDate.of(year, month, 1)
	if (first.dayOfWeek().ordinal() >= DayOfWeek.SATURDAY.ordinal()) {
		return first.plusWeeks(1).getStartOfWeek()
	}
	return first.getStartOfWeek()
}

export function getLastWeekDayOfMonth(month: Month, year: number): LocalDate {
	const end = LocalDate.of(
		month.equals(Month.DECEMBER) ? year + 1 : year,
		((month.ordinal() + 1) % 12) + 1,
		1
	).plusDays(-1)
	return end.getEndOfWeek(DayOfWeek.FRIDAY)
}

export default function getWeekDatesInMonth(month: Month, year: number) {
	const start = getGetFirstWeekDayOfMonth(month, year)
	const end = getLastWeekDayOfMonth(month, year)

	const result: LocalDate[] = []
	for (let date = start; date.compareTo(end) <= 0; date = date.plusDays(1)) {
		if (date.dayOfWeek() !== DayOfWeek.SATURDAY && date.dayOfWeek() !== DayOfWeek.SUNDAY) {
			result.push(date)
		}
	}
	return result
}
