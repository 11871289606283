import type { Id, IIdentifier } from "@/models"

export interface ArrayToMap {
	<T extends IIdentifier>(array: T[]): Map<Id, T>
	<K, T>(array: T[], getKey: (item: T) => K): Map<K, T>
	<K, T, V>(array: T[], getKey: (item: T) => K, getValue: (item: T) => V): Map<K, V>
}

export const arrayToMap: ArrayToMap = <T, K, V = T>(
	array: T[],
	getKey?: (item: T) => K,
	getValue?: (item: T) => V
): Map<K, V> => {
	const result = new Map<K, V>()
	for (const item of array) {
		const key = (getKey ? getKey(item) : (item as IIdentifier).id) as K
		result.set(key, getValue ? getValue(item) : (item as unknown as V))
	}
	return result
}
