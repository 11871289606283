import AuthorizePermission from "@/authentication/AuthorizePermission"
import { SortDirection } from "@/models"
import Permission from "@/models/Permission"
import BasicPage from "@/pages/BasicPage"
import { Month } from "@js-joda/core"
import { css } from "vite-css-in-js"
import {
	RefQueries,
	ScrollPane,
	ShadowContainer,
	defineComponent,
	useQueryStoreRef,
	type ReactiveComponent,
} from "vue-utils"
import PlannerControls from "./PlannerControls"
import PlannerKey from "./PlannerKey"
import PlannerNavigation from "./PlannerNavigation"
import HolidayPlannerTable from "./PlannerTable"
import holidayPlannerStore from "./store"
import { TableSortBy } from "./tableSorting"

const plannerLayoutStyles = css`
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: auto 1fr;
	grid-template-areas: "date-select date-select" "planner-table controls";
	height: 100%;
	font-size: 0.95rem;

	& > div:nth-child(1) {
		grid-area: date-select;
		margin-bottom: 0.75rem;
	}
	& > div:nth-child(2) {
		grid-area: planner-table;
	}
	& > div:nth-child(3) {
		grid-area: controls;
		margin-left: 0.75rem;
	}
`

const HolidayPlanner: ReactiveComponent = () => {
	const store = holidayPlannerStore()

	useQueryStoreRef("year", store, "year", RefQueries.Int)
	useQueryStoreRef("month", store, "month", {
		parse: (str) => Month.of(Number.parseInt(str as string)),
		stringify: (month) => month.value().toString(),
	})

	useQueryStoreRef("sort", store, "sortDirection", RefQueries.enum(SortDirection))
	useQueryStoreRef("by", store, "sortBy", RefQueries.enum(TableSortBy))
	useQueryStoreRef("user", store, "userSearch", RefQueries.String)

	useQueryStoreRef("include-pending", store, "includePendingHolidays", RefQueries.Boolean)
	useQueryStoreRef("hide-without-holiday", store, "hideStaffWithoutHoliday", RefQueries.Boolean)

	useQueryStoreRef("additional-activities", store, "additionalActivities", RefQueries.arrayOf(RefQueries.Int))

	return () => (
		<BasicPage title="Holiday Planner">
			<AuthorizePermission permissions={[Permission.HolidayPlanner]}>
				<div class={plannerLayoutStyles}>
					<ShadowContainer>
						<PlannerNavigation />
					</ShadowContainer>
					<ShadowContainer>
						<ScrollPane class="h-full">
							<HolidayPlannerTable />
						</ScrollPane>
					</ShadowContainer>
					{store.showControls && (
						<ShadowContainer class="flex flex-col">
							<div class="overflow-auto flex-1" style={{ paddingRight: "1rem" }}>
								<PlannerControls />
								<PlannerKey />
							</div>
						</ShadowContainer>
					)}
				</div>
			</AuthorizePermission>
		</BasicPage>
	)
}

export default defineComponent(HolidayPlanner)
