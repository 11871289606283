import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import { WeekPicker } from "@/components/date-pickers"
import { faCalendarAlt, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { convert } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { ref } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import useTimeSheetManagementStore from "../store"

const containerStyles = css`
	display: flex;
	flex: 1 1 auto;
	justify-content: space-evenly;
	align-items: center;
	font-size: 1.125rem;
`

const pickerContainerStyles = css`
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 0.125rem;

	& > button {
		all: unset;
		cursor: pointer;
		min-width: 18rem;
		text-align: center;
		font-weight: 600;
		border-radius: 0.5rem;
		padding: 0.25rem 0;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;

		&:hover {
			background-color: var(--color-info-light-6);
		}
	}
`

const WeekSelector: ReactiveComponent = () => {
	const pickingDate = ref(false)
	const store = useTimeSheetManagementStore()

	const formattedStartOfWeek = $computed(() => {
		const jsDate = convert(store.week).toDate()
		return jsDate.toLocaleDateString(undefined, {
			weekday: "long",
			day: "2-digit",
			month: "long",
			year: "numeric",
		})
	})

	return () => (
		<div class={containerStyles}>
			<BootstrapButton color="primary" onClick={() => (store.week = store.week.plusWeeks(-1))}>
				<Icon icon={faChevronLeft} />
			</BootstrapButton>
			<div class={pickerContainerStyles}>
				<span>Week Starting</span>
				<button title="Open Week Picker" onClick={() => (pickingDate.value = !pickingDate.value)}>
					{formattedStartOfWeek}
					<Icon icon={faCalendarAlt} />
				</button>

				{pickingDate.value && (
					<WeekPicker
						class="absolute"
						style={{ top: "100%", zIndex: 1 }}
						weekStart={store.week}
						setWeekStart={(week) => {
							store.week = week
							pickingDate.value = false
						}}
						onBlur={() => (pickingDate.value = false)}
					/>
				)}
			</div>
			<BootstrapButton color="primary" onClick={() => (store.week = store.week.plusWeeks(1))}>
				<Icon icon={faChevronRight} />
			</BootstrapButton>
		</div>
	)
}

export default defineComponent(WeekSelector)
