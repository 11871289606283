import type { ReactiveComponent } from "vue-utils"
import { defineComponent } from "vue-utils"
import BasicPage from "./BasicPage"

const NotFoundPage: ReactiveComponent = () => () => (
	<BasicPage title="Not Found">
		<div>The page you requested could not be found</div>
	</BasicPage>
)

export default defineComponent(NotFoundPage)
