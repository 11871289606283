import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import UserNameDisplay from "@/components/UserNameDisplay"
import SimpleTable from "@/components/table/SimpleTable"
import TableControls, { type Props as TableControlsProps } from "@/components/table/TableControls"
import { useActivities, useSites } from "@/data/coreDataStore"
import type { RejectedClockEvent } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { DateTimeFormatter, Instant, ZoneId } from "@js-joda/core"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import UserLocationModal from "./UserLocationModal"

interface Props extends Omit<TableControlsProps, "entityName"> {
	events: RejectedClockEvent[]
}

const formatTime = (timeStr: string) => {
	return Instant.parse(timeStr)
		.atZone(ZoneId.SYSTEM)
		.toLocalDateTime()
		.format(DateTimeFormatter.ofPattern("dd/MM/yyy HH:mm"))
}

const ClockEventsTable: ReactiveComponent<Props> = (props) => {
	const { events } = $(props)
	const activities = useActivities()
	const sites = useSites()

	let viewingMapEvent = $shallowRef<RejectedClockEvent | null>(null)

	const renderRow = (event: RejectedClockEvent) => {
		const lat = event.location.latitude.toFixed(5)
		const lng = event.location.longitude.toFixed(5)
		const user = event.user

		return (
			<tr key={event.id}>
				<td>{formatTime(event.timeStamp)}</td>
				<td>{formatEnum(event.action)}</td>
				<td>{user && <UserNameDisplay user={user} />}</td>
				<td>{sites.get(event.siteId)?.name}</td>
				<td>{activities.get(event.activityId)?.name}</td>
				<td>
					<div class="flex spacing-4 justify-center items-center">
						<span>
							{lat}, {lng}
						</span>
						<BootstrapButton color="danger" title="View on Map" onClick={() => (viewingMapEvent = event)}>
							<Icon icon={faMapMarkerAlt} />
						</BootstrapButton>
					</div>
				</td>
			</tr>
		)
	}

	return () => (
		<>
			{viewingMapEvent && (
				<UserLocationModal
					userLocation={viewingMapEvent.location}
					siteLocation={viewingMapEvent.siteLocation}
					close={() => (viewingMapEvent = null)}
				/>
			)}
			<SimpleTable>
				<thead>
					<tr>
						<th>Date</th>
						<th>Action</th>
						<th>User</th>
						<th>Site</th>
						<th>Activity</th>
						<th>User Location</th>
					</tr>
				</thead>
				<tbody>{events.map(renderRow)}</tbody>
			</SimpleTable>
			<TableControls entityName="Rejected Clock Events" {...props} />
		</>
	)
}

export default defineComponent(ClockEventsTable, {
	events: requiredProp(Array),

	page: requiredProp(Number),
	totalItems: requiredProp(Number),
	pageSize: requiredProp(Number),
	setPage: requiredProp(Function),
})
