import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import { useActivity, useSite } from "@/data/coreDataStore"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import type { Activity, Site, TimeSheetEntry } from "@/models"
import Permission from "@/models/Permission"
import UserLocationModal from "@/pages/administration/clock-events/UserLocationModal"
import { getPaidHours } from "@/services/timesheetsService"
import { formatDate, formatDuration, formatTimeInUserTimeZone } from "@/utils/timeUtils"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { Duration, Instant, LocalDate, ZoneId, ZonedDateTime, type LocalTime } from "@js-joda/core"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { OverrideType } from "../service"
import Approval from "./components/Approval"
import ApprovedBy from "./components/ApprovedBy"
import DeleteTimeSheet from "./components/DeleteTimeSheet"
import EditActivity from "./components/EditActivity"
import EditDuration from "./components/EditDuration"
import EditSite from "./components/EditSite"
import EditTime from "./components/EditTime"
import NotesButton from "./components/NotesButton"
import ViewNotes from "./components/ViewNotes"
import { useTimeSheetPermissions } from "./composition/timeSheetPermissions"

interface Props {
	day: LocalDate
	entry: TimeSheetEntry

	setBreakOverride(duration: Duration): Promise<void>
	setClockOverride(type: OverrideType, override: Instant): Promise<void>
	updateManagerNotes(notes: string): Promise<void>
	setSite(site: Site): Promise<void>
	setActivity(site: Activity): Promise<void>
}

const getTimeFor = (time: Instant | null): LocalTime | null => {
	if (!time) {
		return null
	}
	return ZonedDateTime.ofInstant(time, ZoneId.systemDefault()).toLocalTime()
}

const TimeSheetRow: ReactiveComponent<Props> = (props) => {
	const { entry: timeSheet } = $(props)

	const loggedInUser = useLoggedInUser()
	const site = $(useSite(() => timeSheet.siteId))
	const activity = $(useActivity(() => timeSheet.activityId))
	const tsPermissions = useTimeSheetPermissions()

	let viewingNotes = $ref(false)
	let clockInLocOpen = $ref(false)
	let clockOutLocOpen = $ref(false)

	const clockIn = $computed(() => timeSheet.clockIn)
	const clockOut = $computed(() => timeSheet.clockOut)
	const className = $computed(() => {
		if (timeSheet.approvedById !== null) {
			return "approved-row"
		}
		if (!tsPermissions.canEdit(timeSheet)) {
			return "readonly-row"
		}
		return ""
	})

	function getInstantFor(time: LocalTime): Instant {
		return ZonedDateTime.of(time.atDate(props.day), ZoneId.systemDefault()).toInstant()
	}

	async function updateNotes(notes: string) {
		await props.updateManagerNotes(notes)
		viewingNotes = false
	}

	return () => (
		<tr class={className}>
			<td>{formatDate(props.day)}</td>

			<EditSite
				value={site}
				save={props.setSite}
				readonly={
					!tsPermissions.canAdjust(timeSheet) ||
					(!!site &&
						!loggedInUser.isAssignedToSite(site) &&
						!loggedInUser.hasPermission(Permission.ManagedUserTimeSheets))
				}
			/>
			<EditActivity value={activity} save={props.setActivity} readonly={!tsPermissions.canAdjust(timeSheet)} />

			<td>
				<NotesButton
					notes={timeSheet.notes}
					managerNotes={timeSheet.managerNotes}
					onClick={() => (viewingNotes = true)}
				/>
				{viewingNotes && (
					<ViewNotes
						notes={timeSheet.notes}
						managerNotes={timeSheet.managerNotes}
						cancel={() => (viewingNotes = false)}
						readonly={!tsPermissions.canAdjust(timeSheet)}
						updateNotes={(notes) => void updateNotes(notes)}
					/>
				)}
			</td>

			<td>{formatTimeInUserTimeZone(timeSheet.clockIn.entered)}</td>
			<td>{formatTimeInUserTimeZone(timeSheet.clockIn.autoAdjusted)}</td>
			<EditTime
				value={getTimeFor(clockIn.override)}
				save={(time) => props.setClockOverride(OverrideType.ClockIn, getInstantFor(time))}
				readonly={!tsPermissions.canAdjust(timeSheet)}
			/>
			<td>
				{!!timeSheet.clockIn.location && (
					<BootstrapButton color="danger" title="View on Map" onClick={() => (clockInLocOpen = true)} tabindex="-1">
						<Icon icon={faMapMarkerAlt} />
					</BootstrapButton>
				)}
				{clockInLocOpen && !!timeSheet.clockIn.location && !!site && (
					<UserLocationModal
						close={() => (clockInLocOpen = false)}
						siteLocation={site.location ?? undefined}
						userLocation={timeSheet.clockIn.location}
					/>
				)}
			</td>

			<td>{formatTimeInUserTimeZone(timeSheet.clockOut.entered)}</td>
			<td>{formatTimeInUserTimeZone(timeSheet.clockOut.autoAdjusted)}</td>
			<EditTime
				value={getTimeFor(clockOut.override)}
				save={(time) => props.setClockOverride(OverrideType.ClockOut, getInstantFor(time))}
				readonly={!tsPermissions.canAdjust(timeSheet)}
			/>
			<td>
				{!!timeSheet.clockOut.location && (
					<BootstrapButton color="danger" title="View on Map" onClick={() => (clockOutLocOpen = true)} tabindex="-1">
						<Icon icon={faMapMarkerAlt} />
					</BootstrapButton>
				)}
				{clockOutLocOpen && !!timeSheet.clockOut.location && !!site && (
					<UserLocationModal
						close={() => (clockOutLocOpen = false)}
						siteLocation={site.location ?? undefined}
						userLocation={timeSheet.clockOut.location}
					/>
				)}
			</td>

			<td>{formatDuration(timeSheet.break.calculated)}</td>
			<EditDuration
				value={timeSheet.break.adjusted}
				save={props.setBreakOverride}
				readonly={!tsPermissions.canAdjust(timeSheet)}
			/>
			<td>{formatDuration(timeSheet.timeLoggedExcludingBreaks, "0")}</td>
			<td>{formatDuration(getPaidHours(timeSheet))}</td>
			<ApprovedBy approvalDate={timeSheet.approvalDate} approvedBy={timeSheet.approvedBy ?? null} />
			<td>
				<div class="grid w-full flex spacing-2" style={{ gridTemplateColumns: "1fr max-content" }}>
					<Approval
						timeSheet={timeSheet}
						canApprove={tsPermissions.canApprove(timeSheet)}
						canUnApprove={tsPermissions.canUnApprove(timeSheet)}
					/>
					{tsPermissions.canDelete(timeSheet) && <DeleteTimeSheet timeSheetId={timeSheet.id} />}
				</div>
			</td>
		</tr>
	)
}

export default defineComponent(TimeSheetRow, {
	day: requiredProp(Object),
	entry: requiredProp(Object),
	setBreakOverride: requiredProp(Function),
	setClockOverride: requiredProp(Function),
	updateManagerNotes: requiredProp(Function),
	setSite: requiredProp(Function),
	setActivity: requiredProp(Function),
})
