import BootstrapButton, { type Props } from "@/components/BootstrapButton"
import { css } from "vite-css-in-js"
import { type ButtonHTMLAttributes, renderSlot } from "vue"
import type { Component } from "vue-utils"

const buttonStyles = css`
	font-size: 1.125rem;
	padding: 0.7rem 0 !important;
	border-radius: 1.25rem;
	width: 100%;
`

const LargeButton: Component<Props, ButtonHTMLAttributes> = (props, { slots }) => (
	<BootstrapButton class={buttonStyles} {...props}>
		{renderSlot(slots, "default")}
	</BootstrapButton>
)

export default LargeButton
