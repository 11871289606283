import StateLoading from "@/components/state-loading"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import { type CompanyHoliday, HolidayDayType, HolidayType, type Id } from "@/models"
import Permission from "@/models/Permission"
import { getHolidaySettings } from "@/services/holidaySettingsService"
import { arrayToMap } from "@/utils/arrayToMap"
import { formatDate, formatDuration, formatTime } from "@/utils/timeUtils"
import type { Duration, LocalDate, LocalTime } from "@js-joda/core"
import { reactive } from "vue"
import {
	defineComponent,
	ensureLoadingHasResult,
	type ReactiveComponent,
	ShadowContainer,
	useLoadableRef,
} from "vue-utils"
import createCrudTable from "../CrudTable"
import type { CompanyHolidayForm } from "./Form"
import FormContent from "./Form"
import { createCompanyHoliday, deleteCompanyHoliday, listCompanyHolidays } from "./service"

const CrudTable = createCrudTable<CompanyHoliday, CompanyHolidayForm>()

const CompanyHolidays: ReactiveComponent = () => {
	const user = useLoggedInUser()
	const holidaysLoadable = useLoadableRef(async () => {
		const holidays = await listCompanyHolidays()
		return reactive(arrayToMap(holidays))
	})
	const companyHolidays = () => ensureLoadingHasResult(holidaysLoadable)

	async function getDefaultValue(): Promise<CompanyHolidayForm> {
		const holidaySettings = await getHolidaySettings()
		return {
			id: 0,
			name: "",
			date: null,
			type: HolidayDayType.FullDay,
			startTime: null,
			endTime: null,
			breakDuration: null,
			siteId: holidaySettings.companyHolidaySiteId,
			activityId: holidaySettings.companyHolidayActivityId,
			deductFromAllowance: false,
		}
	}

	return () => (
		<StateLoading stores={[holidaysLoadable]}>
			<ShadowContainer>
				<CrudTable
					name="Company Holidays"
					getDefaultValue={getDefaultValue}
					mapValue={(holiday) => ({ ...holiday })}
					createText="Create Company Holiday"
					renderForm={FormContent}
					create={async (formData) => {
						const createdHoliday = await createCompanyHoliday({
							id: 0,
							name: formData.name,
							date: formData.date as LocalDate,
							siteId: formData.siteId as Id,
							activityId: formData.activityId as Id,
							startTime: formData.startTime as LocalTime,
							endTime: formData.endTime as LocalTime,
							breakDuration: formData.breakDuration as Duration,
							type: formData.type,
							isPaid: true,
							createdById: 0,
							deductFromAllowance: formData.deductFromAllowance,
							holidayType: HolidayType.Company,
						})
						companyHolidays().set(createdHoliday.id, createdHoliday)
					}}
					delete={async (holiday) => {
						await deleteCompanyHoliday(holiday.id)
						companyHolidays().delete(holiday.id)
					}}
					edit={() => {
						throw new Error("It is not possible to edit an existing holiday.")
					}}
					values={Array.from(companyHolidays().values()).sort((ch1, ch2) => ch1.date.compareTo(ch2.date))}
					columns={{
						name: {
							label: "Name",
						},
						date: {
							label: "Date",
							renderCell: ({ item: date }) => formatDate(date),
							sort: (d1, d2) => d1.compareTo(d2),
						},
						startTime: {
							label: "Start",
							renderCell: ({ item: time }) => formatTime(time),
							sort: (t1, t2) => t1.compareTo(t2),
						},
						endTime: {
							label: "End",
							renderCell: ({ item: time }) => formatTime(time),
							sort: (t1, t2) => t1.compareTo(t2),
						},
						breakDuration: {
							label: "Break",
							renderCell: ({ item: duration }) => formatDuration(duration),
							sort: (d1, d2) => d1.compareTo(d2),
						},
					}}
					disableEdit
					disableCreate={!user.hasPermission(Permission.CreateCompanyHoliday)}
				/>
			</ShadowContainer>
		</StateLoading>
	)
}

export default defineComponent(CompanyHolidays)
