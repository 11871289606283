import { useTrades } from "@/data/coreDataStore"
import type { Id, PlannerStaffEntry, Trade, UserMetadata } from "@/models"
import { SortDirection } from "@/models"
import { userComparator } from "@/services/usersService"

export enum TableSortBy {
	User = "user",
	Trade = "trade",
}

function compareTrades(user1: UserMetadata, user2: UserMetadata, trades: Map<Id, Trade>) {
	if (user1.tradeId === user2.tradeId) {
		return 0
	}

	const trade1 = user1.tradeId === null ? undefined : trades.get(user1.tradeId)
	const trade2 = user2.tradeId === null ? undefined : trades.get(user2.tradeId)

	if (trade1 === trade2) return 0
	else if (!trade1) return 1
	else if (!trade2) return -1
	else return trade1.name.localeCompare(trade2.name)
}

export function useTableSorting() {
	const trades = useTrades()

	function sortEntries(entries: PlannerStaffEntry[], by: TableSortBy, direction: SortDirection) {
		return [...entries].sort((e1, e2) => {
			let comparison =
				by === TableSortBy.User ? userComparator(e1.user, e2.user) : compareTrades(e1.user, e2.user, trades)
			if (direction === SortDirection.Descending) {
				comparison *= -1
			}
			return comparison
		})
	}

	return {
		sortEntries,
	}
}
