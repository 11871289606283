import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import usePopups from "@/data/popupsStore"
import { showErrorMessage } from "@/utils/composition/useLoading"
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons"
import { useMsal } from "msal-vue"
import { useRouter } from "vue-router"
import { Alert, defineComponent, type ReactiveComponent } from "vue-utils"
import { useKioskModeStore } from "../kiosk-mode/store"

const LogoutButton: ReactiveComponent = () => {
	const { instance } = useMsal()
	const router = useRouter()
	const popups = usePopups()
	const kioskStore = useKioskModeStore()

	async function logout() {
		const confirmation = await popups.confirm(() => (
			<Alert title="Confirm Logout">Are you sure you wish to log out of your account?</Alert>
		))
		if (!confirmation) {
			return
		}

		kioskStore.clearLocalStorage()
		localStorage.removeItem("planner-show-controls")
		localStorage.removeItem("additional-activities")

		try {
			await instance.logoutRedirect()
		} catch (e) {
			console.error(e)
			await showErrorMessage("Failed to log out", "Unable to log out. Try again later or contact an admin")
		}
		await router.replace("/")

		localStorage.clear()
		location.reload()
	}

	return () => (
		<BootstrapButton color="danger" onClick={() => void logout()}>
			<Icon icon={faArrowRightFromBracket} />
			<span>Log Out</span>
		</BootstrapButton>
	)
}

export default defineComponent(LogoutButton)
