import BootstrapButton from "@/components/BootstrapButton"
import { showErrorMessage } from "@/utils/composition/useLoading"
import { AuthError } from "@azure/msal-browser"
import { useMsal } from "msal-vue"
import type { ReactiveComponent } from "vue-utils"
import { defineComponent } from "vue-utils"

const LoginButton: ReactiveComponent = () => {
	const { instance } = useMsal()
	let loggingIn = $ref(false)

	const login = async () => {
		loggingIn = true

		try {
			await instance.loginRedirect()
		} catch (e) {
			if (e instanceof AuthError && e.errorCode === "user_cancelled") {
				return
			}
			console.error(e)
			await showErrorMessage("Failed to log in", "Unable to login. Try again later or contact an admin")
		} finally {
			loggingIn = false
		}
	}

	return () => (
		<BootstrapButton color="primary" isSubmitting={loggingIn} onClick={() => void login()}>
			Log In
		</BootstrapButton>
	)
}

export default defineComponent(LoginButton)
