import { useSystemConfig } from "@/data/systemConfigStore"
import L, { type Map } from "leaflet"
import { onMounted, onUnmounted, ref, shallowRef, type HTMLAttributes } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	initMap(map: Map, container: HTMLElement): void
}

const LeafletMap: ReactiveComponent<Props, HTMLAttributes> = (props, { attrs }) => {
	const mapContainerRef = ref<HTMLDivElement>()
	const systemConfig = useSystemConfig()

	const mapRef = shallowRef<Map | null>(null)
	const resizeObserver = new ResizeObserver((entries) => {
		if (!entries.every((entry) => entry.contentRect.width > 0 && entry.contentRect.height > 0)) {
			return
		}

		if (mapRef.value) {
			mapRef.value.invalidateSize()
		} else {
			initMap()
		}
	})

	function initMap() {
		const container = mapContainerRef.value as HTMLDivElement
		if (!container || mapRef.value) {
			return
		}

		const map = L.map(container)
		mapRef.value = map

		L.Icon.Default.imagePath = "/leaflet/"
		L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
			attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
		}).addTo(map)

		props.initMap(map, container)

		try {
			//Check if the map is initialized
			map.getBounds()
		} catch {
			map.setView([systemConfig.mapLocation.latitude, systemConfig.mapLocation.longitude], systemConfig.mapZoom)
		}
	}

	onMounted(() => {
		const container = mapContainerRef.value as HTMLDivElement
		resizeObserver.observe(container)
	})

	onUnmounted(() => {
		mapRef.value?.remove()
		resizeObserver.disconnect()
	})

	return () => <div ref={mapContainerRef} {...attrs} />
}

export default defineComponent(LeafletMap, {
	initMap: requiredProp(Function),
})
