import { BasicSelect, Required } from "@/components/basic-form"
import { useActivities, useActivity, useSite } from "@/data/coreDataStore"
import type { Activity, Site, TimeSheetEntry } from "@/models"
import { showErrorMessage } from "@/utils/composition/useLoading"
import { SiteListType, useSiteList } from "@/utils/composition/useSiteList"
import { css } from "vite-css-in-js"
import { shallowRef } from "vue"
import { defineComponent, refSetter, requiredProp, type ReactiveComponent } from "vue-utils"
import LargeButton from "../components/LargeButton"
import BasicScreen from "./BasicScreen"

interface Props {
	activeTimeSheet: TimeSheetEntry
	mode: "kiosk" | "self-service"

	back(): void
	switchSite(newSite: Site, newActivity: Activity): void
}

const containerStyles = css`
	flex-grow: 1;
	font-size: 1.125rem;
	max-width: 100%;

	display: flex;
	flex-direction: column;

	label {
		margin-top: 1.5rem;
		font-size: 1.2rem;
	}

	h4 {
		margin-top: 2em;
		margin-bottom: 0;
		font-weight: normal;
		font-size: 1.1em;
	}
`

const infoStyles = css`
	display: grid;
	grid-template-columns: max-content 1fr;
	width: 100%;
	margin-top: 2rem;
	column-gap: 1rem;

	& > div:nth-child(odd) {
		font-weight: bold;
	}
`

const SwitchSite: ReactiveComponent<Props> = (props) => {
	const activity = shallowRef<Activity | null>(null)
	const allActivities = useActivities()

	const activities = $computed(() =>
		Array.from(allActivities.values()).filter(
			(activity) => activity.canClockInWith && (props.mode === "self-service" || !activity.minimumViewRole)
		)
	)

	const site = shallowRef<Site | null>(null)
	const sites = useSiteList(SiteListType.NonArchived)

	const currentSite = $(useSite(() => props.activeTimeSheet.siteId))
	const currentActivity = $(useActivity(() => props.activeTimeSheet.activityId))

	const handleSwitch = () => {
		if (!activity.value) {
			void showErrorMessage("No Activity Selected", "Please select an activity")
			return
		}
		if (!site.value) {
			void showErrorMessage("No Site Selected", "Please select a site")
			return
		}
		props.switchSite(site.value, activity.value)
	}

	return () => (
		<BasicScreen title="Switch Site or Activity">
			<div class={infoStyles}>
				<div>Current Site:</div>
				<div>{currentSite?.name ?? "-"}</div>

				<div>Current Activity:</div>
				<div>{currentActivity?.name ?? "-"}</div>
			</div>

			<div class={containerStyles}>
				<h4>Please select the Site & Activity you are switching to:</h4>

				<label>
					<Required label="New Site" />
					<BasicSelect
						options={sites.value}
						value={site.value}
						setValue={refSetter(site)}
						defaultText="Select a Site"
					/>
				</label>

				<label>
					<Required label="New Activity" />
					<BasicSelect
						options={activities}
						value={activity.value}
						setValue={refSetter(activity)}
						defaultText="Select an Activity"
					/>
				</label>

				<div class="flex justify-center items-center flex-auto spacing-4">
					<LargeButton color="secondary" onClick={props.back}>
						Back
					</LargeButton>
					<LargeButton color="primary" disabled={!site.value || !activity.value} onClick={() => void handleSwitch()}>
						Switch
					</LargeButton>
				</div>
			</div>
		</BasicScreen>
	)
}

export default defineComponent(SwitchSite, {
	activeTimeSheet: requiredProp(Object),
	mode: requiredProp(String),

	back: requiredProp(Function),
	switchSite: requiredProp(Function),
})
