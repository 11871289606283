import BootstrapButton from "@/components/BootstrapButton"
import type { Nullable, CoordinateRadius } from "@/models"
import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, useOnInputNumber, type ReactiveComponent } from "vue-utils"

interface Props {
	location: Nullable<CoordinateRadius>
	hasExisting: boolean
	setLocation(location: Nullable<CoordinateRadius>): void
}

const messageStyles = css`
	display: flex;
	align-items: center;
	margin: 0;
	height: 4.5rem;
`

const controlsStyles = css`
	display: flex;
	align-items: flex-end;
	margin-bottom: 1rem;
	gap: 1rem;
	height: 3.5rem;
`

const LocationControls: ReactiveComponent<Props> = (props) => {
	const { location } = $(props)

	const setValue = <K extends keyof CoordinateRadius>(key: K, value: number) => {
		if (location && Number.isFinite(value) && !Number.isNaN(value)) {
			props.setLocation({
				...location,
				[key]: value,
			})
		}
	}

	const renderControls = (loc: CoordinateRadius) => (
		<div class={controlsStyles}>
			<label>
				Latitude
				<input
					type="number"
					min={-90}
					max={90}
					value={loc.latitude.toFixed(5)}
					onInput={useOnInputNumber((latitude) => setValue("latitude", latitude))}
					step="any"
				/>
			</label>
			<label>
				Longitude
				<input
					type="number"
					min={-180}
					max={180}
					value={loc.longitude.toFixed(5)}
					onInput={useOnInputNumber((longitude) => setValue("longitude", longitude))}
					step="any"
				/>
			</label>
			<label>
				Site Radius (metres)
				<input
					type="number"
					min={50}
					max={15000}
					value={loc.radiusMetres}
					onInput={useOnInputNumber((newRadius) => setValue("radiusMetres", newRadius))}
					step="25"
				/>
			</label>
			<BootstrapButton color="danger" style={{ marginLeft: "auto" }} onClick={() => props.setLocation(null)}>
				Remove Location
			</BootstrapButton>
		</div>
	)

	return () =>
		location ? (
			renderControls(location)
		) : (
			<p class={messageStyles}>
				{props.hasExisting
					? "You are removing the location for this site. Click a point on the map to set a new position, or click Save to apply your changes."
					: "There is no location defined for this site. Pick a point on the map to set the location"}
			</p>
		)
}

export default defineComponent(LocationControls, {
	location: requiredProp(Object, null),
	hasExisting: requiredProp(Boolean),
	setLocation: requiredProp(Function),
})
