import Icon from "@/components/Icon"
import { faExclamationCircle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	messages: string[]
	type: "warning" | "error"
}

const listStyles = css`
	list-style: none;
	padding-left: 0.5rem;
	font-weight: 600;

	display: grid;
	grid-template-columns: max-content auto;
	gap: 0.5rem 0.5rem;
	align-items: center;

	& > li {
		display: contents;
	}

	svg {
		font-size: 1.35rem;
	}

	&[data-type="warning"] svg {
		color: var(--color-warning);
	}
	&[data-type="error"] svg {
		color: var(--color-danger);
	}
`

const HolidayWarnings: ReactiveComponent<Props> = (props) => {
	return () => (
		<ul class={listStyles} data-type={props.type}>
			{props.messages.map((message, i) => (
				<li key={i}>
					<Icon icon={props.type === "error" ? faExclamationCircle : faTriangleExclamation} />
					<span>{message}</span>
				</li>
			))}
		</ul>
	)
}

export default defineComponent(HolidayWarnings, {
	messages: requiredProp(Array),
	type: requiredProp(String),
})
