import useLoggedInUserStore from "@/data/loggedInUserStore"
import LoginNotEnabled from "@/pages/LoginNotEnabled"
import { renderSlot } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const ValidateSession: ReactiveComponent = (_, { slots }) => {
	const store = useLoggedInUserStore()
	const isValid = $computed(
		() => store.type === "done" && store.result !== null && store.result.loginEnabled && !store.result.isArchived
	)

	return () => (isValid ? renderSlot(slots, "default") : <LoginNotEnabled />)
}

export default defineComponent(ValidateSession)
