import usePopups from "@/data/popupsStore"
import { Alert, ConfirmResult } from "vue-utils"

export async function verifyRejectRequest(comments: string) {
	const popups = usePopups()

	if (comments.trim().length === 0) {
		await popups.showAlertPopup(() => (
			<Alert title="Please provide a reason">
				When rejecting a holiday request, you must provide a reason in the comments.
			</Alert>
		))
		return false
	}
	const result = await popups.showConfirmPopup(() => (
		<Alert title="Confirm rejection of request">
			Are you sure you wish to reject this holiday request? The staff member will be notified, and your comments will be
			left as a reason for the rejection.
		</Alert>
	))
	return result === ConfirmResult.Confirm
}

export async function verifyApproveRequest() {
	const popups = usePopups()

	const result = await popups.showConfirmPopup(() => (
		<Alert title="Confirm Approval">
			<p>Please confirm the specified site, activity, and times are correct.</p>
			<p>
				TimeLord will now generate time sheets, and the staff member will be notified their request has been approved,
				including any comments you have left.
			</p>
		</Alert>
	))
	return result === ConfirmResult.Confirm
}
