import { Required } from "@/components/basic-form"
import { useCoreDataValue } from "@/data/coreDataStore"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import { UserRole, compareRoles, type User } from "@/models"
import Permission from "@/models/Permission"
import { formatEnum } from "@/utils/formatEnum"
import { proxyProp } from "@/utils/proxyProp"
import { css } from "vite-css-in-js"
import { ToggleButton, defineComponent, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"

const gridStyles = css`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(min(100%, 13rem), 1fr));
	gap: 2rem;

	button {
		margin-top: 0.2rem;
	}
`

interface Props {
	user: User
	readonly: boolean
}

const BasicDetails: ReactiveComponent<Props> = (props) => {
	const user = proxyProp(props, "user")

	const loggedInUser = useLoggedInUser()
	const userTypes = useCoreDataValue("userTypes")
	const trades = useCoreDataValue("trades")

	const userRoles = Object.values(UserRole)
	const canSelectRole = (role: UserRole) => compareRoles(loggedInUser.role, role) >= 0

	const canEditLogin = $computed(() => loggedInUser.hasPermission(Permission.EditLogin))

	return () => (
		<div class="flex flex-col spacing-6 h-full">
			<div class={gridStyles}>
				<label>
					<Required label="Forename" />
					<input v-model={user.firstName} required disabled={props.readonly} />
				</label>
				<label>
					<Required label="Surname" />
					<input v-model={user.lastName} required disabled={props.readonly} />
				</label>
				<label>
					Email
					<input v-model={user.email} type="email" disabled={props.readonly || !canEditLogin} />
				</label>
				<label>
					Personal Email
					<input v-model={user.personalEmail} type="email" disabled={props.readonly} />
				</label>
				<label>
					Payroll Id
					<input v-model={user.payrollId} type="text" disabled={props.readonly} />
				</label>
				<label>
					<Required label="Role" />
					<select v-model={user.role} required disabled={props.readonly || user.id === loggedInUser.id}>
						{userRoles.map((r) => (
							<option key={r} value={r} disabled={!canSelectRole(r)}>
								{formatEnum(r)}
							</option>
						))}
					</select>
				</label>
				<label>
					Trade
					<select
						value={user.tradeId?.toString() ?? "none"}
						onInput={useOnInput((selected) => (user.tradeId = selected === "none" ? null : Number.parseInt(selected)))}
						disabled={props.readonly}
					>
						<option value="none">-</option>
						{Array.from(trades.values()).map((trade) => (
							<option key={trade.id} value={trade.id}>
								{trade.name}
							</option>
						))}
					</select>
				</label>
				<label>
					<Required label="User Type" />
					<select
						required
						value={user.userTypeId}
						onInput={useOnInput((selected) => (user.userTypeId = Number.parseInt(selected)))}
						disabled={props.readonly}
					>
						{Array.from(userTypes.values()).map((type) => (
							<option key={type.id} value={type.id}>
								{type.name}
							</option>
						))}
					</select>
				</label>
				<label>
					PIN
					<input v-model={user.pin} disabled={props.readonly} />
				</label>
				<label>
					Has Special Arrangement
					<ToggleButton
						toggled={user.specialArrangement}
						setToggled={(value) => (user.specialArrangement = value)}
						disabled={props.readonly}
					/>
				</label>
				<label>
					Kiosk Enabled
					<ToggleButton
						toggled={user.kioskEnabled}
						setToggled={(value) => (user.kioskEnabled = value)}
						disabled={props.readonly}
					/>
				</label>
				<label>
					Login Enabled
					<ToggleButton
						toggled={user.loginEnabled}
						setToggled={(value) => (user.loginEnabled = value)}
						disabled={props.readonly}
					/>
				</label>
				<label>
					Apply Geo Fencing
					<ToggleButton
						toggled={user.applyGeoFencing}
						setToggled={(value) => (user.applyGeoFencing = value)}
						disabled={props.readonly}
					/>
				</label>
				<label>
					Hide on Time Sheets
					<ToggleButton
						toggled={user.hideOnTimeSheets}
						setToggled={(value) => (user.hideOnTimeSheets = value)}
						disabled={props.readonly}
					/>
				</label>
				<label>
					Hide on Planner
					<ToggleButton
						toggled={user.hideOnPlanner}
						setToggled={(value) => (user.hideOnPlanner = value)}
						disabled={props.readonly}
					/>
				</label>
			</div>

			<label class="flex-auto">
				Notes
				<textarea class="flex-auto" v-model={user.notes} disabled={props.readonly} rows={5} />
			</label>
		</div>
	)
}

export default defineComponent(BasicDetails, {
	user: requiredProp(Object),
	readonly: requiredProp(Boolean),
})
