import AuthorizePermission from "@/authentication/AuthorizePermission"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import type { LoggedInUser } from "@/models/LoggedInUser"
import type Permission from "@/models/Permission"
import { computed } from "vue"
import {
	defineComponent,
	optionalProp,
	propsWithDefaults,
	requiredProp,
	type FormTab,
	type ReactiveComponent,
} from "vue-utils"
import RouterTabs from "./RouterTabs"

export interface PermissionTab extends FormTab {
	permission?: Permission | ((user: LoggedInUser) => boolean)
}

interface Props {
	tabs: PermissionTab[]

	type?: "hash" | "query"

	/**
	 * If only a single tab is available, the tabs will be hidden and only the content will be displayed.
	 * Default false
	 */
	hideWithSingle?: boolean
}

const PermissionTabs: ReactiveComponent<Props> = (initialProps) => {
	const props = propsWithDefaults(initialProps, {
		hideWithSingle: false,
	})

	const user = useLoggedInUser()
	const availableTabs = computed(() =>
		props.tabs.filter((tab) => {
			switch (typeof tab.permission) {
				case "undefined":
					return true
				case "string":
					return user.hasPermission(tab.permission)
				case "function":
					return tab.permission(user)
				default:
					throw new Error("Invalid permission check")
			}
		})
	)

	return () => {
		if (availableTabs.value.length === 1 && props.hideWithSingle) {
			const TabContent = availableTabs.value[0].renderContent
			if (TabContent) {
				return <TabContent />
			}
		}
		if (availableTabs.value.length === 0) {
			return <AuthorizePermission permissions={[]} />
		}
		return <RouterTabs tabs={availableTabs.value} type={props.type} />
	}
}

export default defineComponent(PermissionTabs, {
	type: optionalProp(String),
	tabs: requiredProp(Array),
	hideWithSingle: optionalProp(Boolean),
})
