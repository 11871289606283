import type { Trade } from "@/models"
import { renderSlot } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import TradeBlob from "./TradeBlob"

interface Props {
	trade: Trade | null
}

const TradeInformation: ReactiveComponent<Props> = (props, { slots }) => {
	return () => (
		<div class="inline-flex items-baseline spacing-2">
			{!!props.trade && <TradeBlob trade={props.trade} />} {renderSlot(slots, "default")}
		</div>
	)
}

export default defineComponent(TradeInformation, {
	trade: requiredProp(Object, null),
})
