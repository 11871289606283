import type { ReactiveComponent } from "vue-utils"
import { defineComponent } from "vue-utils"
import BasicPage from "./BasicPage"

const LoginNotEnabledPage: ReactiveComponent = () => () => (
	<BasicPage title="Login Disabled" noNav>
		<div>Your account has not been enabled. Please contact an administrator if you believe this is an error</div>
		<br />
	</BasicPage>
)

export default defineComponent(LoginNotEnabledPage)
