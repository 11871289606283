import { SortDirection, type PlannerStaffEntry } from "@/models"
import { LocalDate, Month } from "@js-joda/core"
import { useLocalStorage } from "@vueuse/core"
import { defineStore } from "pinia"
import type { Ref } from "vue"
import { loadLoadable, piniaLoadingState, toLoadable, type Loading } from "vue-utils"
import type { HolidaysPlannerControls } from "./requests"
import { queryHolidayPlannerResults } from "./requests"
import { TableSortBy } from "./tableSorting"

type State = {
	month: Month
	year: number
	sortBy: TableSortBy
	sortDirection: SortDirection
	showControls: Ref<boolean>
	userSearch: string
	data: Loading<PlannerStaffEntry[]>
} & HolidaysPlannerControls

const useHolidaysPlannerStore = defineStore("holidays-planner", {
	state(): State {
		return {
			month: LocalDate.now().month(),
			year: LocalDate.now().year(),
			includePendingHolidays: true,
			hideStaffWithoutHoliday: false,
			additionalActivities: useLocalStorage<number[]>("additional-activities", []) as unknown as number[],
			sortDirection: SortDirection.Ascending,
			sortBy: TableSortBy.User,
			showControls: useLocalStorage("planner-show-controls", false),
			userSearch: "",
			data: piniaLoadingState(),
		}
	},
	actions: {
		updateControl<Key extends keyof HolidaysPlannerControls>(key: Key, value: HolidaysPlannerControls[Key]) {
			const controls = this as HolidaysPlannerControls
			controls[key] = value
			void this.reloadData()
		},
		async queryData(): Promise<PlannerStaffEntry[]> {
			return await queryHolidayPlannerResults({
				month: this.month.value(),
				year: this.year,
				hideStaffWithoutHoliday: this.hideStaffWithoutHoliday,
				additionalActivities: this.additionalActivities,
				includePendingHolidays: this.includePendingHolidays,
			})
		},
		async reloadData() {
			await loadLoadable(toLoadable(this.data, () => this.queryData()))
		},
		nextMonth() {
			if (this.month.equals(Month.DECEMBER)) {
				this.month = Month.JANUARY
				this.year++
			} else {
				this.month = this.month.plus(1)
			}
			void this.reloadData()
		},
		previousMonth() {
			if (this.month.equals(Month.JANUARY)) {
				this.month = Month.DECEMBER
				this.year--
			} else {
				this.month = this.month.plus(-1)
			}
			void this.reloadData()
		},
		setDate(month: Month, year: number) {
			this.month = month
			this.year = year
			void this.reloadData()
		},
	},
})

export default useHolidaysPlannerStore
