import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import InfoMessage from "@/components/InfoMessage"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import Permission from "@/models/Permission"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { defineComponent, useStoreValue, type ReactiveComponent } from "vue-utils"
import useTimeSheetManagementStore from "../store"
import TimeSheetTable from "./TimeSheetTable"
import Heading from "./components/Heading"
import PrintTimeSheets from "./components/PrintTimeSheets"
import "./styles.scss"

const TimeSheetView: ReactiveComponent = () => {
	const store = useTimeSheetManagementStore()
	const entries = $computed(() => store.weekTimeSheets)
	const selectedUser = $(useStoreValue(store, "selectedUser"))

	const loggedInUser = useLoggedInUser()

	let addingRow = $ref(false)

	const renderAddRowButton = () => {
		if (addingRow || !selectedUser || !loggedInUser.hasPermission(Permission.CreateTimeSheets)) {
			return null
		}
		return (
			<div style="margin-top: 1rem">
				<BootstrapButton color="success" plain onClick={() => (addingRow = true)}>
					<Icon icon={faPlus} />
					Add Time Sheet Entry
				</BootstrapButton>
			</div>
		)
	}

	return () =>
		selectedUser ? (
			<>
				<Heading
					user={selectedUser}
					hasSpecialArrangement={selectedUser.specialArrangement}
					weekStarting={store.startOfWeek.toLocalDate()}
				/>
				{entries.length === 0 && !addingRow ? (
					<div class="flex flex-col w-full items-center flex-auto" style="justify-content: space-evenly;">
						<div class="flex flex-col items-center">
							<InfoMessage message="The user does not have any time sheets for that day" />
							{renderAddRowButton()}
						</div>
						<div />
					</div>
				) : (
					<>
						<TimeSheetTable
							key={selectedUser.id}
							addingNewRow={addingRow}
							setAddingRow={(adding) => (addingRow = adding)}
						/>
						{renderAddRowButton()}
					</>
				)}
			</>
		) : (
			<div class="flex flex-col w-full h-full">
				<PrintTimeSheets />
				<div class="flex flex-col flex-1 justify-center items-center" style="justify-content: space-evenly">
					<InfoMessage message="Please Select a User to View a Time Sheet" />
					<div />
				</div>
				<div />
			</div>
		)
}

export default defineComponent(TimeSheetView)
