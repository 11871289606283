import { BasicSelect } from "@/components/basic-form"
import { useActivities, useActivity } from "@/data/coreDataStore"
import type { Activity, ActivityOverride } from "@/models"
import { ToggleButton, defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import type { InlineEditFormProps } from "../InlineEditList"

const OverrideForm: ReactiveComponent<InlineEditFormProps<ActivityOverride, Activity>> = (props) => {
	const { activityId, addsToPaidHours, remainingOptions, mode, setData, readonly = false } = $(props)
	const activity = $(useActivity(() => activityId))
	const activities = useActivities()

	return () => (
		<>
			<BasicSelect
				options={mode === "create" ? remainingOptions : Array.from(activities.values())}
				value={activity}
				setValue={(newActivity) => setData("activityId", newActivity.id)}
				disabled={readonly || mode === "edit"}
				required
			/>
			<ToggleButton
				toggled={addsToPaidHours}
				setToggled={(value) => setData("addsToPaidHours", value)}
				disabled={readonly}
			/>
		</>
	)
}

export default defineComponent(OverrideForm, {
	activityId: requiredProp(Number),
	addsToPaidHours: requiredProp(Boolean),
	remainingOptions: requiredProp(Array),
	mode: requiredProp(String),
	setData: requiredProp(Function),
	readonly: optionalProp(Boolean),
})
