import StateLoading from "@/components/state-loading"
import { listUsers } from "@/services/usersService"
import { arrayToMap } from "@/utils/arrayToMap"
import { watchImmediate } from "@vueuse/core"
import { reactive } from "vue"
import {
	RefQueries,
	defineComponent,
	ensureLoadingHasResult,
	loadLoadable,
	refSetter,
	useLoadableRef,
	useQueryRef,
	type ReactiveComponent,
} from "vue-utils"
import UsersTable from "./UsersTable"

const UsersPage: ReactiveComponent = () => {
	const showArchived = useQueryRef("show-archived", false, RefQueries.Boolean)
	const usersLoadable = useLoadableRef(async () => {
		const users = await listUsers(showArchived.value)
		return reactive(arrayToMap(users))
	})

	watchImmediate(showArchived, () => void loadLoadable(usersLoadable))

	return () => (
		<StateLoading stores={[usersLoadable]}>
			<UsersTable
				users={ensureLoadingHasResult(usersLoadable)}
				showArchived={showArchived.value}
				setShowArchived={refSetter(showArchived)}
			/>
		</StateLoading>
	)
}

export default defineComponent(UsersPage)
