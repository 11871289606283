import AuthorizePermission from "@/authentication/AuthorizePermission"
import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import PlainIconButton from "@/components/PlainIconButton"
import StateLoading from "@/components/state-loading"
import usePopups from "@/data/popupsStore"
import type { HolidayRequest, UserMetadata } from "@/models"
import Permission from "@/models/Permission"
import { faChevronLeft, faPlus, faSync } from "@fortawesome/free-solid-svg-icons"
import { toRef, watch } from "vue"
import { useRouter } from "vue-router"
import {
	Alert,
	defineComponent,
	ensureLoadingHasResult,
	loadLoadable,
	optionalProp,
	requiredProp,
	useLoadableRef,
	type ReactiveComponent,
} from "vue-utils"
import {
	createHolidayRequest,
	formatAllowance,
	getHolidayRequestContext,
	getHolidayRequests,
	getRemainingAllowance,
} from "../service"
import FilterableRequestList from "./FilterableRequestList"
import NewRequestForm from "./new-request"

interface Props {
	user: UserMetadata
	back?: () => void
}

const MyHolidays: ReactiveComponent<Props> = (props) => {
	const router = useRouter()
	const popups = usePopups()
	const remainingAllowanceLoadable = useLoadableRef(() =>
		getRemainingAllowance({
			userId: props.user.id,
		})
	)

	const requestsLoadable = useLoadableRef(() => getHolidayRequests(props.user.id))
	const contextLoadable = useLoadableRef(() => getHolidayRequestContext(props.user.id))

	watch(toRef(props, "user"), () => {
		void loadLoadable(requestsLoadable)
		void loadLoadable(contextLoadable)
	})

	const showForm = $computed(() => {
		return router.currentRoute.value.hash === "#create-request"
	})

	function openForm() {
		void loadLoadable(contextLoadable)
		void router.push({
			...router.currentRoute.value,
			hash: `#create-request`,
		})
	}
	function closeForm() {
		void router.push({
			...router.currentRoute.value,
			hash: undefined,
		})
	}

	async function submitRequest(request: HolidayRequest) {
		const submittedRequest = await createHolidayRequest(request)

		void (async () => {
			await popups.showAlertPopup(() => (
				<Alert title="Request Submitted">Your holiday request was successfully submitted for approval</Alert>
			))
			if (requestsLoadable.type === "done") {
				requestsLoadable.result = [...requestsLoadable.result, submittedRequest]
			}
			closeForm()
		})()
	}

	return () =>
		showForm ? (
			<AuthorizePermission permissions={[Permission.RequestHoliday]}>
				<StateLoading stores={[requestsLoadable, contextLoadable]}>
					<NewRequestForm
						userId={props.user.id}
						onSubmit={submitRequest}
						cancel={closeForm}
						context={ensureLoadingHasResult(contextLoadable)}
					/>
				</StateLoading>
			</AuthorizePermission>
		) : (
			<>
				<div class="flex items-center spacing-2" style={{ marginTop: "1rem" }}>
					<h2 class="flex items-center flex-1" style={{ margin: 0 }}>
						My Holiday Requests
					</h2>
					<PlainIconButton
						icon={faSync}
						title="Refresh"
						onClick={() => {
							void loadLoadable(requestsLoadable)
							void loadLoadable(remainingAllowanceLoadable)
						}}
						disabled={requestsLoadable.type !== "done"}
					/>
					{!!props.back && (
						<BootstrapButton color="secondary" onClick={props.back}>
							<Icon icon={faChevronLeft} />
							Back
						</BootstrapButton>
					)}
				</div>

				<hr />
				<StateLoading stores={[requestsLoadable, remainingAllowanceLoadable]}>
					<p>
						<strong>Holiday Remaining:</strong> {formatAllowance(ensureLoadingHasResult(remainingAllowanceLoadable))}
					</p>
					<BootstrapButton color="success" onClick={openForm}>
						<Icon icon={faPlus} />
						Create New Request
					</BootstrapButton>
					<FilterableRequestList requests={ensureLoadingHasResult(requestsLoadable)} />
				</StateLoading>
			</>
		)
}

export default defineComponent(MyHolidays, {
	user: requiredProp(Object),
	back: optionalProp(Function),
})
