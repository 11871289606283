import StandardForm from "@/components/StandardForm"

import { ConfirmResult, createPopupsStore, type AlertPopup, type ConfirmPopup, type Popup } from "vue-utils"
import { renderSlot } from "vue"

export type { Popup, ConfirmPopup, AlertPopup }
export { ConfirmResult }

const usePopups = createPopupsStore({
	id: "popups",
	state: () => ({
		popups: [],
	}),
	AlertPopup: ({ okText, onClose }, { slots }) => (
		<StandardForm submit={{ text: okText, onClick: onClose }} showDivider>
			{renderSlot(slots, "default")}
		</StandardForm>
	),
	ConfirmPopup: ({ confirmText, cancelText, onSubmit, onCancel }, { slots }) => (
		<StandardForm
			cancel={{
				onClick: onCancel,
				text: cancelText,
			}}
			submit={{
				onClick: onSubmit,
				text: confirmText,
			}}
			onSubmit={onSubmit}
			showDivider
		>
			{renderSlot(slots, "default")}
		</StandardForm>
	),
})

export default usePopups
