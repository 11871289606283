import { useTrade, useUserType } from "@/data/coreDataStore"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import { formatEnum } from "@/utils/formatEnum"
import { css } from "vite-css-in-js"
import { ShadowContainer, defineComponent, type ReactiveComponent } from "vue-utils"
import LogoutButton from "./LogoutButton"

const gridStyles = css`
	* {
		text-align: left;
	}
	th {
		font-weight: 600;
		padding-right: 1rem;
	}
	td {
		font-weight: normal;
	}
`

const UserInformation: ReactiveComponent = () => {
	const user = useLoggedInUser()
	const trade = $(useTrade(() => user.tradeId))
	const userType = $(useUserType(() => user.userTypeId))

	return () => (
		<ShadowContainer>
			<h3>Profile Information</h3>
			<hr />
			<table class={gridStyles}>
				<tbody>
					<tr>
						<th>Name:</th>
						<td>
							{user.firstName} {user.lastName}
						</td>
					</tr>
					<tr>
						<th>Role:</th>
						<td>{formatEnum(user.role)}</td>
					</tr>
					<tr>
						<th>Trade:</th>
						<td>{trade?.name ?? "None"}</td>
					</tr>
					<tr>
						<th>User Type:</th>
						<td>{userType?.name ?? "None"}</td>
					</tr>
				</tbody>
			</table>
			<br />
			<LogoutButton />
		</ShadowContainer>
	)
}

export default defineComponent(UserInformation)
