import SortableHeading from "@/components/table/SortableHeading"
import type { SortDirection } from "@/models"
import type { ThHTMLAttributes } from "vue"
import { defineComponent, optionalProp, type ReactiveComponent, requiredProp } from "vue-utils"
import type { TimeSheetOrderBy } from "../model"

interface Props {
	text: string
	orderBy: TimeSheetOrderBy
	direction: SortDirection
	field: TimeSheetOrderBy
	setSorting(field: TimeSheetOrderBy, direction: SortDirection): void
	defaultDirection?: SortDirection
}

const SortOrderHeader: ReactiveComponent<Props, ThHTMLAttributes> = (props, { attrs }) => {
	return () => (
		<SortableHeading
			direction={props.orderBy === props.field ? props.direction : null}
			setDirection={(direction) => props.setSorting(props.field, direction)}
			defaultDirection={props.defaultDirection}
			{...attrs}
		>
			{props.text}
		</SortableHeading>
	)
}

export default defineComponent(SortOrderHeader, {
	text: requiredProp(String),
	orderBy: requiredProp(String),
	direction: requiredProp(String),
	field: requiredProp(String),
	setSorting: requiredProp(Function),
	defaultDirection: optionalProp(String),
})
