import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import GridRow from "@/components/utility/GridRow"
import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import type { UnwrapRef } from "vue"
import { anyProp, defineComponent, optionalProp, requiredProp, type Component, type ReactiveComponent } from "vue-utils"
import type { RefValue } from "vue/macros"

interface Props<T, O> {
	entities: T[]
	remainingOptions: O[]
	getKey(entity: T): string | number
	Form: Component<InlineEditFormProps<T, O>>
	columnHeaders: string[]
	formGrid: string
	createData(option: O): T
	add(item: T): void
	edit(item: T): void
	remove(item: T): void

	addText: string
	noneText: string

	readonly?: boolean
}

export type InlineEditFormProps<T, O> = T & {
	setData<K extends keyof T>(key: K, value: T[K]): void
	remainingOptions: O[]
	mode: "edit" | "create"
	readonly?: boolean
}

const gridStyles = css`
	display: grid;
	align-items: center;
	column-gap: 2rem;
	row-gap: 1rem;

	margin-top: 1rem;
	margin-bottom: 1rem;

	tbody,
	thead,
	tr {
		display: contents;
	}
	th {
		text-align: left;
		font-weight: 600;
	}
`

const createInlineEditList = <T, O>(): Component<Props<T, O>> => {
	const InlineEditList: ReactiveComponent<Props<T, O>> = (props) => {
		const {
			entities,
			remainingOptions,
			getKey,
			columnHeaders,
			createData,
			formGrid,
			addText,
			noneText,
			readonly = false,
		} = $(props)
		let adding = $ref<T | null>(null)

		function handleAddClick() {
			if (remainingOptions.length === 0) {
				return
			}
			adding = createData(remainingOptions[0]) as RefValue<UnwrapRef<T>> | null
		}
		function handleCreate() {
			props.add(adding as T)
			adding = null
		}

		return () => (
			<div>
				{entities.length === 0 && adding === null && <p style="color: var(--color-info)">{noneText}</p>}

				{(entities.length > 0 || adding !== null) && (
					<table class={gridStyles} style={{ gridTemplateColumns: `${formGrid} calc(9rem + 12px)` }}>
						<thead>
							<tr>
								{columnHeaders.map((header, i) => (
									<th key={i}>{header}</th>
								))}
								<th />
							</tr>
						</thead>
						<tbody>
							{entities.map((entity) => (
								<tr key={getKey(entity)}>
									<props.Form
										{...entity}
										setData={(key, value) => props.edit({ ...entity, [key]: value })}
										remainingOptions={remainingOptions}
										mode="edit"
										readonly={readonly}
									/>
									<td>
										<div class="flex justify-end">
											<BootstrapButton color="danger" disabled={props.readonly} onClick={() => props.remove(entity)}>
												<Icon icon={faTrashCan} />
												Remove
											</BootstrapButton>
										</div>
									</td>
								</tr>
							))}
							{adding !== null && (
								<tr key="adding-form">
									<props.Form
										{...(adding as T)}
										setData={(key, value) => ((adding as T)[key] = value)}
										remainingOptions={remainingOptions}
										mode="create"
										readonly={readonly}
									/>
									<td>
										<GridRow elementCount={2}>
											<BootstrapButton color="secondary" onClick={() => (adding = null)}>
												Cancel
											</BootstrapButton>
											<BootstrapButton color="primary" onClick={handleCreate}>
												Add
											</BootstrapButton>
										</GridRow>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				)}

				{!readonly && remainingOptions.length !== 0 && adding === null && (
					<div>
						<BootstrapButton color="success" plain onClick={handleAddClick}>
							<Icon icon={faPlus} />
							{addText}
						</BootstrapButton>
					</div>
				)}
			</div>
		)
	}

	return defineComponent(InlineEditList, {
		entities: requiredProp(Array),
		remainingOptions: requiredProp(Array),
		getKey: requiredProp(Function),
		Form: anyProp(),
		columnHeaders: requiredProp(Array),
		formGrid: requiredProp(String),
		createData: requiredProp(Function),
		edit: requiredProp(Function),
		add: requiredProp(Function),
		remove: requiredProp(Function),

		addText: requiredProp(String),
		noneText: requiredProp(String),

		readonly: optionalProp(Boolean),
	})
}

export default createInlineEditList
