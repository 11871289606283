import { HolidayRequestStatus, type HolidayRequestFilter } from "@/models"
import { reactive } from "vue"
import { RefQueries, useQueryRef } from "vue-utils"

export const useHolidayRequestFilter = (): HolidayRequestFilter => {
	const userSearchRef = useQueryRef("UserSearch", "", RefQueries.String)
	const statusRef = useQueryRef("status", HolidayRequestStatus.Pending, RefQueries.nullableEnum(HolidayRequestStatus))
	const yearRef = useQueryRef("year", null, RefQueries.NullableInt)

	return reactive({
		userSearch: userSearchRef,
		status: statusRef,
		year: yearRef,
	})
}
