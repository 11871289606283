import { stringFilter, stringSort } from "@/components/data/DataTable"
import { useTrades } from "@/data/coreDataStore"
import { useLoggedInUser } from "@/data/loggedInUserStore"
import type { Trade } from "@/models"
import Permission from "@/models/Permission"
import { createTrade, deleteTrade, editTrade } from "@/services/tradesService"
import type { ReactiveComponent } from "vue-utils"
import { ShadowContainer, defineComponent } from "vue-utils"
import createCrudTable from "../CrudTable"
import FormContent from "./Form"

const CrudTable = createCrudTable<Trade, Trade>()

const Trades: ReactiveComponent = () => {
	const user = useLoggedInUser()
	const trades = useTrades()

	return () => (
		<ShadowContainer>
			<CrudTable
				name="Trades"
				getDefaultValue={() => ({
					id: 0,
					name: "",
					colour: "#ffffff",
					notes: "",
				})}
				mapValue={(trade) => ({
					...trade,
				})}
				createText="Create Trade"
				renderForm={FormContent}
				values={Array.from(trades.values()).sort((t1, t2) => t1.name.localeCompare(t2.name))}
				create={async (trade) => {
					const createdTrade = await createTrade(trade)
					trades.set(createdTrade.id, createdTrade)
				}}
				edit={async (trade, formData) => {
					const newTrade = await editTrade({
						...trade,
						...formData,
					})
					trades.set(newTrade.id, newTrade)
				}}
				delete={async (trade) => {
					await deleteTrade(trade.id)
					trades.delete(trade.id)
				}}
				columns={{
					name: {
						label: "Name",
						filter: stringFilter,
						sort: stringSort,
					},
					colour: {
						label: "Colour",
						renderCell: ({ item: colour }) => (
							<div
								style={{
									borderRadius: "0.5rem",
									backgroundColor: colour,
									height: "1rem",
									width: "7.5rem",
								}}
							/>
						),
					},
				}}
				disableEdit={!user.hasPermission(Permission.EditTrade)}
				disableCreate={!user.hasPermission(Permission.CreateTrade)}
			/>
		</ShadowContainer>
	)
}

export default defineComponent(Trades)
