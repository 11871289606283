import type { IIdentifier, INamed } from "@/models"
import { castUnsafe } from "@/utils/castUnsafe"
import { computed, type OptionHTMLAttributes, type SelectHTMLAttributes } from "vue"
import {
	anyProp,
	defineComponent,
	optionalProp,
	requiredProp,
	type Component,
	type ConsumedProps,
	type ReactiveComponent,
	type ReactiveProps,
} from "vue-utils"

type Props<T> = (AllowDefaultProps<T> | NotAllowedDefaultProps<T>) &
	// eslint-disable-next-line @typescript-eslint/ban-types
	(T extends (IIdentifier & INamed) | string | number ? {} : AnyObjectProps<T>)

type Attrs = Omit<SelectHTMLAttributes, "required" | "disabled" | "value">

interface AnyObjectProps<T> {
	getValue(item: T): string
	getText(item: T): string
}

interface CommonProps<T> {
	options: T[]
	value: T | string | number | null
	disabled?: boolean
	defaultText?: string
	defaultProps?: OptionHTMLAttributes
	optionProps?: (option: T) => OptionHTMLAttributes

	getValue?: (item: T) => string
	getText?: (item: T) => string
}

interface AllowDefaultProps<T> extends CommonProps<T> {
	setValue: (option: T) => void
	required: true
}

interface NotAllowedDefaultProps<T> extends CommonProps<T> {
	setValue: (option: T | null) => void
	required?: false
	defaultText: string
}

export const createSelect = <T,>(): Component<
	Props<T> & Omit<SelectHTMLAttributes, "required" | "disabled" | "value">
> => {
	const BasicSelect: ReactiveComponent<Props<T>, Attrs> = (props, { attrs }) => {
		const currentValue = computed(() => (props.value !== null ? getValue(props.value) : ""))

		function getValue(item: T | string | number): string {
			if (typeof item === "string") {
				return item
			}
			if (typeof item === "number") {
				return String(item)
			}
			if (props.getValue) {
				return props.getValue(item)
			}
			if (typeof item === "object" && item && "id" in item) {
				return (item as IIdentifier).id.toString()
			}
			return String(item)
		}

		function getText(item: T): string {
			if (props.getText) {
				return props.getText(item)
			}
			if (typeof item === "object" && item && "name" in item) {
				return (item as INamed).name
			}
			return String(item)
		}

		const handleOnInput = (event: Event) => {
			const value = (event.target as HTMLSelectElement).value
			const newValue =
				props.defaultText && value === "" ? null : props.options.find((option) => getValue(option) === value)

			if (newValue) {
				props.setValue(newValue)
			} else if (!props.required) {
				props.setValue(null as T)
			}
		}

		function renderOption(option: T) {
			const value = getValue(option)
			const extraProps = props.optionProps ? props.optionProps(option) : {}

			return (
				<option key={value} value={value} {...extraProps}>
					{getText(option)}
				</option>
			)
		}

		return () => (
			<select
				value={currentValue.value}
				onInput={handleOnInput}
				required={props.required}
				disabled={props.disabled}
				{...attrs}
			>
				{props.defaultText && (
					<option key="" value="" disabled={props.required} {...(props.defaultProps ?? {})}>
						{props.defaultText}
					</option>
				)}
				{props.options.map(renderOption)}
			</select>
		)
	}

	return defineComponent(BasicSelect, {
		options: requiredProp(Array),
		value: anyProp(),
		disabled: optionalProp(Boolean),
		defaultText: optionalProp(String),
		defaultProps: optionalProp(Object),
		selectProps: optionalProp(Object),
		optionProps: optionalProp(Function),
		getValue: optionalProp(Function),
		getText: optionalProp(Function),
		setValue: requiredProp(Function),
		required: optionalProp(Boolean),
	} as unknown as ReactiveProps<Props<T>>) as unknown as Component<
		Props<T> & Omit<SelectHTMLAttributes, "required" | "disabled" | "value">
	>
}

const BasicSelect = castUnsafe<
	<T>(props: ConsumedProps<Props<T>, Omit<SelectHTMLAttributes, "placeholder">>) => JSX.Element
>(createSelect())
export default BasicSelect
