import BootstrapButton from "@/components/BootstrapButton"
import Modal from "@/components/Modal"
import LeafletMap from "@/components/mapping/LeafletMap"
import SiteMarker from "@/components/mapping/SiteMarker"
import useLocationMarker from "@/components/mapping/useLocationMarker"
import type { CoordinateRadius } from "@/models"
import type { Map } from "leaflet"
import { ref, toRef } from "vue"
import { defineComponent, ModalTitle, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	userLocation: CoordinateRadius
	siteLocation?: CoordinateRadius

	close(): void
}

const UserLocationModal: ReactiveComponent<Props> = (props) => {
	const { userLocation: userLoc, siteLocation: siteLoc } = $(props)
	const mapRef = ref<Map>()

	function initMap(map: Map, container: HTMLElement) {
		mapRef.value = map

		if (siteLoc) {
			const distance = map.distance([userLoc.latitude, userLoc.longitude], [siteLoc.latitude, siteLoc.longitude])

			//Know the circles don't overlap, therefore adding the radius for both will give a maximum distance away between the two locations
			const maxDistance = distance + userLoc.radiusMetres + siteLoc.radiusMetres
			const centrePoint = {
				lat: (userLoc.latitude + siteLoc.latitude) / 2,
				lng: (userLoc.longitude + siteLoc.longitude) / 2,
			}

			const containerSize = Math.max(container.clientWidth, container.clientHeight)
			const zoomLevel = Math.floor(-Math.log2(maxDistance / (containerSize * 50_000)))

			map.setView(centrePoint, zoomLevel)
		} else {
			const containerSize = Math.min(container.clientWidth, container.clientHeight)
			const zoomLevel = Math.floor(-Math.log2(userLoc.radiusMetres / (containerSize * 50_000)))

			map.setView([userLoc.latitude, userLoc.longitude], zoomLevel)
		}
	}

	if (props.siteLocation) {
		const loc = props.siteLocation
		useLocationMarker({
			createMarker: () =>
				new SiteMarker({
					latitude: loc.latitude,
					longitude: loc.longitude,
					radius: loc.radiusMetres,
					tooltipHtml: "Site Location",
				}),
			locationRef: toRef(props, "siteLocation"),
			mapRef,
		})
	}

	useLocationMarker({
		createMarker: () =>
			new SiteMarker({
				latitude: props.userLocation.latitude,
				longitude: props.userLocation.longitude,
				radius: props.userLocation.radiusMetres,
				tooltipHtml: "User Location",
				hue: 150,
				radiusOptions: {
					color: "#d53c31",
				},
			}),
		locationRef: toRef(props, "userLocation"),
		mapRef,
	})

	return () => (
		<Modal class="flex flex-col" style={{ width: "80%", height: "80%", margin: "2rem" }}>
			<ModalTitle title="User Location" />
			<hr />
			<LeafletMap class="flex-1" initMap={initMap} />
			<div class="flex justify-end">
				<BootstrapButton color="secondary" onClick={props.close} style={{ marginTop: "1rem" }}>
					Close
				</BootstrapButton>
			</div>
		</Modal>
	)
}

export default defineComponent(UserLocationModal, {
	userLocation: requiredProp(Object),
	siteLocation: optionalProp(Object),
	close: requiredProp(Function),
})
