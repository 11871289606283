import BootstrapButton from "@/components/BootstrapButton"
import Icon from "@/components/Icon"
import usePopups, { ConfirmResult } from "@/data/popupsStore"
import type { Id } from "@/models"
import { useLoading } from "@/utils/composition/useLoading"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { Alert, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import useTimeSheetManagementStore from "../../store"

interface Props {
	timeSheetId: Id
}

const DeleteTimeSheet: ReactiveComponent<Props> = (props) => {
	const popups = usePopups()
	const store = useTimeSheetManagementStore()
	const { runAction } = useLoading()

	async function handleDelete() {
		const result = await popups.showConfirmPopup(() => (
			<Alert title="Confirm deletion of time sheet entry">
				<p>Are you sure you wish to delete this time sheet?</p>
				<p>
					The time sheet will be <strong>permanently deleted</strong> and <strong>cannot be recovered</strong>.
				</p>
			</Alert>
		))

		if (result !== ConfirmResult.Confirm) {
			return
		}

		await runAction(store.deleteTimeSheet(props.timeSheetId))
	}

	return () => (
		<BootstrapButton color="danger" onClick={() => void handleDelete()}>
			<Icon icon={faTrash} />
		</BootstrapButton>
	)
}

export default defineComponent(DeleteTimeSheet, {
	timeSheetId: requiredProp(Number),
})
