import { computed, ref } from "vue"
import { LoadingError, downloadFile as downloadFileRes, findFileNameFromHeaders } from "vue-utils"
import { showErrorMessage } from "./useLoading"

export function useFileDownload() {
	const downloadCount = ref(0)
	const isDownloading = computed(() => downloadCount.value > 0)

	async function downloadFile(responsePromise: Promise<Response>, fileName?: string) {
		try {
			downloadCount.value++
			const response = await responsePromise
			await downloadFileRes(response, fileName ?? findFileNameFromHeaders(response.headers) ?? "")
		} catch (e) {
			if (e instanceof LoadingError) {
				void showErrorMessage(e.title, e.message)
			} else {
				void showErrorMessage(
					"Failed to generate report",
					"An unexpected error occurred. Try again or contact an administrator"
				)
				console.error(e)
			}
			throw e
		} finally {
			downloadCount.value--
		}
	}

	return {
		downloadCount,
		isDownloading,
		downloadFile,
	}
}
